import {
  H6Data,
  h6Part1DataSchema,
  H6Part1DataSchema,
  h6Part2DataSchema,
  H6Part2DataSchema,
} from "../schemas/mhaForms/hospital/h6";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const h6Part1Template: FormPartTemplate<H6Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of the hospital in which the patient is liable to be detained",
    },
    {
      type: "readonly",
      label: "Patient name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "date",
      field: "lastExaminedDate",
      label: "Enter date of examination",
      maximum: (today) => today,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) This patient was absent without leave from hospital or the place where the patient ought to have been beginning on the following date.",
      ],
    },
    {
      type: "date",
      field: "absenceWithoutLeaveDate",
      label: "Enter date absence without leave began",
      maximum: (today) => today,
    },
    {
      type: "branch",
      field: "liableToBeDetained",
      label: "(b) Confirm the correct option",
      branches: [
        {
          label:
            "This patient was liable to be detained for a period ending on",
          fieldValue: true,
          fields: [
            {
              type: "date",
              field: "authorityDetentionWouldExpiredDate",
              label:
                "Enter the date authority for detention would have expired on (apart from any extension under section 21)",
              maximum: (today) => today,
            },
          ],
          fieldsOwned: [
            "authorityDetentionWillExpiredDate",
            "authorityDetentionWouldExpiredDate",
          ],
        },
        {
          label: "This patient is liable to be detained for a period ending on",
          fieldValue: false,
          fields: [
            {
              type: "date",
              field: "authorityDetentionWillExpiredDate",
              label:
                "Enter the date authority for detention will expire on (apart from any extension under section 21)",
              minimum: (today) => today,
            },
          ],
          fieldsOwned: [
            "authorityDetentionWouldExpiredDate",
            "authorityDetentionWillExpiredDate",
          ],
        },
      ],
    },
    {
      type: "date",
      field: "patientReturnedHospital",
      label: "(c) Enter date this patient returned to the hospital or place on",
      maximum: (today) => today,
    },
    {
      type: "textbox",
      field: "approvedMentalHealthProfessional",
      label:
        "Enter full name of the approved mental health professional you have consulted",
    },
    {
      type: "textbox",
      field: "namePersonConsulted",
      label:
        "Enter full name of the person you also have consulted who has been professionally concerned with the patient's treatment ",
    },
    {
      type: "textbox",
      field: "professionPersonConsulted",
      label:
        "Enter profession of the person you also have consulted who has been professionally concerned with the patient's treatment ",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital.",
      ],
    },
    {
      type: "multi-select",
      label:
        "(b) It is necessary that this patient should receive treatment in hospital for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      rows: "5",
      label:
        "Your reasons should cover both (a) and (b) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (e.g. out-patient treatment or social services) are available and, if so, why they are not appropriate.",
    },
    {
      type: "textbox",
      field: "patientDetainedReasons",
      rows: "5",
      label:
        "Such treatment cannot be provided unless the patient continues to be detained under the Act, for the following reasons. Reasons should indicate why informal admission is not appropriate.",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I am also of the opinion that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient.",
      ],
    },
    {
      type: "branch",
      field: "authorityForTheDetention",
      label:
        "Is the authority for the detention of this patient due to expire within a period of two months beginning with the date on which this report is to be furnished to the hospital managers on?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: ["effectedReport"],
          fields: [
            {
              type: "readonly",
              textFn: (data) => [
                "Complete the following only if the authority for detention is due to expire within that period of two months ",
              ],
            },
            {
              type: "branch",
              field: "effectedReport",
              label:
                "Shall this report have effect as a report duly furnished under section 20(3) for the renewal of the authority for the detention of this patient?",
              branches: [
                { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
          ],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: ["effectedReport"],
          fields: [],
        },
      ],
    },
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "today consigning it to the hospital managers' internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "otherwise-sent-or-delivered",
          fields: [],
          fieldsOwned: [],
          label:
            "sending or delivering it without using the hospital managers' internal mail system",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: h6Part1DataSchema,
};

export const h6Part2Template: FormPartTemplate<H6Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers through their internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "otherwise-sent-or-delivered",
          fields: [
            {
              type: "date",
              field: "reportReceivedDate",
              label: "Enter the date this report was received on",
              maximum: (today) => today,
            },
          ],
          fieldsOwned: ["reportReceivedDate"],
          label: "received by me on behalf of the hospital managers on",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
  ],
  dataSchema: h6Part2DataSchema,
};

export const h6V1Base: BaseFormTemplate = {
  id: "mha-h6",
  category: "hospital",
  version: "1.0",
  status: "active",
  parts: [h6Part1Template, h6Part2Template],
  formName: "H6",
  section: "21B",
  description:
    "Authority for detention after absence without leave for more than 28 days",
  extractGenericData: (data: H6Data) => ({}),
};
