import {
  M2Data,
  m2Part1DataSchema,
  M2Part1DataSchema,
  m2Part2DataSchema,
  M2Part2DataSchema,
} from "../schemas/mhaForms/miscellaneous/m2";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const m2Part1Template: FormPartTemplate<M2Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },

    {
      type: "textbox",
      field: "nearestRelativeName",
      label:
        "Enter name of nearest relative who gave notice of an intention to discharge ",
    },

    {
      type: "date-time",
      label:
        "Enter date and time nearest relative gave notice of an intention to discharge",
      field: "nearestRelativeIntentionToDischarge",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "readonly",
      label: "Name of patient",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "label",
      label: [
        "I am of the opinion that the patient, if discharged, would be likely to act in a manner dangerous to other persons or to himself or herself.",
      ],
      showStaticLabel: true,
    },

    {
      type: "textbox",
      field: "reasons",
      label: "Enter your reasons for these opinions ",
      rows: "5",
    },

    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: ["timeConsigning"],
          fields: [
            {
              type: "time",
              field: "timeConsigning",
              label: "Enter time",
            },
          ],
          label:
            "consigning it to the hospital managers' internal mail system today at",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: [],
          fields: [],
          label:
            "sending or delivering it without using the hospital managers’ internal mail system",
        },
      ],
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },

    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: m2Part1DataSchema,
};

export const m2Part2Template: FormPartTemplate<M2Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: [],
          fields: [],
          label:
            "furnished to the hospital managers through their internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: ["reportReceivedDateTime"],
          fields: [
            {
              type: "date-time",
              field: "reportReceivedDateTime",
              label: "Enter the date and time this report was received on",
              maximum: (today) => today,
            },
          ],
          label: "received by me on behalf of the hospital managers at",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: m2Part2DataSchema,
};

export const m2V1Base: BaseFormTemplate = {
  id: "mha-m2",
  category: "miscellaneous",
  version: "1.0",
  status: "active",
  formName: "M2",
  section: "25",
  parts: [m2Part1Template, m2Part2Template],
  description: "report barring discharge by nearest relative",
  extractGenericData: (data: M2Data) => ({}),
};
