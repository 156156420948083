import { InferType, object } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  hospital,
  generateDateBeforeTodayValidationRule,
} from "../../shared";

export const cto4Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  hospital: hospital,
  patientsDetentionDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "User cannot enter a date in the future.",
  ).required(
    "Please enter the time and date the patient was detained in hospital after being recalled under section 17E",
  ),
});

export type Cto4Part1DataSchema = InferType<typeof cto4Part1DataSchema>;

export type CTO4Data = [PartData<Cto4Part1DataSchema>];

export const exampleCTO4Data: ExampleFormData<CTO4Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      patientsDetentionDateTime: "2022-01-10T12:00:00.000Z",
    },
  },
];
