import React from "react";
import { Box, Button as MuiButton, Typography, useTheme } from "@mui/material";
import ThalamosLogo from "../assets/img/thalamos_logo.svg";
import { config } from "../config";
import { useLocation } from "react-router-dom";
import { Banner, BannerList } from "~/components/design-system";

const LogoImageStyles = {
  width: "60%",
  height: "auto",
  marginBottom: "1.5em",
};

const loginRedirectUrl = () => {
  const originalQs = new URLSearchParams(window.location.search);

  // Only include the returnTo query parameter if it exists
  const returnTo = originalQs.get("returnTo");

  const newQs = new URLSearchParams();
  if (returnTo) {
    newQs.set("returnTo", returnTo);
  }

  return `${config.backendUrl}/api/oauth/login${
    newQs.size > 0 ? `?${newQs.toString()}` : ""
  }`;
};

export function LoginPage() {
  const theme = useTheme();
  const { search } = useLocation();

  const qs = new URLSearchParams(search);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          padding: "2em", // Adjusted padding for mobile
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          "@media (max-width: 600px)": {
            padding: "1em", // Adjusted padding for smaller screens
          },
        }}
      >
        <img src={ThalamosLogo} alt="Thalamos Logo" style={LogoImageStyles} />
        <Typography
          sx={{ fontSize: "1.2rem", color: "#666", marginBottom: "1.5em" }}
        >
          Click 'Log in' to access Thalamos
        </Typography>
        {qs.get("error") && (
          <Box sx={{ mb: "2em" }}>
            <Banner
              bannerType={BannerList.ERROR}
              title={`Something went wrong, please try to log in again or contact support: ${qs.get(
                "error",
              )}`}
            />
          </Box>
        )}
        <MuiButton
          variant="contained"
          color="primary"
          sx={{
            padding: "1em 2em",
            fontSize: "1rem",
            backgroundColor: theme.palette.primary.lightGreen,
            "&:hover": {
              backgroundColor: theme.palette.primary.lightGreen,
            },
            [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]:
              {
                padding: "0.8em 1.5em", // Adjusted padding for smaller screens
              },
          }}
          onClick={() => {
            window.location.href = loginRedirectUrl();
          }}
        >
          Log in
        </MuiButton>
      </Box>
    </Box>
  );
}
