import { InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import { defaultContextFields, nonEmptyRequiredString } from "../../shared";

const userAccreditation = string()
  .oneOf(["registered-medical-practitioner", "approved-clinician"])
  .default(null)
  .required("Please confirm the correct option");

export const t4Part1DataSchema = object({
  ...defaultContextFields(),
  userAccreditation: userAccreditation,
  treatment: nonEmptyRequiredString.required(
    "Please enter description of treatment or plan of treatment",
  ),
});

export type T4Part1DataSchema = InferType<typeof t4Part1DataSchema>;

export type T4Data = [PartData<T4Part1DataSchema>];

export const exampleT4Data: ExampleFormData<T4Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      userAccreditation: "registered-medical-practitioner",
      treatment: "REASON",
    },
    context: {},
  },
];
