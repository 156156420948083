import React, { useRef } from "react";
import {
  CircularProgress,
  Box,
  TextField,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { v4 } from "uuid";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { breakpoints } from "../../styles/themeCommon";
import {
  Button,
  ButtonIcon,
  MenuFlyout,
  MenuOptionsType,
  Banner,
  BannerList,
} from "~/components/design-system";
import { routeFns } from "../../routes";
import { apiHooks } from "../../api";
import { RioConfiguration } from "@aspire/common/types/rioConfiguration";

function RioInstanceRow({ rioInstance }: { rioInstance: RioConfiguration }) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const options: MenuOptionsType[] = [
    {
      name: "Edit",
      onClick: () => {
        navigate(routeFns.rioInstanceCreateEdit(rioInstance.instanceId));
      },
      icon: "edit",
      disabled: false,
    },
  ];

  return (
    <TableRow>
      <TableCell>{rioInstance.displayName}</TableCell>
      <TableCell>{rioInstance.instanceId}</TableCell>
      <TableCell>
        <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
          <MenuFlyout
            cardRef={cardRef}
            options={options}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export function RioInstancesManagementPage() {
  const [rioInstancesFilterQuery, setRioInstancesFilterQuery] =
    React.useState("");
  const [page, setPage] = React.useState(0);
  const rioInstancesPageSize = 10;
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ data: rioInstances, loading: loadingRioInstances, error }] =
    apiHooks.rio.search(
      rioInstancesFilterQuery,
      rioInstancesPageSize,
      page * rioInstancesPageSize,
    );

  const numPages =
    rioInstances && rioInstances.count
      ? Math.ceil(rioInstances.count / rioInstancesPageSize)
      : 1;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Filter rio instances..."}
          sx={{ width: "100%" }}
          value={rioInstancesFilterQuery}
          onChange={(e) => {
            setRioInstancesFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box
          css={css`
            @media (max-width: ${breakpoints.values.sm}px) {
              margin-left: ${theme.spacing(2)};
            }

            display: flex;
            margin-bottom: ${theme.spacing(2)};
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Button
            variant={"contained"}
            label={"Create"}
            endIcon={ButtonIcon.add}
            onClick={() => navigate(routeFns.rioInstanceCreateEdit(v4()))}
          />
        </Box>
      </Box>
      {!rioInstances && loadingRioInstances && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {!loadingRioInstances && error && (
        <Banner bannerType={BannerList.ERROR} title={error.message} />
      )}

      {!loadingRioInstances &&
        rioInstances &&
        (rioInstances.results.length > 0 ? (
          <Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <button onClick={() => page > 0 && setPage(page - 1)}>
                &#60;
              </button>
              <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
                Page {page + 1} of {numPages}
              </Box>
              <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
                &#62;
              </button>
            </Box>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 700 }}>Display Name</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Instance Id</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rioInstances.results?.map((rioInstance, index) => (
                    <RioInstanceRow key={index} rioInstance={rioInstance} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <p>no rio instances found</p>
        ))}
    </Box>
  );
}
