import {
  CTO10Data,
  cto10Part1DataSchema,
  Cto10Part1DataSchema,
} from "../schemas/mhaForms/cto/cto10";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto10Part1Template: FormPartTemplate<Cto10Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This form gives authority for the assignment of responsibility for this patient from the responsible hospital to the hospital to which responsibility is to be assigned in accordance with the Mental Health (Hospital, Guardianship and Treatment) (England) Regulations 2008.",
      ],
    },
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of responsible hospital",
    },
    {
      type: "hospital-picker",
      field: "receivingHospital",
      label:
        "Enter name and address of hospital to which responsibility is to be assigned ",
    },
    {
      type: "date",
      label:
        "Enter the date this assignment was agreed by the managers of the hospital to which the responsibility is to be assigned on ",
      field: "dateOfAgreement",
      maximum: (today) => today.endOf("day"),
    },
    {
      type: "date",
      label: "Enter the date the assignment is to take place on",
      field: "dateOfAssignment",
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: cto10Part1DataSchema,
};

export const cto10V1Base: BaseFormTemplate = {
  id: "mha-cto10",
  category: "cto",
  version: "1.0",
  status: "active",
  section: "19A",
  parts: [cto10Part1Template],
  formName: "CTO10",
  description:
    "Authority for assignment of responsibility for community patient to hospital under different managers",
  extractGenericData: (data: CTO10Data) => ({}),
};
