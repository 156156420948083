import {
  H4Data,
  h4Part1DataSchema,
  H4Part1DataSchema,
  h4Part2DataSchema,
  H4Part2DataSchema,
} from "../schemas/mhaForms/hospital/h4";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const h4Part1Template: FormPartTemplate<H4Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Patient Name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of hospital in which the patient is liable to be detained",
    },
    {
      type: "hospital-picker",
      field: "hospitalDetained",
      label:
        "Enter name and address of hospital to which the patient is to be transferred",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "Authority is given for the transfer of this patient in accordance with the Mental Health (Hospital, Guardianship and Treatment) (England) Regulations 2008 within 28 days beginning with the date of this authority.",
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: h4Part1DataSchema,
};

export const h4Part2Template: FormPartTemplate<H4Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name of hospital patient was transferred to",
      hideAddress: true,
    },
    {
      type: "date-time",
      label: "Enter date and time of admission to receiving hospital",
      field: "admissionDateTime",
      maximum: (today) => today.endOf("day"),
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This patient was transferred in pursuance of this authority for transfer and admitted to the receiving hospital.",
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: h4Part2DataSchema,
};

export const h4V1Base: BaseFormTemplate = {
  id: "mha-h4",
  category: "hospital",
  version: "1.0",
  status: "active",
  parts: [h4Part1Template, h4Part2Template],
  formName: "H4",
  section: "19",
  description:
    "Authority for transfer from one hospital to another under different managers",
  extractGenericData: (data: H4Data) => {
    // Note: we are not using the `hospitalDetained` field here as it has been misnamed, and actually corresponds
    // to the `hospitalTransferred` (see human-readable form field labels).
    const hospitalDetained = data[0]?.data?.hospital;
    return {
      standalone: {
        hospitalDetained: hospitalDetained
          ? `${hospitalDetained.name} ${hospitalDetained.address} ${hospitalDetained.postalCode}`
          : undefined,
        hospitalTransferred: data[1]?.data?.hospital?.name,
        admissionDateTime: data[1]?.data.admissionDateTime,
      },
    };
  },
};
