import { Box, Pagination, PaginationItem, useTheme } from "@mui/material";
import React from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material/";

export default function PaginationControls({
  numPages,
  page,
  setPage,
}: {
  numPages: number;
  page: number;
  setPage: (params: number) => void;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(3),
      }}
    >
      <Pagination
        sx={{
          ul: {
            "& .MuiPaginationItem-root.MuiPaginationItem-root.Mui-selected": {
              background: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          },
        }}
        disabled={numPages === 1}
        count={numPages}
        page={page + 1}
        onChange={(event: any, value: any) => {
          setPage(value - 1);
        }}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: ArrowBack,
              next: ArrowForward,
            }}
            {...item}
          />
        )}
      />
    </Box>
  );
}
