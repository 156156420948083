import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ThalamosLogo from "../../../../assets/img/thalamos_icon.svg";
import ListItemText from "@mui/material/ListItemText";
import { NotificationsNoneRounded, Menu } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { stringAvatar } from "../helpers";
import { ExtendedThalamosUser, isAdminUser } from "@aspire/common/types/user";
import { mobileMenuData } from "../menuData";
import { css } from "@emotion/react";
import { StickyContainer } from "../../styleWrappers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routeFns } from "../../../../routes";
import { NotificationsContext } from "../../../../Contexts";
import { useContext } from "react";

type Anchor = "bottom" | "right";

export type DrawerDesktopMenuProps = {
  user: ExtendedThalamosUser | null;
  direction: Anchor;
};

export default function MobileMenu({
  user,
  direction,
}: DrawerDesktopMenuProps) {
  const theme = useTheme();
  const [drawerState, setDrawerState] = React.useState({
    bottom: false,
    right: false,
  });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const displayName = user?.name || "Not Logged In";

  const { notifications } = useContext(NotificationsContext);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerState((prevState) => ({ ...prevState, [anchor]: open }));
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "bottom" ? "auto" : 250, p: 2 }}
      role="presentation"
    >
      <List>
        {user?.memberships.length && (
          <Box
            sx={{
              whiteSpace: "break-spaces",
              fontSize: "0.7em",
              padding: theme.spacing(2, 3),
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Typography sx={{ fontSize: "1em", fontWeight: "bold" }}>
              {t("common.currentTeam")}
            </Typography>
            {user?.sessionContext?.type === "team"
              ? user?.sessionContext.teamName
              : user?.sessionContext?.organisationName}
            {(user?.memberships.length || 0) > 1 && (
              <Box onClick={() => navigate(routeFns.contextSelector())}>
                <Link
                  css={css`
                    cursor: pointer;
                  `}
                >
                  {t("layout.menu.changeContext")}
                </Link>
              </Box>
            )}
          </Box>
        )}
        {mobileMenuData(user)
          .filter(
            (item) =>
              !item.requiresTeamContext ||
              user?.sessionContext?.type === "team",
          )
          .filter((item) => !item.isDesktopOnly)
          .filter((item) => !item.requiresAdminUser || isAdminUser(user))
          .map(
            ({
              label,
              icon,
              link,
              disabled,
              onClick,
              text,
              miniText,
              openNewTab,
            }) => (
              <ListItem key={label} disablePadding>
                <ListItemButton
                  disabled={disabled}
                  onClick={() => {
                    if (!disabled) {
                      setDrawerState({ ...drawerState, [anchor]: false });
                      if (link) {
                        if (openNewTab) return window.open(link, "_blank");
                        return label === "logout"
                          ? (window.location.href = link!!)
                          : navigate(link!!);
                      }
                      if (onClick) return onClick();
                    }
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={miniText ?? text} />
                </ListItemButton>
              </ListItem>
            ),
          )}
      </List>
    </Box>
  );

  return (
    <StickyContainer
      data-testid="mobile-menu"
      issticky
      top={0}
      style={{ zIndex: 999 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ flexGrow: 1, px: 2 }}
      >
        <img
          onClick={() => navigate(routeFns.home())}
          alt="thalamoslogo"
          width="40"
          height="45"
          src={ThalamosLogo}
        />
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() =>
              user ? navigate(routeFns.userProfilePage(user.id)) : ""
            }
          >
            <Avatar
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: 30,
                height: 30,
                fontWeight: 700,
                fontSize: 15,
              }}
              {...stringAvatar(displayName)}
            />
          </IconButton>
          <IconButton
            onClick={() => navigate(routeFns.notifications())}
            color="inherit"
          >
            <Badge
              badgeContent={notifications?.unviewedCount}
              color={
                (notifications?.unviewedCount || 0) > 0 ? "error" : "primary"
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <NotificationsNoneRounded
                sx={{ fontSize: 32 }}
                css={css`
                  color: ${theme.palette.primary.hint};
                `}
              />
            </Badge>
          </IconButton>
          <Button onClick={toggleDrawer(direction, true)}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              css={css`
                color: ${theme.palette.primary.hint};
              `}
            >
              <Menu sx={{ fontSize: 30 }} />
              <Typography
                component="p"
                sx={{ textTransform: "capitalize", fontWeight: 600 }}
              >
                Menu
              </Typography>
            </Stack>
          </Button>
        </Stack>
        <Drawer
          sx={{
            display: { sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              ...((direction === "right" && { width: "50vw" }) ||
                (direction === "bottom" && { maxHeight: 350 })),
            },
          }}
          anchor={direction}
          open={!!drawerState[direction]}
          onClose={toggleDrawer(direction, false)}
        >
          {list(direction)}
        </Drawer>
      </Box>
    </StickyContainer>
  );
}
