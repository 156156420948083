import { InferType, boolean, object, string } from "yup";
import {
  defaultContextFields,
  hospital,
  isoDateString,
  nonEmptyRequiredString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
  generateDateAfterTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";
import { requiredBooleanWhen } from "../../acd/util";

export const h6Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    approvedClinicianRequired: true,
    nameErrorMessage: "Please enter your print name ",
  }),
  hospital: hospital,

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date you examined the patient"),

  absenceWithoutLeaveDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date absence without leave began"),

  liableToBeDetained: boolean()
    .default(null)
    .nonNullable("Please confirm the correct option"),

  authorityDetentionWouldExpiredDate: isoDateString
    .default(null)
    .when("liableToBeDetained", {
      is: true,
      then: () =>
        generateDateBeforeTodayValidationRule(
          "date",
          "You cannot enter a date and/or time in the future.",
        ).required(
          "Please enter date authority for detention would have expired on",
        ),
      otherwise: () => isoDateString,
    }),

  authorityDetentionWillExpiredDate: isoDateString
    .default(null)
    .when("liableToBeDetained", {
      is: false,
      then: () =>
        generateDateAfterTodayValidationRule(
          "date",
          "You cannot enter a date and/or time in the past.",
        ).required(
          "Please enter date authority for detention would have expired on",
        ),
      otherwise: () => isoDateString,
    }),

  patientReturnedHospital: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required(
    "Please enter date this patient returned to the hospital or place on",
  ),

  approvedMentalHealthProfessional: nonEmptyRequiredString.required(
    "Please enter full name of the AHMP you have consulted",
  ),
  namePersonConsulted: nonEmptyRequiredString.required(
    "Please enter full name of the person you have consulted",
  ),

  professionPersonConsulted: nonEmptyRequiredString.required(
    "Please enter profession of the person you have consulted",
  ),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),
  reasons: nonEmptyRequiredString.required("Please enter your reasoning"),
  patientDetainedReasons: nonEmptyRequiredString.required(
    "Please enter your reasoning",
  ),
  authorityForTheDetention: boolean()
    .default(null)
    .required(
      "Please confirm if the detention is due to expire within a period of two months beginning with the date this report is to be furnished on",
    ),
  effectedReport: requiredBooleanWhen(
    "authorityForTheDetention",
    "Please confirm if this report have effect as a report duly furnished under section 20(3) for the renewal of the authority for the detention of this patient",
  ),
  reportFurnishedBy: string()
    .oneOf([
      "internal-mail-system",
      "electronic-communication",
      "otherwise-sent-or-delivered",
    ])
    .default(null)
    .required("Please select the option which applies"),
});

export const h6Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your print name ",
  }),
  reportFurnishedBy: string()
    .oneOf([
      "internal-mail-system",
      "electronic-communication",
      "otherwise-sent-or-delivered",
    ])
    .default(null)
    .required("Please select the option which applies"),
  reportReceivedDate: isoDateString.when("reportFurnishedBy", {
    is: (val: string | boolean) => val === "otherwise-sent-or-delivered",
    then: () =>
      // @ts-ignore
      generateDateBeforeTodayValidationRule(
        "date",
        "You cannot enter a date and/or time in the future.",
      ).required("Please enter date this report was received on"),
    otherwise: () => isoDateString,
  }),
});

export type H6Part1DataSchema = InferType<typeof h6Part1DataSchema>;
export type H6Part2DataSchema = InferType<typeof h6Part2DataSchema>;

export type H6Data = [
  PartData<H6Part1DataSchema>,
  PartData<H6Part2DataSchema>?,
];

export const exampleH6Data: ExampleFormData<H6Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      lastExaminedDate: "2021-01-01",
      absenceWithoutLeaveDate: "2021-01-05",
      liableToBeDetained: true,
      patientReturnedHospital: "2022-01-05",
      approvedMentalHealthProfessional: "APPROVED MENTAL HEALTH PROFESSIONAL",
      namePersonConsulted: "NAME PERSON CONSULTED",
      professionPersonConsulted: "PROFESSION OF PERSON CONSULTED",
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
      effectedReport: true,
      reasons: "Reasons",
      patientDetainedReasons: "PATIENT DETAINED REASONS",
      authorityForTheDetention: true,
      reportFurnishedBy: "otherwise-sent-or-delivered",
      authorityDetentionWouldExpiredDate: "2022-01-05",
      authorityDetentionWillExpiredDate: "2022-01-05",
    },
  },
  {
    data: {
      user: {
        name: "Consulted Clinician",
        email: "sarah@clinician.com",
        address: "Consulted Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "otherwise-sent-or-delivered",
      reportReceivedDate: "1990-01-01",
    },
  },
];
