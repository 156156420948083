import { InferType, object } from "yup";

import { ExampleFormData, PartData } from "../../../types/form";

export const a9Part1DataSchema = object({});

export type A9Part1DataSchema = InferType<typeof a9Part1DataSchema>;

export type A9Data = [PartData<A9Part1DataSchema>];

export const exampleA9Data: ExampleFormData<A9Data> = [
  {
    data: {},
  },
];
