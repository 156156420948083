import {
  CTO8Data,
  cto8Part1DataSchema,
  cto8Part2DataSchema,
  Cto8Part1DataSchema,
  Cto8Part2DataSchema,
} from "../schemas/mhaForms/cto/cto8";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto8Part1Template: FormPartTemplate<Cto8Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },

    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "date",
      label: "Enter date of examination",
      field: "lastExaminedDate",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "date",
      label:
        "Enter date the patient was recalled to hospital under section 17E of the Mental Health Act 1983 on",
      field: "recallDateMentalHealthAct",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "date",
      label: "Enter date absence without leave from hospital began",
      field: "absenceStartDate",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "branch",
      label: "Confirm the correct option",
      field: "communityTreatmentOrderStatus",
      branches: [
        {
          fieldValue: "was-subject-community-treatment-order",
          label: "This patient was subject to a community treatment order",
          fieldsOwned: [
            "communityTreatmentOrderExpiryDate",
            "expectedCommunityTreatmentOrderExpiryDate",
          ],
          fields: [
            {
              type: "date",
              label:
                "Enter the date the community treatment order would have expired on (apart from any extension under section 21)",
              field: "communityTreatmentOrderExpiryDate",
              maximum: (today) => today.endOf("day"),
            },
          ],
        },
        {
          fieldValue: "is-subject-community-treatment-order",
          label: "This patient is subject to a community treatment order",
          fieldsOwned: [],
          fields: [
            {
              type: "date",
              label:
                "Enter the date the community treatment order will expire on (apart from any extension under section 21)",
              field: "expectedCommunityTreatmentOrderExpiryDate",
              minimum: (today) => today,
            },
          ],
        },
      ],
    },

    {
      type: "date",
      label: "(c) Enter the date this patient returned to the hospital on",
      field: "patientReturnedHospitalDate",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "textbox",
      field: "approvedMentalHealthProfessional",
      label:
        "Enter full name of the approved mental health professional you have consulted",
    },
    {
      type: "textbox",
      field: "namePersonConsulted",
      label:
        "Enter full name of the person you also have consulted who has been professionally concerned with the patient's treatment ",
    },
    {
      type: "textbox",
      field: "professionPersonConsulted",
      label:
        "Enter profession of the person you also have consulted who has been professionally concerned with the patient's treatment ",
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment.",
      ],
    },

    {
      type: "multi-select",
      label:
        "(b) It is necessary that the patient should receive such treatment for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient's health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient's safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(c) In my opinion, such treatment can be provided without the patient continuing to be detained in a hospital provided the patient is liable to being recalled to hospital for medical treatment.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(d) In my opinion, it is necessary that the responsible clinician should continue to be able to exercise the power under section 17E(1) to recall the patient to hospital.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(e) In my opinion, taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I confirm that in determining whether the criterion at (d) above is met, I have considered what risk there would be of deterioration of the patient’s condition if the patient were to continue not to be detained in hospital, with regard to the patient's history and any other relevant factors.",
      ],
    },

    {
      type: "textbox",
      field: "groundsForOpinion",
      label: "Please enter the grounds your opinion is founded on",
      rows: "5",
    },

    {
      type: "branch",
      label:
        "Is the community treatment order due to expire within a period of two months beginning with the date on which this report is to be furnished to the managers of the responsible hospital on?",
      field: "isCTOExpiringWithinTwoMonths",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: ["isReportEffectualUnderSection20A4"],
          fields: [
            {
              type: "label",
              label: [
                "Complete the following only if the community treatment order is due to expire within that period of two months",
              ],
            },

            {
              type: "branch",
              label:
                "Shall this report have effect as a report duly furnished under section 20A(4) for the extension of the community treatment period for this patient?",
              field: "isReportEffectualUnderSection20A4",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: [],
                  fields: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fieldsOwned: [],
                  fields: [],
                },
              ],
            },
          ],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: [],
          fields: [],
        },
      ],
    },

    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: [],
          fields: [],
          label:
            "today consigning it to the hospital managers' internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: [],
          fields: [],
          label:
            "sending or delivering it without using the hospital managers’ internal mail system",
        },
      ],
    },
  ],
  dataSchema: cto8Part1DataSchema,
};

export const cto8Part2Template: FormPartTemplate<Cto8Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers through their internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fields: [
            {
              type: "date",
              label: "Enter date the report was received on",
              field: "reportReceivedDate",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: ["reportReceivedDate"],
          label: "received by me on behalf of the hospital managers on",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
  ],
  dataSchema: cto8Part2DataSchema,
};

export const cto8V1Base: BaseFormTemplate = {
  id: "mha-cto8",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO8",
  section: "21B",
  parts: [cto8Part1Template, cto8Part2Template],
  description:
    "authority for extension of community treatment period after absence without leave for more than 28 days",
  extractGenericData: (data: CTO8Data) => ({}),
};
