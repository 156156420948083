import React from "react";
import { Box, useTheme } from "@mui/material";
import {
  Banner,
  BannerList,
  Button,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  TextField,
} from "~/components/design-system";

export interface reasonOptions {
  value: string;
  descriptionPrompt?: string | undefined;
}

export function RecordReasonDialog({
  title,
  dropDownLabel,
  bannerTitle,
  onClose,
  onConfirmClick,
  options,
}: {
  title: string;
  bannerTitle?: string;
  dropDownLabel: string;
  onClose: () => void;
  onConfirmClick: (
    reason: string,
    reasonDescription: string | undefined,
  ) => void;
  options: reasonOptions[];
}) {
  const theme = useTheme();

  const [reason, setReason] = React.useState("");
  const [reasonDescription, setReasonDescription] = React.useState<
    string | undefined
  >(undefined);

  const selectedReason = options.find((o) => o.value === reason);
  const descriptionPrompt = selectedReason?.descriptionPrompt;

  const hasReason = !!reason;

  const isDescriptionPromptSet =
    !descriptionPrompt || (descriptionPrompt && !!reasonDescription);

  const bothReasonAndDescriptionValid = hasReason && isDescriptionPromptSet;

  return (
    <PopupDialog open={true} onClose={onClose}>
      <PopupDialogTitle titleText={title} closeDialog={onClose} />
      {bannerTitle && (
        <Box sx={{ mb: 2 }}>
          <Banner title={bannerTitle} bannerType={BannerList.WARNING} />
        </Box>
      )}
      <Dropdown
        label={dropDownLabel}
        selectedValue={reason}
        name={"reason"}
        values={options.map((o) => ({ value: o.value, label: o.value }))}
        onChange={(e) => {
          setReason(e);
          setReasonDescription(undefined);
        }}
      />
      {descriptionPrompt && (
        <Box sx={{ mt: 2 }}>
          <TextField
            name={"reasonDescription"}
            label={descriptionPrompt}
            onChange={(e) => setReasonDescription(e)}
            value={reasonDescription || ""}
            useFullWidth={true}
          />
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: theme.spacing(3),
        }}
      >
        <Button label={"Close"} onClick={onClose} variant="outlined" />
        <Button
          color="primary"
          disabled={!bothReasonAndDescriptionValid}
          label={"Confirm"}
          onClick={() => onConfirmClick(reason, reasonDescription)}
        />
      </Box>
    </PopupDialog>
  );
}
