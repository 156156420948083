import { InferType, object, string } from "yup";
import {
  defaultContextFields,
  hospital,
  nonEmptyString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
  generateDateAfterTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const h5Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    approvedClinicianRequired: true,
    nameErrorMessage: "Please enter your print name",
  }),

  hospital: hospital,

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter the date of examination"),

  detentionExpiryDate: generateDateAfterTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the past.",
  ).required("Please enter the date authority for detention is due to expire"),

  consultedPersonName: nonEmptyString.required(
    "Please enter the full name of the person you have consulted",
  ),
  consultedPersonProfession: nonEmptyString.required(
    "Please enter the profession of the person you have consulted",
  ),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  reasonsDetentionNecessary: nonEmptyString.required(
    "Please enter your reasoning",
  ),
  reasonsInformalAdmissionsInappropriate: nonEmptyString.required(
    "Please enter your reasoning",
  ),

  userProfession: nonEmptyString.required("Please enter your profession"),
});

export const h5Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your print name ",
  }),
  userProfession: nonEmptyString.required("Please enter your profession"),
});

const reportFurnishedBy = string()
  .oneOf([
    "internal-mail-system",
    "electronic-communication",
    "otherwise-sent-or-delivered",
  ])
  .default(null)
  .required("Please select the option which applies");

export const h5Part3DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    approvedClinicianRequired: true,
    nameErrorMessage: "Please enter your print name ",
  }),
  reportFurnishedBy: reportFurnishedBy,
});

export const h5Part4DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your print name ",
  }),
  reportFurnishedBy: reportFurnishedBy,
  receivedDate: string()
    .optional()
    .when("reportFurnishedBy", {
      is: "otherwise-sent-or-delivered",
      then: () =>
        generateDateBeforeTodayValidationRule(
          "date",
          "You cannot enter a date and/or time in the future.",
        ).required("Please enter date the report was received on"),
      otherwise: () => string().optional(),
    }),
});

export type H5Part1DataSchema = InferType<typeof h5Part1DataSchema>;
export type H5Part2DataSchema = InferType<typeof h5Part2DataSchema>;
export type H5Part3DataSchema = InferType<typeof h5Part3DataSchema>;
export type H5Part4DataSchema = InferType<typeof h5Part4DataSchema>;

export type H5Data = [
  PartData<H5Part1DataSchema>,
  PartData<H5Part2DataSchema>?,
  PartData<H5Part3DataSchema>?,
  PartData<H5Part4DataSchema>?,
];

export const exampleH5Data: ExampleFormData<H5Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        isGuest: true,
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        nhsNumber: "1234567890",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      userProfession: "Clinician",
      lastExaminedDate: "2020-01-01",
      consultedPersonName: "Consulted Person",
      detentionExpiryDate: "2050-01-01",
      consultedPersonProfession: "Pscyhiatrist",
      reasonsDetentionNecessary: "Reasons for detention",
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: true,
      necessaryForProtectionOfOtherPersons: false,
      reasonsInformalAdmissionsInappropriate: "Reasons for informal admission",
    },
  },
  {
    data: {
      user: {
        name: "Consulted Clinician",
        email: "sarah@clinician.com",
        address: "Consulted Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      userProfession: "Psychiatrist",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "internal-mail-system",
    },
  },
  {
    data: {
      user: {
        name: "MHA",
        email: "david@mha.com",
        address: "MHA Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "internal-mail-system",
    },
  },
];
