import { InferType, boolean, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  generateDateAfterTodayValidationRule,
  generateDateBeforeTodayValidationRule,
  hospital,
  isoDateString,
  nonEmptyRequiredString,
  nonEmptyString,
  optionalCheckbox,
} from "../../shared";

const reportFurnishedBy = string()
  .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
  .default(null)
  .required("Please select the option which applies");

export const cto8Part1DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
  }),

  hospital: hospital,

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date you examined the patient"),

  recallDateMentalHealthAct: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date patient was recalled to hospital"),

  absenceStartDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date absence without leave from hospital began"),

  communityTreatmentOrderStatus: string()
    .oneOf([
      "was-subject-community-treatment-order",
      "is-subject-community-treatment-order",
    ])
    .default(null)
    .required("Please confirm the correct option"),

  communityTreatmentOrderExpiryDate: isoDateString.when(
    "communityTreatmentOrderStatus",
    {
      is: (value: string) => value === "was-subject-community-treatment-order",
      then: (schema) =>
        generateDateBeforeTodayValidationRule(
          "date",
          "User cannot enter a date in the future.",
        ).required(
          "Please enter date the community treatment order would have expired on",
        ),
      otherwise: () => isoDateString,
    },
  ),

  expectedCommunityTreatmentOrderExpiryDate: isoDateString.when(
    "communityTreatmentOrderStatus",
    {
      is: (value: string) => value === "is-subject-community-treatment-order",
      then: (schema) =>
        generateDateAfterTodayValidationRule(
          "date",
          "You cannot enter a date and/or time in the past.",
        ).required(
          "Please enter date the community treatment order will expire on",
        ),
      otherwise: () => isoDateString,
    },
  ),

  patientReturnedHospitalDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required(
    "Please enter date this patient returned to the hospital or place on",
  ),

  approvedMentalHealthProfessional: nonEmptyRequiredString.required(
    "Please enter full name of the AHMP you have consulted",
  ),
  namePersonConsulted: nonEmptyRequiredString.required(
    "Please enter full name of the person you have consulted",
  ),

  professionPersonConsulted: nonEmptyRequiredString.required(
    "Please enter profession of the person you have consulted",
  ),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  groundsForOpinion: nonEmptyString
    .required()
    // @ts-ignore
    .nonNullable("Please enter your reasoning"),

  isCTOExpiringWithinTwoMonths: boolean()
    .default(null)
    .required("Please select the option which applies"),

  isReportEffectualUnderSection20A4: boolean()
    .nullable()
    .default(null)
    .when("isCTOExpiringWithinTwoMonths", {
      is: true,
      then: () =>
        boolean().required(
          "Please confirm if this report have effect as a report duly furnished under section 20(3) for the renewal of the authority for the detention of this patient",
        ),
      otherwise: () => boolean().default(null).nullable(),
    }),
  reportFurnishedBy: string()
    .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
    .default(null)
    .required("Please select the option which applies"),
});

export const cto8Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  reportFurnishedBy: reportFurnishedBy,
  reportReceivedDate: isoDateString.when("reportFurnishedBy", {
    is: (value: string) => value === "in-person",
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date",
        "You cannot enter a date and/or time in the future.",
      ).required("Please enter a date"),
    otherwise: () => string().optional(),
  }),
});

export type Cto8Part1DataSchema = InferType<typeof cto8Part1DataSchema>;
export type Cto8Part2DataSchema = InferType<typeof cto8Part2DataSchema>;

export type CTO8Data = [
  PartData<Cto8Part1DataSchema>,
  PartData<Cto8Part2DataSchema>?,
];

export const exampleCTO8Data: ExampleFormData<CTO8Data> = [
  {
    data: {
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },

      lastExaminedDate: "2021-01-01",
      recallDateMentalHealthAct: "2021-01-01",
      absenceStartDate: "2021-01-01",
      communityTreatmentOrderStatus: "is-subject-community-treatment-order",

      communityTreatmentOrderExpiryDate: "2021-01-01",

      expectedCommunityTreatmentOrderExpiryDate: "2021-01-01",

      patientReturnedHospitalDate: "2021-01-01",

      isReportEffectualUnderSection20A4: true,

      approvedMentalHealthProfessional: "Bob Smith",

      namePersonConsulted: "Steven Smith",

      professionPersonConsulted: "Stuart Smith",

      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,

      groundsForOpinion: "Reasons",

      isCTOExpiringWithinTwoMonths: true,

      reportFurnishedBy: "electronic-communication",

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "in-person",
      reportReceivedDate: "2022-01-10T12:00:00.000Z",
    },
  },
];
