import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common from "./locales/en/common.json";
import pages from "./locales/en/pages.json";
import forms from "./locales/en/forms.json";
import hooks from "./locales/en/hooks.json";
import layout from "./locales/en/layout.json";
import components from "./locales/en/components.json";

// We don't have a requirement to internationalise yet so let's not
// bother loading from backend etc.
// We just want to have the scaffolding in place
const jsonTranslationFiles = {
  ...common,
  ...pages,
  ...forms,
  ...hooks,
  ...layout,
  ...components,
};
export const resources = {
  en: {
    translation: jsonTranslationFiles,
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
  fallbackLng: "en",
});
