import {
  CTO9Data,
  cto9Part1DataSchema,
  Cto9Part1DataSchema,
  cto9Part2DataSchema,
  Cto9Part2DataSchema,
} from "../schemas/mhaForms/cto/cto9";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto9Part1Template: FormPartTemplate<Cto9Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This patient is treated as if subject to a community treatment order having been transferred to England.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "The patient is to be subject to the following conditions by virtue of that community treatment order:",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      indent: 3.5,
      label: [
        "The patient is to make himself or herself available for examination under section 20A, as requested.",
        "If it proposed to give a certificate under Part 4A of the Act in the patient's case, the patient is to make himself or herself available for examination to enable the certificate to be given, as requested.",
      ],
    },

    {
      type: "branch",
      field: "patientSubjectToConditionsUnder17B2",
      label:
        "Is the patient also subject to any conditions under section 17B(2) of the Act?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [],
          fields: [
            {
              type: "textbox",
              rows: "5",
              field: "conditionsUnderSection17B2",
              label:
                "Enter the conditions the patient is to be subject to under section 17B(2) of the Act",
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I confirm that I consider the above conditions to be made under section 17B(2) of the Act are necessary or appropriate for one or more of the following purposes:",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      showAsBulletPoint: true,
      label: [
        "to ensure that the patient receives medical treatment",
        "to prevent risk of harm to the patient's health or safety",
        "to protect other persons.",
      ],
    },
  ],
  dataSchema: cto9Part1DataSchema,
};

export const cto9Part2Template: FormPartTemplate<Cto9Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["amhp"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedMentalHealthProfessional,
    },
  },
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I agree that the conditions made above under section 17B(2) are necessary or appropriate for one or more of the purposes specified.",
      ],
    },
  ],
  dataSchema: cto9Part2DataSchema,
};

export const cto9V1Base: BaseFormTemplate = {
  id: "mha-cto9",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO9",
  section: "",
  parts: [cto9Part1Template, cto9Part2Template],
  description: "Part 6 – community patients transferred to England",
  extractGenericData: (data: CTO9Data) => ({}),
};
