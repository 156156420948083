import React from "react";
import {
  Box,
  Step,
  StepLabel,
  Stepper as MUIStepper,
  useTheme,
} from "@mui/material";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection";

interface StepperProps {
  steps: string[];
  activeStep: number;
}

const defaultProps: Partial<StepperProps> = {};

export const Stepper = (props: StepperProps) => {
  const allProps = { ...defaultProps, ...props };
  const { steps, activeStep } = allProps;

  const theme = useTheme();

  const { isMobileView } = useScreenDetection();

  return (
    <Box
      sx={{
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <MUIStepper
        alternativeLabel={!isMobileView}
        sx={{
          "& .MuiStepLabel-root": {
            display: "flex",
            flexDirection: "column",
            "& .MuiStepLabel-label.Mui-active": {
              color: theme.palette.text.primary,
            },

            ".Mui-completed": {
              "& svg": {
                color: "secondary.main",
              },
            },
          },
          "& .MuiStepConnector-line": {
            [theme.breakpoints.down("sm")]: {
              bottom: theme.spacing(1.5),
            },
            position: "relative",
          },
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            "& .MuiStepLabel-root": {
              flexDirection: "row",
              "& .MuiStepLabel-iconContainer": {
                marginBottom: 0,
                marginRight: theme.spacing(1),
              },
            },
            "& .MuiStepConnector-root": {
              display: "none",
            },
            "& .MuiStep-root:first-of-type .MuiStepConnector-root": {
              display: "none",
            },
            "& .MuiStep-root:last-child .MuiStepConnector-root": {
              display: "none",
            },
            "& .MuiStepLabel-labelContainer": {
              marginLeft: 0,
              display: "flex",
            },
            "& .MuiStepLabel-wrapper": {
              width: "100%",
              textAlign: "center",
            },
          },
        }}
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    fontSize: "1rem",
                  },
                  "& .MuiStepIcon-text": {
                    fontSize: "1rem",
                  },
                  "@media (max-width: 600px)": {
                    marginBottom: theme.spacing(1),
                  },
                }}
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </MUIStepper>
    </Box>
  );
};
