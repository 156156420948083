import { UUID } from "../util";
import { Organisation } from "./organisations";
import { Team } from "./teams";

export type TeamMembership = {
  type: "team";
  teamId: UUID;
  role: "member" | "manager" | "analytics-viewer";
};
export type OrganisationMembership = {
  type: "organisation";
  organisationId: UUID;
  role: "member" | "manager" | "analytics-viewer";
};
export type Membership = TeamMembership | OrganisationMembership;

export function isTeamMembership(
  membership: Membership,
): membership is TeamMembership {
  return membership?.type === "team";
}

export type ExtendedTeamMembership = TeamMembership & {
  teamName: string;
  teamType: Team["type"];
  teamEmail: string;
  teamStatus: string;

  organisationId: UUID;
  organisationName: string;
  organisationRole: Organisation["role"];

  dataControllerId: UUID;
  dataControllerName: string;
  dataControllerIsPdsEnabled: boolean;
  isTrainingOrganisation?: boolean;
};
export type ExtendedOrganisationMembership = OrganisationMembership & {
  organisationName: string;
  organisationRole: Organisation["role"];

  dataControllerId: UUID;
  dataControllerName: string;
  dataControllerIsPdsEnabled: boolean;
  isTrainingOrganisation?: boolean;
};
export type ExtendedMembership =
  | ExtendedTeamMembership
  | ExtendedOrganisationMembership;

export function extendedMembershipToMembership(
  membership: ExtendedMembership,
): Membership {
  if (membership.type === "team") {
    return {
      type: "team",
      teamId: membership.teamId,
      role: membership.role,
    };
  } else {
    return {
      type: "organisation",
      role: membership.role,
      organisationId: membership.organisationId,
    };
  }
}

export function isExtendedTeamMembership(
  membership: ExtendedMembership,
): membership is ExtendedTeamMembership {
  return membership?.type === "team";
}

export function isOrgMembership(
  membership: Membership,
): membership is OrganisationMembership {
  return membership?.type === "organisation";
}

export function isExtendedOrgMembership(
  membership: ExtendedMembership,
): membership is ExtendedOrganisationMembership {
  return membership?.type === "organisation";
}
