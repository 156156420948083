import React from "react";
import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { baseTemplates } from "@aspire/common/formTemplates";
import {
  ExtendedThalamosUser,
  isGuestUser,
  isIndependentUserSession,
} from "@aspire/common/types/user";
import { GetWorkResponse } from "@aspire/common/types/work";
import {
  FormLabel,
  FormTitle,
  SearchBarWithFilter,
} from "~/components/design-system";
import { useScreenDetection } from "../../hooks/ScreenDetection/useScreenDetection";

export default function SearchAndFilters({
  user,
  patientSearchFilter,
  formFullName,
  work,
  selectedOptions,
  setPatientSearchFilter,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  selectedTab,
  setSelectedTab,
  setSelectedFilteredOptions,
  setSelectedOptions,
}: {
  user: ExtendedThalamosUser;
  patientSearchFilter: string;
  formFullName: (params: any) => string;
  work: GetWorkResponse | undefined;
  selectedOptions: string[];
  setPatientSearchFilter: (params: any) => void;
  fromDate: Dayjs | null;
  setFromDate: (params: Dayjs) => void;
  toDate: Dayjs | null;
  setToDate: (params: Dayjs) => void;
  selectedTab: number;
  setSelectedTab: (params: any) => void;
  setSelectedFilteredOptions: (params: any) => void;
  setSelectedOptions: (params: any) => void;
}) {
  const { isMobileView } = useScreenDetection();

  const allSenderNames = work?.senderNames;

  const formTemplates = allSenderNames ? baseTemplates : [];

  const formSignatures = _.uniqBy(allSenderNames, "userid");

  const tabOptions = [
    {
      tabValue: 0,
      options: formTemplates.map(formFullName),
    },
    {
      tabValue: 1,
      options: formSignatures.map((f) => f?.username),
    },
    {
      tabValue: 2,
      options: [],
    },
  ];
  return (
    <Box>
      <FormTitle
        titleText={
          isIndependentUserSession(user)
            ? t("pages.caseload.title")
            : t("pages.caseload.teamCaseload")
        }
        useReducedTopPadding
        hasTitleBottomMargin={false}
      />
      <FormLabel
        label={
          isIndependentUserSession(user)
            ? t("pages.caseload.searchLabel")
            : t("pages.caseload.searchLabelTeam")
        }
      />
      <SearchBarWithFilter
        hideFilter={isGuestUser(user) || isMobileView}
        filters={["Form Name", "Sender name", "Signature Date"]}
        optionValues={tabOptions!}
        searchQuery={patientSearchFilter}
        setSearchQuery={(e: any) => setPatientSearchFilter(e)}
        selectedOptions={selectedOptions}
        setSelectedOptions={(options) => {
          function extractProperties(
            data: any,
            id: string,
            propFn: (a: any) => string,
          ) {
            return data
              .filter((item: any) => options.includes(propFn(item)))
              .map((item: any) => item[id])
              .filter((item: any) => item);
          }

          const formTemplateIds = extractProperties(
            formTemplates,
            "id",
            formFullName,
          );

          const formSenderNames = extractProperties(
            formSignatures,
            "userid",
            (u) => u.username,
          );

          const combineResults = {
            formTemplateIds: formTemplateIds,
            senderNames: formSenderNames,
          };

          setSelectedFilteredOptions(combineResults);
          setSelectedOptions(options);
        }}
        showDateRange
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </Box>
  );
}
