import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import { App } from "./App";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { initialiseTracing } from "./tracing";
import { appTheme } from "./styles/theme";
import { PusherProvider } from "./lib/pusher/PusherProvider";
import { config } from "./config";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";

const theme = createTheme(appTheme);

initialiseTracing();

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);

console.log(`Code Version: ${config.version || "unknown"}`);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PusherProvider {...config.pusher}>
          <App />
        </PusherProvider>
      </BrowserRouter>
    </ThemeProvider>
  </>,
  // </React.StrictMode>
);
