import { nonAdmissionTemplates } from "../formTemplates";
import {
  OrganisationConfiguration,
  OrganisationConfigurationHierarchy,
} from "../types/configuration";

export const defaultConfiguration: OrganisationConfiguration = {
  enabledForms: nonAdmissionTemplates.map((t) => ({
    id: t.id,
    version: t.version,
  })),
  mhaAdmissionsEnabled: true,
  mhaAdmissionsJointMedRecsEnabled: true,
  acdEnabled: false,
  uploadFormsEnabled: false,
  patientStateEnabled: false,
  patientSearchRecordReasonsEnabled: false,
  rioInstancesEnabled: [],
  helpPageUrl: "https://thalamos.atlassian.net/wiki/spaces/TEHG/overview",
  supportPageUrl: "https://survey.zohopublic.eu/zs/QfCEbJ",
  nhsNumberEnabled: false,
};

export function mergeConfigurations(
  organisationId: string | null,
  configurations: OrganisationConfigurationHierarchy,
): OrganisationConfiguration {
  const configuration = configurations.find(
    (c) => c.organisationId === organisationId,
  );

  if (!configuration) {
    return defaultConfiguration;
  }

  if (!configuration.managingOrganisationId) {
    return { ...defaultConfiguration, ...configuration.configuration };
  }

  const parentConfig = mergeConfigurations(
    configuration.managingOrganisationId,
    configurations,
  );

  return {
    ...parentConfig,
    ...configuration.configuration,
  };
}
