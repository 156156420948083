import {
  H1Data,
  H1Part1DataSchema,
  h1Part1DataSchema,
  h1Part2DataSchema,
  H1Part2DataSchema,
} from "../schemas/mhaForms/hospital/h1";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const h1Part1Template: FormPartTemplate<H1Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      requireSendingWhenSigning: true,
      accreditation: approvedClinicianAccreds,
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedClinicianOrMedicalPractitionerUnderSection52,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },

    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
    {
      type: "branch",
      field: "userAccreditation",
      label: "I am",
      branches: [
        {
          fieldValue: "registered-medical-practitioner",
          fields: [],
          fieldsOwned: [],
          label: "A registered medical practitioner",
        },
        {
          fieldValue: "approved-clinician",
          fields: [],
          fieldsOwned: [],
          label:
            "An approved clinician (who is not a registered medical practitioner)",
        },
      ],
    },

    {
      type: "branch",
      field: "nominee",
      label:
        "Are you a nominee of the registered medical practitioner or approved clinician?",
      branches: [
        {
          fieldValue: true,
          fields: [],
          fieldsOwned: [],
          label: "Yes",
        },
        {
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
          label: "No",
        },
      ],
    },
    {
      type: "readonly",
      label: "Patient name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This patient is an in-patient in this hospital and not at present liable to be detained under the Mental Health Act 1983.",
      ],
    },
    {
      type: "textbox",
      label:
        "It appears to me that an application ought to be made under Part 2 of the Act for this patient's admission to hospital for the following reasons. The full reasons why informal treatment is no longer appropriate must be given.",
      field: "noInformaltreatment",
      rows: "5",
    },
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: ["timeConsigning"],
          fields: [
            {
              type: "time",
              field: "timeConsigning",
              label: "Enter time",
            },
          ],
          label:
            "consigning it to the hospital managers' internal mail system today at",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: [],
          fields: [],
          label:
            "delivering it (or having it delivered) by hand to a person authorised by the hospital managers to receive it",
        },
      ],
    },
  ],
  dataSchema: h1Part1DataSchema,
};

export const h1Part2Template: FormPartTemplate<H1Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers through their internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fields: [
            {
              type: "date-time",
              label: "Enter date the report was received on",
              field: "receivedDate",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: ["receivedDate"],
          label:
            "delivered to me in person as someone authorised by the hospital managers to receive this report at",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
  ],
  dataSchema: h1Part2DataSchema,
};

export const h1V1Base: BaseFormTemplate = {
  id: "mha-h1",
  category: "hospital",
  version: "1.0",
  status: "active",
  formName: "H1",
  section: "5(2)",
  parts: [h1Part1Template, h1Part2Template],
  description: "Report on hospital in-patient",
  extractGenericData: (data: H1Data) => ({
    admissions: {
      admissionHospitalName: data[0]?.data.hospital.name,
      admissionHospitalAddress: data[0]?.data.hospital.address,
      admissionsHospitalPostcode: data[0]?.data.hospital.postalCode,
    },
  }),
};
