export const recordOfDetentionTemplateIds = ["mha-h3"];
export const applicationTemplateIds = [
  "mha-a1",
  "mha-a2",
  "mha-a5",
  "mha-a6",
  "mha-a9",
  "mha-a10",
];

export const jointMedicalRecordsTemplateIds = ["mha-a3", "mha-a7"];
export const singleMedicalRecordsTemplateIds = ["mha-a4", "mha-a8", "mha-a11"];
export const medicalRecommendationTemplateIds = [
  ...jointMedicalRecordsTemplateIds,
  ...singleMedicalRecordsTemplateIds,
];
export const section4Templates = ["mha-a9", "mha-a10"];

export const nonThalamosFormTemplateIds = ["mha-a1", "mha-a5", "mha-a9"];

export const admissionTemplateIds = [
  ...applicationTemplateIds,
  ...medicalRecommendationTemplateIds,
];
