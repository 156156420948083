import React, { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";

import {
  Banner,
  BannerList,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
  renderWarningToast,
  ReviewExistingExternalPatientRecordProps,
  ReviewExistingExternalPatientRecord,
  SectionTitle,
  SystemType,
} from "~/components/design-system";
import { useTranslation } from "react-i18next";
import { DialogProps, useDialogs } from "@toolpad/core/useDialogs";
import { BannerDialog } from "~/pages/FormProgressPage/helpers/BannerDialog";
import { UUID } from "@aspire/common/util";

import { api } from "~/api";
import dayjs from "dayjs";
import { v4 } from "uuid";

import { ExternalPatientLink } from "@aspire/common/types/externalPatientLink";
import { CreateExternalPatientDemograhicsPullEventRequest } from "@aspire/common/types/patientRequests";

/**
 * A hook to do a demographics sync journey for a single
 * external patient link.
 */
export const useSyncExternalPatientLink = (
  externalPatientLink: ExternalPatientLink | undefined,
) => {
  const { t } = useTranslation();

  // These are the patient records we will ask the user to review.
  // If this is set, then the review dialog will be shown.
  // If it is not set, then the review dialog will not be shown.
  const [patientRecordsForReview, setPatientRecordsForReview] = useState<
    PatientRecordsForReview | undefined
  >(undefined);

  const scopedSyncExternalPatientLink = useCallback(
    async (
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => {
      if (externalPatientLink !== undefined) {
        await syncExternalPatientLink(
          externalPatientLink,
          setPatientRecordsForReview,
          t,
          reason,
        );
      }
    },
    [externalPatientLink, t],
  );

  const scopedCancel = useCallback(
    async (pullEventId: string, resolve: () => void) => {
      if (
        externalPatientLink !== undefined &&
        patientRecordsForReview !== undefined
      ) {
        await onCancelReview(
          pullEventId,
          externalPatientLink.patientId,
          setPatientRecordsForReview,
          t,
          resolve,
        );
      }
    },
    [externalPatientLink, patientRecordsForReview, t],
  );

  const scopedConfirm = useCallback(
    async (patientRecords: PatientRecordsForReview, chosen: Chosen) => {
      return onConfirmReview(
        patientRecords,
        chosen,
        setPatientRecordsForReview,
        t,
      );
    },
    [t],
  );
  const reviewDialogProps: ReviewDialogProps =
    externalPatientLink === undefined || patientRecordsForReview === undefined
      ? {}
      : {
          patientRecords: patientRecordsForReview,
          onCancel: scopedCancel,
          onConfirm: scopedConfirm,
        };

  return {
    Component: ReviewDialog,
    componentProps: reviewDialogProps,
    syncExternalPatientLink: scopedSyncExternalPatientLink,
  };
};

export const useSyncExternalPatientLinks = (patientId: UUID) => {
  const { t } = useTranslation();

  // These are the patient records we will ask the user to review.
  // If this is set, then the review dialog will be shown.
  // If it is not set, then the review dialog will not be shown.
  const [patientRecordsForReview, setPatientRecordsForReview] = useState<
    PatientRecordsForReview | undefined
  >(undefined);

  const scopedSyncExternalPatientLink = useCallback(
    async (
      externalPatientLink: ExternalPatientLink,
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => {
      await syncExternalPatientLink(
        externalPatientLink,
        setPatientRecordsForReview,
        t,
        reason,
      );
    },
    [t],
  );

  const scopedConfirm = useCallback(
    async (
      patientRecords: PatientRecordsForReview | undefined,
      chosen: Chosen,
    ) => {
      if (patientRecords !== undefined) {
        return onConfirmReview(
          patientRecords,
          chosen,
          setPatientRecordsForReview,
          t,
        );
      } else {
        return false;
      }
    },
    [t],
  );

  const scopedCancel = useCallback(
    async (pullEventId: string, resolve: () => void) => {
      if (patientRecordsForReview !== undefined) {
        await onCancelReview(
          pullEventId,
          patientId,
          setPatientRecordsForReview,
          t,
          resolve,
        );
      }
    },
    [patientId, patientRecordsForReview, t],
  );

  const reviewDialogProps: ReviewDialogProps = useMemo(
    () =>
      patientRecordsForReview === undefined
        ? {}
        : {
            patientRecords: patientRecordsForReview,
            onCancel: scopedCancel,
            onConfirm: scopedConfirm,
          },
    [patientRecordsForReview, scopedCancel, scopedConfirm],
  );

  return {
    Component: ReviewDialog,
    componentProps: reviewDialogProps,
    syncExternalPatientLink: scopedSyncExternalPatientLink,
  };
};

type PatientRecordsForReview = {
  patientRecords: ReviewExistingExternalPatientRecordProps["patientRecords"];
  eMHAPatientId: UUID;
  eMHAPatientVersion: number;
  pullEventId: string;
  resolve: (value: void | PromiseLike<void>) => void;
};

// This matches the structure of BasePatientDemographics,
// but with the values replaced with the system type.
type Chosen = {
  name: { given: SystemType; family: SystemType };
  address: { address: SystemType; postalCode: SystemType };
  dateOfBirth: SystemType;
};

const syncExternalPatientLink = async (
  externalPatientLink: ExternalPatientLink,
  setPatientRecordsForReview: React.Dispatch<
    React.SetStateAction<PatientRecordsForReview | undefined>
  >,
  t: any,
  reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
) => {
  // We expose the resolve function because there are a number of ways that the sync could end,
  // (failure, no review required, review skipped, review completed, etc) and we need a common
  // way to resolve the promise. We pass this resolve function around so that many code paths
  // can settle the promise.
  return new Promise<void>(async (resolve) => {
    try {
      // Create a pull event for the external patient link
      const pullEventId = v4();
      const pullEventResponse =
        await api.patients.createExternalPatientDemographicsPullEvent(
          externalPatientLink.patientId,
          {
            id: pullEventId,
            externalPatientLinkId: externalPatientLink.id,
            created: dayjs().toISOString(),
            reason,
          },
        );

      if (
        pullEventResponse.status !== 200 ||
        pullEventResponse.data.responseNormalised === undefined
      ) {
        // The pull failed
        throw new Error("Pull failed");
        // TODO: ASP-2082: Audit log this
      }

      // Get the internal patient data for comparison
      const getPatientResponse = await api.patients.get(
        externalPatientLink.patientId,
      );
      if (
        getPatientResponse.status !== 200 ||
        getPatientResponse.data === null
      ) {
        throw new Error("Failed to get patient");
        // TODO: ASP-2082: Audit log this
      }

      // Compare the response from the external system to the internal patient data.
      // If demographic details are different, then a review is required.
      const [a, b] = [
        getPatientResponse.data,
        pullEventResponse.data.responseNormalised,
      ];
      const reviewRequired =
        a.name.family !== b.name.family ||
        a.name.given !== b.name.given ||
        a.address.address !== b.address.address ||
        a.address.postalCode !== b.address.postalCode ||
        a.dateOfBirth !== b.dateOfBirth;

      if (reviewRequired) {
        // Package up some state which will be used to perform the review.
        setPatientRecordsForReview({
          patientRecords: { eMHA: a, Rio: b },
          eMHAPatientId: a.id,
          eMHAPatientVersion: a.version,
          pullEventId: pullEventId,
          resolve: resolve,
        });
      } else {
        // Flag the pull event as "processed"
        await api.patients.updateExternalPatientDemographicsPullEvent(
          externalPatientLink.patientId,
          pullEventId,
          { processed: true },
        );

        // Notify the user - nothing for them to do.
        renderSuccessToast({
          message: t(
            "hooks.externalPatientLink.pullEvent.succeeded.updateNotRequired",
          ),
          autoClose: 5000,
        });

        // This is the end of the user journey
        resolve(undefined);
      }
    } catch (e) {
      // TODO: ASP-2082: Audit Log failure
      renderErrorToast({
        message: t("hooks.externalPatientLink.pullEvent.failed"),
      });

      // End the user journey in failure.
      resolve(undefined);
    }
  });
};

// This is called when the user cancels the review dialog
const onCancelReview = async (
  pullEventId: string,
  patientId: UUID,
  setPatientRecordsForReview: React.Dispatch<
    React.SetStateAction<PatientRecordsForReview | undefined>
  >,
  t: any,
  resolve: PatientRecordsForReview["resolve"],
) => {
  // Flag the pull event as being "not processed"
  await api.patients.updateExternalPatientDemographicsPullEvent(
    patientId,
    pullEventId,
    { processed: false },
  );

  // Chastise the user
  renderWarningToast({
    message: t("hooks.externalPatientLink.pullEvent.succeeded.updateSkipped"),
    autoClose: 5000,
  });

  // End the review
  setPatientRecordsForReview(undefined);
  resolve();
};

// This is called when the user confirms the review dialog
const onConfirmReview = async (
  patientRecordsForReview: PatientRecordsForReview,
  chosen: Chosen,
  setPatientRecordsForReview: React.Dispatch<
    React.SetStateAction<PatientRecordsForReview | undefined>
  >,
  t: any,
) => {
  // Update the eMHA patient record with the chosen values
  const response = await api.patients.update(
    patientRecordsForReview.eMHAPatientId,
    {
      expectedVersion: patientRecordsForReview.eMHAPatientVersion,
      type: "demographics",
      demographics: {
        name: {
          given:
            patientRecordsForReview.patientRecords[chosen.name.given].name
              .given,
          family:
            patientRecordsForReview.patientRecords[chosen.name.family].name
              .family,
        },
        address: {
          address:
            patientRecordsForReview.patientRecords[chosen.address.address]
              .address.address,
          postalCode:
            patientRecordsForReview.patientRecords[chosen.address.postalCode]
              .address.postalCode,
        },
        dateOfBirth:
          patientRecordsForReview.patientRecords[chosen.dateOfBirth]
            .dateOfBirth,
      },
    },
  );

  const success = response.status === 200;
  if (!success) {
    // Inform the user of the failure
    renderErrorToast({
      message: t("hooks.externalPatientLink.pullEvent.succeeded.updateFailed"),
    });
  } else {
    // Flag the pull event as "processed"
    await api.patients.updateExternalPatientDemographicsPullEvent(
      patientRecordsForReview.eMHAPatientId,
      patientRecordsForReview.pullEventId,
      { processed: true },
    );

    // Congratulate the user
    renderSuccessToast({
      message: t(
        "hooks.externalPatientLink.pullEvent.succeeded.updateSucceeded",
      ),
      autoClose: 5000,
    });
  }

  // End the review
  patientRecordsForReview.resolve();
  setPatientRecordsForReview(undefined);
  return success;
};

type ReviewDialogProps = {
  patientRecords?: PatientRecordsForReview;
  onCancel?: (
    pullEventId: PatientRecordsForReview["pullEventId"],
    resolve: PatientRecordsForReview["resolve"],
    ...base: Parameters<ReviewExistingExternalPatientRecordProps["onCancel"]>
  ) => ReturnType<ReviewExistingExternalPatientRecordProps["onCancel"]>;
  onConfirm?: (
    patientRecords: PatientRecordsForReview,
    ...base: Parameters<ReviewExistingExternalPatientRecordProps["onConfirm"]>
  ) => ReturnType<ReviewExistingExternalPatientRecordProps["onConfirm"]>;
};

const SuccessDialog = ({ open, onClose }: DialogProps) => {
  const { t } = useTranslation();

  return (
    open && (
      <BannerDialog
        title={t("pages.patientEditCreate.updateSuccess")}
        message={t("pages.patientEditCreate.updateSuccessDialogMessage")}
        onClose={onClose}
      />
    )
  );
};
const ReviewDialog = ({
  patientRecords,
  onCancel,
  onConfirm,
}: ReviewDialogProps) => {
  const { t } = useTranslation();
  const dialogs = useDialogs();

  const open = [patientRecords, onCancel, onConfirm].every(
    (x) => x !== undefined,
  );

  return (
    <PopupDialog open={open}>
      {open && (
        <Stack gap="1rem">
          <SectionTitle
            titleText={t(
              "components.externalPatientLink.reviewExistingExternalPatientRecord.title",
            )}
          ></SectionTitle>
          <Banner
            bannerType={BannerList.WARNING}
            body={t(
              "components.externalPatientLink.reviewExistingExternalPatientRecord.banner.rio",
            ).split("\n")}
          ></Banner>
          <ReviewExistingExternalPatientRecord
            patientRecords={patientRecords!.patientRecords}
            onConfirm={async (chosen) => {
              const success = await onConfirm!(patientRecords!, chosen);
              if (success) {
                await dialogs.open(SuccessDialog);
              }

              return success;
            }}
            onCancel={() =>
              onCancel!(patientRecords!.pullEventId, patientRecords!.resolve)
            }
          />
        </Stack>
      )}
    </PopupDialog>
  );
};
