import { boolean, InferType, object } from "yup";
import {
  defaultContextFields,
  hospital,
  nonEmptyString,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { PartData, ExampleFormData } from "../../../types/form";

export const h3Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your name",
  }),

  hospital: hospital,

  patientWasInHospitalBeforeSectioning: boolean()
    .default(null)
    .required("Please confirm the correct option"),

  admissionDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date and time of admission"),

  sectionText: nonEmptyString.required("Please state section"),
  sectionValue: nonEmptyString.required("Please state section"),
});
export type H3Part1DataSchema = InferType<typeof h3Part1DataSchema>;

export const h3Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your name",
  }),
  secondMedRecReceivedTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date and time of admission"),
});
export type H3Part2DataSchema = InferType<typeof h3Part2DataSchema>;

export type H3Data = [
  PartData<H3Part1DataSchema>,
  PartData<H3Part2DataSchema>?,
];

export const exampleH3Data: ExampleFormData<H3Data> = [
  {
    data: {
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      user: {
        email: "hospital@manager.com",
        name: "Hospital Manager",
        address: "Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Bob Patient",
        address: "Patient Address",
        postalCode: "SE2 9AA",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      patientWasInHospitalBeforeSectioning: true,
      admissionDateTime: "2022-07-10T12:00:00.000Z",
      sectionText: "Section 2",
      sectionValue: "2",
    },
  },
  {
    data: {
      user: {
        email: "hospital@manager.com",
        name: "Hospital Manager",
        address: "Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Bob Patient",
        address: "Patient Address",
        postalCode: "SE2 9AA",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      secondMedRecReceivedTime: "2022-07-10T12:00:00.000Z",
    },
  },
];
