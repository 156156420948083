import { datadogRum } from "@datadog/browser-rum";
import { config } from "./config";
import { ThalamosUser } from "@aspire/common/types/user";

export function initialiseTracing() {
  if (!config.datadog.disableTracing) {
    datadogRum.init({
      applicationId: config.datadog.applicationId,
      clientToken: config.datadog.clientToken,
      site: "datadoghq.eu",
      service: "aspire",
      env: config.environment ?? "unknown",
      version: config.version ?? "unknown",
      trackUserInteractions: false,
      allowedTracingUrls: [config.backendUrl],
    });
  }
}

export function setUser(user: ThalamosUser | null) {
  if (!config.datadog.disableTracing) {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
      });
    } else {
      datadogRum.clearUser();
    }
  }
}

export function logErrorToDatadog(error: Error) {
  if (!config.datadog.disableTracing) {
    datadogRum.addError(error);
  }
}
