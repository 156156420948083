import {
  acdPart1DataSchema,
  AcdPart1DataSchema,
} from "../../schemas/acd/acdPart1DataSchema";
import dayjs from "dayjs";
import { FormPartTemplate } from "../../types/form";

const ethnicityDropdownOptions: { value: string; label: string }[] = [
  { value: "A", label: "White - British" },
  { value: "B", label: "White - Irish" },
  { value: "C", label: "White - Any other White background" },
  { value: "D", label: "Mixed - White and Black Caribbean" },
  { value: "E", label: "Mixed - White and Black African" },
  { value: "F", label: "Mixed - White and Asian" },
  { value: "G", label: "Mixed - Any other Mixed background" },
  { value: "H", label: "Asian or Asian British - Indian" },
  { value: "J", label: "Asian or Asian British - Pakistani" },
  { value: "K", label: "Asian or Asian British - Bangladeshi" },
  { value: "L", label: "Asian or Asian British - Any other Asian background" },
  { value: "M", label: "Black or Black British - Caribbean" },
  { value: "N", label: "Black or Black British - African" },
  { value: "P", label: "Black or Black British - Any other Black background" },
  { value: "R", label: "Other Ethnic Groups - Chinese" },
  { value: "S", label: "Other Ethnic Groups - Any other ethnic group" },
  { value: "Z", label: "Not stated" },
  { value: "99", label: "Not known" },
];
const genderDropdownOptions: { value: string; label: string }[] = [
  { value: "1", label: "Male (including trans man)" },
  { value: "2", label: "Female (including trans woman)" },
  { value: "3", label: "Non-binary" },
  { value: "4", label: "Other (not listed)" },
  { value: "X", label: "Not known (not recorded)" },
  {
    value: "Z",
    label: "Not stated (person asked but declined to provide a response)",
  },
];
export const acdPart1Template: FormPartTemplate<AcdPart1DataSchema> = {
  signing: {
    confirmationOnly: true,
    userOrTeamRequirement: {
      accreditation: ["acd-facilitator"],
      teamType: [],
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    { type: "label", label: ["My details and personal information"] },
    {
      type: "readonly",
      label: "Name and address of person",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "readonly",
      label: "Service user Date of Birth",
      textFn: (data, context) => [
        data.patient?.dateOfBirth
          ? dayjs(data.patient?.dateOfBirth).format("DD/MM/YYYY")
          : "Unknown",
      ],
    },
    {
      type: "textbox",
      field: "patientNhsNumber",
      label: "Service user NHS Number",
    },
    {
      type: "dropdown",
      valueField: "patientEthnicityCode",
      textField: "patientEthnicityText",
      options: ethnicityDropdownOptions,
      label: "Ethnicity",
    },
    {
      type: "dropdown",
      valueField: "patientGenderCode",
      textField: "patientGenderText",
      options: genderDropdownOptions,
      label: "Gender",
    },
    {
      type: "textbox",
      field: "trustId",
      label: "Trust ID",
    },
    { type: "label", label: ["Who I am"] },
    {
      type: "textbox",
      field: "whatImLikeWhenImWell",
      label:
        "What I'm like when I am well (please consider these things when I am in crisis)",
      rows: "5",
      checkboxToShowField: "whatImLikeWhenImWellSelected",
      afterLabel:
        "What I do for a living, the things I enjoy doing, what my interests are, what my beliefs are.",
    },
    { type: "label", label: ["Health difficulties"] },
    {
      type: "textbox",
      field: "mainMentalHealthDifficulties",
      label: "Main mental health difficulties",
      rows: "5",
      checkboxToShowField: "mainMentalHealthDifficultiesSelected",
    },
    {
      type: "textbox",
      field: "mainPhysicalIllnessOrAllergies",
      label: "Main physical illness and/or allergies",
      rows: "5",
      checkboxToShowField: "mainPhysicalIllnessOrAllergiesSelected",
    },
    {
      type: "textbox",
      field: "learningOrCommunicationDifficultiesOrOtherSignificantDiagnoses",
      label:
        "Learning difficulties, communication difficulties and/or other significant diagnoses",
      rows: "5",
      checkboxToShowField:
        "learningOrCommunicationDifficultiesOrOtherSignificantDiagnosesSelected",
    },
    { type: "label", label: ["Care in Crisis"] },
    {
      type: "textbox",
      field: "thingsThatHaveLedMeToBeingUnwell",
      label: "Things that have led to me becoming unwell before",
      rows: "5",
      checkboxToShowField: "thingsThatHaveLedMeToBeingUnwellSelected",
    },
    {
      type: "textbox",
      field: "keySignsIAmInCrisis",
      label: "Key signs that I am in crisis",
      afterLabel:
        "How do your beliefs/behaviours/speech change when you become unwell?",
      rows: "5",
      checkboxToShowField: "keySignsIAmInCrisisSelected",
    },
    {
      type: "textbox",
      field: "earlySignsIAmLosingCapacity",
      label: "Early signs that I am losing capacity to make decisions",
      afterLabel:
        "How would someone know if you were not making decisions about treatment in your usual way?",
      rows: "5",
      checkboxToShowField: "earlySignsIAmLosingCapacitySelected",
    },
    {
      type: "textbox",
      field: "thingsToAvoid",
      label: "Key things I want to avoid",
      afterLabel:
        "What are the things you worry about most if you become unwell? For example: hurting yourself or others, being less able to care for people, embarrassing or dangerous situations",
      rows: "5",
      checkboxToShowField: "thingsToAvoidSelected",
    },
    {
      type: "textbox",
      field: "keyThingsPeopleMisunderstandAboutMeWhenUnwell",
      label: "Key things people misunderstand about me when I’m unwell",
      rows: "5",
      checkboxToShowField:
        "keyThingsPeopleMisunderstandAboutMeWhenUnwellSelected",
    },
    { type: "label", label: ["My preferences for treatment and care"] },
    {
      type: "textbox",
      field: "communityHomeTreatmentTeamCrisisCare",
      label: "Community/home treatment team crisis care",
      afterLabel:
        "How would someone know you need a Home Treatment Team? How could your community team or a Home Treatment Team help?",
      rows: "5",
      checkboxToShowField: "communityHomeTreatmentTeamCrisisCareSelected",
    },
    {
      type: "textbox",
      field: "whenINeedAdmissionToHospital",
      label: "When I need admission to hospital",
      afterLabel:
        "How could others tell if you need to go to a mental health hospital?",
      rows: "5",
      checkboxToShowField: "whenINeedAdmissionToHospitalSelected",
    },
    {
      type: "textbox",
      field: "mentalHealthActAssessment",
      label: "Mental Health Act Assessment (being sectioned)",
      afterLabel:
        "How could others tell if you needed a Mental Health Act assessment? What might improve your experience of a Mental Health Act assessment?",
      rows: "5",
      checkboxToShowField: "mentalHealthActAssessmentSelected",
    },
    {
      type: "textbox",
      field: "hospitalCare",
      label: "Hospital care",
      afterLabel:
        "You could include information on: How staff could support you, activities to help pass time, preferences for smoking replacements, injuries or emotional trauma staff should be sensitive to, managing compulsory treatment treatment (e.g. physical restraint or seclusion, ward rounds, discharge planning)",
      rows: "5",
      checkboxToShowField: "hospitalCareSelected",
    },
    {
      type: "textbox",
      field: "mentalHealthMedicalTreatments",
      label: "Mental health medical treatments",
      afterLabel:
        "Are there medical treatments you find helpful? (e.g. medicines, ECT). Try to include information about doses and how you prefer to take medications (e.g. tablets, liquids, injections)",
      rows: "5",
      checkboxToShowField: "mentalHealthMedicalTreatmentsSelected",
    },
    {
      type: "textbox",
      field: "mentalHealthNonMedicalTreatments",
      label: "Mental health non-medical treatments",
      afterLabel:
        "Are there non-medical treatments you find helpful (e.g. therapy, exercise, nutrition)?",
      rows: "5",
      checkboxToShowField: "mentalHealthNonMedicalTreatmentsSelected",
    },
    {
      type: "textbox",
      field: "whatIWantToAvoidWhenInCrisis",
      label: "Treatment and care I want to avoid",
      afterLabel:
        "Try to be as specific as possible, include reasons why you want to avoid these types of treatments or care and if there are alternatives you would prefer",
      rows: "5",
      checkboxToShowField: "whatIWantToAvoidWhenInCrisisSelected",
    },
    {
      type: "textbox",
      field: "religiousOrSpiritualBeliefsAndCulturalPractices",
      label: "Religious or spiritual beliefs and cultural practises",
      afterLabel:
        "Do you have a religious faith, spiritual beliefs or cultural practises that you would like health professionals to be aware of?",
      rows: "5",
      checkboxToShowField:
        "religiousOrSpiritualBeliefsAndCulturalPracticesSelected",
    },
    {
      type: "textbox",
      field: "physicalHealthTreatmentAndCareDuringHomeTreatment",
      label:
        "Physical health treatment and care during Home Treatment Team or hospital admissions",
      afterLabel:
        "If you are admitted to mental health hospital will you need staff to help provide treatment and care for any physical health problems?",
      rows: "5",
      checkboxToShowField:
        "physicalHealthTreatmentAndCareDuringHomeTreatmentSelected",
    },
    {
      type: "textbox",
      field: "emergencyPhysicalHealthCareAndTreatment",
      label: "Emergency physical health care and treatment",
      afterLabel:
        "If you need emergency physical health care (e.g. in A&E) is there anything you would like staff to know about your preferences?",
      rows: "5",
      checkboxToShowField: "emergencyPhysicalHealthCareAndTreatmentSelected",
    },
    { type: "label", label: ["Practical help"] },
    { type: "label", label: ["Home"], labelType: "subtitle" },
    {
      type: "branch",
      field: "checkHomeIsSecure",
      label: "Check my home is secure",
      afterLabel:
        "This could include locking doors and turning off electricity, gas or cookers",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [],
          fieldsOwned: [],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "throwAwayPerishableFoodsOrDrinks",
      label: "Throw away any perishable foods or drinks",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [],
          fieldsOwned: [],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    { type: "label", label: ["Care arrangements"], labelType: "subtitle" },
    {
      type: "branch",
      field: "careForChildrenNeedToBeArranged",
      label: "Do you need to arrange care for children?",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              field: "careArrangementsForChildren",
              rows: "5",
              label: "Care arrangements for children",
            },
          ],
          fieldsOwned: ["careArrangementsForChildren"],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "careForDependentRelativesNeedToBeArranged",
      label: "Do you need to arrange care for dependent relative(s)?",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              field: "careArrangementsForDependentRelatives",
              rows: "5",
              label: "Care arrangements for dependent relative(s)",
            },
          ],
          fieldsOwned: ["careArrangementsForDependentRelatives"],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "careForPetsNeedToBeArranged",
      label: "Do you need to arrange care for pet(s)?",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              field: "careArrangementsForPets",
              rows: "5",
              label: "Care arrangements for pet(s)",
            },
          ],
          fieldsOwned: ["careArrangementsForPets"],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "otherCareNeedToBeArranged",
      label: "Do you need to arrange any other care?",
      afterLabel: "Add any other practical help you think will be helpful",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              field: "otherCareArrangements",
              rows: "5",
              label: "Other care arrangements",
            },
          ],
          fieldsOwned: ["otherCareArrangements"],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "isThereAnyoneToInformOnAdmission",
      label: "Is there anyone you want to be informed if you are admitted?",
      afterLabel:
        "This could include people at your work, college or university.",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              label: "Name (First person)",
              field: "informedPerson1Name",
            },
            {
              type: "textbox",
              label: "Relationship to you (First person)",
              field: "informedPerson1Relationship",
            },
            {
              type: "textbox",
              label: "Phone number (First person)",
              field: "informedPerson1PhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (First person)",
              field: "informedPerson1EmailAddress",
            },
            {
              type: "textbox",
              label: "What would you like to inform them? (First person)",
              field: "informedPerson1Information",
            },
            {
              type: "branch",
              field: "secondPersonToInformOnAdmission",
              label:
                "Is there anyone else you want to be informed if you are admitted?",
              branches: [
                {
                  label: "Yes",
                  fieldValue: true,
                  fields: [
                    {
                      type: "textbox",
                      label: "Name (Second person)",
                      field: "informedPerson2Name",
                    },
                    {
                      type: "textbox",
                      label: "Relationship to you (Second person)",
                      field: "informedPerson2Relationship",
                    },
                    {
                      type: "textbox",
                      label: "Phone number (Second person)",
                      field: "informedPerson2PhoneNumber",
                    },
                    {
                      type: "textbox",
                      label: "Email address (Second person)",
                      field: "informedPerson2EmailAddress",
                    },
                    {
                      type: "textbox",
                      label:
                        "What would you like to inform them? (Second person)",
                      field: "informedPerson2Information",
                    },
                  ],
                  fieldsOwned: [
                    "informedPerson2Name",
                    "informedPerson2Relationship",
                    "informedPerson2EmailAddress",
                    "informedPerson2PhoneNumber",
                    "informedPerson2Information",
                  ],
                },
                {
                  label: "No",
                  fieldValue: false,
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
          ],
          fieldsOwned: [
            "informedPerson1Name",
            "informedPerson1Relationship",
            "informedPerson1EmailAddress",
            "informedPerson1PhoneNumber",
            "informedPerson1Information",
            "informedPerson2Name",
            "informedPerson2Relationship",
            "informedPerson2EmailAddress",
            "informedPerson2PhoneNumber",
            "informedPerson2Information",
            "secondPersonToInformOnAdmission",
          ],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "isThereAnyoneNotToInformOnAdmission",
      label:
        "Is there anyone you do not want to be informed if you are admitted?",
      branches: [
        {
          label: "Yes",
          fieldValue: true,
          fields: [
            {
              type: "textbox",
              label: "Name (First person NOT to be informed)",
              field: "notInformedPerson1Name",
            },
            {
              type: "textbox",
              label: "Relationship to you (First person NOT to be informed)",
              field: "notInformedPerson1Relationship",
            },
            {
              type: "branch",
              field: "secondPersonNotToInformOnAdmission",
              label:
                "Is there anyone else you don't want to be informed if you are admitted?",
              branches: [
                {
                  label: "Yes",
                  fieldValue: true,
                  fields: [
                    {
                      type: "textbox",
                      label: "Name (Second person NOT to be informed)",
                      field: "notInformedPerson2Name",
                    },
                    {
                      type: "textbox",
                      label: "Relationship (Second person NOT to be informed)",
                      field: "notInformedPerson2Relationship",
                    },
                  ],
                  fieldsOwned: [
                    "notInformedPerson2Name",
                    "notInformedPerson2Relationship",
                  ],
                },
                {
                  label: "No",
                  fieldValue: false,
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
          ],
          fieldsOwned: [
            "notInformedPerson1Name",
            "notInformedPerson1Relationship",
            "secondPersonNotToInformOnAdmission",
            "notInformedPerson2Name",
            "notInformedPerson2Relationship",
          ],
        },
        {
          label: "No",
          fieldValue: false,
          fields: [],
          fieldsOwned: [],
        },
      ],
    },
    { type: "label", label: ["Key crisis supporters"] },
    { type: "label", label: ["Preferred contact"], labelType: "subtitle" },
    {
      type: "textbox",
      label: "Name (First preferred contact)",
      field: "preferredContact1Name",
    },
    {
      type: "textbox",
      label: "Relationship to you (First preferred contact)",
      field: "preferredContact1Relationship",
    },
    {
      type: "textbox",
      label: "Role in crisis (First preferred contact)",
      field: "preferredContact1RoleInCrisis",
    },
    {
      type: "textbox",
      label: "Phone number (First preferred contact)",
      field: "preferredContact1PhoneNumber",
    },
    {
      type: "textbox",
      label: "Email address (First preferred contact)",
      field: "preferredContact1EmailAddress",
    },
    {
      type: "name-address",
      disableEmail: true,
      disableName: true,
      addressLabel: "Address (First preferred contact)",
      field: "preferredContact1Address",
    },
    {
      type: "branch",
      field: "preferredContact1NearestRelative",
      label:
        "Is this person your nearest relative according to the Mental Health Act 1983?",
      afterLabel:
        "Only one preferred contact can be selected as your nearest relative according to the Mental Health Act 1983",
      branches: [
        {
          fieldValue: "yes",
          label: "Yes",
          fieldsOwned: [
            "isReasonNearestRelative1NotContacted",
            "reasonNearestRelative1NotContacted",
          ],
          fields: [
            {
              type: "branch",
              field: "isReasonNearestRelative1NotContacted",
              label:
                "Is there any reason why you would prefer your nearest relative is not contacted?",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: ["reasonNearestRelative1NotContacted"],
                  fields: [
                    {
                      type: "textbox",
                      label:
                        "Reason you would prefer your nearest relative is not contacted",
                      field: "reasonNearestRelative1NotContacted",
                      rows: "5",
                    },
                  ],
                },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
          ],
        },
        { fieldValue: "no", label: "No", fieldsOwned: [], fields: [] },
        { fieldValue: "unsure", label: "Unsure", fields: [], fieldsOwned: [] },
      ],
    },
    {
      type: "branch",
      field: "doYouHaveASecondPreferredContact",
      label: "Do you have a second preferred contact?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "preferredContact2Name",
            "preferredContact2Relationship",
            "preferredContact2RoleInCrisis",
            "preferredContact2PhoneNumber",
            "preferredContact2EmailAddress",
            "preferredContact2Address",
            "preferredContact2NearestRelative",
            "howPreferredContact2CanAccess",
            "canPreferredContact2Access",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (Second preferred contact)",
              field: "preferredContact2Name",
            },
            {
              type: "textbox",
              label: "Relationship to you (Second preferred contact)",
              field: "preferredContact2Relationship",
            },
            {
              type: "textbox",
              label: "Role in crisis (Second preferred contact)",
              field: "preferredContact2RoleInCrisis",
            },
            {
              type: "textbox",
              label: "Phone number (Second preferred contact)",
              field: "preferredContact2PhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (Second preferred contact)",
              field: "preferredContact2EmailAddress",
            },
            {
              type: "name-address",
              disableEmail: true,
              disableName: true,
              addressLabel: "Address (Second preferred contact)",
              field: "preferredContact2Address",
            },
            {
              type: "branch",
              field: "preferredContact2NearestRelative",
              label:
                "Is this person your nearest relative according to the Mental Health Act 1983?",
              afterLabel:
                "Only one preferred contact can be selected as your nearest relative according to the Mental Health Act 1983",
              branches: [
                {
                  fieldValue: "yes",
                  label: "Yes",
                  fieldsOwned: [
                    "isReasonNearestRelative2NotContacted",
                    "reasonNearestRelative2NotContacted",
                  ],
                  fields: [
                    {
                      type: "branch",
                      field: "isReasonNearestRelative2NotContacted",
                      label:
                        "Is there any reason why you would prefer your nearest relative is not contacted?",
                      branches: [
                        {
                          fieldValue: true,
                          label: "Yes",
                          fieldsOwned: ["reasonNearestRelative2NotContacted"],
                          fields: [
                            {
                              type: "textbox",
                              label:
                                "Reason you would prefer your nearest relative is not contacted",
                              field: "reasonNearestRelative2NotContacted",
                              rows: "5",
                            },
                          ],
                        },
                        {
                          fieldValue: false,
                          label: "No",
                          fieldsOwned: [],
                          fields: [],
                        },
                      ],
                    },
                  ],
                },
                { fieldValue: "no", label: "No", fieldsOwned: [], fields: [] },
                {
                  fieldValue: "unsure",
                  label: "Unsure",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "label",
      label: ["Other crisis supporters"],
      labelType: "subtitle",
    },
    {
      type: "branch",
      field: "doYouHaveACareCoordinator",
      label: "Do you have a Care Co-ordinator contact?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "careCoordinatorName",
            "careCoordinatorOrganisation",
            "careCoordinatorPhoneNumber",
            "careCoordinatorEmailAddress",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (Care Co-ordinator)",
              field: "careCoordinatorName",
            },
            {
              type: "textbox",
              label: "Organisation (Care Co-ordinator)",
              field: "careCoordinatorOrganisation",
            },
            {
              type: "textbox",
              label: "Phone number (Care Co-ordinator)",
              field: "careCoordinatorPhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (Care Co-ordinator)",
              field: "careCoordinatorEmailAddress",
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "branch",
      field: "doYouHaveAConsultant",
      label: "Do you have a Consultant contact?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "consultantName",
            "consultantOrganisation",
            "consultantPhoneNumber",
            "consultantEmailAddress",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (Consultant)",
              field: "consultantName",
            },
            {
              type: "textbox",
              label: "Organisation (Consultant)",
              field: "consultantOrganisation",
            },
            {
              type: "textbox",
              label: "Phone number (Consultant)",
              field: "consultantPhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (Consultant)",
              field: "consultantEmailAddress",
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "branch",
      field: "doYouHaveAGeneralPractitioner",
      label: "Do you have a GP contact?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "generalPractitionerName",
            "generalPractitionerOrganisation",
            "generalPractitionerPhoneNumber",
            "generalPractitionerEmailAddress",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (GP)",
              field: "generalPractitionerName",
            },
            {
              type: "textbox",
              label: "Organisation (GP)",
              field: "generalPractitionerOrganisation",
            },
            {
              type: "textbox",
              label: "Phone number (GP)",
              field: "generalPractitionerPhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (GP)",
              field: "generalPractitionerEmailAddress",
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "branch",
      field: "doYouHaveAnyOtherKeyCrisisSupporterContacts",
      label: "Do you have any other key crisis supporter contacts?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "keyCrisisOtherContact1Name",
            "keyCrisisOtherContact1RoleInCrisis",
            "keyCrisisOtherContact1Organisation",
            "keyCrisisOtherContact1PhoneNumber",
            "keyCrisisOtherContact1EmailAddress",
            "secondKeyCrisisOtherContact",
            "keyCrisisOtherContact2Name",
            "keyCrisisOtherContact2RoleInCrisis",
            "keyCrisisOtherContact2Organisation",
            "keyCrisisOtherContact2PhoneNumber",
            "keyCrisisOtherContact2EmailAddress",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (First other crisis supporter)",
              field: "keyCrisisOtherContact1Name",
            },
            {
              type: "textbox",
              label: "Role in Crisis (First other crisis supporter)",
              field: "keyCrisisOtherContact1RoleInCrisis",
            },
            {
              type: "textbox",
              label: "Organisation (First other crisis supporter)",
              field: "keyCrisisOtherContact1Organisation",
            },
            {
              type: "textbox",
              label: "Phone number (First other crisis supporter)",
              field: "keyCrisisOtherContact1PhoneNumber",
            },
            {
              type: "textbox",
              label: "Email address (First other crisis supporter)",
              field: "keyCrisisOtherContact1EmailAddress",
            },
            {
              type: "branch",
              field: "secondKeyCrisisOtherContact",
              label: "Do you have any other key crisis supporter contacts?",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: [
                    "keyCrisisOtherContact2Name",
                    "keyCrisisOtherContact2RoleInCrisis",
                    "keyCrisisOtherContact2Organisation",
                    "keyCrisisOtherContact2PhoneNumber",
                    "keyCrisisOtherContact2EmailAddress",
                  ],
                  fields: [
                    {
                      type: "textbox",
                      label: "Name (Second other crisis supporter)",
                      field: "keyCrisisOtherContact2Name",
                    },
                    {
                      type: "textbox",
                      label: "Role in Crisis (Second other crisis supporter)",
                      field: "keyCrisisOtherContact2RoleInCrisis",
                    },
                    {
                      type: "textbox",
                      label: "Organisation (Second other crisis supporter)",
                      field: "keyCrisisOtherContact2Organisation",
                    },
                    {
                      type: "textbox",
                      label: "Phone number (Second other crisis supporter)",
                      field: "keyCrisisOtherContact2PhoneNumber",
                    },
                    {
                      type: "textbox",
                      label: "Email address (Second other crisis supporter)",
                      field: "keyCrisisOtherContact2EmailAddress",
                    },
                  ],
                },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "label",
      label: ["How people can access this Advance Choice Document"],
    },
    {
      type: "textbox",
      field: "ambulanceServiceCanAccess",
      label: "How can the ambulance service access this document?",
      rows: "5",
      checkboxToShowField: "ambulanceServiceCanAccessSelected",
    },

    {
      type: "textbox",
      field: "aAndEStaffAccess",
      label: "How can A&E staff access this document?",
      rows: "5",
      checkboxToShowField: "aAndEStaffAccessSelected",
    },

    {
      type: "textbox",
      field: "generalHospitalStaffAccess",
      label: "How can general hospital staff access this document?",
      rows: "5",
      checkboxToShowField: "generalHospitalStaffAccessSelected",
    },

    {
      type: "textbox",
      field: "mentalHealthTrustStaffAccess",
      label: "How can mental health trust staff access this document?",
      rows: "5",
      checkboxToShowField: "mentalHealthTrustStaffAccessSelected",
    },

    {
      type: "textbox",
      field: "AMHPsAccess",
      label:
        "How can Approved Mental Health Professionals (AMHPs) access this document?",
      rows: "5",
      checkboxToShowField: "AMHPsAccessSelected",
    },

    {
      type: "textbox",
      field: "GPsAccess",
      label: "How can GPs access this document?",
      rows: "5",
      checkboxToShowField: "GPsAccessSelected",
    },

    {
      type: "branch",
      field: "canFamilyAndFriendsAccess",
      label: "Can family and/or friends access this document?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "canPreferredContact1Access",
            "howPreferredContact1CanAccess",
            "canPreferredContact2Access",
            "canPreferredContact2Access",
            "howPreferredContact2CanAccess",
            "otherFamilyAndFriend1Name",
            "otherFamilyAndFriend1Relationship",
            "otherFamilyAndFriend1RoleInCrisis",
            "otherFamilyAndFriend1EmailAddress",
            "otherFamilyAndFriend1PhoneNumber",
            "otherFamilyAndFriend1Address",
            "otherFamilyAndFriend1HowCanAccess",
            "otherFamilyAndFriend2Name",
            "otherFamilyAndFriend2Relationship",
            "otherFamilyAndFriend2RoleInCrisis",
            "otherFamilyAndFriend2EmailAddress",
            "otherFamilyAndFriend2PhoneNumber",
            "otherFamilyAndFriend2Address",
            "otherFamilyAndFriend2HowCanAccess",
            "otherFamilyAndFriend2CanAccess",
          ],
          fields: [
            {
              type: "branch",
              field: "canPreferredContact1Access",
              label: "Use first preferred contact?",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: ["howPreferredContact1CanAccess"],
                  fields: [
                    {
                      type: "readonly",
                      label: "First preferred contact details",
                      textFn: (data) => [
                        `Name: ${data.preferredContact1Name}`,
                        `Relationship to you: ${data.preferredContact1Relationship}`,
                      ],
                    },
                    {
                      type: "textbox",
                      label: "How can they access?",
                      field: "howPreferredContact1CanAccess",
                      rows: "5",
                    },
                  ],
                },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
            {
              type: "branch",
              field: "doYouHaveASecondPreferredContact",
              branches: [
                {
                  fieldValue: true,
                  fieldsOwned: [
                    "howPreferredContact2CanAccess",
                    "canPreferredContact2Access",
                  ],
                  label: "Do you have a second preferred contact?",
                  hideLabel: true,
                  fields: [
                    {
                      type: "branch",
                      field: "canPreferredContact2Access",
                      label: "Use second preferred contact?",
                      branches: [
                        {
                          fieldValue: true,
                          label: "Yes",
                          fieldsOwned: ["howPreferredContact2CanAccess"],
                          fields: [
                            {
                              type: "readonly",
                              textFn: (data) => [
                                `Name: ${data.preferredContact2Name}`,
                                `Relationship to you: ${data.preferredContact2Relationship}`,
                              ],
                            },
                            {
                              type: "textbox",
                              label: "How can they access?",
                              field: "howPreferredContact2CanAccess",
                              rows: "5",
                            },
                          ],
                        },
                        {
                          fieldValue: false,
                          label: "No",
                          fieldsOwned: [],
                          fields: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "branch",
              field: "otherFamilyAndFriend1CanAccess",
              label: "Add another person?",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: [
                    "otherFamilyAndFriend1Name",
                    "otherFamilyAndFriend1Relationship",
                    "otherFamilyAndFriend1RoleInCrisis",
                    "otherFamilyAndFriend1EmailAddress",
                    "otherFamilyAndFriend1PhoneNumber",
                    "otherFamilyAndFriend1Address",
                    "otherFamilyAndFriend1HowCanAccess",
                    "otherFamilyAndFriend2Name",
                    "otherFamilyAndFriend2Relationship",
                    "otherFamilyAndFriend2RoleInCrisis",
                    "otherFamilyAndFriend2EmailAddress",
                    "otherFamilyAndFriend2PhoneNumber",
                    "otherFamilyAndFriend2Address",
                    "otherFamilyAndFriend2HowCanAccess",
                    "otherFamilyAndFriend2CanAccess",
                  ],
                  fields: [
                    {
                      type: "textbox",
                      label: "Name (Other Friend & Family)",
                      field: "otherFamilyAndFriend1Name",
                    },
                    {
                      type: "textbox",
                      label: "Relationship (Other Friend & Family)",
                      field: "otherFamilyAndFriend1Relationship",
                    },
                    {
                      type: "textbox",
                      label: "Role in crisis (Other Friend & Family)",
                      field: "otherFamilyAndFriend1RoleInCrisis",
                    },
                    {
                      type: "textbox",
                      label: "Phone number (Other Friend & Family)",
                      field: "otherFamilyAndFriend1PhoneNumber",
                    },
                    {
                      type: "textbox",
                      label: "Email address (Other Friend & Family)",
                      field: "otherFamilyAndFriend1EmailAddress",
                    },
                    {
                      type: "name-address",
                      disableEmail: true,
                      disableName: true,
                      addressLabel: "Address (Other Friend & Family)",
                      field: "otherFamilyAndFriend1Address",
                    },
                    {
                      type: "textbox",
                      label: "How can they access? (Other Friend & Family)",
                      field: "otherFamilyAndFriend1HowCanAccess",
                      rows: "5",
                    },
                    {
                      type: "branch",
                      field: "otherFamilyAndFriend2CanAccess",
                      label: "Add another person?",
                      branches: [
                        {
                          fieldValue: true,
                          label: "Yes",
                          fieldsOwned: [
                            "otherFamilyAndFriend2Name",
                            "otherFamilyAndFriend2Relationship",
                            "otherFamilyAndFriend2RoleInCrisis",
                            "otherFamilyAndFriend2EmailAddress",
                            "otherFamilyAndFriend2PhoneNumber",
                            "otherFamilyAndFriend2Address",
                            "otherFamilyAndFriend2HowCanAccess",
                          ],
                          fields: [
                            {
                              type: "textbox",
                              label: "Name (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2Name",
                            },
                            {
                              type: "textbox",
                              label:
                                "Relationship (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2Relationship",
                            },
                            {
                              type: "textbox",
                              label:
                                "Role in crisis (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2RoleInCrisis",
                            },
                            {
                              type: "textbox",
                              label:
                                "Phone number (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2PhoneNumber",
                            },
                            {
                              type: "textbox",
                              label:
                                "Email address (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2EmailAddress",
                            },
                            {
                              type: "name-address",
                              disableEmail: true,
                              disableName: true,
                              addressLabel:
                                "Address (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2Address",
                            },
                            {
                              type: "textbox",
                              label:
                                "How can they access? (Second Other Friend & Family)",
                              field: "otherFamilyAndFriend2HowCanAccess",
                              rows: "5",
                            },
                          ],
                        },
                        {
                          fieldValue: false,
                          label: "No",
                          fieldsOwned: [],
                          fields: [],
                        },
                      ],
                    },
                  ],
                },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "branch",
      field: "canOtherPerson1Access",
      label: "Can anyone else access this document?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "otherPersonWithAccess1Name",
            "otherPersonWithAccess1Role",
            "otherPersonWithAccess1HowCanAccess",
            "canOtherPerson2Access",
            "otherPersonWithAccess2Name",
            "otherPersonWithAccess2Role",
            "otherPersonWithAccess2HowCanAccess",
          ],
          fields: [
            {
              type: "textbox",
              label: "Name (Other person with access)",
              field: "otherPersonWithAccess1Name",
            },
            {
              type: "textbox",
              label: "Role in crisis (Other person with access)",
              field: "otherPersonWithAccess1Role",
            },
            {
              type: "textbox",
              rows: "5",

              label: "How can they access? (Other person with access)",
              field: "otherPersonWithAccess1HowCanAccess",
            },
            {
              type: "branch",
              field: "canOtherPerson2Access",
              label: "Can anyone else access this document?",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fieldsOwned: [
                    "otherPersonWithAccess2Name",
                    "otherPersonWithAccess2Role",
                    "otherPersonWithAccess2HowCanAccess",
                  ],
                  fields: [
                    {
                      type: "textbox",
                      label: "Name (Second other person with access)",
                      field: "otherPersonWithAccess2Name",
                    },
                    {
                      type: "textbox",
                      label: "Role in crisis (Second other person with access)",
                      field: "otherPersonWithAccess2Role",
                    },
                    {
                      type: "textbox",
                      rows: "5",
                      label:
                        "How can they access? (Second other person with access)",
                      field: "otherPersonWithAccess2HowCanAccess",
                    },
                  ],
                },
                { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
              ],
            },
          ],
        },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
  ],
  dataSchema: acdPart1DataSchema,
};
