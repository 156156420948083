import {
  CTO1Data,
  cto1Part1DataSchema,
  Cto1Part1DataSchema,
  cto1Part2DataSchema,
  Cto1Part2DataSchema,
  cto1Part3DataSchema,
  Cto1Part3DataSchema,
} from "../schemas/mhaForms/cto/cto1";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto1Part1Template: FormPartTemplate<Cto1Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment. ",
      ],
    },
    {
      type: "multi-select",
      label:
        "(b) In my opinion, it is necessary that this patient should receive treatment for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(c) In my opinion, such treatment can be provided without the patient continuing to be detained in a hospital provided the patient is liable to being recalled to hospital for medical treatment. ",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(d) In my opinion, it is necessary that the Responsible Clinician should be able to exercise the power under section 17E(1) to recall the patient to hospital.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(e) In my opinion, taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient. ",
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label: "Please enter the grounds your opinion is founded on",
      rows: "5",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I confirm that in determining whether the criterion at (d) above is met, I have considered what risk there would be of deterioration of the patient’s condition if the patient were not detained in hospital, with regard to the patient's history of mental disorder and any other relevant factors.",
      ],
    },
    {
      type: "label",
      label: [
        "Conditions to which the patient is to be subject by virtue of this community treatment order",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "The patient is to make himself or herself available for examination under section 20A, as requested",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "If it is proposed to give a certificate under Part 4A of the Act in the patient's case, the patient is to make himself or herself available for examination to enable the certificate to be given, as requested.",
      ],
    },
    {
      type: "branch",
      field: "subjectPatientToConditions",
      label:
        "Is the patient also subject to any other conditions under section 17B(2) of the Act?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fields: [
            {
              type: "textbox",
              field: "conditions",
              label:
                "Enter the conditions the patient is to be subject to under section 17B(2) of the Act",
              rows: "5",
            },

            {
              type: "label",
              showStaticLabel: true,
              label: [
                "I confirm that I consider the above conditions to be made under section 17B(2) of the Act are necessary or appropriate for one or more of the following purposes:",
              ],
            },

            {
              type: "label",
              showStaticLabel: true,
              showAsBulletPoint: true,
              indent: 3.5,
              label: [
                "to ensure that the patient receives medical treatment",
                "to prevent risk of harm to the patient's health or safety",
                "to protect other persons",
              ],
            },
          ],
          fieldsOwned: ["conditions"],
        },
        { fieldValue: false, label: "No", fields: [], fieldsOwned: [] },
      ],
    },
  ],
  dataSchema: cto1Part1DataSchema,
};

export const cto1Part2Template: FormPartTemplate<Cto1Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["amhp"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedMentalHealthProfessional,
    },
  },
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My name and address",
      field: "user",
      hideEmail: true,
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    { type: "label", showStaticLabel: true, label: ["I agree that:"] },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(i) the above patient meets the criteria for a community treatment order to be made",
        "(ii) it is appropriate to make a community treatment order, and",
        "(iii) the conditions made above under section 17B(2) are necessary or appropriate for one or more of the purposes specified",
      ],
    },
  ],
  dataSchema: cto1Part2DataSchema,
};

export const cto1Part3Template: FormPartTemplate<Cto1Part3DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I exercise my power under section 17A of the Mental Health Act 1983 to make a community treatment order in respect of the patient named in Part 1 of this Form. ",
      ],
    },
    {
      type: "date-time",
      label:
        "Enter the date and time this community treatment order is to be effective from",
      field: "effectiveDateTime",
      minimum: (today) => today,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: cto1Part3DataSchema,
  autoAssignToPartSignatory: 1,
};

export const cto1V1Base: BaseFormTemplate = {
  id: "mha-cto1",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO1",
  section: "17A",
  parts: [cto1Part1Template, cto1Part2Template, cto1Part3Template],
  description: "Community treatment order",
  extractGenericData: (data: CTO1Data) => ({
    standalone: {
      effectiveDateTime: data[2]?.data.effectiveDateTime,
    },
  }),
};
