import React, { useState, useEffect, useCallback } from "react";

import { Typeahead } from "~/components/design-system";
import {
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Button, FormTitle } from "~/components/design-system";
import { api } from "../../api";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../routes";
import { Membership } from "@aspire/common/types/memberships";
import { ThalamosUser } from "@aspire/common/types/user";
import { inputValueMatch } from "~/components/design-system/Typeahead/helpers";
import { getRoleLabel } from "~/util";

export interface ContextSelectorPageProps {
  userName: string;
  userId: string;
  memberships: (Membership & { name: string })[];
  sessionContext: boolean;
  defaultSelectedContext?: ThalamosUser["defaultSelectedContext"] | null;
  renderLogoutFn: () => void;
  reloadUser: () => void;
}

export function ContextSelectorPage({
  userId,
  userName,
  memberships,
  sessionContext,
  defaultSelectedContext,
  renderLogoutFn,
  reloadUser,
}: ContextSelectorPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = useState("");
  const [saveAsDefault, setSaveAsDefault] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    if (!defaultSelectedContext) {
      setSelectedTeam(
        selectedTeam
          .split(" ")
          .filter((word) => word !== "DEFAULT")
          .join(" "),
      );
      setSaveAsDefault(false);
      return;
    }

    const defaultRole = defaultSelectedContext.role;

    const defaultTeam = memberships.find(
      (m) =>
        (m.type === "team" &&
          m.teamId === defaultSelectedContext.id &&
          m.role === defaultRole) ||
        (m.type === "organisation" &&
          m.organisationId === defaultSelectedContext.id &&
          m.role === defaultRole),
    );

    if (defaultTeam) {
      setSelectedTeam(
        `${defaultTeam.name} - ${getRoleLabel(defaultTeam.role)} DEFAULT`,
      );
      setSaveAsDefault(true);
    } else {
      setSelectedTeam("");
      setSaveAsDefault(false);
    }
  }, [defaultSelectedContext, memberships]);

  const teamOptions = memberships.map((membership, index) => {
    const label = `${membership.name} - ${getRoleLabel(membership.role)}`;

    const isDefault =
      defaultSelectedContext &&
      ((membership.type === "team" &&
        membership.teamId === defaultSelectedContext.id &&
        membership.role === defaultSelectedContext.role) ||
        (membership.type === "organisation" &&
          membership.organisationId === defaultSelectedContext.id &&
          membership.role === defaultSelectedContext.role));

    const optionLabel = isDefault ? `${label} DEFAULT` : label;

    return {
      value:
        membership.type === "team"
          ? membership.teamId
          : membership.organisationId,
      label: optionLabel,
      role: membership.role,
      index,
      category: getRoleLabel(membership.role),
    };
  });

  const selectedTeamOption = teamOptions.find(
    (team) => team.label === selectedTeam,
  );

  const selectedTeamOptionRole = selectedTeamOption?.role;
  const selectedTeamOptionValue = selectedTeamOption?.value;

  useEffect(() => {
    if (
      selectedTeamOptionValue &&
      selectedTeamOptionValue === defaultSelectedContext?.id &&
      selectedTeamOptionRole &&
      selectedTeamOptionRole === defaultSelectedContext?.role
    )
      return setSaveAsDefault(true);

    return setSaveAsDefault(false);
  }, [selectedTeam]);

  const onSessionContextSelected = async (newContext: Membership) => {
    await api.users.setSessionContext(newContext);
    reloadUser();
  };

  const onDefaultContextChanged = async (
    newContext: ThalamosUser["defaultSelectedContext"] | null,
  ) => {
    return api.users.update(userId, {
      defaultSelectedContext: newContext,
    });
  };

  const redirectTo = useCallback(() => {
    navigate(getReturnToHref(search), { replace: true });
  }, [navigate, search]);

  return (
    <ContextSelectorInnerPage
      userName={userName}
      sessionContext={sessionContext}
      selectedTeam={selectedTeam}
      setSelectedTeam={setSelectedTeam}
      saveAsDefault={saveAsDefault}
      setSaveAsDefault={setSaveAsDefault}
      memberships={memberships}
      onSessionContextSelected={onSessionContextSelected}
      onDefaultContextChanged={onDefaultContextChanged}
      selectedTeamOption={selectedTeamOption}
      teamOptions={teamOptions}
      renderLogoutFn={renderLogoutFn}
      redirectTo={redirectTo}
      defaultSelectedContext={defaultSelectedContext}
      closeTeamSelector={() => navigate(-1)}
    />
  );
}

type TeamOptions = {
  value: string;
  label: string;
  role: string;
  index: number;
  category: string;
};

type ContextSelectorInnerLayoutProps = {
  userName: string;
  sessionContext: boolean;
  selectedTeam: string;
  setSelectedTeam: (team: string) => void;
  saveAsDefault: boolean;
  setSaveAsDefault: (save: boolean) => void;
  memberships: (Membership & { name: string })[];
  onSessionContextSelected: (membership: Membership) => void;
  onDefaultContextChanged: (
    defaultSelectedContext: ThalamosUser["defaultSelectedContext"] | null,
  ) => void;
  selectedTeamOption: TeamOptions | undefined;
  teamOptions: TeamOptions[];
  renderLogoutFn: () => void;
  redirectTo: () => void;
  defaultSelectedContext?: ThalamosUser["defaultSelectedContext"] | null;
  closeTeamSelector: () => void;
};

export function ContextSelectorInnerPage({
  userName,
  sessionContext,
  setSelectedTeam,
  selectedTeam,
  teamOptions,
  saveAsDefault,
  setSaveAsDefault,
  renderLogoutFn,
  memberships,
  onDefaultContextChanged,
  onSessionContextSelected,
  selectedTeamOption,
  redirectTo,
  defaultSelectedContext,
  closeTeamSelector,
}: ContextSelectorInnerLayoutProps) {
  const hasNotSelectedTeamMatch = inputValueMatch(
    teamOptions,
    "label",
    selectedTeam,
  );

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "80vh",
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ p: 4, minWidth: { md: 600 } }}>
        <Box display="flex" justifyContent="space-between">
          <FormTitle subtitleText={t("pages.contextSelector.subtitleText")} />
          {sessionContext && (
            <Close
              data-testid="close-team-selector"
              sx={{
                color: "primary.hint",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              fontSize="small"
              onClick={closeTeamSelector}
            />
          )}
        </Box>
        <Typography sx={{ mb: 3.175, fontWeight: 600 }}>
          {t("pages.contextSelector.welcome")} {userName}!.{" "}
          {t("pages.contextSelector.chooseContext")}
        </Typography>
        <Typeahead
          enableFuse={false}
          testId="select-team"
          onInputChange={setSelectedTeam}
          placeholder={t("pages.contextSelector.typeaheadPlaceholder")}
          name={"select-form"}
          inputValue={selectedTeam}
          label={t("pages.contextSelector.typeaheadLabel")}
          options={teamOptions}
          groupBy={(option: TeamOptions) => option.category}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={saveAsDefault}
              onChange={async () => {
                const newSaveAsDefault = !saveAsDefault;
                setSaveAsDefault(newSaveAsDefault);

                if (newSaveAsDefault) {
                  await onDefaultContextChanged({
                    id: selectedTeamOption?.value ?? "",
                    role: selectedTeamOption?.role ?? "",
                  });
                } else if (
                  !newSaveAsDefault &&
                  defaultSelectedContext?.id === selectedTeamOption?.value
                ) {
                  await onDefaultContextChanged(null);
                }
              }}
              disabled={!hasNotSelectedTeamMatch}
              data-testid="save-as-default"
            />
          }
          label={t("pages.contextSelector.saveAsDefaultTeam")}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3.2,
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            label={t("pages.contextSelector.logOut")}
            onClick={renderLogoutFn}
          />
          <Button
            disabled={!hasNotSelectedTeamMatch}
            label={t("pages.contextSelector.continue")}
            onClick={async () => {
              if (!selectedTeam) return;

              const teamsOrgsWithoutName = memberships.map(
                ({ name, ...rest }) => rest,
              );

              await onSessionContextSelected(
                teamsOrgsWithoutName[
                  selectedTeamOption?.index ?? 0
                ] as Membership,
              );

              return redirectTo();
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
}

function getReturnToHref(search: string) {
  const query = new URLSearchParams(search);
  const fromParam = query.get("returnTo");

  // Default to root path and prevent us redirecting to the page we're on
  if (!fromParam || fromParam === routeFns.contextSelector()) return "/";
  else return fromParam;
}
