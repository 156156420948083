import {
  BranchingFormBuilderField,
  MhFormContext,
} from "@aspire/common/types/form";
import { FieldProps } from "../../pages/FieldProps";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import React from "react";
import { HelperText } from "../design-system/HelperText/HelperText";
import { renderField } from "../../pages/FormDraft/FormDraftCompletionPage";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Banner, BannerList } from "../design-system/Banner/Banner";

export type BranchFormFieldProps<Data extends { [k: string]: any }> = {
  field: BranchingFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
  disabled?: boolean;
};

export function BranchFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: BranchFormFieldProps<Data>) {
  const theme = useTheme();

  const isError =
    fieldProps.errors[field.field] && fieldProps.touched[field.field];

  const value = fieldProps.values[field.field];

  const showWarningBanner =
    field.warningBanner &&
    field.warningBanner.condition(
      fieldProps.values,
      fieldProps.context as MhFormContext,
    );

  return (
    <Box sx={{ mb: field.branches[0]?.hideLabel ? 0 : 3 }}>
      <FormGroup data-testid={`field:${field.field}`}>
        {field.label && (
          <Typography
            css={css`
              margin-bottom: ${theme.spacing(0.5)};
              font-weight: bold;
              font-size: ${theme.spacing(1.875)};
              color: ${isError && theme.palette.error.main};
            `}
          >
            {field.label}
          </Typography>
        )}

        {field.afterLabel && (
          <Box sx={{ mb: 0.5 }}>
            <HelperText subtext={field.afterLabel} />
          </Box>
        )}

        <RadioGroup value={value} name={field.field}>
          {field.branches.map((b) =>
            b.hideLabel ? (
              <Box sx={{ mt: "-4em" }}></Box>
            ) : (
              <>
                <BoxRadioFormControlLabel
                  field={field}
                  fieldProps={fieldProps}
                  branch={b}
                />
                {b.visibleFields &&
                  b.visibleFields.map((fieldConfig, index) => {
                    const shouldFieldBeInDisabledState = b.fieldValue === value;

                    const updatedFieldConfig = {
                      ...fieldConfig,
                      disabled: !shouldFieldBeInDisabledState,
                    };

                    return (
                      <>
                        <Box key={index}>
                          {renderField(updatedFieldConfig, fieldProps)}
                        </Box>
                      </>
                    );
                  })}
              </>
            ),
          )}
        </RadioGroup>
      </FormGroup>
      {field.branches
        .find((branch) => branch.fieldValue === value)
        ?.fields.map((fieldConfig) => {
          return (
            <Box sx={{ mt: 1.15 }}>{renderField(fieldConfig, fieldProps)}</Box>
          );
        })}
      {isError && (
        <HelperText errorMessage={fieldProps.errors[field.field] as string} />
      )}
      {showWarningBanner && (
        <Box sx={{ mt: 2 }}>
          <Banner
            title={field.warningBanner!.message}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}
    </Box>
  );
}

export function BoxRadioFormControlLabel<Data extends { [k: string]: any }>({
  field,
  fieldProps,
  branch,
}: {
  field: BranchingFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
  branch: any;
}) {
  const theme = useTheme();
  const allFields = field.branches.flatMap((branch) => branch.fieldsOwned);
  return (
    <Box
      sx={{
        display: "inline-block",
        ml: field.indent ? field.indent : 0,
      }}
    >
      <FormControlLabel
        value={branch.fieldValue}
        disabled={field.disabled}
        control={
          <Radio
            icon={<RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />}
            checkedIcon={
              <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
            }
            onClick={() => {
              let defaultValues = fieldProps.validationSchema.getDefault();
              fieldProps.setValues({
                // Existing value for non-affected fields
                ...fieldProps.values,
                // Reset values for fields "owned" by the branch field
                ...Object.fromEntries(
                  allFields.map((field) => [field, defaultValues[field]]),
                ),
                // The new value of the branch field itself
                [field.field]: branch.fieldValue,
              });
              allFields.map((field) => {
                fieldProps.setFieldTouched(field, false, false);
              });
            }}
          />
        }
        label={
          <Typography
            css={css`
              font-size: ${theme.spacing(1.75)};
            `}
          >
            {branch.label}
          </Typography>
        }
      />
    </Box>
  );
}
