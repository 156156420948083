import React, { useState } from "react";
import {
  Banner,
  BannerList,
  PopupDialog,
  TextField,
  Button,
  FormTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { Box } from "@mui/material";
import { api } from "../../../../api";
import { routeFns } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import { ExtendedThalamosUser } from "@aspire/common/types/user";

export function AddFileNoteDialog({
  formId,
  closeFn,
  reloadFormContext,
  formContextId,
  patientId,
  user,
}: {
  formId: string;
  closeFn: () => void;
  reloadFormContext: () => void;
  formContextId: string;
  patientId: string;
  user: ExtendedThalamosUser;
}) {
  const [fileNote, setFileNote] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const navigate = useNavigate();

  const sendFileNoteRequest = async () => {
    setIsSubmitting(true);
    try {
      const result = await api.forms.addFileNote(formId, {
        note: fileNote,
      });
      if (result.status === 204) {
        const message = `A file note was successfully added`;
        renderSuccessToast({ message });
        reloadFormContext();
        navigate(
          routeFns.formContextPageSuccessDialog(formContextId, patientId, {
            title: "Action successful",
            message: message,
          }),
          { replace: true },
        );
      } else {
        const errorMessage = (result.data as any)?.reason || "Unknown error";
        throw new Error(errorMessage);
      }
    } catch (e: any) {
      renderErrorToast({ message: e.message });
      setSubmitError(e.message);
      setIsSubmitting(false);
    }
  };
  return (
    <PopupDialog open={true} onClose={closeFn} testId="add-file-note">
      <FormTitle
        useReducedTopPadding
        hasTitleBottomMargin={false}
        subtitleText={"Add file note"}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.INFO}
          title={"This file note will be saved as a page attatched to the PDF"}
        />
      </Box>
      <TextField
        name="addFileNote"
        label="Add file note"
        onChange={(e) => setFileNote(e)}
        value={fileNote}
        useFullWidth={true}
        multiline
        rows="5"
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 3,
        }}
      >
        <Button label={"Close"} onClick={closeFn} variant="outlined" />
        <Button
          color="primary"
          disabled={!fileNote && !isSubmitting}
          label={"Confirm"}
          onClick={sendFileNoteRequest}
        />
      </Box>
    </PopupDialog>
  );
}
