import React, { useRef } from "react";
import { ManagedLocationsContext } from "../../Contexts";
import Fuse from "fuse.js";
import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box, useTheme } from "@mui/material";
import { ConfirmationModal } from "../ConfirmationModal";
import { bool, object, string } from "yup";
import { api } from "../../api";
import {
  Button,
  ButtonIcon,
  MenuFlyout,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { breakpoints } from "../../styles/themeCommon";
import { css } from "@emotion/react";
import { Formik } from "formik";
import {
  formValidationErrorMessages,
  nonEmptyString,
  postalCodeValidationRule,
} from "@aspire/common/schemas/shared";
import {
  DropdownFormField,
  NameAddressFormField,
  TextboxFormField,
} from "~/components/form";
import {
  ManagedLocation,
  ManagedLocationTrustStatus,
} from "@aspire/common/managedLocations";

const managedLocationSchema = object({
  type: string()
    .default(null)
    .oneOf(["hospital", "localAuthority"])
    // @ts-ignore
    .nonNullable("Type is required"),

  typeLabel: string().default(""),

  name: nonEmptyString
    .default("")
    .required("Please enter a name")
    .max(255, formValidationErrorMessages.tooManyCharactersError),

  address: object({
    address: nonEmptyString
      .required("Address is required")
      .max(255, formValidationErrorMessages.tooManyCharactersError),
    postalCode: postalCodeValidationRule("type", "hospital"),
    isConfirmed: bool().required().isTrue("Please confirm your address"),
  }).default({
    address: "",
    isConfirmed: false,
    postalCode: "",
  }),

  trustStatus: string()
    .oneOf(["active", "inactive"])
    .default("active")
    .required(),

  managingOrganisation: nonEmptyString
    .default("")
    .required("Trust/Organisation is required"),
  odsCode: string().nullable().default(null).optional(),
});

type ManagedLocationFormData = {
  type: "hospital" | "localAuthority" | null;

  typeLabel: string;
  name: string;
  trustStatus: ManagedLocationTrustStatus;
  address: {
    address: string;
    postalCode: string;
    isConfirmed: boolean;
  };
  managingOrganisation: string;
  odsCode: string | null;
};

export function ListsManagementPage() {
  const theme = useTheme();
  const managedLocationsContext = React.useContext(ManagedLocationsContext);

  const managedLocationSearch = new Fuse(
    managedLocationsContext.managedLocations,
    {
      keys: ["name", "address", "postalCode", "odsCode"],
      threshold: 0.3,
    },
  );

  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] =
    React.useState<ManagedLocation | null>(null);
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(0);

  const results = query
    ? managedLocationSearch.search(query)
    : managedLocationsContext.managedLocations.map((ml) => ({ item: ml }));

  const numPages =
    results && results.length ? Math.ceil(results.length / 10) : 1;

  return (
    <Box>
      <PopupDialog
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      >
        <Formik<ManagedLocationFormData>
          validationSchema={managedLocationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const result = await api.configuration.addAdminManagedLocation({
              type: values.type!,
              name: values.name.trim(),
              address: values.address.address,
              postalCode: values.address.postalCode,
              trustStatus: values.trustStatus,
              odsCode: values.odsCode ? values.odsCode : undefined,
              managingOrganisation: values.managingOrganisation
                ? values.managingOrganisation.trim()
                : undefined,
            });

            if (result.status === 204) {
              renderSuccessToast({ message: "Managed location added" });
              managedLocationsContext.refetchManagedLocations();
              setShowCreateDialog(false);
            } else if (result.status === 409) {
              // @ts-ignore
              renderErrorToast({ message: result.data.reason });
            } else {
              renderErrorToast({ message: "Failed to add managed location" });
            }
            setSubmitting(false);
          }}
          initialValues={
            {
              type: null,
              name: "",
              address: { address: "", postalCode: "", isConfirmed: false },
              managingOrganisation: "",
              trustStatus: "active",
              odsCode: "",
            } as ManagedLocationFormData
          }
        >
          {(formikData) => {
            const {
              values,
              setValues,
              setFieldTouched,
              errors,
              touched,
              handleBlur,
              submitForm,
            } = formikData;

            const fieldProps = {
              validationSchema: managedLocationSchema,
              values,
              setValues,
              setFieldTouched,
              errors,
              touched,
              context: {},
              handleBlur,
            };

            return (
              <>
                <Box sx={{ mb: 1 }}>
                  <DropdownFormField
                    field={{
                      type: "dropdown",
                      label: "Type (*)",
                      valueField: "type",
                      textField: "typeLabel",
                      options: [
                        { label: "Hospital", value: "hospital" },
                        { label: "Local Authority", value: "localAuthority" },
                      ],
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box sx={{ mb: 0 }}>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      label: "Name (*)",
                      field: "name",
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <NameAddressFormField
                    field={{
                      type: "name-address",
                      disableName: true,
                      field: "address",
                      addressLabel: "Address (*)",
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      label: "Trust/Organisation (*)",
                      field: "managingOrganisation",
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.trustStatus === "active"}
                        onChange={() =>
                          setValues({
                            ...values,
                            trustStatus:
                              values.trustStatus === "active"
                                ? "inactive"
                                : "active",
                          })
                        }
                      />
                    }
                    label="Display Trust?"
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      label: "ODS Code (optional)",
                      field: "odsCode",
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Button label={"Create"} onClick={submitForm} />
              </>
            );
          }}
        </Formik>
      </PopupDialog>

      <PopupDialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      >
        {selectedItem && (
          <Formik<ManagedLocationFormData>
            validationSchema={managedLocationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const result = await api.configuration.updateAdminManagedLocation(
                selectedItem.id,
                {
                  type: values.type!,
                  name: values.name.trim(),
                  address: values.address.address,
                  postalCode: values.address.postalCode,
                  trustStatus: values.trustStatus,
                  odsCode: values.odsCode ? values.odsCode : undefined,
                  managingOrganisation: values.managingOrganisation
                    ? values.managingOrganisation.trim()
                    : undefined,
                },
              );

              if (result.status === 204) {
                renderSuccessToast({ message: "Managed location updated" });
                managedLocationsContext.refetchManagedLocations();
                setShowEditDialog(false);
              } else if (result.status === 409) {
                renderErrorToast({
                  // @ts-ignore
                  message: result.data.reason,
                });
              } else {
                renderErrorToast({
                  message: "Failed to update managed location",
                });
              }
              setSubmitting(false);
            }}
            initialValues={
              {
                type: selectedItem.type,
                name: selectedItem.name,
                trustStatus: selectedItem.trustStatus,
                address: {
                  address: selectedItem.address,
                  postalCode: selectedItem.postalCode,
                  isConfirmed: true,
                },
                managingOrganisation: selectedItem.managingOrganisation,
                odsCode: selectedItem.odsCode,
              } as ManagedLocationFormData
            }
          >
            {(formikData) => {
              const {
                values,
                setValues,
                setFieldTouched,
                errors,
                touched,
                handleBlur,
                submitForm,
              } = formikData;

              const fieldProps = {
                validationSchema: managedLocationSchema,
                values,
                setValues,
                setFieldTouched,
                errors,
                touched,
                context: {},
                handleBlur,
              };

              return (
                <>
                  <Box sx={{ mb: 1 }}>
                    <DropdownFormField
                      field={{
                        type: "dropdown",
                        label: "Type (*)",
                        valueField: "type",
                        textField: "typeLabel",
                        options: [
                          { label: "Hospital", value: "hospital" },
                          { label: "Local Authority", value: "localAuthority" },
                        ],
                      }}
                      fieldProps={fieldProps}
                    />
                  </Box>
                  <Box sx={{ mb: 0 }}>
                    <TextboxFormField
                      field={{
                        type: "textbox",
                        label: "Name (*)",
                        field: "name",
                      }}
                      fieldProps={fieldProps}
                    />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <NameAddressFormField
                      field={{
                        type: "name-address",
                        disableName: true,
                        field: "address",
                        addressLabel: "Address (*)",
                      }}
                      fieldProps={fieldProps}
                    />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <TextboxFormField
                      field={{
                        type: "textbox",
                        label: "Trust/Organisation (*)",
                        field: "managingOrganisation",
                      }}
                      fieldProps={fieldProps}
                    />
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.trustStatus === "active"}
                          onChange={() =>
                            setValues({
                              ...values,
                              trustStatus:
                                values.trustStatus === "active"
                                  ? "inactive"
                                  : "active",
                            })
                          }
                        />
                      }
                      label="Display Trust?"
                    />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <TextboxFormField
                      field={{
                        type: "textbox",
                        label: "ODS Code (optional)",
                        field: "odsCode",
                      }}
                      fieldProps={fieldProps}
                    />
                  </Box>
                  <Button label={"Update"} onClick={submitForm} />
                </>
              );
            }}
          </Formik>
        )}
      </PopupDialog>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Type to filter..."}
          sx={{ width: "100%" }}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box
          css={css`
            @media (max-width: ${breakpoints.values.sm}px) {
              margin-left: ${theme.spacing(2)};
            }

            display: flex;
            margin-bottom: ${theme.spacing(2)};
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Button
            variant={"contained"}
            label={"Create"}
            endIcon={ButtonIcon.add}
            onClick={() => setShowCreateDialog(true)}
          />
        </Box>
      </Box>
      <Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
          <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
            Page {page + 1} of {numPages}
          </Box>
          <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
            &#62;
          </button>
        </Box>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Address</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Postcode</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  Trust/Organisaton
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Display Trust?</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>ODS Code</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results &&
                results
                  .slice(page * 10, page * 10 + 10)
                  .map((r) => (
                    <ListManagementItems
                      setShowEditDialog={setShowEditDialog}
                      setSelectedItem={setSelectedItem}
                      resultItem={r}
                      refetchManagedLocations={
                        managedLocationsContext.refetchManagedLocations
                      }
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export function ListManagementItems({
  resultItem,
  setShowEditDialog,
  setSelectedItem,
  refetchManagedLocations,
}: {
  resultItem: { item: ManagedLocation };
  setShowEditDialog: (value: boolean) => void;
  setSelectedItem: (item: ManagedLocation) => void;
  refetchManagedLocations: () => void;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const options = [
    {
      name: "Edit",
      onClick: () => {
        setSelectedItem(resultItem.item);
        setShowEditDialog(true);
      },
      icon: "edit",
      disabled: false,
    },
    {
      name: "Delete",
      onClick: () => {
        setConfirmFn({
          confirmFn: async () => {
            const result = await api.configuration.deleteAdminManagedLocation(
              resultItem.item.id,
            );

            if (result.status === 204) {
              renderSuccessToast({
                message: "Successfully deleted location.",
              });
              refetchManagedLocations();
            } else {
              renderErrorToast({
                message: "Failed to delete location.",
              });
            }

            setConfirmFn(null);
          },
          message: `Are you sure you want to delete ${resultItem.item.name}?`,
        });
      },
      icon: "delete",
      disabled: false,
    },
  ];

  return (
    <>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      <TableRow key={resultItem.item.name}>
        <TableCell>
          {resultItem.item.type === "localAuthority" ? "LA" : "Hospital"}
        </TableCell>
        <TableCell>{resultItem.item.name}</TableCell>
        <TableCell>{resultItem.item.address}</TableCell>
        <TableCell>{resultItem.item.postalCode}</TableCell>
        <TableCell>{resultItem.item.managingOrganisation}</TableCell>
        <TableCell>{resultItem.item.trustStatus}</TableCell>
        <TableCell>{resultItem.item.odsCode}</TableCell>
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
