import {
  BaseFormTemplate,
  FormContextMiniForm,
  FormPartTemplate,
  ValidationOutcome,
} from "../types/form";
import { FormContextData } from "../types/formContextData";
import { MergedPatientData } from "../types/patientRequests";

export type SelectedFormsObject = {
  id: string;
  templateId: string;
};

export type FormTemplateWithLongDescription = BaseFormTemplate & {
  longDescription: string;
};

export function getHeadingOrSubtitle(
  formContextType: string,
  mainFormTemplate: BaseFormTemplate | null,
  applicationTemplate: BaseFormTemplate | null,
  isHeading: boolean,
): string {
  if (formContextType === "admission") {
    const template = isHeading ? mainFormTemplate : applicationTemplate;

    if (template) {
      return `Section ${template.section} - Admission`;
    } else {
      return "Admission";
    }
  } else {
    return getFullFormName(mainFormTemplate);
  }
}

export function getFullFormName(template: BaseFormTemplate | null): string {
  if (!template) return "";

  const formName =
    template.category === "discharge"
      ? template.formName
      : `Form ${template.formName}`;

  if (template.section)
    return `${formName}: Section ${template?.section}${
      template?.description ? ` - ${template?.description}` : ""
    }`;

  // Form without a section:
  return `${formName}${
    template?.description ? `: ${template?.description}` : ""
  }`;
}

// This helps us find if a form has been merged or not, it will return null if it cannot find a merged form
export function findFormsMerged(
  mergedPatientData: MergedPatientData | undefined,
  formContext: Omit<FormContextData, "patient">,
  form?: FormContextMiniForm,
) {
  if (!mergedPatientData || !formContext) return null;

  const { patientId } = formContext;

  // find all the form context and/or forms that have been merged
  const hasBeenMerged = findMergedInfoIfFormBeforeLastMerge(
    mergedPatientData,
    formContext,
    patientId,
    form,
  );

  // Check if forms belong to a different patient when no form is provided if true then the form would have been merged
  const isNotCurrentPatientForms =
    !form &&
    !formContext.forms.some((formEntry) => formEntry.patientId === patientId)
      ? mergedPatientData
      : null;

  // Check if the form belongs to a different patient when a form is provided if they are not the same then we assume that form has been merged
  const isDifferentPatientInForm =
    form && form.patientId !== patientId ? mergedPatientData : null;

  return hasBeenMerged || isNotCurrentPatientForms || isDifferentPatientInForm;
}

export function findMergedInfoIfFormBeforeLastMerge(
  mergedPatientData: MergedPatientData | undefined,
  formContext: Omit<FormContextData, "patient">,
  patientId: string,
  form?: FormContextMiniForm,
) {
  const hasFormBeenMerged =
    mergedPatientData?.previouslyMergedPatientsAndFormContextInfo?.find(
      (mergedInfo) =>
        mergedInfo.formContextId === formContext.id ||
        (form && mergedInfo.patientId !== patientId),
    );

  // we assume the form has been merged if we can match the patient id to the hasFormBeenMerged patient id
  const isFormPatientIdMatchingLastMerge = form
    ? form.patientId === hasFormBeenMerged?.patientId
    : hasFormBeenMerged;

  return isFormPatientIdMatchingLastMerge ? hasFormBeenMerged : null;
}

export function canLaunchForm({
  linkableForms,
  supportingFormsProvided,
  notSelectedReason,
  validationOutcome,
  requiresSupportingForms,
}: {
  linkableForms: FormPartTemplate<any>["signing"]["linkableForms"];
  supportingFormsProvided: boolean;
  notSelectedReason: string;
  validationOutcome: ValidationOutcome[] | null;
  requiresSupportingForms: boolean;
}) {
  const isBypassed =
    linkableForms?.isBypassable &&
    supportingFormsProvided &&
    !!notSelectedReason;
  const hasValidSelection =
    validationOutcome?.some((v) => v.type === "success") ||
    validationOutcome?.every((v) => v.type === "warning" && v.isIgnorable);
  const canLaunchForm =
    !requiresSupportingForms || isBypassed || hasValidSelection;

  return canLaunchForm;
}

// Intialise selected formTemplate state
export function initialiseForm(
  activeTemplates: FormTemplateWithLongDescription[],
  formTemplateIdFromUrl: string | undefined,
) {
  let initialFormTemplate =
    activeTemplates.find((t) => t.id === formTemplateIdFromUrl) ??
    (activeTemplates.length === 1 ? activeTemplates[0] : undefined);

  const initialFormInputValue = initialFormTemplate?.longDescription ?? "";

  return { initialFormInputValue, initialFormTemplate };
}

// Pre-compute the "long description" for each form template + sort forms by name
export function preprocessTemplates<T extends BaseFormTemplate>(
  templates: T[],
) {
  const numericCollator = new Intl.Collator([], { numeric: true });
  return templates
    .map((template) => ({
      ...template,
      longDescription: getFullFormName(template),
    }))
    .sort((a, b) => numericCollator.compare(a.formName, b.formName));
}
