import { InferType, object, string } from "yup";
import {
  defaultAddress,
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  hospital,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

const patientAccreditationStatus = string()
  .oneOf([
    "current-responsible-hospital",
    "transfer-to-responsible-hospital",
    "patient-responsible-hospital",
  ])
  .default(null)
  .required("Please confirm the correct option");

export const cto6Part1DataSchema = object({
  ...defaultContextFields({ userAddressOptional: true }),

  hospitalDetained: hospital,

  hospitalTransferred: hospital,

  patientAccreditationStatus: patientAccreditationStatus,

  responsibleHospital: object()
    .optional()
    .default(defaultAddress)
    .when("patientAccreditationStatus", {
      is: (value: string) => value === "patient-responsible-hospital",
      then: () => hospital,
      otherwise: () => object().default(defaultAddress).optional(),
    }),
}).required();

export const cto6Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  hospitalAdmittedTo: object({
    name: string().trim().required("Please enter hospital name"),
  })
    .nullable()
    .default({ name: "" }),

  admissionDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date and time of admission to receiving hospital"),
}).required();

export type Cto6Part1DataSchema = InferType<typeof cto6Part1DataSchema>;
export type Cto6Part2DataSchema = InferType<typeof cto6Part2DataSchema>;

export type CTO6Data = [
  PartData<Cto6Part1DataSchema>,
  PartData<Cto6Part2DataSchema>?,
];

export const exampleCTO6Data: ExampleFormData<CTO6Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospitalDetained: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },

      hospitalTransferred: {
        name: "Hospital",
        address: "Hospital Address 2",
        postalCode: "ABC 123",
        isConfirmed: true,
      },

      patientAccreditationStatus: "patient-responsible-hospital",

      responsibleHospital: {
        name: "Hospital",
        address: "Hospital Address 3",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      hospitalAdmittedTo: {
        name: "Hospital",
      },

      admissionDateTime: "2022-07-10T12:00:00.000Z",
    },
  },
];
