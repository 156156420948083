import { bool, boolean, InferType, object, string } from "yup";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

export const defaultAddress = {
  address: "",
  postalCode: "",
  isConfirmed: false,
  name: "",
};

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export function dateAndTimeWithin24HoursValidation() {
  return isoDateTimeString.test(
    "within-24-hours",
    "You cannot enter a date and/or time more than 24 hours in the past.",
    (value: string | null) => {
      return dateAndTimeWithin24Hours(value);
    },
  );
}

export function dateAndTimeWithin24Hours(value: string | null): boolean {
  const currentDate = dayjs();
  const twentyFourHoursAgo = currentDate.subtract(24, "hour");

  return dayjs(value).isAfter(twentyFourHoursAgo);
}

// Common function for date validation
function generateDateValidationRule(
  dateType: "date" | "date-time",
  description: string,
  validationFunction: (value: string | null) => boolean,
) {
  const formatString =
    dateType === "date-time" ? isoDateTimeString : isoDateString;

  return formatString.test(
    "date-range-validation",
    description,
    validationFunction,
  );
}

// Function to validate if a date is before today
export function generateDateBeforeTodayValidationRule(
  dateType: "date" | "date-time",
  description: string,
) {
  return generateDateValidationRule(dateType, description, isDateBeforeToday);
}

// Function to validate if a date is after today
export function generateDateAfterTodayValidationRule(
  dateType: "date" | "date-time",
  description: string,
) {
  return generateDateValidationRule(dateType, description, isDateAfterToday);
}

// Since the date is in UTC, we need to add the UK offset to the current date
// as the date coming from the browser might be in BST. This logic is very specific
// to validating dates sent from the browser to the server and should not be used
// for other purposes
// ASP-1760
function ukLocalDate() {
  const ukOffset = dayjs().tz("Europe/London").utcOffset();
  return dayjs().add(ukOffset, "minute");
}

// Shared function to check if a given date is before today
function isDateBeforeToday(value: string | null): boolean {
  if (!value) {
    // Allow null values
    return true;
  }

  // Since the date is in UTC, we need to add the UK offset to the current date
  // as the date coming from the browser might be in BST
  const currentDate = ukLocalDate();
  const enteredDate = dayjs(value);
  return enteredDate.isSameOrBefore(currentDate, "day");
}

// Shared function to check if a given date is after today
function isDateAfterToday(value: string | null): boolean {
  if (!value) {
    // Allow null values
    return true;
  }

  const currentDate = ukLocalDate();
  const enteredDate = dayjs(value);

  return enteredDate.isSameOrAfter(currentDate, "day");
}

export function postalCodeValidationRule(
  propertyName: string,
  valueToMatch: string,
) {
  return string()
    .test(
      "postalCodeRequired",
      "Postal code is required",
      function (value, context) {
        // Retrieve the value of the specified property name from the context
        const propertyValue = context?.from?.[1]?.value[propertyName];
        // Check if the property value matches the provided value and postal code is not provided
        if (propertyValue === valueToMatch && !value) {
          // Return a validation error indicating that the postal code is required
          return this.createError({
            message: "Postal code is required",
            path: "address.postalCode",
          });
        }
        return true;
      },
    )
    .default("")
    .max(8, formValidationErrorMessages.postalCodeCharacterError);
}

export function requiredPostalCode() {
  return string()
    .trim()
    .required(formValidationErrorMessages.postalCodeRequiredError)
    .max(8, formValidationErrorMessages.postalCodeCharacterError);
}

export const optionalAddress = () => {
  return object({
    address: string()
      .trim()
      .max(255, formValidationErrorMessages.tooManyCharactersError),
    postalCode: string()
      .trim()
      .max(8, formValidationErrorMessages.postalCodeCharacterError), // TODO: regex
    isConfirmed: bool()
      .default(false)
      .when("address", {
        is: (a: string) => a,
        then: (s) => s.oneOf([true], "Please confirm the address"),
        otherwise: (s) => s,
      }),
  })
    .nullable()
    .default(defaultAddress);
};

export const formValidationErrorMessages = {
  nameRequiredError: "Name is required",
  addressRequiredError: "Address is required",
  addressConfirmationRequiredError: "Address confirmation is required",
  postalCodeRequiredError: "Please enter a postal code",
  postalCodeCharacterError: "Too many characters entered for postcode.",
  tooManyCharactersError: "Too many characters entered.",
};

export const nameAndAddress = (
  errorOverrides: {
    nameRequiredError?: string;
    addressRequiredError?: string;
    addressConfirmationRequiredError?: string;
  } = {},
  isPostCodeRequired = false,
) => {
  const errorMessages = {
    ...formValidationErrorMessages,
    ...errorOverrides,
  };
  return object({
    name: string()
      .trim()
      .required(errorMessages.nameRequiredError)
      .max(255, errorMessages.tooManyCharactersError),
    address: string()
      .trim()
      .required(errorMessages.addressRequiredError)
      .max(255, errorMessages.tooManyCharactersError),
    postalCode: isPostCodeRequired
      ? requiredPostalCode()
      : string().trim().max(8, errorMessages.postalCodeCharacterError),
    isConfirmed: bool()
      .default(false)
      .when("address", {
        is: (a: string) => a,
        then: (s) =>
          s.oneOf([true], errorMessages.addressConfirmationRequiredError),
        otherwise: (s) => s,
      }),
  })
    .nullable()
    .default(null);
};

export const hospital = object({
  name: string()
    .trim()
    .required("Please enter hospital name")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  address: string()
    .trim()
    .required("Please enter hospital address")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  postalCode: requiredPostalCode(), // TODO regex
  isConfirmed: bool()
    .required("Please confirm the name and address is correct")
    .oneOf([true], "Please confirm the name and address is correct"),
})
  .required("hospital-required")
  .default(defaultAddress);

type DefaultContextOps = {
  localAuthorityRequired?: boolean;
  section12Required?: boolean;
  approvedClinicianRequired?: boolean;
  userNameOptional?: boolean;

  userAddressOptional?: boolean;

  nameErrorMessage?: string;
};
export const practitioner = ({
  localAuthorityRequired,
  section12Required,
  approvedClinicianRequired,
  userAddressOptional,
  userNameOptional,
  nameErrorMessage,
}: DefaultContextOps = {}) =>
  object({
    // Thalamos user ID
    id: string(),

    email: string()
      .trim()
      .matches(/.+@.+\..+/)
      .required("Email address is required"),
    name: userNameOptional
      ? string()
          .nullable()
          .optional()
          .max(255, formValidationErrorMessages.tooManyCharactersError)
      : string()
          .trim()
          .required(
            nameErrorMessage || formValidationErrorMessages.nameRequiredError,
          )
          .max(255, formValidationErrorMessages.tooManyCharactersError),
    address: userAddressOptional
      ? string()
          .nullable()
          .optional()
          .max(255, formValidationErrorMessages.tooManyCharactersError)
      : string()
          .trim()
          .required(formValidationErrorMessages.addressRequiredError)
          .max(255, formValidationErrorMessages.tooManyCharactersError),
    postalCode: userAddressOptional
      ? string()
          .trim()
          .max(8, formValidationErrorMessages.postalCodeCharacterError)
      : requiredPostalCode(), // TODO regex
    isConfirmed: userAddressOptional
      ? bool()
      : bool().required().oneOf([true], "Please confirm your address"),

    isGuest: bool().required(),

    isSection12Approved: section12Required
      ? boolean().required("Please confirm your Section 12 approval status")
      : boolean().nullable().optional(),

    isApprovedClinician: approvedClinicianRequired
      ? boolean().oneOf(
          [true],
          "Please confirm your Approved/Responsible Clinician status",
        )
      : boolean().nullable().optional(),

    localAuthority: localAuthorityRequired
      ? string()
          .trim()
          .required("Please enter the Local Authority that approved you")
      : string().optional(),
  }).default({ ...defaultAddress, email: "", name: "" });

export const isTimeString = string()
  .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, "Please enter a valid time")
  .nullable()
  .default(null);

export const isoDateString = string()
  .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, "Please enter a valid date")
  .nullable()
  .default(null);

export const isoDateTimeString = string()
  .matches(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
    "Please enter a valid date / time",
  )
  .nullable()
  .default(null);

export const nonEmptyString = string().trim().nullable().default(null);
export const nonEmptyRequiredString = nonEmptyString.required().nonNullable();

export const defaultAmhpSchemaFields = {
  amActingOnBehalfOfApprovingAuthority: boolean()
    .default(null)
    .required("Please select the option which applies"),

  nameOfLocalAuthorityActingOnBehalfOf: nonEmptyString.when(
    "amActingOnBehalfOfApprovingAuthority",
    {
      is: false,
      then: (schema) =>
        schema.required(
          "Please enter the local social services authority you are acting on behalf of",
        ),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
};

export type HospitalSchema = InferType<typeof hospital>;

export const defaultContextFields = (opts: DefaultContextOps = {}) => ({
  user: practitioner(opts).required(),

  patient: object({
    name: string()
      .trim()
      .required(formValidationErrorMessages.nameRequiredError)
      .max(255, formValidationErrorMessages.tooManyCharactersError),
    address: string()
      .trim()
      .required()
      .max(255, formValidationErrorMessages.tooManyCharactersError),
    postalCode: string().trim(), // intentionally not setting postcode to max 8, further details as to why, in this tickets comments https://thalamos.atlassian.net/browse/ASP-2124 // TODO: regex
    dateOfBirth: isoDateString.nullable().optional(),
    nhsNumber: string()
      .nullable()
      .matches(/^[0-9]{10}$/)
      .default(null)
      .optional(),
  })
    .default({
      postalCode: "",
      address: "",
      name: "",
      dateOfBirth: null,
      nhsNumber: null,
    })
    .required(),
});

export const confirmCheckbox = boolean()
  .oneOf([true], "Please confirm statement")
  .default(false)
  .required("Please confirm statement");

export const optionalCheckbox = boolean().default(false).required();
