import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { Box } from "@mui/material";
import { useState } from "react";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system";
import { api } from "~/api";

export interface UserEmailAddressUpdateDialogProps {
  user: ExtendedThalamosUser;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function UserEmailAddressUpdateDialog({
  user,
  open,
  onClose,
  onSuccess,
}: UserEmailAddressUpdateDialogProps) {
  const [email, setEmail] = useState<string>(user.email);

  const onSubmit = async () => {
    const result = await api.users.updateEmail(user.id, email);

    if (result.status === 204) {
      renderSuccessToast({ message: "Email updated successfully" });
      onSuccess();
    } else {
      renderErrorToast({
        message: `Failed to update email (${result.status}): ${(result.data as any).reason}`,
      });
    }
  };

  return (
    <PopupDialog open={open} onClose={onClose}>
      <Box sx={{ padding: "2em" }}>
        <Box sx={{ mb: "1em" }}>
          <Banner
            bannerType={BannerList.WARNING}
            title={`This will update the user's email address. Beware - if the new 
                email address is an SSO email address then the user's current password will be deleted.
                If their new email is a username/password email address and they used to be on SSO,
                then they will need to perform a password reset. For guest and independent users the team
                email address will be updated at the same time.`}
          />
        </Box>
        <TextField
          name="Email"
          label="User Email"
          value={email}
          onChange={setEmail}
          useFullWidth={true}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button label={"Close"} onClick={onClose} color="inherit" />
          <Button
            label={"Submit"}
            onClick={onSubmit}
            disabled={email === user.email}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
