import {
  acdPart2DataSchema,
  AcdPart2DataSchema,
  acdPart3DataSchema,
  AcdPart3DataSchema,
  acdPart4DataSchema,
  AcdPart4DataSchema,
  AcdPart5DataSchema,
  acdPart5DataSchema,
  acdPart6DataSchema,
  AcdPart6DataSchema,
  acdPart7DataSchema,
  AcdPart7DataSchema,
} from "../../schemas/acd/acd";
import {
  BaseFormTemplate,
  FormPartTemplate,
  TextboxFormBuilderField,
} from "../../types/form";
import { acdPart1Template } from "./acdPart1Template";

const documentYourCapacityField: TextboxFormBuilderField<AcdPart3DataSchema> = {
  type: "textbox",
  label:
    "Briefly document your capacity assessment and the conclusion you reached",
  field: "documentYourCapacity",
  rows: "5",
  afterLabel:
    "You should include a description of the information you shared with the person and whether the person could understand, retain, use/weigh the information and communicate their decision. Ideally use the person's own words. ",
};

export const acdPart2Template: FormPartTemplate<AcdPart2DataSchema> = {
  signing: {
    confirmationOnly: true,
    userOrTeamRequirement: {
      accreditation: ["acd-facilitator"],
      teamType: [],
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    {
      type: "label",
      label: ["Mental health treatment and care recommendations"],
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data
            .communityHomeTreatmentTeamCrisisCare || "Not answered",
        ];
      },
      label:
        "Community/home treatment team crisis care, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data.whenINeedAdmissionToHospital ||
            "Not answered",
        ];
      },
      label: "When I need admission to hospital, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data.mentalHealthActAssessment ||
            "Not answered",
        ];
      },
      label:
        "Mental Health Act Assessment (being sectioned), as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data.hospitalCare || "Not answered",
        ];
      },
      label: "Hospital care, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data.mentalHealthMedicalTreatments ||
            "Not answered",
        ];
      },
      label: "Mental health medical treatments, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data
            .mentalHealthNonMedicalTreatments || "Not answered",
        ];
      },
      label:
        "Mental health non-medical treatments, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data.whatIWantToAvoidWhenInCrisis ||
            "Not answered",
        ];
      },
      label:
        "What I want to avoid when I am in crisis, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data
            .religiousOrSpiritualBeliefsAndCulturalPractices || "Not answered",
        ];
      },
      label:
        "Religious or spiritual beliefs and cultural practises, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data
            .physicalHealthTreatmentAndCareDuringHomeTreatment ||
            "Not answered",
        ];
      },
      label:
        "Physical health treatment and care during Home Treatment Team or hospital admissions, as requested by service user",
    },
    {
      type: "readonly",
      textFn: (data, context) => {
        return [
          context.previousPartsFormData[0].data
            .emergencyPhysicalHealthCareAndTreatment || "Not answered",
        ];
      },
      label:
        "Emergency physical health care and treatment, as requested by service user",
    },
    {
      type: "textbox",
      label: "Our mental health treatment and care recommendations",
      rows: "10",
      field: "ourMentalHealthTreatmentAndCareRecommendations",
      afterLabel:
        "This could be completed during meetings to discuss future mental health treatment and care. It should contain easy to read clinical recommendations for future health professionals as agreed by the person, present health professionals and family/friends involved in their care",
    },
    {
      type: "branch",
      field: "showUpdateDocumentDate",
      branches: [
        {
          fieldValue: true,
          hideLabel: true,
          label: "showUpdateDocumentDate",
          fields: [
            {
              type: "dropdown",
              label: "We would like to update this document in",
              valueField: "updateDocumentDate",
              textField: "updateDocumentDateText",
              options: [
                { value: "6-months", label: "6 months" },
                { value: "1-year", label: "1 year" },
                { value: "3-years", label: "3 years" },
                { value: "other", label: "Other" },
              ],
              fieldsOwned: ["updateDocumentOtherDate"],
            },
          ],
          fieldsOwned: [],
        },
      ],
    },
    {
      type: "branch",
      field: "updateDocumentDate",
      branches: [
        {
          hideLabel: true,
          fieldValue: "other",
          label: "Other",
          fields: [
            {
              type: "date",
              label: "Specify other date",
              field: "updateDocumentOtherDate",
            },
          ],
          fieldsOwned: [],
        },
      ],
    },
  ],
  dataSchema: acdPart2DataSchema,
};

export const acdPart3Template: FormPartTemplate<AcdPart3DataSchema> = {
  signing: {
    confirmationOnly: true,
    userOrTeamRequirement: {
      accreditation: ["doctor", "nurse", "social-worker", "hcpc"],
      teamType: ["ward"],
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    { type: "label", label: ["Mental capacity"] },
    {
      type: "textbox",
      label:
        "Assessment of capacity to make Advance Choices about treatment and care",
      field: "assessmentOfCapacityToMakeAdvanceChoicesAboutTreatmentAndCare",
      rows: "5",
    },
    {
      type: "branch",
      label:
        "Does the person have capacity to make Advance Choices about treatment and care?",
      field:
        "doesThePersonHaveCapacityToMakeAdvanceChoicesAboutTreatmentAndCare",
      branches: [
        { fieldValue: "yes", label: "Yes", fields: [], fieldsOwned: [] },
        {
          fieldValue: "no",
          label: "No",
          fields: [documentYourCapacityField],
          fieldsOwned: ["documentYourCapacity"],
        },
        {
          fieldValue: "unsure",
          label: "Unsure",
          fields: [documentYourCapacityField],
          fieldsOwned: ["documentYourCapacity"],
        },
      ],
    },
    {
      type: "textbox",
      label: "Occupation of person completing assessment of capacity",
      field: "occupation",
    },
    {
      type: "date",
      label: "Date of capacity assessment",
      field: "capacityAssessmentDate",
      maximum: (d) => d,
    },
  ],
  dataSchema: acdPart3DataSchema,
};

export const acdPart4Template: FormPartTemplate<AcdPart4DataSchema> = {
  signing: {
    confirmationOnly: true,
    userOrTeamRequirement: {
      accreditation: ["acd-facilitator"],
      teamType: [],
    },
  },

  autoAssignToPartSignatory: 2,
  formBuilderFields: [
    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    {
      type: "label",
      label: ["Other views"],
    },
    {
      type: "textbox",
      checkboxToShowField: "summaryOfOtherConsultationsSelected",
      label: "Summary of other consultations",
      field: "summaryOfOtherConsultations",
      rows: "5",
      afterLabel:
        "This can include the views of family/loved ones, legal advice, advocates or specialist medical opinions. This should be used to record any other people involved in making this Advance Choice Document",
    },
    {
      type: "textbox",
      checkboxToShowField: "additionalCommentsSelected",
      label: "Additional comments that have not already been covered",
      field: "additionalComments",
      rows: "5",
      afterLabel:
        "This box can be used for any additional views or comments that have not already be covered.",
    },
  ],
  dataSchema: acdPart4DataSchema,
};

export const acdPart5Template: FormPartTemplate<AcdPart5DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["doctor", "nurse", "social-worker", "hcpc"],
      teamType: ["ward"],
    },
  },

  autoAssignToPartSignatory: 3,
  formBuilderFields: [
    { type: "label", label: ["Confirmation of involvement - Clinician"] },
    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    {
      type: "confirmation-checkbox",
      checkboxLabel:
        "I confirm I have been involved in making this Advance Choice Document",
      field: "confirmInvolvement",
    },
  ],
  dataSchema: acdPart5DataSchema,
};

export const acdPart6Template: FormPartTemplate<AcdPart6DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["acd-facilitator"],
      teamType: [],
    },
  },

  autoAssignToPartSignatory: 1,
  formBuilderFields: [
    { type: "label", label: ["Confirmation of involvement - Service user"] },

    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    {
      type: "confirmation-checkbox",
      checkboxLabel:
        "I confirm I have been involved in making this Advance Choice Document",
      field: "confirmInvolvement",
    },
  ],
  dataSchema: acdPart6DataSchema,
};

export const acdPart7Template: FormPartTemplate<AcdPart7DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["acd-facilitator"],
      teamType: [],
    },
  },

  autoAssignToPartSignatory: 2,
  formBuilderFields: [
    { type: "label", label: ["Confirmation of involvement - Facilitator"] },

    {
      type: "practitioner",
      label: "Name and address of person completing this section of the form",
      field: "user",
    },
    {
      type: "confirmation-checkbox",
      checkboxLabel:
        "I confirm I have been involved in making this Advance Choice Document",
      field: "confirmInvolvement",
    },
  ],
  dataSchema: acdPart7DataSchema,
};

export const acdV1Base: BaseFormTemplate = {
  id: "acd",
  category: "misc",
  version: "1.0",
  status: "active",
  formName: "ACD",
  section: "130ZA (Draft Mental Health Bill)",
  parts: [
    acdPart1Template,
    acdPart2Template,
    acdPart3Template,
    acdPart4Template,
    acdPart5Template,
    acdPart6Template,
    acdPart7Template,
  ],
  description: "Advance Choice Document",
  extractGenericData: (data: any) => ({}),
  autoFinalise: true,
};
