import { ExternalPatientLink } from "../types/externalPatientLink";

export const conexesTokenDurationInMinutes = 24 * 60; // 1 day
export const conexesTokenRefreshThresholdInMinutes = 5;

export function getHighestPriorityLink(
  externalLinks: ExternalPatientLink[] | null,
) {
  const candidateLinks = (externalLinks ?? []).filter(
    (externalPatientLink) =>
      externalPatientLink.externalSystemType === "rio" &&
      externalPatientLink.externalPatientIdType === "nhs.number",
  );

  // The lowest number is the highest priority (i.e. 1 is higher priority than 2)
  return candidateLinks.sort(
    (a, b) => a.priorityWithinExternalSystem - b.priorityWithinExternalSystem,
  )?.[0];
}

export function getExternalPatientIdValue(
  externalLinks: ExternalPatientLink[] | null,
) {
  const highestProirityLink = getHighestPriorityLink(externalLinks);

  if (!highestProirityLink) return null;

  return highestProirityLink?.externalPatientIdValue;
}
