import { bool, InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  hospital,
  isoDateTimeString,
  nameAndAddress,
  isoDateString,
} from "../../shared";
import { ReceptionScenario } from "../../../types/miscellaneousForm";

const receptionScenario = string()
  .oneOf(Object.values(ReceptionScenario))
  .default(null)
  .required("Please confirm the correct option");

export const m1Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your name",
  }),

  receptionScenario: receptionScenario,

  // Scenario 1 - admission to hospital
  admissionDateTime: isoDateTimeString.when("receptionScenario", {
    is: (val: string | boolean) => val === ReceptionScenario.AdmittedToHospital,
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date-time",
        "User cannot enter a date in the future.",
      ).required("Please enter date and time patient was admitted to hospital"),
    otherwise: () => string().optional(),
  }),

  admissionHospital: hospital.when("receptionScenario", {
    is: (val: string | boolean) => val === ReceptionScenario.AdmittedToHospital,
    then: () =>
      hospital.required("Please enter hospital patient was admitted to"),
    otherwise: () =>
      object({
        name: string().trim().optional(),
        address: string().trim().optional(),
        postalCode: string().optional(),
        isConfirmed: bool().optional(),
      }),
  }),

  // Scenario 2 - received into guardianship
  guardianshipDate: isoDateString.when("receptionScenario", {
    is: (val: string | boolean) => val === "received-into-guardianship",
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date",
        "User cannot enter a date in the future.",
      ).required(
        "Please enter date the patient was received into the guardianship on",
      ),
    otherwise: () => string().optional(),
  }),

  guardianNameAndAddress: nameAndAddress(
    {
      nameRequiredError:
        "Please enter name of guardian patient was received into the guardianship of",
      addressRequiredError:
        "Please enter address of guardian patient was received into the guardianship of",
    },
    true,
  ).when("receptionScenario", {
    is: (val: string | boolean) => val === "received-into-guardianship",
    then: (s) => s.required("Please enter name and address of guardian"),
    otherwise: (s) => s.optional(),
  }),

  // Scenario 3 - became a community patient
  dischargeIntoCommunityDate: isoDateString.when("receptionScenario", {
    is: (val: string | boolean) => val === ReceptionScenario.CommunityPatient,
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date",
        "User cannot enter a date in the future.",
      ).required(
        "Please enter date patient became a community patient as if discharged from the responsible hospital on",
      ),
    otherwise: () => string().optional(),
  }),

  responsibleHospital: hospital.when("receptionScenario", {
    is: (val: string | boolean) => val === ReceptionScenario.CommunityPatient,
    then: () =>
      hospital.required(
        "Please enter name of hospital patient became a community patient as if discharged from",
      ),
    otherwise: () =>
      object({
        name: string().trim().optional(),
        address: string().trim().optional(),
        postalCode: string().optional(),
        isConfirmed: bool().optional(),
      }),
  }),
});

export type M1Part1DataSchema = InferType<typeof m1Part1DataSchema>;

export type M1Data = [PartData<M1Part1DataSchema>];

export const exampleM1Data: ExampleFormData<M1Data> = [
  {
    data: {
      receptionScenario: ReceptionScenario.AdmittedToHospital,

      admissionDateTime: "2022-01-10T12:00:00.000Z",
      admissionHospital: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },

      guardianNameAndAddress: null,

      responsibleHospital: {
        isConfirmed: null,
        name: "",
        address: "",
        postalCode: "",
      },

      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
