import { boolean, InferType, object, string } from "yup";
import {
  defaultAmhpSchemaFields,
  defaultContextFields,
  nonEmptyString,
  optionalCheckbox,
  generateDateAfterTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

// @ts-ignore
export const cto1Part1DataSchema = object({
  ...defaultContextFields({ approvedClinicianRequired: true }),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-recall-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  reasons: nonEmptyString
    .required()
    // @ts-ignore
    .nonNullable("Please enter the grounds your opinion is founded on"),

  subjectPatientToConditions: boolean()
    .required()
    .default(null)
    .nonNullable(
      "Please confirm if the patient is subject to any other conditions under section 17B(2) ",
    ),

  conditions: string()
    .when("subjectPatientToConditions", {
      is: true,
      then: () =>
        nonEmptyString.required(
          "Please enter the conditions the patient is to be subject to under section 17B(2) ",
        ),
      otherwise: () => string().default(null).notRequired(),
    })
    .default(""),
});

export const cto1Part2DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,
});

export const cto1Part3DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
    userAddressOptional: true,
    userNameOptional: true,
  }),

  effectiveDateTime: generateDateAfterTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the past.",
  ).required(
    "Please enter the time and date this community treatment order is to be effective from",
  ),
});

export type Cto1Part1DataSchema = InferType<typeof cto1Part1DataSchema>;
export type Cto1Part2DataSchema = InferType<typeof cto1Part2DataSchema>;
export type Cto1Part3DataSchema = InferType<typeof cto1Part3DataSchema>;

export type CTO1Data = [
  PartData<Cto1Part1DataSchema>,
  PartData<Cto1Part2DataSchema>?,
  PartData<Cto1Part3DataSchema>?,
];

export const exampleCto1Data: ExampleFormData<CTO1Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,

      reasons: "Reasons",

      subjectPatientToConditions: true,

      conditions: "Conditions",
    },
  },
  {
    data: {
      user: {
        email: "amhp@amhp.com",
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
        localAuthority: "Local Authority",
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Another LA",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      effectiveDateTime: "2022-01-10T12:00:00.000Z",
    },
  },
];
