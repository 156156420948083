import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

export interface HorizontalLineProps {
  noMargin?: boolean;
  topMargin?: boolean;
  customMargin?: number;
  customBottomBorderColor?: string;
}

export const HorizontalLine = (props: HorizontalLineProps) => {
  const {
    noMargin = false,
    topMargin = false,
    customMargin = undefined,
    customBottomBorderColor = undefined,
  }: HorizontalLineProps = props;
  const theme = useTheme();
  return (
    <div
      css={css`
        width: 100%;
        ${customMargin && ` margin-top: ${theme.spacing(customMargin)};`}
        ${topMargin && ` margin-top: ${theme.spacing(3)};`}
        ${noMargin !== true && `margin-bottom: ${theme.spacing(3)};`}
        border-bottom: 1px solid ${customBottomBorderColor
          ? customBottomBorderColor
          : theme.palette.background.horizontalGray};
      `}
    />
  );
};
