import React from "react";
import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";
import { css } from "@emotion/react";

export const ThalamosLink = (
  props: PropsWithChildren<{
    to: string | undefined | null;
    disabled?: boolean;
    openNewTab?: boolean;
  }>,
) => {
  const { to, openNewTab, disabled, ...rest } = props;

  return to && !disabled ? (
    <Link
      target={openNewTab ? "_blank" : ""}
      {...rest}
      rel="noopener noreferrer"
      to={to}
      css={css`
        text-decoration: none; /* no underline */
        color: inherit; /* inherit text color */
      `}
    >
      {props.children}
    </Link>
  ) : (
    <>{props.children}</>
  );
};
