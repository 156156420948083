/// <reference types="@types/google.maps" />
import React, { useCallback, useRef, useState } from "react";
import { TextFieldProps, TextField } from "../TextField/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { usePlacesWidget } from "react-google-autocomplete";
import { Box } from "@mui/material";
import { Stack, Typography, useTheme, Checkbox } from "@mui/material";
import { HelperText } from "../HelperText/HelperText";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

interface AddressFieldErrors {
  overall?: string;
  address?: string;
  postalCode?: string;
  isConfirmed?: string;
}

export const getPostalCode = (place: google.maps.places.PlaceResult) => {
  let postalCode = null;
  place?.address_components?.forEach((component) => {
    component?.types?.forEach((type) => {
      if (type === "postal_code") {
        postalCode = component?.long_name;
      }
    });
  });
  return postalCode;
};

type Placeholder = {
  address: string;
  postalCode: string;
};

export interface AddressFieldProps
  extends Omit<TextFieldProps, "placeholder" | "value"> {
  placeholder?: Placeholder;
  value?: { [key: string]: any };
  errors?: AddressFieldErrors;
  addressLabel?: string;
  postalLabel?: string;
  googleMapsApiKey: string;
  addressCheckBoxLabel: string;
  hideConfirmation?: boolean;
  testId?: string;
}

const defaultProps: Partial<AddressFieldProps> = {
  useFullWidth: true,
  hideConfirmation: false,
};

export const AddressField = (props: AddressFieldProps) => {
  const allProps: AddressFieldProps = { ...defaultProps, ...props };
  const {
    testId,
    name,
    label,
    addressLabel,
    postalLabel,
    placeholder,
    subtext,
    subtextIcon,
    errors,
    warningMessage,
    value,
    onChange,
    disabled,
    isHighlighted,
    readOnly,
    showHelperText,
    isSubTextAlignedRight,
    useFullWidth,
    InputProps,
    onChangeSubControl,
    googleMapsApiKey,
    addressCheckBoxLabel,
    hideConfirmation,
  } = allProps;

  const theme = useTheme();

  const { t } = useTranslation();

  const isConfirmationSelected = !!value?.isConfirmed;

  const [isConfirmationDisabled, setIsConfirmationDisabled] = useState(false);

  const onPlaceSelected = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const whitelist = [
        "street_address",
        "administrative_area_level_2",
        "premise",
        "subpremise",
        "street_number",
        "route",
        "postal_town",
        "floor",
      ];

      const postalCode = getPostalCode(place) || "";

      // Is this an "establishment" such as a Hospital?
      // If so, we want to include the "name" field in the final result.
      const nameComponent = (place?.types ?? []).includes("establishment")
        ? (place?.name as string | undefined)
        : undefined;

      type AddressComponent = {
        long_name: string;
        types: string[];
      };

      const addressComponents = (
        (place?.address_components as AddressComponent[] | undefined) ?? []
      )
        .filter((component) =>
          component.types.some((type) => whitelist.includes(type)),
        )
        .map((component) => component.long_name);

      const allComponents = [nameComponent, ...addressComponents].reduce(
        (accumulator, current) => {
          // Filter out undefined and duplicate entries
          if (current === undefined || accumulator.includes(current)) {
            return accumulator;
          } else {
            return [...accumulator, current];
          }
        },
        [] as string[],
      );

      const formattedAddress = allComponents.join(", ");

      // Has the address changed as part of this interaction?
      const hasChanged =
        formattedAddress !== value?.address || postalCode !== value?.postalCode;

      if (hasChanged && onChange) {
        const changedValues = {
          address: formattedAddress,
          postalCode,
          isConfirmed: false,
        };

        onChange(changedValues);
      }
    },
    [onChange, value?.address, value?.postalCode],
  );

  // Make sure we always have access to the most recent
  // version of the callback, which will have new bound
  // values courtesy of the hook dependencies.
  const onPlaceSelectedRef = useRef(onPlaceSelected);
  onPlaceSelectedRef.current = onPlaceSelected;

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    // This usePlacesWidget never stores anything but the initial version
    // of this callback. It doesn't put it in any of it's hook dependencies.
    // To work around this, we use a second level of indirection through a
    // ref hook to ensure we always call with the latest bound dependencies.
    onPlaceSelected: (place) => {
      onPlaceSelectedRef.current(place);
    },
    options: {
      fields: ["name", "type", "address_components", "formatted_address"],
      // ASP-1574
      // This types array needs to be empty in order to be able to lookup
      // both residential and establishment (e.g. hospital) addresses.
      // TODO: Write a Component Test which validates it offers us:
      // * "London Bridge" suggests "London Bridge Pier" (a "residential" address)
      // * "Arrowe Park" suggests "Arrowe Park Hospital" (an "establishment" address)
      types: [],
      componentRestrictions: { country: ["uk"] },
    },
  });

  const handleChange = (k: string, v: string) => {
    const hasChanged = value?.[k] !== v;

    if (hasChanged && onChange) {
      onChange({ ...value, [k]: v, isConfirmed: false });
    }
  };

  const handleConfirmSelected = () => {
    onChange && onChange({ ...value, isConfirmed: !isConfirmationSelected });
  };

  const isInputDisabled = () => disabled;

  const renderHelperText = () =>
    showHelperText &&
    (errors || warningMessage || subtext) && (
      <HelperText
        subtext={subtext}
        errorMessage={[
          errors?.overall,
          errors?.address,
          errors?.postalCode,
          errors?.isConfirmed,
        ]
          .filter((x) => x)
          .join(", ")}
        warningMessage={warningMessage}
      />
    );

  return (
    <Element name={name} data-testid={`field:${testId}`}>
      <Stack flexDirection={{ md: "row", sm: "column" }} width="100%">
        <Stack
          width="100%"
          sx={{
            paddingRight: {
              lg: 2,
            },
            mr: { md: 1 },
          }}
        >
          <TextField
            name={`${name}_address`}
            label={label}
            muiTextFieldLabel={addressLabel || t("common.address")}
            value={value?.address}
            onChange={(v) => handleChange("address", v)}
            inputRef={ref}
            placeholder={placeholder?.address}
            disabled={isInputDisabled()}
            readOnly={readOnly}
            InputProps={{
              error: !!errors?.address,
              autoComplete: "off",
              type: "search",
              autoCorrect: "off",
              inputProps: { "data-testid": "text-field-address" },
              ...(InputProps || {}),
            }}
            useFullWidth={useFullWidth}
            isHighlighted={isHighlighted}
            subtextIcon={subtextIcon}
            isSubControlChecked={false}
          />
        </Stack>
        <Stack justifyContent="flex-end">
          <TextField
            name={`${name}_postalCode`}
            muiTextFieldLabel={postalLabel || t("common.postalCode")}
            value={value?.postalCode}
            onChange={(v) => handleChange("postalCode", v)}
            placeholder={placeholder?.postalCode}
            disabled={isInputDisabled()}
            readOnly={readOnly}
            showHelperText={showHelperText}
            InputProps={{
              error: !!errors?.postalCode,
              type: "search",
              inputProps: { "data-testid": "text-field-postcode" },
              autoComplete: "off",
              autoCorrect: "off",
              ...(InputProps || {}),
            }}
            onChangeSubControl={onChangeSubControl}
            useFullWidth={useFullWidth}
            isSubTextAlignedRight={isSubTextAlignedRight}
            isHighlighted={isHighlighted}
            subtextIcon={subtextIcon}
            isSubControlChecked={false}
          />
        </Stack>
      </Stack>
      {!readOnly && !hideConfirmation && (
        <Box
          onClick={handleConfirmSelected}
          sx={{
            mt: -1.2,
            alignItems: "center",
            display: "inline-flex",
            color: isConfirmationSelected
              ? theme.palette.common.white
              : theme.palette.text.secondary,
            backgroundColor: isConfirmationSelected
              ? theme.palette.primary.main
              : theme.palette.background.default,
            borderRadius: "6px",
          }}
        >
          <Checkbox
            checked={isConfirmationSelected}
            disabled={isConfirmationDisabled}
            sx={{ color: "primary.main", p: 0.75 }}
            inputProps={
              {
                "aria-label": "label-confirmation",
                "data-testid": "address-picker-confirm-checkbox",
              } as any
            }
            checkedIcon={
              <CheckIcon
                sx={{
                  color: disabled
                    ? theme.palette.text.hint
                    : theme.palette.text.primary,
                }}
              />
            }
          />
          <Typography sx={{ pr: 2, fontSize: 14, fontWeight: 700 }}>
            <span>{addressCheckBoxLabel}</span>
          </Typography>
        </Box>
      )}

      {renderHelperText()}
    </Element>
  );
};
