import React from "react";
import { Patient } from "@aspire/common/types/patient";
import { CSSTransition } from "react-transition-group";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { HorizontalLine } from "../../form/HorizontalLine";
import { VerticalLine } from "../../form/VerticalLine";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useStickyHeader } from "../../../hooks/StickyHeader/useStickyHeader";
import { StickyContainer } from "../styleWrappers";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { routeFns } from "../../../routes";
import { LoggedInUserContext } from "../../../Contexts";
import { MenuFlyout } from "../../design-system/MenuFlyout";
import { isGuestUserSession } from "@aspire/common/types/user";
import { PatientCrisisInfo } from "../PatientCrisisInfo";
import { css } from "@emotion/react";
import "./PatientBannerTransition.css";

import { PatientMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientMergeDialog";
import { PatientUndoMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientUndoMergeDialog";
import {
  CreateExternalPatientDemograhicsPullEventRequest,
  PatientTimelineResponse,
} from "@aspire/common/types/patientRequests";
import { MhaStatus } from "@aspire/common/types/patientState/PatientState";
import { PatientStateDisplay } from "./PatientStateDisplay";
import { v4 } from "uuid";
import { ExternalPatientLinkEnhanced as BaseExternalPatientLink } from "@aspire/common/types/externalPatientLinkEnhanced";
import { ManualPullButton } from "../../design-system/ExternalPatientLink/ManualPullButton/ManualPullButton";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage";
import { useMhaStatus } from "~/hooks/apiCalls";

type ExternalPatientLink = Pick<
  BaseExternalPatientLink,
  | "id"
  | "patientId"
  | "externalSystemType"
  | "externalSystemId"
  | "externalSystemDisplayName"
  | "externalPatientIdType"
  | "externalPatientIdValue"
  | "dateLastProcessed"
  | "priorityWithinExternalSystem"
  | "canPull"
  | "canPush"
>;

export interface PatientBannerProps {
  patient: Patient;

  patientTimeline?: PatientTimelineResponse;
  reloadPatientTimeline?: () => void;
  nhsNumber?: string;

  externalPatientLinks?: {
    links: ExternalPatientLink[];
    onSync: (
      externalPatientLink: BaseExternalPatientLink,
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => Promise<void>;
  };

  disableSticky?: boolean;
  isHorizontalLineHidden?: boolean;
  isHidden?: boolean;
}

const BannerContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<any>(({ issticky, ismobilemode, theme }) => ({
  flexDirection: "column",
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(0.8),
  padding: issticky && ismobilemode ? theme.spacing(1.5) : theme.spacing(2.5),
}));

function Spacer() {
  const theme = useTheme();
  return (
    <Box sx={{ marginLeft: "0.7em", marginRight: "0.7em", height: "100%" }}>
      <VerticalLine color={theme.palette.text.disabled} height="100%" />
    </Box>
  );
}

export function PatientBanner(props: PatientBannerProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;
  const isEditable = !isGuestUserSession(user);
  const patientMhaStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;
  const nhsNumberEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  const showEditMhaStatus = userCanEditMhaStatus(user);
  const { mhaStatus } = useMhaStatus({
    patientId: props.patient.id,
  });

  const { isSticky } = useStickyHeader();
  const { isMobileView } = useScreenDetection();
  const [isMainMenuOpen, setIsMainMenuOpen] = React.useState(false);
  const location = useLocation();
  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const [showPatientUndoMergeDialog, setShowPatientUndoMergeDialog] =
    React.useState(false);

  const smallTextStyles = {
    color: "primary.hint",
    fontSize: theme.spacing(1.375),
  };

  const mainTextStyles = {
    color: "text.primary",
    fontSize: isMobileView ? theme.spacing(1.5) : theme.spacing(2),
  };

  const patientAge = dayjs().diff(dayjs(props.patient.dateOfBirth), "year");

  const showExtendedContent =
    props.disableSticky || (!isSticky && !isMobileView);

  const cardRef = React.useRef(null);

  const menuFlyoutOptions = [];

  const disableEditMHAStatusBtn =
    location.pathname === routeFns.editMhaStatusPage(props.patient.id) ||
    location.pathname.endsWith("confirm-mha-status-finalise");

  if (location.pathname !== routeFns.patientHome(props.patient.id)) {
    menuFlyoutOptions.push({
      icon: "view",
      name: t("layout.patientBanner.link"),
      link: routeFns.patientHome(props.patient.id),
      disabled: false,
    });
  }

  if (isEditable) {
    menuFlyoutOptions.push({
      icon: "person",
      name: t("layout.patientBanner.edit"),
      link: routeFns.patientEdit(props.patient.id),
      disabled: false,
    });
  }

  const hasPatientMergeAccess = user?.hasPatientMergeAccess ?? false;

  if (hasPatientMergeAccess) {
    menuFlyoutOptions.push({
      icon: "merge",
      name: t("layout.patientBanner.merge"),
      onClick: () => setShowPatientMergeDialog(true),
      disabled: false,
    });
  }

  const mostRecentlyMergedPatient =
    props.patientTimeline?.mergedPatientData.lastMergedPatient;

  // we should only show the undo merge option if the merged patient
  // was last merged more than 30 days ago.
  const isMostRecentlyMergedBefore30Days = dayjs(
    mostRecentlyMergedPatient?.lastMerged,
  ).isBefore(dayjs().add(30, "day"));

  if (
    mostRecentlyMergedPatient &&
    isMostRecentlyMergedBefore30Days &&
    hasPatientMergeAccess
  ) {
    menuFlyoutOptions.push({
      icon: "undo",
      name: t("layout.patientBanner.undoMerge"),
      onClick: () => setShowPatientUndoMergeDialog(true),
      disabled: false,
    });
  }

  if (isMobileView && !disableEditMHAStatusBtn) {
    menuFlyoutOptions.push({
      icon: "edit",
      name: t("pages.editMHAStatus.editMHAStatus"),
      link: routeFns.editMhaStatusPage(props.patient.id),
      disabled: false,
    });
  }

  // The NHS label is displayed if the NHS number feature is turned on.
  // If the feature is turned off, the label will still be displayed if the patient already has an NHS number recorded.
  const showNhsLabel = !!(
    nhsNumberEnabled ||
    (!nhsNumberEnabled && props.nhsNumber)
  );

  return (
    <>
      {showPatientMergeDialog && props.patientTimeline && (
        <PatientMergeDialog
          patientTimeline={props.patientTimeline}
          closeMergeDialog={() => setShowPatientMergeDialog(false)}
        />
      )}

      {showPatientUndoMergeDialog &&
        props.patientTimeline &&
        props.patientTimeline.mergedPatientData.lastMergedPatient && (
          <PatientUndoMergeDialog
            reloadPatientTimeline={props.reloadPatientTimeline}
            patientTimeline={props.patientTimeline}
            closeUndoMergeDialog={() => setShowPatientUndoMergeDialog(false)}
          />
        )}
      <StickyContainer
        disablesticky={props.disableSticky ? "true" : undefined}
        issticky={isSticky || isMobileView ? "true" : undefined}
        ismobilemode={isMobileView ? "true" : undefined}
        top={isMobileView ? 58 : isGuestUserSession(user) ? 30 : 0}
        style={{ zIndex: 999 }}
      >
        <BannerContainer
          issticky={isSticky ? "true" : undefined}
          ismobilemode={isMobileView ? "true" : undefined}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
                "& .MuiIconButton-root": {
                  backgroundColor: theme.palette.secondary.lightBlue,
                },
              },
            }}
            display="flex"
            onClick={() => setIsMainMenuOpen(true)}
          >
            <Box>
              <Box
                sx={{
                  fontSize: theme.spacing(1.55),
                  color: "primary.hint",
                  marginBottom: "0.2em",
                }}
              >
                <Typography sx={{ ...smallTextStyles }}>
                  {t("layout.patientBanner.name")}
                </Typography>
              </Box>
              <Typography
                sx={{
                  ...mainTextStyles,
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {props.patient.name.family}, {props.patient.name.given}
              </Typography>
            </Box>

            <Box display="flex" marginLeft="auto">
              {showNhsLabel && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ ...smallTextStyles }}>
                    {t("layout.patientBanner.nhsNumber")}
                  </Typography>

                  <Typography sx={{ ...mainTextStyles }}>
                    {props.nhsNumber ?? t("common.unknown")}
                  </Typography>
                </Box>
              )}
              {!props.isHidden && (
                <Box
                  ref={cardRef}
                  css={css`
                    display: flex;
                    margin-left: ${theme.spacing(5.2)};
                    align-items: center;
                  `}
                  onClick={() => setIsMainMenuOpen(true)}
                >
                  {!!menuFlyoutOptions.length && (
                    <MenuFlyout
                      cardRef={cardRef}
                      options={menuFlyoutOptions}
                      isOpen={isMainMenuOpen}
                      onClose={() => setIsMainMenuOpen(false)}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box sx={{ marginTop: "0.5em", width: "100%" }}>
              <HorizontalLine noMargin={true} />
            </Box>
          </CSSTransition>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ ...smallTextStyles }}>
                    {t("layout.patientBanner.age")}
                  </Typography>
                  <Typography sx={{ ...mainTextStyles }}>
                    {patientAge
                      ? patientAge
                      : t("layout.patientBanner.unknown")}
                  </Typography>
                </Box>
                <Spacer />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ ...smallTextStyles }}>
                    {t("layout.patientBanner.address")}
                  </Typography>
                  <Typography sx={{ ...mainTextStyles }}>
                    {props.patient.address.address}
                  </Typography>
                </Box>
                <Spacer />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ ...smallTextStyles }}>
                    {t("layout.patientBanner.postcode")}
                  </Typography>
                  <Typography sx={{ ...mainTextStyles }}>
                    {props.patient.address.postalCode}
                  </Typography>
                </Box>

                <>
                  <Spacer />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ ...smallTextStyles }}>
                      {t("layout.patientBanner.dateOfBirth")}
                    </Typography>
                    <Typography sx={{ ...mainTextStyles }}>
                      {props.patient.dateOfBirth
                        ? dayjs(props.patient.dateOfBirth).format("DD MMM YYYY")
                        : t("layout.patientBanner.unknown")}
                    </Typography>
                  </Box>
                </>

                <Spacer />
              </Box>
              {props.externalPatientLinks !== undefined && (
                <Stack
                  direction={"column"}
                  gap={"0.5rem"}
                  sx={{ marginTop: "0.5rem" }}
                >
                  {props.externalPatientLinks.links
                    .filter(
                      (externalPatientLink) => externalPatientLink.canPull,
                    )
                    .map((externalPatientLink) => {
                      return (
                        <ManualPullButton
                          key={JSON.stringify(externalPatientLink)}
                          externalSystemType={
                            externalPatientLink.externalSystemType
                          }
                          externalSystemDisplayName={
                            externalPatientLink.externalSystemDisplayName
                          }
                          dateLastProcessed={
                            externalPatientLink.dateLastProcessed
                          }
                          onSync={async () => {
                            await props.externalPatientLinks!.onSync(
                              externalPatientLink,
                              "manual pull",
                            );
                          }}
                        />
                      );
                    })}
                </Stack>
              )}
            </Box>
          </CSSTransition>
          {patientMhaStatusEnabled && (
            <CSSTransition
              in={showExtendedContent}
              timeout={300}
              classNames={{
                enter: "fade-enter",
                enterActive: "fade-enter-active",
                exit: "fade-exit",
                exitActive: "fade-exit-active",
              }}
              unmountOnExit
            >
              <>
                <Box sx={{ marginTop: "0.5em", width: "100%" }}>
                  <HorizontalLine noMargin={true} />
                </Box>
                <Box sx={{ marginTop: "1em" }}>
                  <PatientStateDisplay
                    state={
                      mhaStatus?.states[0] ?? {
                        id: v4(),
                        isLatest: true,
                        effectiveDateTime: dayjs().toISOString(),
                        patientId: props.patient.id,
                        state: { mhaStatus: { status: MhaStatus.Unknown } },
                      }
                    }
                    disableEditMHAStatusBtn={disableEditMHAStatusBtn}
                    showEditMhaStatus={showEditMhaStatus}
                  />
                </Box>
              </>
            </CSSTransition>
          )}
        </BannerContainer>

        {!props.isHidden && (
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <PatientCrisisInfo />
          </CSSTransition>
        )}
        {!props.isHorizontalLineHidden && (
          <Box sx={{ my: isMobileView ? 2 : 3 }}>{/*<HorizontalLine />*/}</Box>
        )}
      </StickyContainer>
    </>
  );
}
