import { boolean, InferType, object, string } from "yup";
import {
  defaultAddress,
  defaultAmhpSchemaFields,
  defaultContextFields,
  hospital,
  nameAndAddress,
  nonEmptyString,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const a6Part1DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,

  hospital: hospital,

  hasNearestRelativeConsultation: boolean()
    .default(null)
    .required(
      "Please confirm if consultation with the nearest relative has taken place ",
    ),

  nearestRelativeStatement: string()
    .nullable()
    .default("")
    .when("hasNearestRelativeConsultation", {
      is: true,
      then: () => string().required("Please select the option which applies"),
      otherwise: () => string().default(null).nullable(),
    }),

  nearestRelativeOrCountyCourt: boolean()
    .nullable()
    .default(null)
    .when("nearestRelativeStatement", {
      is: (value: string) => value === "person-has-been-authorised",
      then: () => boolean().required("Please select the option which applies"),
      otherwise: () => boolean().default(null).nullable(),
    }),

  nearestRelativeOrAuthorisedPersonInfo: object()
    .optional()
    .when(["nearestRelativeStatement", "patientNearestRelative"], {
      is: (nearestRelativeStatement: string, patientNearestRelative: string) =>
        nearestRelativeStatement === "person-has-been-authorised" ||
        nearestRelativeStatement === "best-of-my-knowledge" ||
        patientNearestRelative === "patient-nearest-relative-meaning-act" ||
        patientNearestRelative === "patient-nearest-relative-under-act",
      then: (schema) => nameAndAddress({}, true),
      otherwise: (schema) => schema.notRequired(),
    })
    .default({ ...defaultAddress }),

  nearestRelativeKnownAct: string()
    .nullable()
    .default("")
    .when("hasNearestRelativeConsultation", {
      is: false,
      then: (schema) => schema.required("Please select the option which apply"),
      otherwise: (schema) => schema.notRequired(),
    }),

  patientNearestRelative: string()
    .nullable()
    .default("")
    .when("nearestRelativeKnownAct", {
      is: (value: string) => value === "patient-nearest-relative",
      then: (schema) => schema.required("Please select the option which apply"),
      otherwise: (schema) => schema.notRequired(),
    }),

  makingThisApplication: string()
    .nullable()
    .default("")
    .when("nearestRelativeKnownAct", {
      is: (value: string) => value === "patient-nearest-relative",
      then: (schema) => schema.required("Please select the option which apply"),
      otherwise: (schema) => schema.notRequired(),
    }),

  explainReasoning: nonEmptyString.when("makingThisApplication", {
    is: (value: string) =>
      value === "unreasonable-delay" || value === "reasonably-practicable",
    then: (schema) => schema.required("Please enter your reasoning"),
    otherwise: (schema) => schema.notRequired(),
  }),

  lastSeenDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter last date you saw the patient on"),

  medicalPractitionersPreviousAcquaintance: boolean()
    .default(null)
    .required(
      "Please confirm if one of the medical practitioners have previous acquaintance with the patient before making their recommendation",
    ),

  noRecommendation: nonEmptyString.when(
    "medicalPractitionersPreviousAcquaintance",
    {
      is: false,
      then: (schema) => schema.required("Please enter your reasoning"),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
});

export type A6Part1DataSchema = InferType<typeof a6Part1DataSchema>;

export type A6Data = [PartData<A6Part1DataSchema>];

export const exampleA6Data: ExampleFormData<A6Data> = [
  {
    data: {
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },

      user: {
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        email: "david@amhp.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
        localAuthority: "Local Authority",
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Other LA",

      hasNearestRelativeConsultation: false,
      nearestRelativeStatement: "person-has-been-authorised",
      nearestRelativeOrCountyCourt: true,
      nearestRelativeKnownAct: "patient-nearest-relative",

      patientNearestRelative: "patientNearestRelative",
      makingThisApplication: "makingThisApplication",
      explainReasoning: "explainReasoning",

      nearestRelativeOrAuthorisedPersonInfo: {
        name: "name",
        address: "Address",
        isConfirmed: true,
        postalCode: "ABC 123",
      },

      lastSeenDate: "2021-01-01",

      medicalPractitionersPreviousAcquaintance: true,
      noRecommendation: "noRecommendation",
    },
  },
];
