import React from "react";
import { formIsApplication, getLatestVersion } from "@aspire/common/types/form";
import { Box, useTheme, Radio, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  calculateWorkItemStatus,
  MenuOptionsType,
} from "~/components/design-system";
import { useNavigate, useLocation } from "react-router-dom";
import { getBaseFormTemplate } from "@aspire/common/formTemplates";
import { routeFns } from "../routes";
import dayjs from "dayjs";
import { FormRow } from "./FormProgressPage/helpers/FormRow";
import { api } from "../api";
import { triggerDownload } from "../util";
import { css } from "@emotion/react";
import { firstBy } from "thenby";
import { FormContextData } from "@aspire/common/types/formContextData";
import { getHeadingOrSubtitle } from "@aspire/common/util/form";

export function PatientTimelineCard({
  formContext,
  setPdfViewFormId,
  showRadioSelector,
  showCheckBoxSelector,
  selectedRadioValue,
  setSelectedRadioValue,
  disableCardClick,
  checkedFormItems,
  setCheckedFormItems,
  lastMerged,
}: {
  formContext: Omit<FormContextData, "patient">;
  setPdfViewFormId?: (formId: string | null) => void;
  showRadioSelector?: boolean;
  selectedRadioValue?: string;
  showCheckBoxSelector?: boolean;
  setSelectedRadioValue?: (params: any) => void;
  disableCardClick?: boolean;
  checkedFormItems?: any;
  setCheckedFormItems?: (params: any) => void;
  lastMerged?: string | undefined;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const summary = calculateWorkItemStatus(theme, {
    formContextType: formContext.type,
    forms: formContext.forms,
    otherWorkItems: formContext.workItems,
  });

  // For a standalone form context:
  //   - The "main form" is the only form in the context
  // For an admission form context:
  //   - The "main form" is the most recent Application
  //
  // TODO: Should admission status be more sophisticated?
  const mainForm =
    formContext.type === "standalone"
      ? formContext.forms[0]
      : formContext.forms
          .filter(formIsApplication)
          .sort(firstBy("updated", -1))[0]!;

  const mainFormTemplate = mainForm && getBaseFormTemplate(mainForm);

  const heading: string = getHeadingOrSubtitle(
    formContext.type,
    mainFormTemplate,
    null,
    true,
  );

  const subheading = `${
    formContext.type === "standalone" &&
    getLatestVersion(formContext.forms[0]).signatures.length > 0
      ? `Signed by: ${formContext.forms[0].versions
          .at(-1)!
          .signatures.map((s) => s.userName)
          .join(", ")}.`
      : ""
  }  Last updated:
    ${dayjs.utc(formContext.lastFormUpdated || formContext.updated).fromNow()}`;

  const canDownload =
    mainForm && getLatestVersion(mainForm).signatures.length > 0;

  const formContextPage = routeFns.formContextPage(
    formContext.id,
    formContext.patientId,
  );
  const isOnFormPage = location.pathname.startsWith(formContextPage);

  const menuOptions: MenuOptionsType[] = [
    ...(isOnFormPage || disableCardClick
      ? []
      : [
          {
            name: "Open",
            link: routeFns.formContextPage(
              formContext.id,
              formContext.patientId,
            ),
            icon: "transfer",
            disabled: false,
          },
        ]),
    ...(canDownload
      ? [
          {
            icon: "view",
            onClick: async () => {
              setPdfViewFormId && setPdfViewFormId(mainForm.id);
            },
            name: "View PDF",
            disabled: false,
          },
          {
            name: "Download",
            onClick: async () => {
              const result = await api.forms.getPdf(
                mainForm.id,
                formContext.type === "admission",
              );
              if (result.status === 200) {
                const dataUri = `data:application/pdf;base64,${result.data}`;
                triggerDownload(dataUri, "form.pdf");
              }
            },
            icon: "download",
            disabled: false,
          },
        ]
      : []),
  ];

  return (
    <Box display="flex" alignItems="center">
      {showRadioSelector && (
        <Radio
          sx={{ mr: 1, color: "primary.main" }}
          checked={formContext.id === selectedRadioValue}
          onChange={(e) => {
            setSelectedRadioValue && setSelectedRadioValue(e.target.value);
          }}
          value={formContext.id}
          name="radio-button"
          inputProps={{ "aria-label": "radio-button-selector" }}
          checkedIcon={
            <CheckIcon
              css={css`
                color: "primary.main";
              `}
            />
          }
        />
      )}
      {showCheckBoxSelector && setCheckedFormItems && (
        <Checkbox
          sx={{ color: "primary.main", maxHeight: 100 }}
          checked={checkedFormItems[formContext.id]}
          onChange={() => {
            setCheckedFormItems((prevSelected: string[]) => {
              const isSelected = prevSelected.includes(formContext.id);
              return isSelected
                ? prevSelected.filter((id: string) => id !== formContext.id)
                : [...prevSelected, formContext.id];
            });
          }}
        />
      )}
      <FormRow
        menuOptions={disableCardClick ? [] : menuOptions}
        heading={heading}
        subHeading={subheading}
        summary={summary}
        onClick={
          !disableCardClick ? () => navigate(formContextPage) : undefined
        }
        lastMerged={lastMerged}
      />
    </Box>
  );
}
