import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A5UploadableData,
  A5UploadablePart1DataSchema,
  a5UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a5";
import {
  a1Part1DataSchema,
  A1Part1DataSchema,
} from "../schemas/mhaForms/admission/a1";
import { a5And6Part1Signing } from "./helpers/signingLogic";

export const a5Part1Template: FormPartTemplate<A1Part1DataSchema> = {
  signing: a5And6Part1Signing,
  formBuilderFields: [],
  dataSchema: a1Part1DataSchema,
};

export const a5UploadablePart1Template: FormPartTemplate<A5UploadablePart1DataSchema> =
  {
    signing: a5And6Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date",
        field: "lastSeenDate",
        label: "Date signee last saw the patient on",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Signature date",
        maximum: (today) => today,
      },
    ],
    dataSchema: a5UploadablePart1DataSchema,
  };

export const a5V1Base: UploadableBaseFormTemplate = {
  id: "mha-a5",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a5Part1Template],
  formName: "A5",
  section: "3",
  description: "Application by nearest relative for admission for treatment",
  extractGenericData: (data: A5UploadableData) => ({
    admissions: {
      // TODO - need to think about this and what we want to track - one for @mette
      applicationHospitalName: data[0].data?.hospital.name,
    },
  }),
  linkableForms: [
    { templateId: "mha-a7", reason: "supported-by" },
    { templateId: "mha-a8", reason: "supported-by" },
  ],

  uploadable: true,
  uploadablePartTemplates: [a5UploadablePart1Template],
};
