import { css } from "@emotion/react";
import { Tab, Tabs, useTheme } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ExtendedThalamosUser,
  isIndependentUserSession,
} from "@aspire/common/types/user";
import { GetWorkResponse } from "@aspire/common/types/work";
import { routeFns } from "../../routes";
import { WorkMode } from "./types";

export default function WorkTabs({
  mode,
  work,
  user,
  setPage,
}: {
  mode: WorkMode;
  work: GetWorkResponse | undefined;
  user: ExtendedThalamosUser;
  setPage: () => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = {
    tabsGrayed: css`
      font-weight: 600;
      color: ${theme.palette.text.border} !important;
      z-index: 1;
      padding: ${theme.spacing(1)};
      font-size: ${theme.spacing(1.625)};
      &[aria-selected="true"] {
        border-bottom: 3px solid ${theme.palette.primary.main};
      }
    `,
    tabs: css`
      font-weight: 600;
      z-index: 1;
      padding: ${theme.spacing(1)};
      font-size: ${theme.spacing(1.625)};
      &[aria-selected="true"] {
        border-bottom: 3px solid ${theme.palette.primary.main};
      }
    `,
  };
  return (
    <Tabs
      value={mode === "user-caseload" ? 0 : mode === "caseload" ? 1 : 2}
      onChange={(ev, value) => {
        setPage();

        if (value === 0) {
          navigate(routeFns.caseloadUser());
        } else if (value === 1) {
          navigate(routeFns.caseloadCaseload());
        } else {
          navigate(routeFns.caseloadArchive());
        }
      }}
      sx={{
        mb: 1,
        minHeight: 0,
        "& .MuiTabs-flexContainer": {
          gap: theme.spacing(4),
          justifyContent: "flex-start",
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.text.primary,
          paddingBottom: theme.spacing(1.2),
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
        "& .MuiTab-root": {
          color: theme.palette.text.primary,
          minHeight: theme.spacing(1),
          minWidth: theme.spacing(0),
          padding: theme.spacing(0, 0.5, 1.3, 0.5),
        },
      }}
    >
      <Tab
        label={`${t("pages.caseload.myCaseload")}${
          work?.myCaseload ? ` (${work?.myCaseload?.count})` : ""
        }`}
        value={0}
        css={
          work && work?.myCaseload?.count === 0
            ? styles.tabsGrayed
            : styles.tabs
        }
      />
      <Tab
        label={`${
          isIndependentUserSession(user)
            ? t("pages.caseload.title")
            : t("pages.caseload.teamCaseload")
        }${work?.caseload ? ` (${work?.caseload?.count})` : ""}`}
        value={1}
        css={
          work && work?.caseload?.count === 0 ? styles.tabsGrayed : styles.tabs
        }
      />
      <Tab
        css={
          work && work?.archive?.count === 0 ? styles.tabsGrayed : styles.tabs
        }
        label={`${t("pages.caseload.tabLabelArchive")}${
          work?.archive ? ` (${work?.archive?.count})` : ""
        }`}
        value={2}
      />
    </Tabs>
  );
}
