import { boolean, InferType, object } from "yup";
import {
  defaultAddress,
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  nameAndAddress,
  optionalCheckbox,
} from "../../../shared";
import { ExampleFormData, PartData } from "../../../../types/form";
import { optionalEmail } from "../../../acd/util";

export const a8UploadablePart1DataSchema = object({
  ...defaultContextFields(),

  nameAndAddressOfSignee: nameAndAddress(
    {
      nameRequiredError: "Please enter name of signee",
      addressRequiredError: "Please enter address of signee",
    },
    true,
  ).default(defaultAddress),

  emailAddressOfSignee: optionalEmail,

  practitionerApprovedUnderSection12: boolean()
    .default(null)
    .required(
      "Please confirm if signee is approved under section 12 of the Act",
    ),

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date signee last examined this patient on"),

  previousAcquaintance: boolean()
    .default(null)
    .required(
      "Please confirm if signee had previous acquaintance with the patient ",
    ),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  signatureDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter signature date "),
});

export type A8UploadablePart1DataSchema = InferType<
  typeof a8UploadablePart1DataSchema
>;

export type A8UploadableData = [PartData<A8UploadablePart1DataSchema>];

export const exampleA8UploadableData: ExampleFormData<A8UploadableData> = [
  {
    data: {
      emailAddressOfSignee: "stuart@thalamos.co.uk",
      lastExaminedDate: "2021-01-01",
      previousAcquaintance: true,
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
      practitionerApprovedUnderSection12: true,
      signatureDate: "2021-01-01",

      nameAndAddressOfSignee: {
        name: "name",
        address: "Address",
        isConfirmed: true,
        postalCode: "ABC 123",
      },
      user: {
        name: "Dr. Sarah Glass",
        address: "143 Fake Street",
        postalCode: "AB1 2CD",
        email: "sarah.glass@houses.com",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: false,
      },
      patient: {
        name: "Bob Smith",
        address: "123 Fake Street",
        postalCode: "AB1 2CD",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
