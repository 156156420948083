import {
  CTO3Data,
  cto3Part1DataSchema,
  Cto3Part1DataSchema,
} from "../schemas/mhaForms/cto/cto3";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto3Part1Template: FormPartTemplate<Cto3Part1DataSchema> = {
  overrideSignatureSuccessMessage:
    "This notice of recall must be printed and served to the patient in line with the MHA, The Code of Practice and your organisation's CTO policy. It must also be shared with someone acting on behalf of the responsible hospital. You can download a copy of this form once sent or by closing this window. ",
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Name of patient",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of hospital patient is recalled to under section 17E of the Mental Health Act 1983",
    },

    {
      type: "branch",
      label: "Select either (a) or (b)",
      field: "selectedOption",
      branches: [
        {
          fieldValue: "requires-hospital-treatment-for-mental-disorder",
          label: "(a) In my opinion",
          fieldsOwned: ["groundsForOpinion"],
          visibleFields: [
            {
              showStaticLabel: true,
              type: "label",
              indent: 3.5,
              label: [
                "(i) you require treatment in hospital for mental disorder and",
                "(ii) there would be a risk of harm to your health or safety or to other persons if you were not recalled to hospital for that purpose",
              ],
            },
          ],
          fields: [
            {
              type: "textbox",
              field: "groundsForOpinion",
              label: "This opinion is founded on the following grounds",
              rows: "10",
            },
          ],
        },
        {
          fieldValue: "failed-compliance-with-section-17B",
          label:
            "(b) You have failed to comply with the condition imposed under section 17B of the Mental Health Act 1983 that you make yourself available for examination for the purpose of:",
          fieldsOwned: [
            "extensionConsiderationUnderSection20A",
            "enablePart4ACertificate",
          ],
          visibleFields: [
            {
              type: "multi-select",
              indent: 3.5,
              options: [
                {
                  field: "extensionConsiderationUnderSection20A",
                  label:
                    "(i) consideration of extension of the community treatment period under section 20A",
                },
                {
                  field: "enablePart4ACertificate",
                  label: "(ii) enabling a Part 4A certificate to be given",
                },
              ],
            },
          ],
          fields: [],
        },
      ],
    },

    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: cto3Part1DataSchema,
};

export const cto3V1Base: BaseFormTemplate = {
  id: "mha-cto3",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO3",
  section: "17E",
  parts: [cto3Part1Template],
  description: "community treatment order: notice of recall to hospital",
  extractGenericData: (data: CTO3Data) => ({}),
};
