import {
  ExtendedThalamosUser,
  isGuestUserSession,
} from "@aspire/common/types/user";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { Banner, BannerList } from "~/components/design-system";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../../routes";
import { FormContextData } from "@aspire/common/types/formContextData";
import { hasNoActiveWorkItemOrRejectedOrCancelled } from "../admission/AdmissionsFormPage";

export function WorkItemStatusCard({
  formContext,
  reloadFormContext,
  user,
  amAmhpUser,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  user: ExtendedThalamosUser;
  amAmhpUser?: boolean;
  isAdmission?: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormContextClosed = formContext.status === "closed";

  const isWorkItemCompleted =
    formContext?.activeTeamworkWorkItem?.status === "completed";

  // You don't have a work item
  if (hasNoActiveWorkItemOrRejectedOrCancelled(formContext)) {
    return (
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={
            "You are viewing this assessment as read-only because you are not a participant. If you feel you should be involved in this assessment please contact one of the participants listed below outside of Thalamos and ask them to invite you"
          }
        />
      </Box>
    );
  }

  // Is the workitem completed or is the workItem completed and the form context is closed
  if (isWorkItemCompleted) {
    const isFinalised = formContext.forms?.[0]?.status === "finalised";

    const isGuest = isGuestUserSession(user);

    const isGuestUserAndRequestTypeApplication =
      isGuest &&
      formContext?.activeTeamworkWorkItem?.requestType === "application";

    const isFormContextTypeAdmission = formContext.type === "admission";

    const isClosed = formContext.status === "closed";
    const isClosedByUserId = formContext.closedByUserId === user.id;
    const isClosedByTeamId =
      formContext.closedByTeamId === user.sessionContext?.teamId;

    function getBannerTitle(isFinalised: boolean) {
      if (isFinalised) {
        return "This form is in your team's archive. Administrative scrutiny has been marked as complete by an MHA office. No further changes can be made but the form can still be viewed and downloaded.";
      } else {
        if (isFormContextTypeAdmission && isWorkItemCompleted && isClosed) {
          return amAmhpUser && (isClosedByUserId || isClosedByTeamId)
            ? t("pages.formPage.bannerInfoArchiveAdmissionNoApp")
            : isFormContextClosed && (!isClosedByUserId || !isClosedByTeamId)
              ? t("pages.formPage.bannerInfoArchiveAdmissionNoAppNonAmhp")
              : t("pages.formPage.bannerInfoArchiveAdmission");
        }
        if (isWorkItemCompleted) {
          return t("pages.formPage.bannerInfoArchive");
        }
      }
    }

    const canReopen =
      amAmhpUser &&
      isClosed &&
      (isClosedByUserId || isClosedByTeamId) &&
      !isFinalised;
    const canUnarchive = !canReopen;

    const rightButtonLabel = canReopen
      ? "REOPEN"
      : canUnarchive && formContext.status === "active"
        ? "UNARCHIVE"
        : "";

    const isItemCompletedOrFinalised = isFinalised || isWorkItemCompleted;

    return (
      <>
        {isItemCompletedOrFinalised && (
          <Box sx={{ mb: 2 }}>
            <Banner
              bannerType={BannerList.INFO}
              title={getBannerTitle(isFinalised) || ""}
              rightButtonLabel={rightButtonLabel}
              disabledButtonRight={isSubmitting}
              onRightButton={async () => {
                if (
                  isClosed &&
                  ((amAmhpUser && isFormContextTypeAdmission && !isFinalised) ||
                    (isFormContextTypeAdmission &&
                      isGuestUserAndRequestTypeApplication))
                ) {
                  navigate(
                    routeFns.formContextReopenAdmissionDialog(
                      formContext.id,
                      formContext.patientId,
                    ),
                  );
                } else {
                  await api.work.unArchive(
                    formContext.activeTeamworkWorkItem!.id,
                  );
                }
                setIsSubmitting(true);
                reloadFormContext();
                setIsSubmitting(false);
              }}
            />
          </Box>
        )}
      </>
    );
  }

  // The work item for your team exists but is not claimed by you
  if (
    formContext.activeTeamworkWorkItem &&
    formContext.activeTeamworkWorkItem.assignedUserId !== user.id
  ) {
    return (
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={
            formContext.activeTeamworkWorkItem.assignedUserId
              ? `You can’t work on this assessment as someone else (${
                  formContext.activeTeamworkWorkItem.assignedUser!.name
                }) in your team has claimed it. Click claim if you need to work on this assessment.`
              : `You can’t work on this assessment as it is currently unclaimed in your team. Click claim if you need to work on this assessment.`
          }
          rightButtonLabel={"Claim"}
          disabledButtonRight={isSubmitting}
          onRightButton={async () => {
            setIsSubmitting(true);
            if (formContext.activeTeamworkWorkItem?.assignedUserId) {
              await api.work.unClaim(formContext.activeTeamworkWorkItem!.id);
            }
            await api.work.claim(formContext.activeTeamworkWorkItem!.id);
            reloadFormContext();
            setIsSubmitting(false);
          }}
        />
      </Box>
    );
  }

  // All is ok
  return <></>;
}
