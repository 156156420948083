import { apiHooks } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Chip, Typography } from "@mui/material";
import { routeFns } from "../routes";
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DefaultPageProps } from "./defaultProps";
import { ExtendedOrganisation } from "@aspire/common/types/extendedOrganisation";

export function OrganisationHomePageInner({
  org,
}: {
  org: ExtendedOrganisation;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <h1>
        {t("pages.organisationHomePage.organisation")}: {org.name} ({org.role})
      </h1>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          css={css`
            margin-right: 1em;
          `}
        >
          {t("common.managingOrganisation")}:
        </Typography>
        <Chip
          onClick={() =>
            org?.managingOrganisationId &&
            navigate(routeFns.organisationHome(org.managingOrganisationId))
          }
          label={org.managingOrganisationName || "None (Root Organisation)"}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
        <Typography
          css={css`
            margin-right: 1em;
          `}
        >
          {t("common.dataController")}
        </Typography>
        <Chip
          onClick={() =>
            navigate(routeFns.organisationHome(org.dataControllerId))
          }
          label={
            org.dataControllerId === org.id ? "Self" : org.dataControllerName
          }
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
        <Typography
          css={css`
            margin-right: 1em;
          `}
        >
          {t("common.childOrganisations")}
        </Typography>
        {org.childOrganisations.map((child) => (
          <Chip
            sx={{ marginRight: "0.5em" }}
            onClick={() => navigate(routeFns.organisationHome(child.id))}
            label={child.name}
          />
        ))}
      </Box>
    </>
  );
}

export function OrganisationHomePage({}: DefaultPageProps) {
  let { organisationId } = useParams();

  const [{ data: org, loading: orgLoading }] = apiHooks.organisations.get(
    organisationId!,
  );

  return orgLoading || !org ? (
    <></>
  ) : (
    <OrganisationHomePageInner org={org.organisation} />
  );
}
