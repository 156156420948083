import { InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import { defaultContextFields, nonEmptyString } from "../../shared";

export const cto12Part1DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
  }),

  patientConsentOrCompetenceCertification: string()
    .oneOf(["patient-consent-certification", "patient-competence-and-consent"])
    .default(null)
    .required("Please confirm the correct option"),

  treatmentDescription: nonEmptyString.required().nonNullable(
    // @ts-ignore
    "Please give description of treatment plan or plan of treatment",
  ),
});

export type Cto12Part1DataSchema = InferType<typeof cto12Part1DataSchema>;

export type CTO12Data = [PartData<Cto12Part1DataSchema>];

export const exampleCTO12Data: ExampleFormData<CTO12Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      patientConsentOrCompetenceCertification: "patient-competence-and-consent",

      treatmentDescription: "The treatment is",
    },
  },
];
