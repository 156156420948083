import { boolean, InferType, object } from "yup";
import {
  defaultAmhpSchemaFields,
  defaultContextFields,
  hospital,
  nonEmptyString,
  generateDateBeforeTodayValidationRule,
  dateAndTimeWithin24HoursValidation,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const a10Part1DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,

  hospital: hospital,

  lastSeenDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  )
    .concat(dateAndTimeWithin24HoursValidation())
    .required("Please enter last date you saw the patient"),

  medicalPractitionersPreviousAcquaintance: boolean()
    .default(null)
    .required(
      "Please confirm if one of the medical practitioners have previous acquaintance with the patient before making their recommendation",
    ),

  noPreviousAcquaintanceExplanation: nonEmptyString.when(
    "medicalPractitionersPreviousAcquaintance",
    {
      is: false,
      then: (schema) => schema.required("Please enter your reasoning"),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
});

export type A10Part1DataSchema = InferType<typeof a10Part1DataSchema>;

export type A10Data = [PartData<A10Part1DataSchema>];

export const exampleA10Data: ExampleFormData<A10Data> = [
  {
    data: {
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },

      user: {
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        email: "david@amhp.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
        localAuthority: "Local Authority",
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Other LA",

      lastSeenDateTime: "2021-01-01T15:00:00",

      medicalPractitionersPreviousAcquaintance: false,
      noPreviousAcquaintanceExplanation:
        "There are no medical practitioners who were previously acquiantanced with the patient.",
    },
  },
];
