import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import React from "react";
import { HelperText } from "../design-system/HelperText/HelperText";
import { Section12CheckboxFormBuilderField } from "@aspire/common/types/form";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { FieldProps } from "../../pages/FieldProps";
import { useTranslation } from "react-i18next";

export type Section12ConfirmationCheckboxFormFieldProps<
  Data extends { [k: string]: any },
> = {
  field: Section12CheckboxFormBuilderField;
  fieldProps: FieldProps<Data>;
};

export function Section12ConfirmationCheckboxFormField<
  Data extends { [k: string]: any },
>({ field, fieldProps }: Section12ConfirmationCheckboxFormFieldProps<Data>) {
  const theme = useTheme();
  const { t } = useTranslation();

  const fieldName = "isSection12Approved";

  // @ts-ignore
  const userErrors = fieldProps.errors["user"] || {};
  const touchedErrors = fieldProps.touched["user"] || {};

  // @ts-ignore
  const isError = userErrors[fieldName] && touchedErrors[fieldName];

  // @ts-ignore
  const errorMessage = userErrors[fieldName] as string;

  return (
    <Box sx={{ mb: 3 }} data-testid={`field:${fieldName}`}>
      <FormGroup>
        <Box display="inline-block">
          <Typography
            css={css`
              margin-bottom: ${theme.spacing(0.5)};
              font-weight: bold;
              font-size: ${theme.spacing(1.875)};
              color: ${isError && theme.palette.error.main};
            `}
          >
            {field.checkboxLabel}
          </Typography>

          <RadioGroup value={fieldProps.values["user"][fieldName]}>
            <FormControlLabel
              value={true}
              control={
                <Radio
                  icon={
                    <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                  }
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                  }
                />
              }
              onClick={() => {
                fieldProps.setValues({
                  ...fieldProps.values,
                  user: {
                    ...fieldProps.values["user"],
                    [fieldName]: true,
                  },
                });
              }}
              label={
                <Typography style={{ fontSize: theme.spacing(1.75) }}>
                  {t("common.yes")}
                </Typography>
              }
            />

            <FormControlLabel
              value={false}
              control={
                <Radio
                  icon={
                    <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                  }
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                  }
                />
              }
              onClick={() => {
                fieldProps.setValues({
                  ...fieldProps.values,
                  user: {
                    ...fieldProps.values["user"],
                    [fieldName]: false,
                  },
                });
              }}
              label={
                <Typography style={{ fontSize: theme.spacing(1.75) }}>
                  {t("common.no")}
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
        {isError && <HelperText errorMessage={errorMessage} />}
      </FormGroup>
    </Box>
  );
}
