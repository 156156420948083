import { api } from "../../../../api";
import { renderErrorToast } from "~/components/design-system";
import { routeFns } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import { FormContextData } from "@aspire/common/types/formContextData";

export async function createDraftAndHandleResult(
  newDraftId: string,
  formId: string,
  navigateFn: ReturnType<typeof useNavigate>,
  formContext: Omit<FormContextData, "patient">,
) {
  const result = await api.drafts.create(newDraftId, {
    formId: formId,
  });

  if (result.status === 201) {
    navigateFn(routeFns.formDraftsComplete(newDraftId, formContext.patientId));
  } else {
    renderErrorToast({ message: "Failed to create draft" });
  }
}
