import { boolean, InferType, object } from "yup";
import {
  defaultContextFields,
  nonEmptyRequiredString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const a4Part1DataSchema = object({
  ...defaultContextFields({ section12Required: true }),

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter the date you last examined the patient"),

  previousAcquaintance: boolean()
    .default(null)
    .required("Please select the option which applies"),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  reasons: nonEmptyRequiredString.required("Please enter your reasons"),
});

export type A4Part1DataSchema = InferType<typeof a4Part1DataSchema>;

export type A4Data = [PartData<A4Part1DataSchema>];

export const exampleA4Data: ExampleFormData<A4Data> = [
  {
    data: {
      lastExaminedDate: "2021-01-01",
      previousAcquaintance: false,
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
      reasons: "Reasons",
      user: {
        name: "Dr. Sarah Glass",
        address: "143 Fake Street",
        postalCode: "AB1 2CD",
        email: "sarah.glass@houses.com",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: false,
      },
      patient: {
        name: "Bob Smith",
        address: "123 Fake Street",
        postalCode: "AB1 2CD",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
