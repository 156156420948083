import {
  A6Data,
  A6Part1DataSchema,
  a6Part1DataSchema,
} from "../schemas/mhaForms/admission/a6";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A6UploadableData,
  a6UploadablePart1DataSchema,
  A6UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a6";
import { a5And6Part1Signing } from "./helpers/signingLogic";

export const a6Part1Template: FormPartTemplate<A6Part1DataSchema> = {
  signing: a5And6Part1Signing,
  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I apply for the admission of this patient for treatment in accordance with Part 2 of the Mental Health Act 1983.",
      ],
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    {
      type: "branch",
      label: "Has consultation with the nearest relative taken place?",
      field: "hasNearestRelativeConsultation",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "nearestRelativeStatement",
            "nearestRelativeOrAuthorisedPersonInfo",
            "nearestRelativeOrCountyCourt",
          ],
          fields: [
            {
              type: "branch",
              label: "Select the applicable option",
              field: "nearestRelativeStatement",
              branches: [
                {
                  fieldValue: "best-of-my-knowledge",
                  label:
                    "(a) I have consulted the following person who to the best of my knowledge and belief is the patient's nearest relative within the meaning of the Act ",
                  fields: [
                    {
                      type: "name-address",
                      field: "nearestRelativeOrAuthorisedPersonInfo",
                      nameLabel: "Enter name of patient's nearest relative",
                      addressLabel:
                        "Enter address of patient's nearest relative",
                    },
                    {
                      type: "label",
                      showStaticLabel: true,
                      label: [
                        "That person has not notified me or the local social services authority on whose behalf I am acting that he or she objects to this application being made.",
                      ],
                    },
                  ],
                  fieldsOwned: [
                    "nearestRelativeOrCountyCourt",
                    "nearestRelativeOrAuthorisedPersonInfo",
                  ],
                },
                {
                  fieldValue: "person-has-been-authorised",
                  label:
                    "(b) I have consulted the following person who I understand has been authorised to exercise the functions under the Act of the patient's nearest relative by:",
                  visibleFields: [
                    {
                      type: "branch",
                      field: "nearestRelativeOrCountyCourt",
                      indent: 3.5,
                      branches: [
                        {
                          fieldValue: true,
                          label: "a county court",
                          fields: [],
                          fieldsOwned: [],
                        },
                        {
                          fieldValue: false,
                          label: "the patient's nearest relative",
                          fields: [],
                          fieldsOwned: [],
                        },
                      ],
                    },
                  ],
                  fields: [
                    {
                      type: "name-address",
                      field: "nearestRelativeOrAuthorisedPersonInfo",
                      addressLabel: "Enter address of authorised person",
                      nameLabel: "Enter name of authorised person ",
                    },
                  ],
                  fieldsOwned: [
                    "nearestRelativeOrCountyCourt",
                    "nearestRelativeOrAuthorisedPersonInfo",
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: [
            "nearestRelativeKnownAct",
            "patientNearestRelative",
            "nearestRelativeOrAuthorisedPersonInfo",
            "makingThisApplication",
            "explainReasoning",
          ],
          fields: [
            {
              type: "branch",
              label: "Select the applicable option",
              field: "nearestRelativeKnownAct",
              branches: [
                {
                  fieldValue: "unable-to-ascertain",
                  label:
                    "(a) I have been unable to ascertain who is this patient's nearest relative within the meaning of the Act",
                  fieldsOwned: [],
                  fields: [],
                },
                {
                  fieldValue: "knowledge-and-belief",
                  label:
                    "(b) To the best of my knowledge and belief this patient has no nearest relative within the meaning of the Act",
                  fieldsOwned: [],
                  fields: [],
                },
                {
                  fieldValue: "patient-nearest-relative",
                  label: "(c) I understand that this following person is",
                  fieldsOwned: [
                    "patientNearestRelative",
                    "nearestRelativeOrAuthorisedPersonInfo",
                    "makingThisApplication",
                    "explainReasoning",
                  ],
                  visibleFields: [
                    {
                      type: "branch",
                      field: "patientNearestRelative",
                      indent: 3.5,
                      branches: [
                        {
                          fieldValue: "patient-nearest-relative-meaning-act",
                          label:
                            "(i) this patient's nearest relative within the meaning of the Act ",
                          fields: [
                            {
                              type: "name-address",
                              field: "nearestRelativeOrAuthorisedPersonInfo",
                              nameLabel:
                                "Enter name of patient's nearest relative",
                              addressLabel:
                                "Enter address of patient's nearest relative ",
                            },
                          ],
                          fieldsOwned: [
                            "nearestRelativeOrAuthorisedPersonInfo",
                          ],
                        },
                        {
                          fieldValue: "patient-nearest-relative-under-act",
                          label:
                            "(ii) authorised to exercise the functions of this patient's nearest relative under the Act",
                          fieldsOwned: [
                            "nearestRelativeOrAuthorisedPersonInfo",
                          ],
                          fields: [
                            {
                              type: "name-address",
                              field: "nearestRelativeOrAuthorisedPersonInfo",
                              nameLabel: "Enter name of authorised person",
                              addressLabel:
                                "Enter address of authorised person",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  fields: [
                    {
                      type: "branch",
                      label: "But in my opinion",
                      field: "makingThisApplication",
                      branches: [
                        {
                          fieldValue: "reasonably-practicable",
                          label:
                            "It is not reasonably practicable to consult that person before making this application",
                          fieldsOwned: ["explainReasoning"],
                          fields: [
                            {
                              type: "textbox",
                              rows: "5",
                              field: "explainReasoning",
                              label:
                                "Explain why it is not reasonably practicable to consult that person before making this application",
                            },
                          ],
                        },
                        {
                          fieldValue: "unreasonable-delay",
                          label:
                            "It would involve unreasonable delay to consult that person before making this application",
                          fieldsOwned: ["explainReasoning"],
                          fields: [
                            {
                              type: "textbox",
                              rows: "5",
                              field: "explainReasoning",
                              label:
                                "Explain why it would involve unreasonable delay to consult that person before making this application",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "date",
      field: "lastSeenDate",
      label: "Enter the date you last saw the patient",
      maximum: (today) => today,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This date was within the period of 14 days ending on the day this application is completed.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I have interviewed the patient and l am satisfied that detention in a hospital is in all the circumstances of the case the most appropriate way of providing the care and medical treatment of which the patient stands in need.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This application is founded on two medical recommendations in the prescribed form.",
      ],
    },

    {
      type: "branch",
      field: "medicalPractitionersPreviousAcquaintance",
      label:
        "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation? If neither had previous acquaintance with the patient, you must explain why. ",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: ["noRecommendation"],
          fields: [
            {
              type: "textbox",
              rows: "5",
              field: "noRecommendation",
              label:
                "Explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient",
            },
          ],
        },
      ],
    },
  ],
  dataSchema: a6Part1DataSchema,
};

export const a6UploadablePart1Template: FormPartTemplate<A6UploadablePart1DataSchema> =
  {
    signing: a5And6Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data, context) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "branch",
        field: "hasNearestRelativeConsultation",
        label: "Has consultation with the nearest relative taken place?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "date",
        field: "lastSeenDate",
        label: "Date signee last saw the patient on",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Signature Date",
        maximum: (today) => today,
      },
    ],
    dataSchema: a6UploadablePart1DataSchema,
  };

export const a6V1Base: UploadableBaseFormTemplate = {
  id: "mha-a6",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a6Part1Template],
  formName: "A6",
  section: "3",
  description:
    "Application by an Approved Mental Health Professional for Admission for Treatment",
  linkableForms: [
    { templateId: "mha-a7", reason: "supported-by" },
    { templateId: "mha-a8", reason: "supported-by" },
  ],
  extractGenericData: (data: A6Data | A6UploadableData) => ({
    admissions: {
      applicationHospitalName: data[0].data?.hospital.name,
      hasNearestRelativeConsultation:
        data[0].data?.hasNearestRelativeConsultation!,
      amhpLastSeenDate: data[0].data?.lastSeenDate,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [a6UploadablePart1Template],
};
