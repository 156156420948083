import {
  CTO6Data,
  cto6Part1DataSchema,
  Cto6Part1DataSchema,
  cto6Part2DataSchema,
  Cto6Part2DataSchema,
} from "../schemas/mhaForms/cto/cto6";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto6Part1Template: FormPartTemplate<Cto6Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Patient name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "hospital-picker",
      field: "hospitalDetained",
      label:
        "Enter name and address of hospital in which the patient is detained",
    },

    {
      type: "hospital-picker",
      field: "hospitalTransferred",
      label:
        "Enter name and address of hospital to which the patient is to be transferred",
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This form authorises the transfer of this patient in accordance with the Mental Health (Hospital, Guardianship and Treatment) (England) Regulations 2008.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I attach a copy of Form CTO4 recording the patient's detention in hospital after recall",
      ],
    },

    {
      type: "branch",
      field: "patientAccreditationStatus",
      label: "Confirm the correct option",
      branches: [
        {
          fieldValue: "current-responsible-hospital",
          label:
            "The hospital in which the patient is currently detained is the patient's responsible hospital.",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: "transfer-to-responsible-hospital",
          label:
            "The hospital to which the patient is to be transferred is the patinent's responsible hospital.",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: "patient-responsible-hospital",
          label: "The patient's responsible hospital is another hospital.",
          fieldsOwned: [],
          fields: [
            {
              type: "hospital-picker",
              field: "responsibleHospital",
              label:
                "Enter the name and address of patient's responsible hospital",
            },
          ],
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: cto6Part1DataSchema,
};

export const cto6Part2Template: FormPartTemplate<Cto6Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      hideAddress: true,
      field: "hospitalAdmittedTo",
      label:
        "Enter name of hospital this patient was admitted to in pursuance of this authority for transfer",
    },

    {
      type: "date-time",
      label: "Enter date and time of admission to receiving hospital",
      field: "admissionDateTime",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: cto6Part2DataSchema,
};

export const cto6V1Base: BaseFormTemplate = {
  id: "mha-cto6",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO6",
  section: "17F(2)",
  parts: [cto6Part1Template, cto6Part2Template],
  description:
    "Authority for transfer of recalled community patient to a hospital under different managers",
  extractGenericData: (data: CTO6Data) => ({
    standalone: {
      admissionDateTime: data[1]?.data.admissionDateTime,
    },
  }),
};
