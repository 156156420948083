import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import {
  A3Data,
  A3Part1DataSchema,
  a3Part1DataSchema,
  a3Part2DataSchema,
  A3Part2DataSchema,
} from "../schemas/mhaForms/admission/a3";
import { validateSection2ExaminationDate } from "./section2";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A3UploadableData,
  A3UploadablePart1DataSchema,
  a3UploadablePart1DataSchema,
  A3UploadablePart2DataSchema,
  a3UploadablePart2DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a3";

const a3Part1Signing: FormPartTemplate<A3Part1DataSchema>["signing"] = {
  userOrTeamRequirement: { accreditation: ["doctor"], teamType: [] },
};

export const a3Part1Template: FormPartTemplate<A3Part1DataSchema> = {
  signing: a3Part1Signing,
  formBuilderFields: [
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "We, registered medical practitioners, recommend that this patient be admitted to a hospital for assessment in accordance with Part 2 of the Mental Health Act 1983. ",
      ],
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "date",
      label: "Enter the date you last examined this patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
      warningFn: validateSection2ExaminationDate,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In our opinion, " +
          "this patient is suffering from mental disorder " +
          "of a nature or degree " +
          "which warrants the detention of the patient in hospital " +
          "for assessment (or for assessment followed by medical treatment) " +
          "for at least a limited period.",
      ],
    },
    {
      type: "multi-select",
      label: "(b) In our opinion, this patient ought to be so detained",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "in the interests of the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "in the interests of the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "with a view to the protection of other persons.",
        },
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "Our reasons for these opinions are: " +
        "(Your reasons should cover both (a) and (b) above. " +
        "As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour " +
        "lead you to your opinion; explain why the patient ought to be admitted to hospital and why informal admission " +
        "is not appropriate).",
      rows: "10",
    },
  ],
  dataSchema: a3Part1DataSchema,
};

const a3Part2Signing: FormPartTemplate<A3Part2DataSchema>["signing"] = {
  userOrTeamRequirement: {
    accreditation: ["doctor"],
    teamType: [],
    friendlyDescription:
      RequirementFriendlyDescription.registeredMedicalPractitioner,
  },
};
export const a3Part2Template: FormPartTemplate<A3Part2DataSchema> = {
  signing: a3Part2Signing,
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "date",
      label: "Enter the date you last examined this patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
      warningFn: validateSection2ExaminationDate,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "I am approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder",
    },
  ],
  dataSchema: a3Part2DataSchema,
};

export const a3UploadablePart1Template: FormPartTemplate<A3UploadablePart1DataSchema> =
  {
    signing: a3Part1Signing,
    formBuilderFields: [
      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of first practitioner",
        addressLabel: "Address of first practitioner",
      },
      {
        type: "textbox",
        label: "Email address of first practitioner",
        field: "emailAddressOfSignee",
      },
      {
        type: "date",
        label: "Date first practitioner last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
        warningFn: validateSection2ExaminationDate,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label:
          "Did first practitioner have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is first practitioner approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },
      {
        type: "multi-select",
        label: "Confirm detention criteria",
        options: [
          {
            field: "necessaryForPatientsOwnHealth",
            label: "in the interests of the patient's own health",
          },
          {
            field: "necessaryForPatientsOwnSafety",
            label: "in the interests of the patient's own safety",
          },
          {
            field: "necessaryForProtectionOfOtherPersons",
            label: "with a view to the protection of other persons",
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "First practitioners date of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a3UploadablePart1DataSchema,
  };

export const a3UploadablePart2Template: FormPartTemplate<A3UploadablePart2DataSchema> =
  {
    signing: a3Part2Signing,
    formBuilderFields: [
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of second practitioner",
        addressLabel: "Address of second practitioner",
      },

      {
        type: "textbox",
        label: "Email address of second practitioner",
        field: "emailAddressOfSignee",
      },

      {
        type: "date",
        label: "Date second practitioner last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
        warningFn: validateSection2ExaminationDate,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label:
          "Did second practitioner have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is second practitioner approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Second practitioners date of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a3UploadablePart2DataSchema,
  };

export const a3V1Base: UploadableBaseFormTemplate = {
  id: "mha-a3",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a3Part1Template, a3Part2Template],
  formName: "A3",
  section: "2",
  description: "Joint Medical Recommendation for Admission for Assessment",
  extractGenericData: (data: A3Data | A3UploadableData) => {
    const part1Data = data[0].data;

    return {
      admissions: {
        section12ApprovedStatus: data
          .filter((d) => d)
          .map((d) => d!.data?.user?.isSection12Approved || false),
        lastExaminedDate: data
          .filter((d) => d)
          .map((d) => d!.data?.lastExaminedDate),
        examinerPreviousAcquaintance: part1Data?.previousAcquaintance,

        ...("necessaryForPatientsOwnHealth" in part1Data
          ? {
              necessaryForPatientsOwnHealth: (part1Data as A3Part1DataSchema)
                ?.necessaryForPatientsOwnHealth,
              necessaryForPatientsOwnSafety: (part1Data as A3Part1DataSchema)
                ?.necessaryForPatientsOwnSafety,
              necessaryForProtectionOfOtherPersons: (
                part1Data as A3Part1DataSchema
              )?.necessaryForProtectionOfOtherPersons,
            }
          : {}),
      },
    };
  },

  uploadable: true,
  uploadablePartTemplates: [
    a3UploadablePart1Template,
    a3UploadablePart2Template,
  ],
};
