import { InferType, object } from "yup";

import { ExampleFormData, PartData } from "../../../types/form";

export const a1Part1DataSchema = object({});

export type A1Part1DataSchema = InferType<typeof a1Part1DataSchema>;

export type A1Data = [PartData<A1Part1DataSchema>];

export const exampleA1Data: ExampleFormData<A1Data> = [
  {
    data: {},
  },
];
