import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import {
  A7Data,
  A7Part1DataSchema,
  a7Part1DataSchema,
  a7Part2DataSchema,
  A7Part2DataSchema,
} from "../schemas/mhaForms/admission/a7";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A7UploadableData,
  a7UploadablePart1DataSchema,
  A7UploadablePart1DataSchema,
  a7UploadablePart2DataSchema,
  A7UploadablePart2DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a7";

const a7Part1Signing: FormPartTemplate<A7Part1DataSchema>["signing"] = {
  userOrTeamRequirement: {
    accreditation: ["doctor"],
    teamType: [],
  },
};

const a7Part2Signing: FormPartTemplate<A7Part2DataSchema>["signing"] = {
  userOrTeamRequirement: {
    accreditation: ["doctor"],
    teamType: [],
    friendlyDescription:
      RequirementFriendlyDescription.registeredMedicalPractitioner,
  },
};

export const a7Part1Template: FormPartTemplate<A7Part1DataSchema> = {
  signing: a7Part1Signing,

  formBuilderFields: [
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "We, registered medical practitioners, recommend that this patient be admitted to a hospital for treatment in accordance with Part 2 of the Mental Health Act 1983.",
      ],
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "date",
      label: "Enter the date you last examined this patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In our opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital.",
      ],
    },
    {
      type: "multi-select",
      label:
        "(b) In our opinion, it is necessary that this patient should receive treatment in hospital",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "for the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "for the patient's own safety ",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "for the protection of other persons",
        },
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(c) In our opinion, such treatment cannot be provided unless the patient is detained under section 3 of the act.",
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "Because: (Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate; indicate why informal admission is not appropriate.)",
      rows: "10",
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "We are also of the opinion that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all the other circumstances of the case, appropriate medical treatment is available to the patient at the following hospital (or one of the following hospitals).",
      ],
    },
    {
      type: "textbox",
      field: "hospitalsAndParts",
      label:
        "Enter name of hospital(s). If appropriate treatment is available only in a particular part of the hospital, say which part.",
      rows: "10",
    },
  ],
  dataSchema: a7Part1DataSchema,
};

export const a7Part2Template: FormPartTemplate<A7Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["doctor"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.registeredMedicalPractitioner,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "date",
      label: "Enter the date you last examined this patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
  ],
  dataSchema: a7Part2DataSchema,
};

export const a7UploadablePart1Template: FormPartTemplate<A7UploadablePart1DataSchema> =
  {
    signing: a7Part1Signing,
    formBuilderFields: [
      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of first practitioner",
        addressLabel: "Address of first practitioner",
      },

      {
        type: "textbox",
        label: "Email address of first practitioner",
        field: "emailAddressOfSignee",
      },

      {
        type: "date",
        label: "Date first practitioner last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label:
          "Did first practitioner have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is first practitioner approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "multi-select",
        label: "Confirm detention criteria",
        options: [
          {
            field: "necessaryForPatientsOwnHealth",
            label: "for the patient's own health",
          },
          {
            field: "necessaryForPatientsOwnSafety",
            label: "for the patient's own safety",
          },
          {
            field: "necessaryForProtectionOfOtherPersons",
            label: "for the protection of other persons",
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "First practitioner date of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a7UploadablePart1DataSchema,
  };

export const a7UploadablePart2Template: FormPartTemplate<A7UploadablePart2DataSchema> =
  {
    signing: a7Part2Signing,
    formBuilderFields: [
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of second practitioner",
        addressLabel: "Address of second practitioner",
      },

      {
        type: "textbox",
        label: "Email address of second practitioner",
        field: "emailAddressOfSignee",
      },

      {
        type: "date",
        label: "Date second practitioner last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label:
          "Did second practitioner have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is second practitioner approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Second practitioner date of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a7UploadablePart2DataSchema,
  };

export const a7V1Base: UploadableBaseFormTemplate = {
  id: "mha-a7",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a7Part1Template, a7Part2Template],
  formName: "A7",
  section: "3",
  description: "Joint medical recommendation for admission for treatment ",
  extractGenericData: (data: A7Data | A7UploadableData) => ({
    admissions: {
      section12ApprovedStatus: data
        .filter((d) => d)
        .map((d) => d!.data?.user?.isSection12Approved || false),
      lastExaminedDate: data
        .filter((d) => d)
        .map((d) => d!.data?.lastExaminedDate),
      examinerPreviousAcquaintance: data[0].data?.previousAcquaintance,
      necessaryForPatientsOwnHealth:
        data[0].data?.necessaryForPatientsOwnHealth,
      necessaryForPatientsOwnSafety:
        data[0].data?.necessaryForPatientsOwnSafety,
      necessaryForProtectionOfOtherPersons:
        data[0].data?.necessaryForProtectionOfOtherPersons,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [
    a7UploadablePart1Template,
    a7UploadablePart2Template,
  ],
};
