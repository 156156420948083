import React, { useContext } from "react";
import { Box } from "@mui/material";
import { isNonNull } from "@aspire/common/util";

import {
  PractitionerFormBuilderField,
  MhFormContext,
} from "@aspire/common/types/form";
import { FieldProps } from "../../pages/FieldProps";
import { NameAddressFormField } from "./NameAndAddressFormField/NameAddressFormField";
import { useTranslation } from "react-i18next";
import { LoggedInUserContext } from "../../Contexts";
import { isGuestUser } from "@aspire/common/types/user";

function compareUserDemographics(
  currentUser: {
    name: string;
    address: { address: string; postalCode: string };
  },
  comparisonData: {
    name: string;
    address: string;
    postalCode?: string;
  },
) {
  // Generate warnings if the practitioner name or address entered on the form don't match
  // the details saved on their user profile
  const nameIsDifferent =
    currentUser.name && currentUser.name !== comparisonData?.name;
  const addressIsDifferent =
    currentUser.address?.address &&
    currentUser.address?.address !== comparisonData?.address;
  const postalCodeIsDifferent =
    currentUser.address?.postalCode &&
    currentUser.address?.postalCode !== comparisonData?.postalCode;

  return [nameIsDifferent, addressIsDifferent, postalCodeIsDifferent];
}

export type PractitionerFormFieldProps<Data extends { [k: string]: any }> = {
  field: PractitionerFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function PractitionerFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: PractitionerFormFieldProps<Data>) {
  const userContext = useContext(LoggedInUserContext);
  const { t } = useTranslation();

  const hideWarningBanners = field.hideWarningBanners;

  // Don't show warnings if the user is a guest user
  // as they always have to enter their name/address
  const guestUser = isGuestUser(userContext?.user);

  const previousPractitionerData = (
    fieldProps.context as MhFormContext
  ).previousPartsFormData?.find((c) => c.data.user.id === userContext?.user.id)
    ?.data.user;

  const currentUserProfileMismatchWarnings = compareUserDemographics(
    userContext?.user!,
    fieldProps.values[field.field],
  );

  const previousSignedFormPartMismatchWarnings = previousPractitionerData
    ? compareUserDemographics(
        {
          name: fieldProps.values[field.field].name,
          address: {
            address: fieldProps.values[field.field].address,
            postalCode: fieldProps.values[field.field].postalCode,
          },
        },
        previousPractitionerData,
      )
    : [false, false, false];

  const showPreviousSignedFormPartWarnings =
    previousSignedFormPartMismatchWarnings.some((x) => x);

  const [nameIsDifferent, addressIsDifferent, postalCodeIsDifferent] =
    showPreviousSignedFormPartWarnings
      ? previousSignedFormPartMismatchWarnings
      : currentUserProfileMismatchWarnings;

  const nameWarnings = [
    nameIsDifferent
      ? showPreviousSignedFormPartWarnings
        ? t("pages.formPage.nameDoesNotMatchPreviousFormPart")
        : t("pages.formPage.nameDoesNotMatchProfile")
      : undefined,
  ].filter(isNonNull);

  const addressWarnings = [
    addressIsDifferent
      ? showPreviousSignedFormPartWarnings
        ? t("pages.formPage.addressDoesNotMatchPreviousFormPart")
        : t("pages.formPage.addressDoesNotMatchProfile")
      : undefined,
    postalCodeIsDifferent
      ? showPreviousSignedFormPartWarnings
        ? t("pages.formPage.postalCodeDoesNotMatchPreviousFormPart")
        : t("pages.formPage.postalCodeDoesNotMatchProfile")
      : undefined,
  ].filter(isNonNull);

  const warnings = {
    name: nameWarnings.length ? nameWarnings.join(", ") : undefined,
    address: addressWarnings.length ? addressWarnings.join(", ") : undefined,
  };

  return (
    <Box>
      <NameAddressFormField
        field={{
          nameLabel: field.label,
          type: "name-address",
          field: field.field,
          disableAddress: field.hideAddress,
          disableName: field.hideName,
          disableEmail: field.hideEmail,
        }}
        fieldProps={fieldProps}
        warnings={
          hideWarningBanners ||
          (guestUser && !showPreviousSignedFormPartWarnings)
            ? {}
            : warnings
        }
      />
    </Box>
  );
}
