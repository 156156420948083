import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const PatientCrisisInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.royalBlue,
        display: "flex",
        py: 1,
        px: 3,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 1.25,
        alignSelf: "stretch",
        borderRadius: "0px 0px 6px 6px",
        height: "auto",
      }}
    >
      <Box>
        <Typography sx={{ color: "white" }}>
          <Link
            href="https://www.nhs.uk/service-search/mental-health/find-an-urgent-mental-health-helpline"
            className="find-crisis-link"
            target="_blank"
            rel="noreferrer"
            sx={{ fontSize: 13, color: "white", textDecoration: "underline" }}
          >
            {t("common.clickHere")}
          </Link>
          <Typography component="span" sx={{ fontSize: 13 }}>
            &nbsp;{t("components.patientCrisisInfo.text")}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
