import React from "react";
import { styled } from "@mui/material/styles";

import { IconButton, IconButtonProps, alpha } from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
  isExpanded: boolean;
}

export const StickyContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<any>(({ disableSticky, issticky, theme, top, ismobilemode }) => ({
  ...(!disableSticky && {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    ...(issticky && {
      ...(!ismobilemode && { paddingTop: theme.spacing(1) }),
      position: "sticky",
      top: top,
      minHeight: "auto",
      zIndex: 999,
    }),
  }),
}));

export const Container = styled(
  "div",
  {},
)<any>(
  ({
    theme,
    addMarginBottom,
    styleAsClickable,
    removeBottomPaddingAndMargin,
  }) => ({
    padding: removeBottomPaddingAndMargin
      ? theme.spacing(2, 2, 0, 2)
      : theme.spacing(2),
    marginBottom: removeBottomPaddingAndMargin
      ? theme.spacing(0)
      : theme.spacing(2),
    width: "100%",
    backgroundColor: "white",
    border: `0.5px solid ${theme.palette.background.container}`,
    boxShadow:
      "0px 1px 1px rgba(84, 89, 107, 0.25), 0px 0px 1px rgba(84, 89, 107, 0.31)",
    borderRadius: "6px",
    ...(addMarginBottom && { marginBottom: theme.spacing(2) }),

    "&:hover": styleAsClickable
      ? {
          cursor: "pointer",
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }
      : {},
  }),
);

export const SubContainer = styled(
  "div",
  {},
)<any>(({ theme, addMarginBottom, styleAsClickable }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
  width: "100%",
  backgroundColor: "white",
  border: `1px solid ${theme.palette.common.lightBlue}`,
  boxShadow:
    "0px 1px 1px rgba(84, 89, 107, 0.25), 0px 0px 1px rgba(84, 89, 107, 0.31)",
  borderRadius: "6px",
  ...(addMarginBottom && { marginBottom: theme.spacing(2) }),

  "&:hover": styleAsClickable
    ? {
        cursor: "pointer",
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }
    : {},
}));

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { isExpanded, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, isExpanded }) => ({
  transform: !isExpanded ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
