import React from "react";
import { api, apiHooks } from "../../api";
import { useParams } from "react-router-dom";
import { RioConfiguration } from "@aspire/common/types/rioConfiguration";
import { routeFns } from "../../routes";
import { Box } from "@mui/material";
import { Formik } from "formik";
import { CreateRioInstanceRequest } from "@aspire/common/types/rioRequests";
import { nonEmptyRequiredString } from "@aspire/common/schemas/shared";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import {
  Button,
  FormLabel,
  renderErrorToast,
  renderSuccessToast,
  TextField,
  HelperText,
} from "~/components/design-system";

const rioInstanceDetailsSchema = object({
  displayName: nonEmptyRequiredString
    .default("")
    .required("Please enter a display name"),
  clickthrough: object().shape(
    {
      apiKey: string().when(["hmacSha512SecretKey", "launchUrl"], {
        is: (hmacSha512SecretKey: string, launchUrl: string) =>
          hmacSha512SecretKey || launchUrl,
        then: (schema: any) =>
          schema.required(
            "Please enter all clickthrough fields or none of them",
          ),
      }),
      hmacSha512SecretKey: string().when(["apiKey", "launchUrl"], {
        is: (apiKey: string, launchUrl: string) => apiKey || launchUrl,
        then: (schema: any) =>
          schema.required(
            "Please enter all clickthrough fields or none of them",
          ),
      }),
      launchUrl: string().when(["apiKey", "hmacSha512SecretKey"], {
        is: (apiKey: string, hmacSha512SecretKey: string) =>
          apiKey || hmacSha512SecretKey,
        then: (schema: any) =>
          schema.required(
            "Please enter all clickthrough fields or none of them",
          ),
      }),
    },
    [
      ["apiKey", "hmacSha512SecretKey"],
      ["apiKey", "launchUrl"],
      ["hmacSha512SecretKey", "launchUrl"],
    ],
  ),
  conexesConfiguration: object({
    documentType: nonEmptyRequiredString
      .default("")
      .required("Document type is required"),
    authentication: object({
      userId: nonEmptyRequiredString
        .default("")
        .required("User Id is required"),
      systemName: nonEmptyRequiredString
        .default("")
        .required("System name is required"),
      url: nonEmptyRequiredString.default("").required("Url is required"),
      certificates: object({
        ca: nonEmptyRequiredString.default("").required("Ca is required"),
        cert: nonEmptyRequiredString.default("").required("Cert is required"),
        key: nonEmptyRequiredString.default("").required("Key is required"),
      }),
    }),
  }),
});

function RioInstanceEdit({
  rioInstanceId,
  rioInstance,
  refetchRioInstance,
}: {
  rioInstanceId: string;
  rioInstance: RioConfiguration | null;
  refetchRioInstance: () => void;
}) {
  const initialData = {
    ...rioInstanceDetailsSchema.getDefault(),
    ...(rioInstance
      ? {
          ...rioInstance,
          displayName: rioInstance.displayName ?? "",
          conexesConfiguration: {
            documentType: rioInstance.conexesConfiguration.documentType ?? "",
            authentication: {
              userId:
                rioInstance.conexesConfiguration.authentication.userId ?? "",
              systemName:
                rioInstance.conexesConfiguration.authentication.systemName ??
                "",
              url: rioInstance.conexesConfiguration.authentication.url ?? "",
              certificates: {
                ca:
                  rioInstance.conexesConfiguration.authentication.certificates
                    .ca ?? "",
                cert:
                  rioInstance.conexesConfiguration.authentication.certificates
                    .cert ?? "",
                key:
                  rioInstance.conexesConfiguration.authentication.certificates
                    .key ?? "",
              },
            },
          },
          clickthrough: {
            apiKey: rioInstance.clickthrough?.apiKey ?? "",
            hmacSha512SecretKey:
              rioInstance.clickthrough?.hmacSha512SecretKey ?? "",
            launchUrl: rioInstance.clickthrough?.launchUrl ?? "",
          },
        }
      : {}),
  };

  return (
    <Formik<CreateRioInstanceRequest>
      initialValues={initialData}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const cleanedRioInstanceData = {
          displayName: values.displayName.trim(),
          conexesConfiguration: {
            documentType: values.conexesConfiguration.documentType.trim(),
            authentication: {
              userId: values.conexesConfiguration.authentication.userId.trim(),
              systemName:
                values.conexesConfiguration.authentication.systemName.trim(),
              url: values.conexesConfiguration.authentication.url.trim(),
              certificates: {
                ca: values.conexesConfiguration.authentication.certificates.ca.trim(),
                cert: values.conexesConfiguration.authentication.certificates.cert.trim(),
                key: values.conexesConfiguration.authentication.certificates.key.trim(),
              },
            },
          },
          ...(values.clickthrough?.apiKey && {
            clickthrough: {
              apiKey: values.clickthrough?.apiKey.trim(),
              hmacSha512SecretKey:
                values.clickthrough?.hmacSha512SecretKey.trim(),
              launchUrl: values.clickthrough?.launchUrl.trim(),
            },
          }),
        };
        if (rioInstance) {
          const result = await api.rio.instance.update(
            rioInstanceId,
            cleanedRioInstanceData,
          );

          if (result.status === 204) {
            refetchRioInstance();
            renderSuccessToast({
              message: "Rio Instance updated successfully",
            });
          } else {
            renderErrorToast({
              message: `Rio Instance update failed: ${result.data.reason}`,
            });
          }
        } else {
          const result = await api.rio.instance.create(
            rioInstanceId,
            cleanedRioInstanceData,
          );
          if (result.status === 204) {
            refetchRioInstance();
            renderSuccessToast({
              message: "Rio Instance created successfully",
            });
          } else {
            renderErrorToast({
              message: `Rio Instance create failed: ${result.data.reason}`,
            });
          }
        }
        setSubmitting(false);
      }}
      validationSchema={rioInstanceDetailsSchema}
    >
      {(formikData) => {
        const { values, setValues, errors, touched, submitForm } = formikData;

        return (
          <>
            <Box sx={{ maxWidth: "600px", marginBottom: "2em" }}>
              <FormLabel
                label={"Display name (*)"}
                error={touched.displayName && !!errors.displayName}
              />
              <TextField
                name={"displayName"}
                useFullWidth={true}
                value={values.displayName}
                onChange={(e) => {
                  setValues({
                    ...values,
                    displayName: e,
                  });
                }}
              />
              {errors.displayName && touched.displayName && (
                <Box sx={{ mb: 4 }}>
                  <HelperText errorMessage={errors.displayName as string} />
                </Box>
              )}

              <FormLabel
                label={"Conexes document type (*)"}
                error={
                  touched.conexesConfiguration?.documentType &&
                  !!errors.conexesConfiguration?.documentType
                }
              />
              <TextField
                name={"conexesDocumentType"}
                useFullWidth={true}
                value={values.conexesConfiguration.documentType}
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      documentType: e,
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.documentType &&
                touched.conexesConfiguration?.documentType && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.documentType as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes authentication user id (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.userId &&
                  !!errors.conexesConfiguration?.authentication?.userId
                }
              />
              <TextField
                name={"conexesAuthenticationUserId"}
                useFullWidth={true}
                value={values.conexesConfiguration?.authentication?.userId}
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        userId: e,
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.userId &&
                touched.conexesConfiguration?.authentication?.userId && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication
                          .userId as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes Authentication system name (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.systemName &&
                  !!errors.conexesConfiguration?.authentication?.systemName
                }
              />
              <TextField
                name={"conexesAuthenticationSystemName"}
                useFullWidth={true}
                value={values.conexesConfiguration.authentication.systemName}
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        systemName: e,
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.systemName &&
                touched.conexesConfiguration?.authentication?.systemName && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication
                          .systemName as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes Authentication URL (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.url &&
                  !!errors.conexesConfiguration?.authentication?.url
                }
              />
              <TextField
                name={"conexesAuthenticationUrl"}
                useFullWidth={true}
                value={values.conexesConfiguration.authentication.url}
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        url: e,
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.url &&
                touched.conexesConfiguration?.authentication?.url && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication.url as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes Authentication Certificates CA (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.certificates
                    ?.ca &&
                  !!errors.conexesConfiguration?.authentication?.certificates
                    ?.ca
                }
              />
              <TextField
                multiline={true}
                rows="5"
                name={"conexesAuthenticationCertificatesCA"}
                useFullWidth={true}
                value={
                  values.conexesConfiguration.authentication.certificates.ca
                }
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        certificates: {
                          ...values.conexesConfiguration.authentication
                            .certificates,
                          ca: e,
                        },
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.certificates?.ca &&
                touched.conexesConfiguration?.authentication?.certificates
                  ?.ca && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication.certificates
                          .ca as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes Authentication Certificates Cert (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.certificates
                    ?.cert &&
                  !!errors.conexesConfiguration?.authentication?.certificates
                    ?.cert
                }
              />
              <TextField
                multiline={true}
                rows="5"
                name={"conexesAuthenticationCertificatesCert"}
                useFullWidth={true}
                value={
                  values.conexesConfiguration.authentication.certificates.cert
                }
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        certificates: {
                          ...values.conexesConfiguration.authentication
                            .certificates,
                          cert: e,
                        },
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.certificates
                ?.cert &&
                touched.conexesConfiguration?.authentication?.certificates
                  ?.cert && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication.certificates
                          .cert as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Conexes Authentication Certificates Key (*)"}
                error={
                  touched.conexesConfiguration?.authentication?.certificates
                    ?.key &&
                  !!errors.conexesConfiguration?.authentication?.certificates
                    ?.key
                }
              />
              <TextField
                multiline={true}
                rows="5"
                name={"conexesAuthenticationCertificatesKey"}
                useFullWidth={true}
                value={
                  values.conexesConfiguration.authentication.certificates.key
                }
                onChange={(e) => {
                  setValues({
                    ...values,
                    conexesConfiguration: {
                      ...values.conexesConfiguration,
                      authentication: {
                        ...values.conexesConfiguration.authentication,
                        certificates: {
                          ...values.conexesConfiguration.authentication
                            .certificates,
                          key: e,
                        },
                      },
                    },
                  });
                }}
              />
              {errors.conexesConfiguration?.authentication?.certificates?.key &&
                touched.conexesConfiguration?.authentication?.certificates
                  ?.key && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        errors.conexesConfiguration.authentication.certificates
                          .key as string
                      }
                    />
                  </Box>
                )}
              <FormLabel
                label={"Clickthrough API Key"}
                error={
                  (touched.clickthrough as any)?.apiKey &&
                  !!(errors.clickthrough as any)?.apiKey
                }
              />
              <TextField
                name={"clickthroughApiKey"}
                useFullWidth={true}
                value={values.clickthrough?.apiKey}
                onChange={(e) => {
                  setValues({
                    ...values,
                    clickthrough: {
                      ...values.clickthrough!,
                      apiKey: e,
                    },
                  });
                }}
              />
              {(errors.clickthrough as any)?.apiKey &&
                (touched.clickthrough as any)?.apiKey && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        (errors.clickthrough as any)?.apiKey as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Clickthrough hmacsha512 secret key"}
                error={
                  (touched.clickthrough as any)?.hmacSha512SecretKey &&
                  !!(errors.clickthrough as any)?.hmacSha512SecretKey
                }
              />
              <TextField
                name={"clickthroughHmacSha512SecretKey"}
                useFullWidth={true}
                value={values.clickthrough?.hmacSha512SecretKey || ""}
                onChange={(e) => {
                  setValues({
                    ...values,
                    clickthrough: {
                      ...values.clickthrough!,
                      hmacSha512SecretKey: e,
                    },
                  });
                }}
              />
              {(errors.clickthrough as any)?.hmacSha512SecretKey &&
                (touched.clickthrough as any)?.hmacSha512SecretKey && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        (errors.clickthrough as any)
                          ?.hmacSha512SecretKey as string
                      }
                    />
                  </Box>
                )}

              <FormLabel
                label={"Clickthrough launch URL"}
                error={
                  (touched.clickthrough as any)?.launchUrl &&
                  !!(errors.clickthrough as any)?.launchUrl
                }
              />
              <TextField
                name={"clickthroughLaunchUrl"}
                useFullWidth={true}
                value={values.clickthrough?.launchUrl}
                onChange={(e) => {
                  setValues({
                    ...values,
                    clickthrough: {
                      ...values.clickthrough!,
                      launchUrl: e,
                    },
                  });
                }}
              />
              {(errors.clickthrough as any)?.launchUrl &&
                (touched.clickthrough as any)?.launchUrl && (
                  <Box sx={{ mb: 4 }}>
                    <HelperText
                      errorMessage={
                        (errors.clickthrough as any)?.launchUrl as string
                      }
                    />
                  </Box>
                )}
            </Box>

            <Box sx={{ mb: 2, mt: 1 }}>
              <Button
                label={rioInstance ? "Update" : "Create"}
                onClick={submitForm}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
}

function RioInstanceEditPageInner({
  rioInstanceId,
  rioInstance,
  refetchRioInstance,
}: {
  rioInstanceId: string;
  rioInstance: RioConfiguration | null;
  refetchRioInstance: () => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {rioInstance ? (
        <h2>{rioInstance.displayName}</h2>
      ) : (
        <h2>Create New Rio Instance</h2>
      )}
      <RioInstanceEdit
        rioInstanceId={rioInstanceId}
        rioInstance={rioInstance}
        refetchRioInstance={refetchRioInstance}
      />
    </Box>
  );
}

export function RioInstanceCreateEdit() {
  const { rioInstanceId } = useParams();
  const navigate = useNavigate();
  const [
    { data: rioInstance, loading: rioInstanceLoading, response },
    refetchRioInstance,
  ] = apiHooks.rio.get(rioInstanceId!);

  return rioInstanceLoading ? (
    <></>
  ) : (
    <>
      <RioInstanceEditPageInner
        rioInstanceId={rioInstanceId!}
        rioInstance={response?.status === 200 ? rioInstance! : null}
        refetchRioInstance={refetchRioInstance}
      />
      <Box sx={{ mb: 2, mt: 1 }}>
        <Button
          label={"Back"}
          onClick={() => navigate(routeFns.adminRioInstancesPage())}
        />
      </Box>
    </>
  );
}
