import { boolean, InferType, object, string } from "yup";
import {
  defaultContextFields,
  nonEmptyRequiredString,
  nonEmptyString,
  optionalAddress,
} from "../shared";
import {
  optionalEmail,
  phoneNumber,
  requiredBooleanWhen,
  requiredStringBasedOnCheckbox,
  requiredStringWhen,
} from "./util";

export const acdPart1DataSchema = object({
  ...defaultContextFields(),
  patientGenderCode: nonEmptyString
    .default("")
    .required("Please confirm gender"),
  patientGenderText: nonEmptyRequiredString,
  patientEthnicityCode: nonEmptyString
    .default("")
    .required("Please confirm ethnicity"),
  patientEthnicityText: nonEmptyRequiredString,

  patientNhsNumber: nonEmptyString
    .default(null)
    .matches(/[0-9]{10}/, "Please enter a valid NHS number")
    .required("Please confirm NHS number"),

  trustId: nonEmptyString.required("Please confirm Trust ID"),

  mainMentalHealthDifficultiesSelected: boolean().default(true),
  mainMentalHealthDifficulties: requiredStringBasedOnCheckbox(
    "mainMentalHealthDifficultiesSelected",
    "Please confirm main mental health difficulties",
  ),

  mainPhysicalIllnessOrAllergiesSelected: boolean().default(true),
  mainPhysicalIllnessOrAllergies: requiredStringBasedOnCheckbox(
    "mainPhysicalIllnessOrAllergiesSelected",
    "Please confirm main physical illness and allergies",
  ),
  learningOrCommunicationDifficultiesOrOtherSignificantDiagnosesSelected:
    boolean().default(true),
  learningOrCommunicationDifficultiesOrOtherSignificantDiagnoses:
    requiredStringBasedOnCheckbox(
      "learningOrCommunicationDifficultiesOrOtherSignificantDiagnosesSelected",
      "Please confirm learning difficulties, communication difficulties or other significant diagnoses",
    ),

  // Section 2

  whatImLikeWhenImWellSelected: boolean().default(true),
  whatImLikeWhenImWell: requiredStringBasedOnCheckbox(
    "whatImLikeWhenImWellSelected",
    "Please confirm what you’re like when you’re well",
  ),

  // Section 3

  thingsThatHaveLedMeToBeingUnwellSelected: boolean().default(true),
  thingsThatHaveLedMeToBeingUnwell: requiredStringBasedOnCheckbox(
    "thingsThatHaveLedMeToBeingUnwellSelected",
    "Please confirm things that have led to you becoming unwell before",
  ),

  keySignsIAmInCrisisSelected: boolean().default(true),
  keySignsIAmInCrisis: requiredStringBasedOnCheckbox(
    "keySignsIAmInCrisisSelected",
    "Please confirm signs that you’re in crisis ",
  ),

  earlySignsIAmLosingCapacitySelected: boolean().default(true),
  earlySignsIAmLosingCapacity: requiredStringBasedOnCheckbox(
    "earlySignsIAmLosingCapacitySelected",
    "Please confirm signs that you’re losing capacity to make decisions",
  ),
  thingsToAvoidSelected: boolean().default(true),
  thingsToAvoid: requiredStringBasedOnCheckbox(
    "thingsToAvoidSelected",
    "Please confirm key things you want to avoid",
  ),

  keyThingsPeopleMisunderstandAboutMeWhenUnwellSelected:
    boolean().default(true),
  keyThingsPeopleMisunderstandAboutMeWhenUnwell: requiredStringBasedOnCheckbox(
    "keyThingsPeopleMisunderstandAboutMeWhenUnwellSelected",
    "Please confirm things people misunderstand about you when you’re unwell",
  ),

  // Section 4

  communityHomeTreatmentTeamCrisisCareSelected: boolean().default(true),
  communityHomeTreatmentTeamCrisisCare: requiredStringBasedOnCheckbox(
    "communityHomeTreatmentTeamCrisisCareSelected",
    "Please confirm preferences",
  ),

  whenINeedAdmissionToHospitalSelected: boolean().default(true),
  whenINeedAdmissionToHospital: requiredStringBasedOnCheckbox(
    "whenINeedAdmissionToHospitalSelected",
    "Please confirm preferences",
  ),

  mentalHealthActAssessmentSelected: boolean().default(true),
  mentalHealthActAssessment: requiredStringBasedOnCheckbox(
    "mentalHealthActAssessmentSelected",
    "Please confirm preferences",
  ),

  hospitalCareSelected: boolean().default(true),
  hospitalCare: requiredStringBasedOnCheckbox(
    "hospitalCareSelected",
    "Please confirm preferences",
  ),

  mentalHealthMedicalTreatmentsSelected: boolean().default(true),
  mentalHealthMedicalTreatments: requiredStringBasedOnCheckbox(
    "mentalHealthMedicalTreatmentsSelected",
    "Please confirm preferences",
  ),

  ambulanceServiceCanAccessSelected: boolean().default(true),
  ambulanceServiceCanAccess: requiredStringBasedOnCheckbox(
    "ambulanceServiceCanAccessSelected",
    "Please confirm if the ambulance service can access",
  ),

  aAndEStaffAccessSelected: boolean().default(true),
  aAndEStaffAccess: requiredStringBasedOnCheckbox(
    "aAndEStaffAccessSelected",
    "Please confirm if A&E staff access",
  ),

  mentalHealthNonMedicalTreatmentsSelected: boolean().default(true),
  mentalHealthNonMedicalTreatments: requiredStringBasedOnCheckbox(
    "mentalHealthNonMedicalTreatmentsSelected",
    "Please confirm preferences",
  ),

  whatIWantToAvoidWhenInCrisisSelected: boolean().default(true),
  whatIWantToAvoidWhenInCrisis: requiredStringBasedOnCheckbox(
    "whatIWantToAvoidWhenInCrisisSelected",
    "Please confirm preferences",
  ),

  religiousOrSpiritualBeliefsAndCulturalPracticesSelected:
    boolean().default(true),
  religiousOrSpiritualBeliefsAndCulturalPractices:
    requiredStringBasedOnCheckbox(
      "religiousOrSpiritualBeliefsAndCulturalPracticesSelected",
      "Please confirm preferences",
    ),

  physicalHealthTreatmentAndCareDuringHomeTreatmentSelected:
    boolean().default(true),
  physicalHealthTreatmentAndCareDuringHomeTreatment:
    requiredStringBasedOnCheckbox(
      "physicalHealthTreatmentAndCareDuringHomeTreatmentSelected",
      "Please confirm preferences",
    ),

  emergencyPhysicalHealthCareAndTreatmentSelected: boolean().default(true),
  emergencyPhysicalHealthCareAndTreatment: requiredStringBasedOnCheckbox(
    "emergencyPhysicalHealthCareAndTreatmentSelected",
    "Please confirm preferences",
  ),

  // Section 5 - Practical Help
  checkHomeIsSecure: boolean()
    .default(null)
    .nonNullable("Please confirm statement"),

  throwAwayPerishableFoodsOrDrinks: boolean()
    .default(null)
    .nonNullable("Please confirm statement"),

  careForChildrenNeedToBeArranged: boolean()
    .default(null)
    .nonNullable("Please confirm statement"),
  careArrangementsForChildren: requiredStringWhen(
    "careForChildrenNeedToBeArranged",
    "Please confirm care arrangements for your children",
  ),

  careForPetsNeedToBeArranged: boolean()
    .default(null)
    .nonNullable("Please confirm care arrangements for pets"),
  careArrangementsForPets: requiredStringWhen(
    "careForPetsNeedToBeArranged",
    "Please confirm care arrangements for your pet(s)",
  ),

  careForDependentRelativesNeedToBeArranged: boolean()
    .default(null)
    .nonNullable("Please confirm statement"),
  careArrangementsForDependentRelatives: requiredStringWhen(
    "careForDependentRelativesNeedToBeArranged",
    "Please confirm care arrangements for your dependent relative(s)",
  ),

  otherCareNeedToBeArranged: boolean()
    .default(null)
    .nonNullable("Please confirm other care arrangements"),
  otherCareArrangements: requiredStringWhen(
    "otherCareNeedToBeArranged",
    "Please confirm other care arrangements",
  ),

  isThereAnyoneToInformOnAdmission: boolean()
    .default(null)
    .nonNullable("Please confirm if there anyone you want to be informed"),

  informedPerson1Name: requiredStringWhen(
    "isThereAnyoneToInformOnAdmission",
    "Please confirm name",
  ),
  informedPerson1Relationship: requiredStringWhen(
    "isThereAnyoneToInformOnAdmission",
    "Please confirm relationship to you",
  ),
  informedPerson1PhoneNumber: phoneNumber,
  informedPerson1EmailAddress: optionalEmail,
  informedPerson1Information: string().default(null).nullable(),

  secondPersonToInformOnAdmission: requiredBooleanWhen(
    "isThereAnyoneToInformOnAdmission",
    "Please confirm if you would like to add another person",
  ),

  informedPerson2Name: requiredStringWhen(
    "secondPersonToInformOnAdmission",
    "Please confirm name",
  ),
  informedPerson2Relationship: requiredStringWhen(
    "secondPersonToInformOnAdmission",
    "Please confirm relationship to you",
  ),
  informedPerson2PhoneNumber: phoneNumber,
  informedPerson2EmailAddress: optionalEmail,
  informedPerson2Information: string().default(null).nullable(),

  isThereAnyoneNotToInformOnAdmission: boolean()
    .default(null)
    .nonNullable("Please confirm who you don’t want to be informed"),

  notInformedPerson1Name: requiredStringWhen(
    "isThereAnyoneNotToInformOnAdmission",
    "Please confirm name",
  ),
  notInformedPerson1Relationship: requiredStringWhen(
    "isThereAnyoneNotToInformOnAdmission",
    "Please confirm relationship to you",
  ),

  secondPersonNotToInformOnAdmission: requiredBooleanWhen(
    "isThereAnyoneNotToInformOnAdmission",
    "Please confirm if you would like to add another person",
  ),

  notInformedPerson2Name: requiredStringWhen(
    "secondPersonNotToInformOnAdmission",
    "Please confirm name",
  ),
  notInformedPerson2Relationship: requiredStringWhen(
    "secondPersonNotToInformOnAdmission",
    "Please confirm relationship to you",
  ),

  // section 6 - Key Crisis Supporters
  preferredContact1Name: nonEmptyString.required("Please confirm name"),
  preferredContact1Relationship: nonEmptyString.required(
    "Please confirm relationship to you",
  ),
  preferredContact1RoleInCrisis: nonEmptyString.required(
    "Please confirm role in crisis",
  ),
  preferredContact1PhoneNumber: phoneNumber,
  preferredContact1EmailAddress: optionalEmail,
  preferredContact1Address: optionalAddress().required(
    "Please confirm address",
  ),
  preferredContact1NearestRelative: string()
    .default(null)
    .nullable()
    .test(
      "at-least-one-detention-reason-selected",
      "Please confirm if this preferred contact is your nearest relative ",
      (_, { parent }) => {
        const validValues = ["no", "unsure"];
        return (
          parent.preferredContact1NearestRelative === "yes" ||
          parent.preferredContact2NearestRelative === "yes" ||
          validValues.includes(parent.preferredContact1NearestRelative) ||
          validValues.includes(parent.preferredContact2NearestRelative)
        );
      },
    )
    .test(
      "at-least-one-detention-reason-selected",
      "Only one preferred contact can be selected as your nearest relative",
      (_, { parent }) => {
        return !(
          parent.preferredContact1NearestRelative === "yes" &&
          parent.preferredContact2NearestRelative === "yes"
        );
      },
    ),

  isReasonNearestRelative1NotContacted: requiredBooleanWhen(
    "preferredContact1NearestRelative",
    "Please confirm reason if there is a reason not to contact your nearest relative",
    "yes",
  ),

  reasonNearestRelative1NotContacted: requiredStringWhen(
    "isReasonNearestRelative1NotContacted",
    "Please confirm reason",
  ),

  doYouHaveASecondPreferredContact: boolean()
    .default(null)
    .nonNullable("Do you have a second preferred contact?"),

  preferredContact2Name: requiredStringWhen(
    "doYouHaveASecondPreferredContact",
    "Please confirm name",
  ),
  preferredContact2Relationship: requiredStringWhen(
    "doYouHaveASecondPreferredContact",
    "Please confirm relationship to you",
  ),
  preferredContact2RoleInCrisis: requiredStringWhen(
    "doYouHaveASecondPreferredContact",
    "Please confirm role in crisis",
  ),
  preferredContact2PhoneNumber: phoneNumber,
  preferredContact2EmailAddress: optionalEmail,
  preferredContact2Address: optionalAddress().when(
    "doYouHaveASecondPreferredContact",
    {
      is: true,

      then: () =>
        // @ts-ignore
        optionalAddress().required("Please enter address"),
      otherwise: () => optionalAddress().nullable(),
    },
  ),
  preferredContact2NearestRelative: string()
    .default(null)
    .nullable()
    .test(
      "at-least-one-detention-reason-selected",
      "Please confirm if this preferred contact is your nearest relative",
      (_, { parent }) => {
        const validValues = ["no", "unsure"];
        return (
          parent.preferredContact1NearestRelative === "yes" ||
          parent.preferredContact2NearestRelative === "yes" ||
          validValues.includes(parent.preferredContact1NearestRelative) ||
          validValues.includes(parent.preferredContact2NearestRelative)
        );
      },
    )
    .test(
      "at-least-one-detention-reason-selected",
      "Only one preferred contact can be selected as your nearest relative",
      (_, { parent }) => {
        return !(
          parent.preferredContact1NearestRelative === "yes" &&
          parent.preferredContact2NearestRelative === "yes"
        );
      },
    ),

  isReasonNearestRelative2NotContacted: requiredBooleanWhen(
    "preferredContact2NearestRelative",
    "Please confirm reason if there is a reason not to contact your nearest relative",
    "yes",
  ),

  reasonNearestRelative2NotContacted: requiredStringWhen(
    "isReasonNearestRelative2NotContacted",
    "Please confirm reason",
  ),

  // other crisis supporters
  doYouHaveACareCoordinator: boolean()
    .default(null)
    .nonNullable("Please confirm if you have a Care Co-ordinator contact"),

  careCoordinatorName: requiredStringWhen(
    "doYouHaveACareCoordinator",
    "Please confirm name",
  ),

  careCoordinatorOrganisation: requiredStringWhen(
    "doYouHaveACareCoordinator",
    "Please confirm organisation",
  ),

  careCoordinatorPhoneNumber: phoneNumber,
  careCoordinatorEmailAddress: optionalEmail,

  doYouHaveAConsultant: boolean()
    .default(null)
    .nonNullable("Please confirm if you have a Care Co-ordinator contact"),

  consultantName: requiredStringWhen(
    "doYouHaveAConsultant",
    "Please confirm name",
  ),

  consultantOrganisation: requiredStringWhen(
    "doYouHaveAConsultant",
    "Please confirm organisation",
  ),

  consultantPhoneNumber: phoneNumber,
  consultantEmailAddress: optionalEmail,

  doYouHaveAGeneralPractitioner: boolean()
    .default(null)
    .nonNullable("Please confirm if you have a Care Co-ordinator contact"),

  generalPractitionerName: requiredStringWhen(
    "doYouHaveAGeneralPractitioner",
    "Please confirm name",
  ),

  generalPractitionerOrganisation: requiredStringWhen(
    "doYouHaveAGeneralPractitioner",
    "Please confirm organisation",
  ),

  generalPractitionerPhoneNumber: phoneNumber,
  generalPractitionerEmailAddress: optionalEmail,

  doYouHaveAnyOtherKeyCrisisSupporterContacts: boolean()
    .default(null)
    .nonNullable("Please confirm if you have another contact"),

  keyCrisisOtherContact1Name: requiredStringWhen(
    "doYouHaveAnyOtherKeyCrisisSupporterContacts",
    "Please confirm name",
  ),

  keyCrisisOtherContact1RoleInCrisis: requiredStringWhen(
    "doYouHaveAnyOtherKeyCrisisSupporterContacts",
    "Please confirm role in crisis",
  ),

  keyCrisisOtherContact1Organisation: requiredStringWhen(
    "doYouHaveAnyOtherKeyCrisisSupporterContacts",
    "Please confirm organisation",
  ),

  keyCrisisOtherContact1PhoneNumber: phoneNumber,
  keyCrisisOtherContact1EmailAddress: optionalEmail,

  secondKeyCrisisOtherContact: requiredBooleanWhen(
    "doYouHaveAnyOtherKeyCrisisSupporterContacts",
    "Please confirm if you would like to add another person",
  ),

  keyCrisisOtherContact2Name: requiredStringWhen(
    "secondKeyCrisisOtherContact",
    "Please confirm name",
  ),

  keyCrisisOtherContact2RoleInCrisis: requiredStringWhen(
    "secondKeyCrisisOtherContact",
    "Please confirm role in crisis",
  ),

  keyCrisisOtherContact2Organisation: requiredStringWhen(
    "secondKeyCrisisOtherContact",
    "Please confirm organisation",
  ),

  keyCrisisOtherContact2PhoneNumber: phoneNumber,
  keyCrisisOtherContact2EmailAddress: optionalEmail,

  // section 7
  // How people can access this Advance Choice Document

  generalHospitalStaffAccessSelected: boolean().default(true),
  generalHospitalStaffAccess: requiredStringBasedOnCheckbox(
    "generalHospitalStaffAccessSelected",
    "Please confirm if general hospital staff can access",
  ),

  canFamilyAndFriendsAccess: boolean()
    .default(null)
    .nonNullable("Please confirm if family and friends can access"),

  canPreferredContact1Access: requiredBooleanWhen(
    "canFamilyAndFriendsAccess",
    "Please confirm if your preferred contact can access",
  ),

  howPreferredContact1CanAccess: requiredStringWhen(
    "canPreferredContact1Access",
    "Please confirm how your preferred contact can access",
  ),

  // Only make this required if there is a second preferred contact specified
  canPreferredContact2Access: boolean()
    .nullable()
    .default(null)
    .test(
      "at-least-one-detention-reason-selected",
      "Please confirm if your second preferred contact can access",
      (_, { parent }) => {
        return (
          !parent.canFamilyAndFriendsAccess ||
          !parent.doYouHaveASecondPreferredContact ||
          parent.canPreferredContact1Access !== undefined
        );
      },
    ),

  howPreferredContact2CanAccess: requiredStringWhen(
    "canPreferredContact2Access",
    "Please confirm how your preferred contact can access",
  ),

  otherFamilyAndFriend1CanAccess: requiredBooleanWhen(
    "canFamilyAndFriendsAccess",
    "Please confirm if you would like to add another person",
  ),

  otherFamilyAndFriend1Name: requiredStringWhen(
    "otherFamilyAndFriend1CanAccess",
    "Please confirm name",
  ),

  otherFamilyAndFriend1Relationship: requiredStringWhen(
    "otherFamilyAndFriend1CanAccess",
    "Please confirm relationship",
  ),

  otherFamilyAndFriend1RoleInCrisis: requiredStringWhen(
    "otherFamilyAndFriend1CanAccess",
    "Please confirm role in crisis",
  ),

  otherFamilyAndFriend1PhoneNumber: phoneNumber,
  otherFamilyAndFriend1EmailAddress: optionalEmail,
  otherFamilyAndFriend1Address: optionalAddress(),

  otherFamilyAndFriend1HowCanAccess: requiredStringWhen(
    "otherFamilyAndFriend1CanAccess",
    "Please confirm how they can access",
  ),

  otherFamilyAndFriend2CanAccess: requiredBooleanWhen(
    "otherFamilyAndFriend1CanAccess",
    "Please confirm if you would like to add another person",
  ),

  otherFamilyAndFriend2Name: requiredStringWhen(
    "otherFamilyAndFriend2CanAccess",
    "Please confirm name",
  ),

  otherFamilyAndFriend2Relationship: requiredStringWhen(
    "otherFamilyAndFriend2CanAccess",
    "Please confirm relationship",
  ),

  otherFamilyAndFriend2RoleInCrisis: requiredStringWhen(
    "otherFamilyAndFriend2CanAccess",
    "Please confirm role in crisis",
  ),

  otherFamilyAndFriend2PhoneNumber: phoneNumber,
  otherFamilyAndFriend2EmailAddress: optionalEmail,
  otherFamilyAndFriend2Address: optionalAddress(),

  otherFamilyAndFriend2HowCanAccess: requiredStringWhen(
    "otherFamilyAndFriend2CanAccess",
    "Please confirm how they can access",
  ),

  mentalHealthTrustStaffAccessSelected: boolean().default(true),
  mentalHealthTrustStaffAccess: requiredStringBasedOnCheckbox(
    "mentalHealthTrustStaffAccessSelected",
    "Please confirm if mental health trust staff can access",
  ),

  AMHPsAccessSelected: boolean().default(true),
  AMHPsAccess: requiredStringBasedOnCheckbox(
    "AMHPsAccessSelected",
    "Please confirm if Approved Mental Health Professionals (AMHPs) can access ",
  ),

  GPsAccessSelected: boolean().default(true),
  GPsAccess: requiredStringBasedOnCheckbox(
    "GPsAccessSelected",
    "Please confirm if GPs can access",
  ),

  canOtherPerson1Access: boolean()
    .default(null)
    .nonNullable("Please confirm if anyone else can access "),

  otherPersonWithAccess1Name: requiredStringWhen(
    "canOtherPerson1Access",
    "Please confirm name",
  ),

  otherPersonWithAccess1Role: requiredStringWhen(
    "canOtherPerson1Access",
    "Please confirm role",
  ),

  otherPersonWithAccess1HowCanAccess: requiredStringWhen(
    "canOtherPerson1Access",
    "Please confirm how",
  ),

  canOtherPerson2Access: requiredBooleanWhen(
    "canOtherPerson1Access",
    "Please confirm if anyone else can access ",
  ),

  otherPersonWithAccess2Name: requiredStringWhen(
    "canOtherPerson2Access",
    "Please confirm name",
  ),

  otherPersonWithAccess2Role: requiredStringWhen(
    "canOtherPerson2Access",
    "Please confirm role",
  ),

  otherPersonWithAccess2HowCanAccess: requiredStringWhen(
    "canOtherPerson2Access",
    "Please confirm how",
  ),
});
export type AcdPart1DataSchema = InferType<typeof acdPart1DataSchema>;
