export enum MhaStatus {
  Unknown = "Unknown",
  NotDetained = "Not Detained",
  Incomplete = "Incomplete",
  Expired = "Expired",
  Section2 = "Section 2",
  Section3 = "Section 3",
  Section4 = "Section 4",
  Section5_2 = "Section 5(2)",
  Section5_4 = "Section 5(4)",
  Section17A = "Section 17A",
}

export const DetainedStatuses = [
  MhaStatus.Section5_4,
  MhaStatus.Section5_2,
  MhaStatus.Section4,
  MhaStatus.Section2,
  MhaStatus.Section17A,
  MhaStatus.Section3,
  MhaStatus.NotDetained,
];

export enum DischargeReason {
  Section23 = "Section 23",
  HoldingPowersDischarge = "Holding Powers Discharge",
  SectionExpired = "Section Expired",
  TribunalOrNearestRelative = "Discharged by Tribunal/Nearest Relative",
  Other = "Other",
}

export type PatientStateHospitalDetails = {
  name: string;
  address: string;
  postalCode: string;
  odsCode?: string;
};

export type PatientStateTrustDetails = {
  name: string;
  odsCode: string;
};

export type MhaStatusState = {
  status: MhaStatus;
  initialStartDateTime?: string;
  startDateTime?: string;
  expiryDateTime?: string; // Calculated from startDateTime, status, and numberOfRenewals
  numberOfRenewals?: number;
};

export type PatientState = {
  episodeOfCare?: {
    startDateTime?: string;
    dischargeDateTime?: string;
    dischargeReason?: DischargeReason;
  };

  mhaStatus: MhaStatusState;

  hospital?: PatientStateHospitalDetails;

  trust?: PatientStateTrustDetails;
};
