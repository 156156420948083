import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A1UploadableData,
  A1UploadablePart1DataSchema,
  a1UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a1";
import {
  a1Part1DataSchema,
  A1Part1DataSchema,
} from "../schemas/mhaForms/admission/a1";

import { a1AndA2Part1Signing } from "./helpers/signingLogic";

export const a1Part1Template: FormPartTemplate<A1Part1DataSchema> = {
  signing: a1AndA2Part1Signing,
  formBuilderFields: [],
  dataSchema: a1Part1DataSchema,
};

export const a1UploadablePart1Template: FormPartTemplate<A1UploadablePart1DataSchema> =
  {
    signing: a1AndA2Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date",
        field: "lastSeenDate",
        label: "Date signee last saw the patient on",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Signature date",
        maximum: (today) => today,
      },
    ],
    dataSchema: a1UploadablePart1DataSchema,
  };

export const a1V1Base: UploadableBaseFormTemplate = {
  id: "mha-a1",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a1Part1Template],
  formName: "A1",
  section: "2",
  description: "Application by nearest relative for admission for assessment",
  linkableForms: [
    { templateId: "mha-a3", reason: "supported-by" },
    { templateId: "mha-a4", reason: "supported-by" },
  ],
  extractGenericData: (data: A1UploadableData) => ({
    admissions: {
      applicationHospitalName: data[0].data?.hospital.name,
      amhpLastSeenDate: data[0].data?.lastSeenDate,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [a1UploadablePart1Template],
};
