import {
  H2Data,
  H2Part1DataSchema,
  h2Part1DataSchema,
} from "../schemas/mhaForms/hospital/h2";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const h2Part1Template: FormPartTemplate<H2Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["nurse"],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.registeredNurse,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "readonly",
      label: "Patient name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) It appears to me that this patient, who is receiving treatment for mental disorder as an in-patient of this hospital, is suffering from mental disorder to such a degree that it is necessary for the patient's health or safety or for the protection of others for this patient to be immediately restrained from leaving the hospital.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(b) And it appears to me that it is not practicable to secure the immediate attendance of a registered medical practitioner or an approved clinician (who is not a registered medical practitioner) for the purpose of furnishing a report under section 5(2) of the Mental Health Act 1983.",
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
    {
      type: "branch",
      field: "registeredNurseIn",
      label: "I am a nurse registered in",
      branches: [
        {
          fieldValue: "sub-part1-mental-health-nursing",
          fieldsOwned: [],
          fields: [],
          label:
            "Sub-Part 1 of the register, whose entry includes an entry to indicate the nurse's field of practice is mental health nursing",
        },
        {
          fieldValue: "sub-part-2-mental-health-nursing",
          fieldsOwned: [],
          fields: [],
          label:
            "Sub-Part 2 of the register, whose entry includes an entry to indicate the nurse's field of practice is mental health nursing",
        },
        {
          fieldValue: "sub-part-1-disabilities-nursing",
          fieldsOwned: [],
          fields: [],
          label:
            "Sub-Part 1 of the register, whose entry includes an entry to indicate the nurse's field of practice is learning disabilities nursing",
        },
        {
          fieldValue: "sub-part-2-disabilities-nursing",
          fieldsOwned: [],
          fields: [],
          label:
            "Sub-Part 2 of the register, whose entry includes an entry to indicate the nurse's field of practice is learning disabilities nursing",
        },
      ],
    },
  ],
  dataSchema: h2Part1DataSchema,
};

export const h2V1Base: BaseFormTemplate = {
  id: "mha-h2",
  category: "hospital",
  version: "1.0",
  status: "active",
  formName: "H2",
  section: "5(4)",
  parts: [h2Part1Template],
  description: "Record of hospital in-patient",
  extractGenericData: (data: H2Data) => ({
    admissions: {
      admissionHospitalName: data[0]?.data.hospital.name,
      admissionHospitalAddress: data[0]?.data.hospital.address,
      admissionsHospitalPostcode: data[0]?.data.hospital.postalCode,
    },
  }),
};
