import {
  A8Data,
  a8Part1DataSchema,
  A8Part1DataSchema,
} from "../schemas/mhaForms/admission/a8";
import { validateSection2ExaminationDate } from "./section2";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A8UploadableData,
  a8UploadablePart1DataSchema,
  A8UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a8";

const a8Part1Signing: FormPartTemplate<A8Part1DataSchema>["signing"] = {
  userOrTeamRequirement: { accreditation: ["doctor"], teamType: [] },
};

export const a8Part1Template: FormPartTemplate<A8Part1DataSchema> = {
  signing: a8Part1Signing,
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I, a registered medical practitioner, recommend that the patient be admitted to a hospital for treatment in accordance with Part 2 of the Mental Health Act 1983.",
      ],
    },
    {
      type: "date",
      label: "Enter the date you last examined this patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
      warningFn: validateSection2ExaminationDate,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
    {
      type: "label",
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital.",
      ],
      showStaticLabel: true,
    },
    {
      type: "multi-select",
      label:
        "(b) In my opinion it is necessary that this patient should receive treatment in hospital for ",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },
    {
      type: "label",
      label: [
        "(c) In my opinion, such treatment cannot be provided unless the patient is detained under section 3 of the Act.",
      ],
      showStaticLabel: true,
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "Because: (Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate; indicate why informal admission is not appropriate.).",
      rows: "10",
    },

    {
      type: "label",
      label: [
        "I am also of the opinion that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all the other circumstances of the case, appropriate medical treatment is available to the patient at the following hospital (or one of the following hospitals).",
      ],
      showStaticLabel: true,
    },

    {
      type: "textbox",
      field: "nameOfHospitals",
      label:
        "Enter name of hospital(s). If appropriate treatment is available only in a particular part of the hospital, say which part.",
      rows: "5",
    },
  ],
  dataSchema: a8Part1DataSchema,
};

export const a8UploadablePart1Template: FormPartTemplate<A8UploadablePart1DataSchema> =
  {
    signing: a8Part1Signing,
    formBuilderFields: [
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date",
        label: "Date signee last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label: "Did signee have previous acquaintance with the patient? ",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is signee approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "multi-select",
        label: "Confirm detention criteria",
        options: [
          {
            field: "necessaryForPatientsOwnHealth",
            label: "for the patient’s own health",
          },
          {
            field: "necessaryForPatientsOwnSafety",
            label: "for the patient’s own safety",
          },
          {
            field: "necessaryForProtectionOfOtherPersons",
            label: "for the protection of other persons",
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Date of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a8UploadablePart1DataSchema,
  };

export const a8V1Base: UploadableBaseFormTemplate = {
  id: "mha-a8",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a8Part1Template],
  formName: "A8",
  section: "3",
  description: "Medical Recommendation for Admission for Treatment",
  extractGenericData: (data: A8Data | A8UploadableData) => ({
    admissions: {
      section12ApprovedStatus: [data[0].data?.user?.isSection12Approved!],
      lastExaminedDate: [data[0].data?.lastExaminedDate],
      examinerPreviousAcquaintance: data[0].data?.previousAcquaintance,
      necessaryForPatientsOwnHealth:
        data[0].data?.necessaryForPatientsOwnHealth,
      necessaryForPatientsOwnSafety:
        data[0].data?.necessaryForPatientsOwnSafety,
      necessaryForProtectionOfOtherPersons:
        data[0].data?.necessaryForProtectionOfOtherPersons,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [a8UploadablePart1Template],
};
