import {
  S23Data,
  S23Part1DataSchema,
  s23Part1DataSchema,
} from "../../schemas/mhaForms/discharge/s23";
import { RequirementFriendlyDescription } from "../../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../../types/form";

export const s23Part1Template: FormPartTemplate<S23Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "readonly",
      label: "Name and address of patient discharge is made in respect of ",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
    {
      type: "branch",
      field: "orderForDischarge",
      branches: [
        {
          fieldValue: true,
          hideLabel: true,
          label: "orderForDischarge",
          fields: [
            {
              type: "dropdown",
              label: "Who has made the order for discharge?",
              valueField: "updateOrderForDischarge",
              textField: "updateOrderForDischargeText",
              options: [
                {
                  value: "responsibleClinician",
                  label: "The responsible clinician",
                },
                {
                  value: "nearestRelative",
                  label: "The nearest relative of the patient",
                },
                {
                  value: "hospitalManager",
                  label: "The Hospital Manager (s2, s3, s4, s17A)",
                },
                {
                  value: "responsibleLocalSocialServiceAuthority",
                  label:
                    "The Responsible local social service authority (s7 only)",
                },
              ],
              fieldsOwned: ["orderInWriting", "updateNearestRelativeAddress"],
            },
          ],
          fieldsOwned: [],
        },
      ],
    },

    {
      type: "branch",
      field: "updateOrderForDischarge",
      branches: [
        {
          hideLabel: true,
          fieldValue: "nearestRelative",
          label: "nearestRelative",
          fields: [
            {
              type: "name-address",
              field: "updateNearestRelativeAddress",
              disableEmail: true,
              nameLabel:
                "Enter name of the nearest relative who is ordering the discharge",
              addressLabel:
                "Enter address of the nearest relative who is ordering the discharge",
            },
            {
              type: "branch",
              label: "Has this order been made in writing?",
              field: "orderInWriting",
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fields: [],
                  fieldsOwned: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
          ],
          fieldsOwned: [],
        },
      ],
    },

    {
      type: "branch",
      field: "stateSectionPatient",
      branches: [
        {
          fieldValue: true,
          hideLabel: true,
          label: "stateSectionPatient",
          fields: [
            {
              type: "dropdown",
              label: "State section patient is subject to",
              valueField: "updateStateSectionPatient",
              textField: "updateStateSectionPatientText",
              options: [
                {
                  value: "admissionForAssessment",
                  label: "Section 2: Admission for assessment",
                },
                {
                  value: "admissionForTreatment",
                  label: "Section 3: Admission for treatment",
                },
                {
                  value: "casesOfEmergency",
                  label:
                    "Section 4: Admission for assessment in cases of emergency",
                },
                {
                  value: "applicationForGuardianship",
                  label: "Section 7: Application for guardianship",
                },
                {
                  value: "communityTreatmentOrders",
                  label: "Section 17A: Community treatment orders",
                },
                {
                  value: "powerOfCourts",
                  label:
                    "Section 37: Powers of courts to order hospital admission or guardianship",
                },
              ],
              fieldsOwned: [
                "dischargeFromDetention",
                "hospital",
                "remainingInHospitalInformalPatient",
                "nameOfLocalAuthorityActingOnBehalfOf",
              ],
            },
          ],
          fieldsOwned: [],
        },
      ],
    },

    {
      type: "branch",
      field: "updateStateSectionPatient",
      branches: [
        {
          hideLabel: true,
          fieldValue: "powerOfCourts",
          label: "powerOfCourts",
          fields: [
            {
              type: "hospital-picker",
              field: "hospital",
              label:
                "Enter name and address of the hospital in which the patient is detained",
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },
            {
              type: "branch",
              label:
                "Will the patient be remaining in hospital as an informal patient?",
              field: "remainingInHospitalInformalPatient",
              warningBanner: {
                condition: (data, context) => {
                  return !!data.remainingInHospitalInformalPatient;
                },
                message:
                  "If the patient is remaining in hospital as an informal patient, you should assess whether the patient has capacity to consent to care and treatment, and where the patient is assessed as lacking capacity, assess if there is a deprivation of liberty. Speak to the the mental health law team if you are unsure.",
              },
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fields: [],
                  fieldsOwned: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from detention",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },

        {
          hideLabel: true,
          fieldValue: "admissionForAssessment",
          label: "admissionForAssessment",
          fields: [
            {
              type: "hospital-picker",
              field: "hospital",
              label:
                "Enter name and address of the hospital in which the patient is detained",
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },
            {
              type: "branch",
              label:
                "Will the patient be remaining in hospital as an informal patient?",
              field: "remainingInHospitalInformalPatient",
              warningBanner: {
                condition: (data, context) => {
                  return !!data.remainingInHospitalInformalPatient;
                },
                message:
                  "If the patient is remaining in hospital as an informal patient, you should assess whether the patient has capacity to consent to care and treatment, and where the patient is assessed as lacking capacity, assess if there is a deprivation of liberty. Speak to the the mental health law team if you are unsure.",
              },
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fields: [],
                  fieldsOwned: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from detention",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },

        {
          hideLabel: true,
          fieldValue: "admissionForTreatment",
          label: "admissionForTreatment",
          fields: [
            {
              type: "hospital-picker",
              field: "hospital",
              label:
                "Enter name and address of the hospital in which the patient is detained",
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },
            {
              type: "branch",
              label:
                "Will the patient be remaining in hospital as an informal patient?",
              field: "remainingInHospitalInformalPatient",
              warningBanner: {
                condition: (data, context) => {
                  return !!data.remainingInHospitalInformalPatient;
                },
                message:
                  "If the patient is remaining in hospital as an informal patient, you should assess whether the patient has capacity to consent to care and treatment, and where the patient is assessed as lacking capacity, assess if there is a deprivation of liberty. Speak to the the mental health law team if you are unsure.",
              },
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fields: [],
                  fieldsOwned: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from detention",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },
        {
          hideLabel: true,
          fieldValue: "casesOfEmergency",
          label: "casesOfEmergency",
          fields: [
            {
              type: "hospital-picker",
              field: "hospital",
              label:
                "Enter name and address of the hospital in which the patient is detained",
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },
            {
              type: "branch",
              label:
                "Will the patient be remaining in hospital as an informal patient?",
              field: "remainingInHospitalInformalPatient",
              warningBanner: {
                condition: (data, context) => {
                  return !!data.remainingInHospitalInformalPatient;
                },
                message:
                  "If the patient is remaining in hospital as an informal patient, you should assess whether the patient has capacity to consent to care and treatment, and where the patient is assessed as lacking capacity, assess if there is a deprivation of liberty. Speak to the the mental health law team if you are unsure.",
              },
              branches: [
                {
                  fieldValue: true,
                  label: "Yes",
                  fields: [],
                  fieldsOwned: [],
                },
                {
                  fieldValue: false,
                  label: "No",
                  fields: [],
                  fieldsOwned: [],
                },
              ],
            },
            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from detention",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },

        {
          hideLabel: true,
          fieldValue: "applicationForGuardianship",
          label: "applicationForGuardianship",
          fields: [
            {
              type: "local-authority-picker",
              label:
                "Enter name of the responsible local social service authority",
              field: "nameOfLocalAuthorityActingOnBehalfOf",
            },

            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },

            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from Section 7",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },
        {
          hideLabel: true,
          fieldValue: "communityTreatmentOrders",
          label: "communityTreatmentOrders",
          fields: [
            {
              type: "hospital-picker",
              field: "hospital",
              label: "Enter name and address of the responsible hospital",
            },

            {
              type: "label",
              showStaticLabel: true,
              label: [
                "The patient no longer meets the conditions of being detained under the Mental Health Act",
              ],
            },

            {
              type: "date-time",
              field: "dischargeFromDetention",
              label: "Enter date and time of discharge from Section 17A",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: [],
        },
      ],
    },
  ],
  dataSchema: s23Part1DataSchema,
};

export const s23V1Base: BaseFormTemplate = {
  id: "mha-s23",
  category: "discharge",
  version: "1.0",
  status: "active",
  formName: "Discharge of patients",
  section: "23",
  parts: [s23Part1Template],
  description: "",
  extractGenericData: (data: S23Data) => {
    const section = data[0]?.data?.updateStateSectionPatient;
    const hospital = data[0].data?.hospital;

    const hospitalDetained =
      [
        "admissionForAssessment",
        "admissionForTreatment",
        "casesOfEmergency",
      ].includes(section!) && "name" in hospital
        ? hospital
        : undefined;
    const hospitalResponsible =
      section === "communityTreatmentOrders" && "name" in hospital
        ? hospital
        : undefined;

    const standalone = {
      dischargeFromDetention: data[0].data.dischargeFromDetention,
      stateSectionPatient: data[0].data.updateStateSectionPatientText,
      hospitalDetained: hospitalDetained
        ? `${hospitalDetained.name} ${hospitalDetained.address} ${hospitalDetained.postalCode}`
        : undefined,
      hospitalResponsible: hospitalResponsible
        ? `${hospitalResponsible.name} ${hospitalResponsible.address} ${hospitalResponsible.postalCode}`
        : undefined,
      localSocialServiceAuthority:
        data[0].data.nameOfLocalAuthorityActingOnBehalfOf || "",
    };

    return { standalone };
  },
};
