import {
  errorValidationOutcome,
  Form,
  FormPartTemplate,
  getLatestVersion,
  warningValidationOutcome,
} from "../../types/form";
import { checkMedRecValidity, MedRecFormPart } from "./medRecValidityLogic";

import { A1UploadablePart1DataSchema } from "../../schemas/mhaForms/admission/uploadable/a1";
import { A5UploadablePart1DataSchema } from "../../schemas/mhaForms/admission/uploadable/a5";
import { A10Part1DataSchema } from "../../schemas/mhaForms/admission/a10";
import dayjs from "dayjs";

// Simple check to see if we have a manual upload form
export const findManualFormUpload = (
  forms: Pick<
    Form<MedRecFormPart[]>,
    "formTemplate" | "id" | "versions" | "latestVersion" | "source"
  >[],
) => {
  return forms
    .flatMap((form) => form || [])
    .some((f) => f.source === "manual-upload");
};

const warningUploadedMessage = warningValidationOutcome(
  "You are about to select a form that has been uploaded. Please make sure the medical recommendations are made by two different doctors.",
  true,
);

const validateMedRecValidity = (
  forms: Pick<
    Form<MedRecFormPart[]>,
    "formTemplate" | "id" | "versions" | "latestVersion" | "source"
  >[],
  validForms: Array<Array<string>>,
  requirementsDescription: string,
) => {
  const hasManualUpload = findManualFormUpload(forms);

  const medRecValidityCheck = checkMedRecValidity(forms, {
    validFormsCombinations: validForms,
    requirementsDescription,
    requireAtLeastOneSection12: true,
    hasManualUpload,
  });

  return hasManualUpload
    ? [warningUploadedMessage, medRecValidityCheck]
    : [medRecValidityCheck];
};

export const a1AndA2Part1Signing: FormPartTemplate<A1UploadablePart1DataSchema>["signing"] =
  {
    userOrTeamRequirement: { accreditation: ["amhp"], teamType: [] },
    linkableForms: {
      formTemplateIds: ["mha-a4", "mha-a3"],
      source: "patient-forms",
      isBypassable: true,
      isMultiSelect: true,
      confirmAfterCompletion: false,
      checkValidityFn: (forms) => {
        return validateMedRecValidity(
          forms,
          [["mha-a3"], ["mha-a4", "mha-a4"]],
          "You must either provide exactly one Joint Medical Recommendation (A3) or two individual Medical Recommendation forms (A4)",
        );
      },
    },
  };

export const a5And6Part1Signing: FormPartTemplate<A5UploadablePart1DataSchema>["signing"] =
  {
    userOrTeamRequirement: { accreditation: ["amhp"], teamType: [] },

    linkableForms: {
      formTemplateIds: ["mha-a7", "mha-a8"],
      source: "patient-forms",
      isBypassable: true,
      isMultiSelect: true,
      confirmAfterCompletion: false,
      checkValidityFn: (forms) => {
        return validateMedRecValidity(
          forms,
          [["mha-a7"], ["mha-a8", "mha-a8"]],
          "You must either provide exactly one Joint Medical Recommendation (A7) or two individual Medical Recommendation forms (A8)",
        );
      },
    },
  };

export const a9And10Part1Signing: FormPartTemplate<A10Part1DataSchema>["signing"] =
  {
    userOrTeamRequirement: { accreditation: ["amhp"], teamType: [] },
    linkableForms: {
      formTemplateIds: ["mha-a11"],
      source: "patient-forms",
      isBypassable: true,
      isMultiSelect: false,
      confirmAfterCompletion: true,
      checkValidityFn: (forms) => {
        if (forms.length !== 1 || forms[0].formTemplate.id !== "mha-a11") {
          return [
            warningValidationOutcome(
              "You must provide exactly one Emergency Medical Recommendation (A11).",
            ),
          ];
        }

        const lastExaminedDate = getLatestVersion(forms[0])?.data[0].data
          .examinedTimeAndDate;
        if (
          !lastExaminedDate ||
          dayjs(lastExaminedDate).isBefore(dayjs().subtract(24, "hour"))
        ) {
          return [
            errorValidationOutcome(
              "The Doctor must have examined the patient with the last 24 hours.",
            ),
          ];
        }

        return [
          {
            type: "success",
            reason: "The time of the examination was within the last 24 hours.",
          },
        ];
      },
    },
  };
