import React, { useCallback } from "react";
import { ExternalPatientLinkEnhanced } from "@aspire/common/types/externalPatientLinkEnhanced";

import { useSyncExternalPatientLink } from "./common/syncExternalPatientLink";
import { useUploadFormPdfViaExternalPatientLinks } from "./common/uploadFormPdfViaExternalPatientLink";
import { FormContextData } from "@aspire/common/types/formContextData";

export function useExternalPatientLink(
  externalPatientLink: ExternalPatientLinkEnhanced | undefined,
) {
  const {
    Component: SyncComponent,
    componentProps: syncProps,
    syncExternalPatientLink,
  } = useSyncExternalPatientLink(externalPatientLink);

  const {
    Component: UploadComponent,
    componentProps: uploadProps,
    uploadFormPdfViaExternalPatientLink,
  } = useUploadFormPdfViaExternalPatientLinks();

  const scopedUploadFormPdfViaExternalPatientLink = useCallback(
    (formId: string, formContext: FormContextData) => {
      if (externalPatientLink) {
        return uploadFormPdfViaExternalPatientLink(
          externalPatientLink,
          formId,
          formContext,
        );
      }
    },
    [externalPatientLink, uploadFormPdfViaExternalPatientLink],
  );

  // Compose the components we need into a singular
  // thing to allow the hooks to display their own ui
  const Component = () => (
    <>
      <SyncComponent {...syncProps} />
      <UploadComponent {...uploadProps} />
    </>
  );

  return {
    syncExternalPatientLink,
    uploadFormPdfViaExternalPatientLink:
      scopedUploadFormPdfViaExternalPatientLink,
    Component,
  };
}
