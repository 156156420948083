import React, { useState } from "react";
import {
  Add,
  ArrowBack,
  ArrowForward,
  GetApp,
  Close,
  InsertDriveFileOutlined,
  CreateOutlined,
  Delete,
  Print,
} from "@mui/icons-material";
import whiteFilter from "../../../assets/img/whiteFilter.svg";
import blackFilter from "../../../assets/img/blackFilter.svg";
import {
  ButtonProps,
  Icon,
  Button as MUIButton,
  Theme,
  useTheme,
  Hidden,
} from "@mui/material";
import { css } from "@emotion/react";

export interface ThalamosButtonProps {
  label: ButtonProps["aria-label"];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: ButtonProps["disabled"];
  startIcon?: ButtonIcon;
  endIcon?: ButtonIcon;

  // Color props
  color?: ButtonProps["color"];
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  error?: boolean;
  warning?: boolean;

  // Variant props
  variant?: ButtonProps["variant"];
  outlined?: boolean;
  contained?: boolean;

  // Size props
  size?: ButtonSize;
  small?: boolean;
  medium?: boolean;
  large?: boolean;

  className?: string;
  labelClassName?: string;
  fullWidth?: ButtonProps["fullWidth"];
  sx?: any;
  hideLabel?: boolean;
  customFontSize?: number;
  testId?: string;
  type?: "button" | "submit" | "reset";
}

const defaultProps: Partial<ThalamosButtonProps> = {
  disabled: false,
  fullWidth: false,
  hideLabel: false,
  type: "button",
};

export enum ButtonIcon {
  arrow = "arrow",
  add = "add",
  filter = "filter",
  ie = "ie",
  download = "download",
  delete = "delete",
  downloadSecondary = "downloadSecondary",
  arrowBack = "arrow-back",
  close = "close",
  create = "create",
  print = "print",
}

export type ButtonSize = "small" | "medium" | "large";

const getIcon = (
  icon: ButtonIcon,
  variant: ButtonProps["variant"],
  theme: Theme,
  buttonColor: ButtonProps["color"],
) => {
  const themeColor =
    (theme.palette[buttonColor as keyof typeof theme.palette] as any)?.main ??
    theme.palette.primary.main;
  const iconColor = variant === "contained" ? "white" : themeColor;
  switch (icon) {
    case ButtonIcon.arrow:
      return <ArrowForward htmlColor={iconColor} />;
    case ButtonIcon.add:
      return <Add htmlColor={iconColor} />;
    case ButtonIcon.filter:
      return (
        <Icon
          css={css`
            line-height: 1px;
          `}
        >
          <img
            alt="filter"
            src={variant === "outlined" ? blackFilter : whiteFilter}
          />
        </Icon>
      );
    case ButtonIcon.download:
      return <GetApp htmlColor={iconColor} />;
    case ButtonIcon.delete:
      return <Delete htmlColor={iconColor} />;
    case ButtonIcon.downloadSecondary:
      return <InsertDriveFileOutlined htmlColor={iconColor} />;
    case ButtonIcon.arrowBack:
      return <ArrowBack htmlColor={iconColor} />;
    case ButtonIcon.close:
      return <Close htmlColor={iconColor} />;
    case ButtonIcon.create:
      return <CreateOutlined htmlColor={iconColor} />;
    case ButtonIcon.print:
      return <Print htmlColor={iconColor} />;
  }
};

function getSizes(
  size?: ButtonSize,
  small?: boolean,
  medium?: boolean,
  large?: boolean,
): {
  fontSize: string;
  verticalAlign: string;
  height: string;
  paddingX: string;
} {
  let actualSize = size ?? (small && "small") ?? (large && "large") ?? "medium";
  switch (actualSize) {
    case "small":
      return {
        fontSize: "13px",
        verticalAlign: "0",
        height: "30px",
        paddingX: "16px",
      };
    case "large":
      return {
        fontSize: "17px",
        verticalAlign: "2px",
        height: "40px",
        paddingX: "16px",
      };
    case "medium":
    default:
      return {
        fontSize: "15px",
        verticalAlign: "1px",
        height: "34px",
        paddingX: "16px",
      };
  }
}

export const Button = (props: ThalamosButtonProps) => {
  const allProps: ThalamosButtonProps = { ...defaultProps, ...props };
  const {
    label,
    onClick,
    disabled,
    startIcon,
    endIcon,
    className,
    labelClassName,
    fullWidth,
    sx,
    hideLabel,
    customFontSize,
    type,
    testId,
  } = allProps;

  const sizes = getSizes(
    allProps.size,
    allProps.small,
    allProps.medium,
    allProps.large,
  );
  const variant =
    props.variant || (props.outlined && "outlined") || "contained";
  const color =
    props.color ||
    (props.secondary && "secondary") ||
    (props.success && "success") ||
    (props.warning && "warning") ||
    (props.error && "error") ||
    "primary";

  const theme = useTheme();
  const [buttonAlreadyClicked, setButtonAlreadyClicked] = useState(false);

  // TODO: this behaviour as default seems really dumb but was ported from Thalamos Asess
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setButtonAlreadyClicked(true);
    return new Promise((resolve) => resolve(onClick && onClick(event)))
      .then((result) => {
        setButtonAlreadyClicked(false);
        return result;
      })
      .finally(() => {
        setButtonAlreadyClicked(false);
      });
  };

  return (
    <MUIButton
      type={type}
      sx={sx}
      fullWidth={fullWidth}
      disableRipple
      css={css`
        padding-left: ${sizes.paddingX};
        padding-right: ${sizes.paddingX};
        height: ${sizes.height};
        align-items: center;
        justify-content: center;
      `}
      color={color}
      onClick={handleClick}
      variant={variant}
      disabled={disabled || buttonAlreadyClicked}
      aria-label={label}
      data-testid={testId}
      className={className}
      {...(startIcon && {
        startIcon: getIcon(startIcon, variant, theme, color),
      })}
      {...(endIcon && { endIcon: getIcon(endIcon, variant, theme, color) })}
    >
      <span
        css={css`
          font-size: ${customFontSize ?? sizes.fontSize};
          line-height: 1;
          position: relative;
          top: ${sizes.verticalAlign};
          letter-spacing: 0.4px;
        `}
        className={labelClassName}
      >
        {hideLabel ? <Hidden mdDown>{label}</Hidden> : label}
      </span>
    </MUIButton>
  );
};
