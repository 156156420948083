import { ExpiryPeriod, getFormExpiryPeriods } from "@aspire/common/config";

/*
 The main place to import all configuration from env vars. Having
 this in one place makes it easier to mock out in tests (they don't like
 import.meta.env calls)
 */
export type AspireConfig = {
  backendUrl: string;
  googleMapsApiKey: string;
  datadog: {
    applicationId: string;
    clientToken: string;
    disableTracing: boolean;
  };
  environment: string;
  enableTestIdVizualizer: boolean;
  formExpiryPeriods: {
    h3Section4ExpiryPeriod: ExpiryPeriod;
    s15AmendSection4Expiry: ExpiryPeriod;
    s15AmendSection2And3Expiry: ExpiryPeriod;
  };
  version?: string;
  pusher: {
    cluster: string;
    clientKey: string;
    channelPrefix?: string;
  };
  auth0: {
    domain: string;
    clientId: string;
  };
};

export const isDevEnv = ["local", "dev", "docker", "staging"].includes(
  import.meta.env.VITE_ENVIRONMENT,
);

// If the environment is production OR a specific environment variable is set to true
// then use the 'real' expiry, otherwise use shorter ones for testing
const useShortExpiryPeriods =
  isDevEnv && import.meta.env.VITE_USE_REAL_EXPIRY_TIMES !== "true";

export const config: AspireConfig = {
  backendUrl: import.meta.env.VITE_BACKEND_URL,
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
  datadog: {
    applicationId:
      import.meta.env.VITE_DATADOG_APPLICATION_ID ||
      "904d62ba-ddc8-4a57-89b0-a84af778170c",
    clientToken:
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN ||
      "pub73b2b14a5677cc99dab8573e7d9a591d",
    disableTracing: import.meta.env.VITE_DATADOG_DISABLE_TRACING === "true",
  },
  environment: import.meta.env.VITE_ENVIRONMENT,
  enableTestIdVizualizer: isDevEnv,
  version: import.meta.env.VITE_CODE_VERSION,
  formExpiryPeriods: getFormExpiryPeriods(useShortExpiryPeriods),
  pusher: {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER || "eu",
    clientKey: import.meta.env.VITE_PUSHER_CLIENT_KEY || "f8d11e33083bd40bc95b",
    channelPrefix:
      (import.meta.env.VITE_PUSHER_CHANNEL_PREFIX?.length ?? 0) === 0
        ? undefined
        : import.meta.env.VITE_PUSHER_CHANNEL_PREFIX,
  },
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN || "thalamos-dev.uk.auth0.com",
    clientId:
      import.meta.env.VITE_AUTH0_CLIENT_ID ||
      "PQelCK06wzgkNKHa6vyNuB31oUkSEiAZ",
  },
};
