import { Box, CircularProgress, DialogContent } from "@mui/material";
import { entries } from "./Entries";
import {
  AuditTrailEntry,
  AuditTrailEntryType,
} from "@aspire/common/types/audit";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { apiHooks } from "../../../../api";
import { PopupDialog, PopupDialogTitle } from "~/components/design-system";
import dayjs from "dayjs";
import {
  DischargeReason,
  MhaStatus,
} from "@aspire/common/types/patientState/PatientState";
import { cto5V1Base } from "@aspire/common/formBuilderTemplates/cto5";
import { formatMhaStatus } from "~/components/util";
import { s23V1Base } from "@aspire/common/formBuilderTemplates/discharge/s23";

export function generateMergeMessage(audit: AuditTrailEntry) {
  return `${audit?.data?.mergedPatient || "unknown"} was merged into ${
    audit?.data?.destinationPatient || "unknown"
  }. ${audit?.data?.reason ? `(Reason: ${audit.data.reason})` : ""}
  ${
    audit?.data?.reasonDescription
      ? `(reason description -  ${audit.data.reasonDescription})`
      : ""
  }`;
}

export function generateUnmergeMessage(audit: AuditTrailEntry) {
  return `${audit?.data?.destinationPatient || "unknown"} was unmerged from ${
    audit?.data?.mergedPatient || "unknown"
  }. ${audit?.data?.reason ? `(Reason: ${audit.data.reason})` : ""}
  ${
    audit?.data?.reasonDescription
      ? `(Reason description:  ${audit.data.reasonDescription})`
      : ""
  }`;
}

export function generateUpdatePatientMessage(audit: AuditTrailEntry) {
  const patientFieldChanges = audit?.data?.patientFieldChanges;
  const updatedFields = patientFieldChanges
    ? `Updated: patient ${Object.values(patientFieldChanges).join(
        ", ",
      )} has been updated`
    : "";

  return updatedFields;
}

export function generateUpdatePatientStateWithManualEventMessage(
  audit: AuditTrailEntry,
) {
  if (audit?.data?.status === MhaStatus.NotDetained) {
    let dischargeReason = audit?.data?.dischargeReason;
    if (dischargeReason === DischargeReason.Section23) {
      dischargeReason = `discharged ${audit.data.dischargeReason}`;
    }
    if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
      dischargeReason = `discharged from holding powers`;
    }
    if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
      dischargeReason = `discharged by Tribunal.`;
    }
    return `MHA Status updated to ${
      audit?.data?.status
    }, Reason: ${dischargeReason ?? "Unknown"}`;
  }

  return `MHA Status manually input as ${formatMhaStatus(audit.data.status) ?? "Unknown"} ${audit.data.startDateTime ? `, with a start date time of ${dayjs(audit.data.startDateTime).format("DD/MM/YYYY HH:mm")}` : ""}`;
}

export function generateAutomaticallyExpiredPatientStateMessage(
  audit: AuditTrailEntry,
) {
  return `This patient's MHA status ${audit.data.status ?? "Unknown"} expired at ${dayjs(audit.data.expiryDateTime).format("DD/MM/YYYY HH:mm")}`;
}

export function generateAutomaticallyPatientStateMessage(
  audit: AuditTrailEntry,
) {
  const formatStatus = formatMhaStatus(audit.data.status);
  if (audit.data.status === MhaStatus.Unknown) return null;

  if (audit.data.status === MhaStatus.Incomplete)
    return `MHA Status ${formatStatus ?? "Unknown"}, data is missing.`;

  if (audit.data.status === MhaStatus.Section3) {
    if (audit.data.formTemplateId === cto5V1Base.id) {
      return `MHA Status updated to ${formatStatus ?? "Unknown"}, revocation of community treatment (CTO5) signed.`;
    }
  }

  if (
    audit.data.status === MhaStatus.Section4 ||
    audit.data.status === MhaStatus.Section2 ||
    audit.data.status === MhaStatus.Section3
  ) {
    return `MHA Status updated to ${formatStatus ?? "Unknown"}, ${audit.data.status ?? "Unknown"} record of detention form (H3) signed.`;
  }

  if (audit.data.status === MhaStatus.Section17A)
    return `MHA Status updated to ${formatStatus ?? "Unknown"}, Community Treatment Order form (CTO1) signed.`;

  if (audit.data.status === MhaStatus.NotDetained) {
    if (audit.data.formTemplateId === s23V1Base.id) {
      return `MHA Status updated to ${formatStatus ?? "Unknown"}, Section 23 form signed.`;
    }
    return `MHA Status updated to ${formatStatus ?? "Unknown"}, discharge from holding powers form signed.`;
  }

  return `MHA Status updated to ${formatStatus ?? "Unknown"}, ${formatStatus ?? "Unknown"} form signed.`;
}

export function generateUpdatePatientDemographicsPullEvent(
  audit: AuditTrailEntry,
) {
  if (audit.data.processed === true) {
    return null;
  } else {
    return "Patient details synchronised with Rio. Available updates were ignored/skipped.";
  }
}

function getMessageFn(typesToExclude: AuditTrailEntryType[]) {
  return (audit: AuditTrailEntry): string | string[] | null => {
    if (typesToExclude.includes(audit.type)) {
      return null;
    }

    switch (audit.type) {
      case AuditTrailEntryType.createPatient:
        return `Patient ${audit?.resources[0]?.resourceName} was created`;

      case AuditTrailEntryType.mergePatient:
        return generateMergeMessage(audit);

      case AuditTrailEntryType.unmergePatient:
        return generateUnmergeMessage(audit);

      case AuditTrailEntryType.updatePatient:
        return generateUpdatePatientMessage(audit);

      case AuditTrailEntryType.updatePatientStateWithManualEvent:
        return generateUpdatePatientStateWithManualEventMessage(audit);

      case AuditTrailEntryType.automaticallyExpirePatientState:
        return generateAutomaticallyExpiredPatientStateMessage(audit);

      case AuditTrailEntryType.updatePatientStateAutomatically:
        return generateAutomaticallyPatientStateMessage(audit);

      case AuditTrailEntryType.updatePatientDemographicsPullEvent:
        return generateUpdatePatientDemographicsPullEvent(audit);

      case AuditTrailEntryType.patientSearchReasoning:
        return `${
          audit?.data?.reason
            ? `
        Patient record accessed via search under the following reason: ${audit.data.reason}`
            : ""
        }
        ${
          audit?.data?.reasonDescription
            ? `- ${audit.data.reasonDescription}`
            : ""
        }`;

      default:
        return null;
    }
  };
}

export function PatientActivityLog({
  patientId,
  user,
  onClose,
}: {
  patientId: string;
  user: ExtendedThalamosUser;
  onClose: () => void;
}) {
  const [{ data: auditData, loading: auditDataLoading }] =
    apiHooks.audit.searchByPatientId(patientId, {
      limit: 1000,
      offset: 0,
      fromDate: "2000-01-01",
      toDate: "2050-01-01",
    });

  const patientMhaStatusEnabled =
    user.sessionOrganisationConfiguration?.patientStateEnabled;

  const patientSearchRecordReasonsEnabled =
    user.sessionOrganisationConfiguration?.patientSearchRecordReasonsEnabled;

  return (
    <PopupDialog open={true} onClose={onClose} fullWidth>
      <PopupDialogTitle
        titleText={`Activity Log for patient`}
        closeDialog={onClose}
      />
      <DialogContent>
        {auditDataLoading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {entries(
              user,
              auditData?.entries.reverse()!,

              // Exclude MHA status related entries if patientMhaStatusEnabled is false
              getMessageFn([
                ...(patientSearchRecordReasonsEnabled
                  ? []
                  : [AuditTrailEntryType.patientSearchReasoning]),
                ...(patientMhaStatusEnabled
                  ? []
                  : [
                      AuditTrailEntryType.updatePatientStateAutomatically,
                      AuditTrailEntryType.updatePatientStateWithManualEvent,
                      AuditTrailEntryType.automaticallyExpirePatientState,
                    ]),
              ]),
            )}
          </Box>
        )}
      </DialogContent>
    </PopupDialog>
  );
}
