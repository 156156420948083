import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, apiHooks } from "../../api";
import {
  ExtendedThalamosUser,
  isGuestUser,
  TeamGuestUserConversionRequest,
} from "@aspire/common/types/user";
import { routeFns } from "../../routes";
import { Formik, FormikHelpers } from "formik";
import { object } from "yup";
import {
  nonEmptyRequiredString,
  nonEmptyString,
} from "@aspire/common/schemas/shared";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { DropdownFormField, TextboxFormField } from "~/components/form";
import {
  Banner,
  BannerList,
  Button,
  FormLabel,
  HelperText,
  ReadOnlyContent,
  renderErrorToast,
  TextField,
} from "~/components/design-system";
import { OrganisationPicker } from "./OrganisationPicker";

import { ExtendedTeamMembership } from "@aspire/common/types/memberships";

function ConvertToUser({
  userId,
  user,
}: {
  userId: string;
  user: ExtendedThalamosUser;
}) {
  const [submitError, setSubmitError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  return (
    <Formik<{ name: string }>
      initialValues={{ name: "" }}
      onSubmit={async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
          const result = await api.users.convertGuestUser(userId, {
            type: "user",
            name: values.name,
          });
          if (result.status === 409) {
            setSubmitError("User with that email already exists");
          } else {
            navigate(routeFns.userMembershipsPage(userId));
          }
        } catch (e) {
          setSubmitError("Unknown error!");
        }
      }}
      validationSchema={object({
        name: nonEmptyRequiredString,
      })}
    >
      {({ values, setValues, errors, touched, submitForm, isSubmitting }) => (
        <Box sx={{ maxWidth: 600 }}>
          <Box sx={{ mb: "2em" }}>
            <Banner
              title={
                "Convert this user to a full user. They will need to be added to a normal team, but will continue to have access to their Guest team until it is removed."
              }
              bannerType={BannerList.INFO}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              name={"email"}
              readOnly={true}
              useFullWidth={true}
              value={user.email}
            />
          </Box>
          <Box>
            <FormLabel label={"Name"} error={touched.name && !!errors.name} />

            <TextField
              name={"name"}
              useFullWidth={true}
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e })}
            />
            {errors.name && touched.name && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.name as string} />
              </Box>
            )}
          </Box>
          <Box>
            {submitError && (
              <Box sx={{ mb: 4 }}>
                <Banner
                  bannerType={BannerList.ERROR}
                  title={submitError as string}
                />
              </Box>
            )}
            <Button
              disabled={isSubmitting}
              label={"Create"}
              onClick={submitForm}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
}

const teamSchema = object({
  teamName: nonEmptyString
    .default("")
    .required("Please enter a name for the converted team"),
  parentOrganisationId: nonEmptyRequiredString.required(
    "Please select an organisation to be the parent",
  ),
  parentOrganisationName: nonEmptyRequiredString,
  teamType: nonEmptyRequiredString
    .oneOf(["mha", "amhp", "ward", "generic"])
    .required("Please select a team type"),
  teamTypeText: nonEmptyRequiredString.default(""),
});

type TeamDataType = {
  teamName: string;
  parentOrganisationId: string;
  parentOrganisationName: string;
  teamType: string;
  teamTypeText: string;
};

function ConvertToTeam({
  userId,
  user,
}: {
  userId: string;
  user: ExtendedThalamosUser;
}) {
  const navigate = useNavigate();

  return (
    <Formik<TeamDataType>
      onSubmit={async (values) => {
        const teamId = (user.memberships[0] as ExtendedTeamMembership).teamId;
        const result = await api.users.convertGuestUser(userId, {
          type: "team",
          teamName: values.teamName,
          teamType:
            values.teamType as TeamGuestUserConversionRequest["teamType"],
          parentOrganisationId: values.parentOrganisationId,
        });

        if (result.status === 204) {
          navigate(routeFns.teamCreateEdit(teamId));
        } else {
          renderErrorToast({ message: JSON.stringify(result.data) });
        }
      }}
      validationSchema={teamSchema}
      initialValues={{
        teamName: "",
        parentOrganisationId: "",
        parentOrganisationName: "",
        teamType: "",
        teamTypeText: "",
      }}
    >
      {(formikData) => {
        const {
          values,
          setValues,
          setFieldTouched,
          errors,
          touched,
          handleBlur,
          submitForm,
          isSubmitting,
        } = formikData;

        const fieldProps = {
          validationSchema: teamSchema,
          values,
          setValues,
          setFieldTouched,
          errors,
          touched,
          context: {},
          handleBlur,
        };

        return (
          <Box sx={{ maxWidth: "500px" }}>
            <Box sx={{ mb: 2 }}>
              <ReadOnlyContent label={"Email"} content={[user.email]} />
            </Box>
            <Box sx={{ mb: 0 }}>
              <TextboxFormField
                field={{
                  type: "textbox",
                  label: "Name of new team (*)",
                  field: "teamName",
                }}
                fieldProps={fieldProps}
              />

              <OrganisationPicker
                label={"Parent organisation for new team (*)"}
                error={
                  touched.parentOrganisationId
                    ? (errors.parentOrganisationId as string)
                    : null
                }
                idField={"parentOrganisationId"}
                nameField={"parentOrganisationName"}
                values={values}
                setValues={setValues}
              />

              <DropdownFormField
                field={{
                  type: "dropdown",
                  label: "Team Type (*)",
                  valueField: "teamType",
                  textField: "teamTypeText",
                  options: [
                    { value: "amhp", label: "AMHP" },
                    { value: "mha", label: "MHA" },
                    { value: "ward", label: "Ward" },
                    { value: "generic", label: "Generic" },
                  ],
                }}
                fieldProps={fieldProps}
              />

              <Button
                label={"Submit"}
                disabled={isSubmitting}
                onClick={submitForm}
              />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}

function AdminConvertGuestUserPageInner({
  userId,
  user,
}: {
  userId: string;
  user: ExtendedThalamosUser;
}) {
  const [type, setType] = React.useState<"user" | "team" | null>(null);

  return (
    <>
      <h3>
        Convert guest user - {user.name} ({user.email})
      </h3>

      <Box sx={{ mb: "2em" }}>
        <RadioGroup value={type} name={"type"}>
          <FormControlLabel
            label={"Convert to user"}
            value={"user"}
            onClick={() => setType("user")}
            control={
              <Radio
                icon={
                  <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                }
                checkedIcon={
                  <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                }
              />
            }
          />
          <FormControlLabel
            label={"Convert to team"}
            value={"team"}
            onClick={() => setType("team")}
            control={
              <Radio
                icon={
                  <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                }
                checkedIcon={
                  <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                }
              />
            }
          />
        </RadioGroup>
      </Box>
      {type === "user" ? <ConvertToUser userId={userId} user={user} /> : null}
      {type === "team" ? <ConvertToTeam userId={userId} user={user} /> : null}
    </>
  );
}

export function AdminConvertGuestUserPage({}: {}) {
  let { userId } = useParams();
  const navigate = useNavigate();

  const [{ data: user, loading: userLoading, response }] =
    apiHooks.users.getUserDetails(userId!);

  useEffect(() => {
    if (!userLoading) {
      if (!(response?.status === 200 && user && isGuestUser(user))) {
        navigate(routeFns.adminUsersPage());
      }
    }
  }, [userLoading, user, response]);

  return userLoading || !user || response?.status !== 200 ? (
    <></>
  ) : (
    <AdminConvertGuestUserPageInner userId={userId!} user={user!} />
  );
}
