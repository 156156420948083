export interface ExpiryPeriod {
  value: number;
  unit: "days" | "hours" | "minutes";
}

export function getFormExpiryPeriods(useShortExpiryPeriods: boolean) {
  return {
    h3Section4ExpiryPeriod: useShortExpiryPeriods
      ? ({ value: 5, unit: "minutes" } as ExpiryPeriod)
      : ({ value: 72, unit: "hours" } as ExpiryPeriod),
    s15AmendSection4Expiry: useShortExpiryPeriods
      ? ({ value: 10, unit: "minutes" } as ExpiryPeriod)
      : ({ value: 72, unit: "hours" } as ExpiryPeriod),
    s15AmendSection2And3Expiry: useShortExpiryPeriods
      ? ({ value: 30, unit: "minutes" } as ExpiryPeriod)
      : ({ value: 14, unit: "days" } as ExpiryPeriod),
  };
}
