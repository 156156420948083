import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Base64FileUpload,
  TranscodeImageProps,
  UploadedFile,
} from "../Base64FileUpload/Base64FileUpload";

export type UploadProps = {
  setUploadedFile: (
    callback: (prevUploadedFiles: UploadedFile[] | null) => UploadedFile[],
  ) => void;
  setFileError: (error: string | null) => void;
  labelType?: "form-upload" | "signature";
  icon?: any;
  accept?: string;
  multi?: boolean;
  uploadMultiple?: boolean;
  transcodeImage?: TranscodeImageProps;
  validMimeTypes: string[];
  maxFileSizeBytes?: number;
  fileTooLargeErrorText?: string;
  disabled?: boolean;
};

const DEFAULT_MAX_FILE_SIZE_BYTES = 200_000;

export const Upload = ({
  setUploadedFile,
  setFileError,
  labelType,
  icon,
  accept,
  multi,
  uploadMultiple,
  transcodeImage,
  validMimeTypes,
  maxFileSizeBytes,
  fileTooLargeErrorText,
  disabled,
}: UploadProps) => {
  const { t } = useTranslation();

  const onDone = useCallback(
    (files: UploadedFile[]) => {
      let allValid = true;

      for (const file of files) {
        // It must be one of the supported mime types
        const isValidType = validMimeTypes.includes(file.type);

        if (!isValidType) {
          const validMimeTypesStrings = validMimeTypes
            .map((type) => type.split("/")[1])
            .join(", ");

          const errorMessage = t("pages.common.signature.uploadLabelErrorImg", {
            validMimeTypes: validMimeTypesStrings,
          });

          setFileError(errorMessage);
          allValid = false;
          break;
        }

        // If we are transcoding the image, we don't care about the original file size
        const isImage = file.type.startsWith("image/");
        const isValidSize =
          (transcodeImage !== undefined && isImage) ||
          file.size < (maxFileSizeBytes ?? DEFAULT_MAX_FILE_SIZE_BYTES);

        if (!isValidSize) {
          setFileError(
            fileTooLargeErrorText ??
              t("pages.common.signature.uploadLabelErrorFileSize1MB"),
          );
          allValid = false;
          break;
        }
      }

      if (allValid) {
        setFileError(null);

        setUploadedFile((prevUploadedFiles: UploadedFile[] | null) => {
          // TODO: I don't understand why this logic is in here
          if (!multi) {
            return files;
          }

          return [...(prevUploadedFiles ?? []), ...files];
        });
      }
    },
    [multi, setFileError, setUploadedFile, t, transcodeImage],
  );

  return (
    <Base64FileUpload
      disabled={disabled}
      icon={icon}
      transcodeImage={transcodeImage}
      onDone={onDone}
      accept={accept}
      label={
        labelType === "form-upload"
          ? "Select a file"
          : t("components.signatureDialog.uploadLabel")
      }
      multi={uploadMultiple}
    />
  );
};
