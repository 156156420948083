import { InferType, object } from "yup";
import {
  defaultContextFields,
  hospital,
  generateDateBeforeTodayValidationRule,
  generateDateAfterTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const cto10Part1DataSchema = object({
  ...defaultContextFields({ userAddressOptional: true }),

  hospital: hospital,

  receivingHospital: hospital,

  dateOfAgreement: generateDateBeforeTodayValidationRule(
    "date",
    "User can't add date in the future.",
  ).required(
    "Please enter date this assignment was agreed by the managers of the hospital to which the responsibility is to be assigned on",
  ),

  dateOfAssignment: generateDateAfterTodayValidationRule(
    "date",
    "You cannot enter a date in the past.",
  ).required("Enter the date the assignment is to take place on"),
});

export type Cto10Part1DataSchema = InferType<typeof cto10Part1DataSchema>;

export type CTO10Data = [PartData<Cto10Part1DataSchema>];

export const exampleCTO10Data: ExampleFormData<CTO10Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },

      receivingHospital: {
        isConfirmed: true,
        name: "Receiving Hospital",
        address: "Receiving Hospital Address",
        postalCode: "DEF 123",
      },

      dateOfAgreement: "2021-01-01",

      dateOfAssignment: "2021-01-02",
    },
  },
];
