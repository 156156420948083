import { UUID } from "../util";
import { Patient } from "./patient";
import { Form } from "./form";
import { FormContextType } from "./formcontext";
import { ExtendedWorkItem } from "./work";

// Event names (and the channels they are sent to) can only contain characters which are alphanumeric, ‘-’ or ‘_’
export enum NotificationEvents {
  resourceReload = "resource-reload",
}

// Channel names should only include lower and uppercase letters, numbers and the following punctuation _ - = @ , . ;
// Channel names may contain a maximum of 164 characters
export const notificationChannelFns = {
  user: {
    caseload: (userId: string | undefined) => `user.${userId}.caseload`,
    profile: (userId: string | undefined) => `user.${userId}.profile`,
    notifications: (userId: string | undefined) =>
      `user.${userId}.notifications`,
  },
  org: {
    profile: (orgId: string | undefined) => `org.${orgId}.profile`,
  },
  team: {
    profile: (teamId: string | undefined) => `team.${teamId}.profile`,
    notifications: (teamId: string | undefined) =>
      `team.${teamId}.notifications`,
  },
  work: {
    item: (itemId: string | undefined) => `work.${itemId}`,
    userItems: (userId: string | undefined) => `work.user.${userId}`,
    items: (teamOrOrganisationId: string | undefined) =>
      `work.context.${teamOrOrganisationId}`,
    archive: (teamOrOrganisationId: string | undefined) =>
      `work.archive.${teamOrOrganisationId}`,
  },
  formDraft: (formDraftId: string) => `formdraft.${formDraftId}`,
  form: (formId: string) => `form.${formId}`,
  formContext: (formContextId: string) => `form-context.${formContextId}`,
  patient: (patientId: string) => `patient.${patientId}`,
  patientTimeline: (patientId: string) => `patient.${patientId}.timeline`,
  mhaStatus: (patientId: string) => `patient.${patientId}.mha-status`,
};

export type ThalamosNotification = {
  id: UUID;
  text: string;
  status: "new" | "deleted";
  userId?: string;
  teamId: string;
  workId?: string;
  createdByUser?: UUID;
  createdByTeam?: UUID;
};

export type ExtendedThalamosNotification = ThalamosNotification & {
  workItem?: Pick<ExtendedWorkItem, "formId">; // TOOD part, type

  formContextId?: UUID;
  formContextType?: FormContextType;

  viewed: boolean;

  patient: Partial<Pick<Patient, "name" | "id">>;
  forms: Pick<Form<any>, "id" | "formTemplate" | "created">[];
  createdAt: string;
  createdByUserName: string;
  createdByTeamName: string;
};

export type ResourceReloadEvent = {
  type: NotificationEvents.resourceReload;
};

export type BrowserNotificationEvent = ResourceReloadEvent;

export type NotificationsResponse = {
  notifications: ExtendedThalamosNotification[];
  count: number;
  unviewedCount: number;
};
