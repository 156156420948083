import { DateTimeFormBuilderField } from "@aspire/common/types/form";
import { FieldProps } from "../../pages/FieldProps";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FormControl, useTheme } from "@mui/material";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormLabel } from "../design-system/FormLabel";
import { HelperText } from "../design-system/HelperText/HelperText";
import { Element } from "react-scroll";
import { css } from "@emotion/react";
import {
  ukTimezoneHackeryOnBrowserLocalValue,
  ukTimezoneHackeryOnUTCValue,
} from "./timezoneHacksForDatePickers";
import { useTranslation } from "react-i18next";
import { TextField } from "../design-system/TextField/TextField";

export type DateTimeFormFieldProps<Data extends { [k: string]: any }> = {
  field: DateTimeFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function DateTimeFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: DateTimeFormFieldProps<Data>) {
  const isError = !!(
    fieldProps.touched[field.field] && fieldProps.errors[field.field]
  );
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Element
      name={field.field}
      data-testid={`field:${field.field}`}
      css={css`
        margin-bottom: ${theme.spacing(3)};
      `}
    >
      {field.label && <FormLabel label={field.label} error={isError} />}

      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            showToolbar={true}
            ampm={false}
            inputFormat={"DD/MM/YYYY HH:mm"}
            minDateTime={field.minimum && field.minimum(dayjs())}
            maxDateTime={field.maximum && field.maximum(dayjs())}
            PopperProps={{
              placement: "auto",
            }}
            onChange={(value: Dayjs | null) => {
              fieldProps.setFieldTouched(field.field, true, false);
              fieldProps.setValues({
                ...fieldProps.values,
                [field.field]: ukTimezoneHackeryOnBrowserLocalValue(value),
              });
            }}
            value={ukTimezoneHackeryOnUTCValue(fieldProps.values[field.field])}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      {!isError && field.showDateTimeMessage !== false && (
        <HelperText subtext={t("forms.ukDateTimeWarning")} />
      )}
      {isError && (
        <HelperText errorMessage={fieldProps.errors[field.field] as string} />
      )}
    </Element>
  );
}
