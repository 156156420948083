import {
  H5Data,
  h5Part1DataSchema,
  H5Part1DataSchema,
  H5Part2DataSchema,
  h5Part2DataSchema,
  h5Part3DataSchema,
  H5Part3DataSchema,
  H5Part4DataSchema,
  h5Part4DataSchema,
} from "../schemas/mhaForms/hospital/h5";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const h5Part1Template: FormPartTemplate<H5Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of hospital in which the patient is liable to be detained",
    },
    {
      type: "readonly",
      label: "Patient name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "date",
      label: "Enter date of examination",
      field: "lastExaminedDate",
      maximum: (today) => today.endOf("day"),
    },
    {
      type: "date",
      label: "Enter date authority for detention is due to expire",
      field: "detentionExpiryDate",
      minimum: (today) => today.endOf("day"),
    },
    {
      type: "textbox",
      label:
        "Enter full name of the person you have consulted who has been professionally concerned with the patient's treatment",
      field: "consultedPersonName",
    },
    {
      type: "textbox",
      label:
        "Enter profession of the person you have consulted who has been professionally concerned with the patient's treatment",
      field: "consultedPersonProfession",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) This patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital.",
      ],
    },
    {
      type: "multi-select",
      label:
        "(b) It is necessary that this patient should receive treatment in hospital for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient’s own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient’s own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },
    {
      type: "textbox",
      field: "reasonsDetentionNecessary",
      label:
        "Your reasons should cover both (a) and (b) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate.",
      rows: "10",
    },
    {
      type: "textbox",
      field: "reasonsInformalAdmissionsInappropriate",
      label:
        "Such treatment cannot be provided unless the patient continues to be detained under the Act, for the following reasons. Reasons should indicate why informal admission is not appropriate.",
      rows: "10",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I am also of the opinion that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all the other circumstances of the case, appropriate medical treatment is available to the patient.",
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
    {
      type: "textbox",
      label: "My profession",
      field: "userProfession",
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: h5Part1DataSchema,
};

export const h5Part2Template: FormPartTemplate<H5Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["nurse", "doctor", "hcpc", "social-worker"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.professionalConcernedWithPatientCareDifferentProfessionFromResponsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I agree with the responsible clinician that: this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital; it is necessary for the patient’s own health or safety or for the protection of other persons that the patient should receive treatment and it cannot be provided unless the patient continues to be detained under the Act; and that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient.",
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
    {
      type: "textbox",
      label: "My profession",
      field: "userProfession",
    },
  ],
  dataSchema: h5Part2DataSchema,
};

export const h5Part3Template: FormPartTemplate<H5Part3DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "today consigning it to the hospital managers’ internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "otherwise-sent-or-delivered",
          fields: [],
          fieldsOwned: [],
          label:
            "sending or delivering it without using the hospital managers’ internal mail system",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: h5Part3DataSchema,
  autoAssignToPartSignatory: 1,
};

export const h5Part4Template: FormPartTemplate<H5Part4DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },
  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers through their internal mail system.",
        },
        {
          fieldValue: "electronic-communication",
          fields: [],
          fieldsOwned: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication.",
        },
        {
          fieldValue: "otherwise-sent-or-delivered",
          fields: [
            {
              type: "date",
              label: "Please enter date the report was received on",
              field: "receivedDate",
              maximum: (today) => today.endOf("day"),
            },
          ],
          fieldsOwned: ["receivedDate"],
          label: "received by me on behalf of the hospital managers on",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: h5Part4DataSchema,
};

export const h5V1Base: BaseFormTemplate = {
  id: "mha-h5",
  category: "hospital",
  version: "1.0",
  status: "active",
  parts: [h5Part1Template, h5Part2Template, h5Part3Template, h5Part4Template],
  formName: "H5",
  section: "20",
  description: "Renewal of authority for detention",
  extractGenericData: (data: H5Data) => ({}),
};
