import React from "react";
import { MenuFlyout, MenuOptionsType } from "~/components/design-system";
import { Box, useTheme } from "@mui/material";
import { SubContainer } from "~/components/layout/styleWrappers";
import { Chip, Tooltip, Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { css } from "@emotion/react";
import { WorkItemStatusBox } from "../../Caseload/StatusTableCells";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ukLocalFormatDateTime } from "../../../util";

type FormRowProps = {
  formDataChips?: string[];
  menuOptions: MenuOptionsType[];
  heading: string;
  subHeading: string;
  summary?: { colour: string; statusText: string };
  onClick?: () => void;
  lastMerged?: string;
  formUploadedAt?: string;
};

export function FormRow({
  formDataChips,
  menuOptions,
  heading,
  subHeading,
  summary,
  onClick,
  lastMerged,
  formUploadedAt,
}: FormRowProps) {
  const theme = useTheme();
  const cardRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  const styles = {
    listItem: css`
      &:hover {
        background-color: ${theme.palette.background.default};
        cursor: pointer;
        & :first-of-type {
          color: ${theme.palette.primary.main};
          fill: ${theme.palette.primary.main};
        }
      }
      padding-bottom: 1px;
      padding-top: 0;
    `,
    subtitle: css`
      font-size: ${theme.spacing(1.625)};
      font-weight: 400;
      color: ${theme.palette.primary.hint};
    `,
    container: css`
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        width: 100%;
      }
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        width: 50%;
      }

      border-left: ${theme.spacing(0.4) +
      ` solid ` +
      theme.palette.primary.main};
      padding-top: ${theme.spacing(0.5)};
      padding-bottom: ${theme.spacing(0.5)};
      align-content: center;
      padding-left: ${theme.spacing(1.2)};
      padding-right: ${theme.spacing(2)};
    `,
    root: css`
      flex-direction: row;
      margin-bottom: ${theme.spacing(1)};
    `,
    title: css`
      font-size: ${theme.spacing(2.75)};
      font-weight: 400;
      color: ${theme.palette.text.primary};
      text-align: left;
    `,
    icon: css`
      padding: 0;
      color: ${theme.palette.text.secondary};
      margin-right: ${theme.spacing(0.7)};
      &:hover {
        color: ${theme.palette.primary.main};
      }
    `,
  };

  return (
    <Box sx={{ marginBottom: "1em", width: "100%" }} onClick={onClick}>
      <SubContainer styleAsClickable={!!onClick}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ sm: "start", md: "center" }}
        >
          <Box css={styles.container} data-testid="box-id">
            <Box display="flex" alignItems="center" css={styles.root}>
              <Typography
                variant="h2"
                css={styles.title}
                data-testid="title-id"
              >
                <span>{heading}</span>
              </Typography>
            </Box>
            <Typography css={styles.subtitle}>{subHeading}</Typography>

            {!!lastMerged && (
              <Box display="flex" alignItems={"center"}>
                <Typography
                  css={css`
                    font-size: ${theme.spacing(1.625)};
                    font-weight: 600;
                    color: ${theme.palette.error.main};
                    font-style: italic;
                    margin-top: ${theme.spacing(0.5)};
                  `}
                >
                  {t("common.merge")}:{" "}
                  {dayjs(lastMerged).format("YYYY-MM-DD HH:mm")}
                </Typography>
                <Tooltip
                  title="Form(s) started in another patient, and was merged into this patient"
                  arrow
                >
                  <IconButton>
                    <InfoOutlinedIcon
                      sx={{ color: theme.palette.error.main }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {!!formUploadedAt && (
              <Chip
                sx={{ mt: 1 }}
                color={"primary"}
                label={`Form Uploaded: ${ukLocalFormatDateTime(
                  formUploadedAt,
                )}`}
              />
            )}
          </Box>

          <Box
            display="flex"
            flexDirection={{ sm: "column", md: "row" }}
            css={css`
              @media screen and (max-width: ${theme.breakpoints.values.md}px) {
                margin-top: ${theme.spacing(1)};
     
              },
              `}
          >
            <Box>
              {!!formDataChips?.length && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography
                    css={css`
                      font-size: ${theme.spacing(1.625)};
                      color: ${theme.palette.primary.hint};
                      margin-bottom: ${theme.spacing(0.5)};
                    `}
                  >
                    Form data
                  </Typography>
                  <Box
                    sx={{
                      gap: 1,
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "500px",
                      fontSize: 13,
                    }}
                  >
                    {formDataChips.map((label) => (
                      <Chip
                        sx={{
                          backgroundColor: "common.lightGray",
                        }}
                        key={label}
                        label={label}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {summary && (
                <>
                  <Box sx={{ ml: 1.6 }}>
                    <WorkItemStatusBox
                      color={theme.palette.common.black}
                      backgroundColor={summary.colour}
                      headingText={"Summary"}
                      mainText={summary.statusText}
                      align="flex-start"
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent={{ md: "flex-end", sm: "flex-start" }}
            alignItems="center"
            marginLeft="auto"
          >
            <Box
              css={css`
                display: flex;
              `}
              ref={cardRef}
              onClick={() => setIsOpen(!isOpen)}
            >
              {!!menuOptions.length && (
                <Box
                  css={css`
                    @media screen and (max-width: ${theme.breakpoints.values
                        .md}px) {
                      margin-top: ${theme.spacing(2)};
                    }
                  `}
                >
                  <MenuFlyout
                    cardRef={cardRef}
                    options={menuOptions}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </SubContainer>
    </Box>
  );
}
