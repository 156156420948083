import { boolean, InferType, object } from "yup";
import {
  dateAndTimeWithin24HoursValidation,
  defaultAddress,
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  nameAndAddress,
  optionalCheckbox,
} from "../../../shared";
import { ExampleFormData, PartData } from "../../../../types/form";
import { optionalEmail } from "../../../acd/util";

export const a11UploadablePart1DataSchema = object({
  ...defaultContextFields(),

  nameAndAddressOfSignee: nameAndAddress(
    {
      nameRequiredError: "Please enter name of signee",
      addressRequiredError: "Please enter address of signee",
    },
    true,
  ).default(defaultAddress),

  emailAddressOfSignee: optionalEmail,

  practitionerApprovedUnderSection12: boolean()
    .default(null)
    .required(
      "Please confirm if signee is approved under section 12 of the Act",
    ),

  examinedTimeAndDate: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  )
    .concat(dateAndTimeWithin24HoursValidation())
    .required(
      "Please enter date and time signee last examined this patient on",
    ),

  previousAcquaintance: boolean()
    .default(null)
    .required("Please select the option which applies"),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  signatureDate: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter signature date "),
});

export type A11UploadablePart1DataSchema = InferType<
  typeof a11UploadablePart1DataSchema
>;

export type A11UploadableData = [PartData<A11UploadablePart1DataSchema>];

export const exampleA11UploadableData: ExampleFormData<A11UploadableData> = [
  {
    data: {
      nameAndAddressOfSignee: {
        name: "name",
        address: "Address",
        isConfirmed: true,
        postalCode: "ABC 123",
      },
      emailAddressOfSignee: "stuart@thalamos.co.uk",
      examinedTimeAndDate: "2021-01-01T15:00:00",
      previousAcquaintance: false,
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
      practitionerApprovedUnderSection12: true,
      signatureDate: "2021-01-01",
      user: {
        name: "Dr. Sarah Glass",
        address: "143 Fake Street",
        postalCode: "AB1 2CD",
        email: "sarah.glass@houses.com",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: false,
      },
      patient: {
        name: "Bob Smith",
        address: "123 Fake Street",
        postalCode: "AB1 2CD",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
