import {
  BaseFormTemplate,
  FormIdAndVersion,
  UploadableBaseFormTemplate,
  UserOrTeamRequirement,
} from "./types/form";
import { ExtendedThalamosUser } from "./types/user";
import { Team } from "./types/teams";

import { a2V1Base } from "./formBuilderTemplates/a2";
import { a4V1Base } from "./formBuilderTemplates/a4";
import { a3V1Base } from "./formBuilderTemplates/a3";
import { a5V1Base } from "./formBuilderTemplates/a5";
import { a6V1Base } from "./formBuilderTemplates/a6";
import { a7V1Base } from "./formBuilderTemplates/a7";
import { a8V1Base } from "./formBuilderTemplates/a8";
import { a10V1Base } from "./formBuilderTemplates/a10";
import { a11V1Base } from "./formBuilderTemplates/a11";

import { cto1V1Base } from "./formBuilderTemplates/cto1";
import { cto2V1Base } from "./formBuilderTemplates/cto2";
import { cto4V1Base } from "./formBuilderTemplates/cto4";
import { cto5V1Base } from "./formBuilderTemplates/cto5";
import { cto10V1Base } from "./formBuilderTemplates/cto10";
import { cto7V1Base } from "./formBuilderTemplates/cto7";
import { cto3V1Base } from "./formBuilderTemplates/cto3";
import { cto9V1Base } from "./formBuilderTemplates/cto9";
import { cto8V1Base } from "./formBuilderTemplates/cto8";

import { h1V1Base } from "./formBuilderTemplates/h1";
import { h2V1Base } from "./formBuilderTemplates/h2";
import { h3V1Base } from "./formBuilderTemplates/h3";
import { h4V1Base } from "./formBuilderTemplates/h4";
import { h5V1Base } from "./formBuilderTemplates/h5";
import { h6V1Base } from "./formBuilderTemplates/h6";

import { t2V1Base } from "./formBuilderTemplates/t2";
import { t4V1Base } from "./formBuilderTemplates/t4";
import { acdV1Base } from "./formBuilderTemplates/acd/acd";
import { s23V1Base } from "./formBuilderTemplates/discharge/s23";
import { hpdV1Base } from "./formBuilderTemplates/discharge/hpd";

import { m1V1Base } from "./formBuilderTemplates/m1";
import { m2V1Base } from "./formBuilderTemplates/m2";

import {
  applicationTemplateIds,
  medicalRecommendationTemplateIds,
} from "./sections";
import { cto6V1Base } from "./formBuilderTemplates/cto6";
import { cto12V1Base } from "./formBuilderTemplates/cto12";
import { hasIntersection } from "./util";
import { a1V1Base } from "./formBuilderTemplates/a1";
import { a9V1Base } from "./formBuilderTemplates/a9";

export const doesUserMeetRequirement = (
  user: Pick<ExtendedThalamosUser, "accreditations"> & {
    memberships: { type: "organisation" | "team"; teamType?: Team["type"] }[];
  },
  requirement: UserOrTeamRequirement,
) => {
  // Accreditations
  const userAccreditationTypes = [
    ...user.accreditations.map((acc) => acc.type),
    // Everyone in an org with ACD enabled can do an ACD for now, as this feels like the least disgusting hack;
    "acd-facilitator",
  ];
  const hasProhibitedAccreditation = hasIntersection(
    requirement.prohibitedAccreditations,
    userAccreditationTypes,
  );
  const hasValidAccreditation = hasIntersection(
    requirement.accreditation,
    userAccreditationTypes,
  );

  // Team types
  const userTeamTypes = user.memberships.map((m) => m.teamType).filter(Boolean);
  const hasValidTeam = hasIntersection(requirement.teamType, userTeamTypes);
  const hasProhibitedTeam = hasIntersection(
    requirement.prohibitedTeamTypes,
    userTeamTypes,
  );
  return (
    (hasValidAccreditation || hasValidTeam) &&
    !hasProhibitedAccreditation &&
    !hasProhibitedTeam
  );
};

export const baseTemplates: BaseFormTemplate[] = [
  a1V1Base,
  a2V1Base,
  a3V1Base,
  a4V1Base,
  a5V1Base,
  a6V1Base,
  a7V1Base,
  a8V1Base,
  a9V1Base,
  a10V1Base,
  a11V1Base,

  cto1V1Base,
  cto2V1Base,
  cto4V1Base,
  cto5V1Base,
  cto10V1Base,
  cto7V1Base,
  cto3V1Base,
  cto6V1Base,
  cto12V1Base,
  cto9V1Base,
  cto8V1Base,

  h1V1Base,
  h2V1Base,
  h3V1Base,
  h4V1Base,
  h5V1Base,
  h6V1Base,

  t2V1Base,
  t4V1Base,
  acdV1Base,
  s23V1Base,
  hpdV1Base,

  m1V1Base,
  m2V1Base,
];

export const formUploadBaseTemplates: UploadableBaseFormTemplate[] = [
  a1V1Base,
  a2V1Base,
  a3V1Base,
  a4V1Base,
  a5V1Base,
  a6V1Base,
  a7V1Base,
  a8V1Base,
  a9V1Base,
  a10V1Base,
  a11V1Base,
];

export const nonAdmissionTemplates = baseTemplates.filter(
  (t) =>
    !applicationTemplateIds.includes(t.id) &&
    !medicalRecommendationTemplateIds.includes(t.id),
);

export function getBaseFormTemplate(
  id: string,
  version?: string,
): BaseFormTemplate | null;
export function getBaseFormTemplate(
  id: FormIdAndVersion,
): BaseFormTemplate | null;
export function getBaseFormTemplate(form: {
  formTemplate: FormIdAndVersion;
}): BaseFormTemplate | null;
export function getBaseFormTemplate(
  idOrObject: string | FormIdAndVersion | { formTemplate: FormIdAndVersion },
  versionArg?: string,
): BaseFormTemplate | null {
  const id =
    (idOrObject as { formTemplate: FormIdAndVersion })?.formTemplate?.id ??
    (idOrObject as FormIdAndVersion)?.id ??
    (idOrObject as string) ??
    null;
  let v =
    versionArg ??
    (idOrObject as FormIdAndVersion)?.version ??
    (idOrObject as { formTemplate: FormIdAndVersion })?.formTemplate?.version ??
    null;

  return (
    baseTemplates.find((t) => t.id === id && (!v || t.version === v)) || null
  );
}

export function acknowledgedFormTemplateFor(
  id: string,
): BaseFormTemplate | null {
  return (
    baseTemplates.find((template) =>
      template.linkableForms?.some((lf) => lf.templateId === id),
    ) || null
  );
}

export type FormPartAssignmentOptionsSearch = {
  id: string;
  version: string;
  part: number;
  query: string;
};
