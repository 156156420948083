import React from "react";
import { Typography, useTheme, Box } from "@mui/material";

import { css } from "@emotion/react";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection";

import { Upload, UploadProps } from "./Upload/Upload";
import { UploadedFile } from "./Base64FileUpload/Base64FileUpload";

import CloudUpload from "../../../assets/img/cloud_upload.svg";
import { useTranslation } from "react-i18next";

export type UploadedFileSectionProps = {
  uploadedFile: UploadedFile;
  fileError: string;
} & Pick<UploadProps, "setUploadedFile" | "setFileError">;

const ONE_MB_BYTES = 1_024 * 1_024;

export const UploadedFileSection = ({
  uploadedFile,
  setUploadedFile,
  setFileError,
  fileError,
}: UploadedFileSectionProps) => {
  const theme = useTheme();
  const { isMobileView } = useScreenDetection();
  const { t } = useTranslation();
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        min-height: ${theme.spacing(15.5)};
        align-items: center;
        ${!isMobileView && `margin: ${theme.spacing(1, 4, 2, 4)};`}
      `}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        <Box>
          {uploadedFile ? (
            <img
              src={uploadedFile.base64 as string}
              alt="base64"
              height={190}
            />
          ) : (
            <img
              css={css`
                line-height: 5px;
                margin-bottom: ${theme.spacing(3.3)};
              `}
              alt="cloudUpload"
              src={CloudUpload}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Upload
            setUploadedFile={setUploadedFile}
            setFileError={setFileError}
            validMimeTypes={["image/jpeg", "image/png"]}
            transcodeImage={{
              maxWidth: 400,
              maxHeight: 145,
              mimeType: "image/jpeg",
            }}
            maxFileSizeBytes={ONE_MB_BYTES}
            fileTooLargeErrorText={t(
              "pages.common.signature.uploadLabelErrorFileSize1MB",
            )}
          />
          {fileError && <Typography color="error">{fileError}</Typography>}
        </Box>
      </Box>
    </Box>
  );
};
