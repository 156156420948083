import React from "react";

import { useTheme, ButtonProps } from "@mui/material";

import { Button, ButtonIcon } from "../design-system/Button/Button";
import { Box, alpha } from "@mui/material";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SyncProblem from "@mui/icons-material/SyncProblem";

import { css } from "@emotion/react";
import { breakpoints } from "../../styles/themeCommon";
import { useScreenDetection } from "../../hooks/ScreenDetection/useScreenDetection";
import { LoadingSpinner } from "../design-system/LoadingSpinner";
import { FancyIcon } from "../design-system/FancyIcon";
import { AutoSaveState } from "../types/form";
import { useTranslation } from "react-i18next";

export interface FormFooterProps {
  onSave: (params: any) => void;
  onCancel: (params: any) => void;
  saveLabel?: ButtonProps["aria-label"];
  discardLabel?: ButtonProps["aria-label"];
  discardIcon?: ButtonIcon;
  saveIcon?: ButtonIcon;
  disableSubmit?: ButtonProps["disabled"];
  disableDiscard?: ButtonProps["disabled"];
  secondaryLeftButton?: React.ReactNode;
  secondaryRightButton?: React.ReactNode;
  hideSubmit?: boolean;
  hideDiscard?: boolean;
  saveVariant?: ButtonProps["variant"];
  minHeight?: boolean;
  startIcon?: ButtonIcon;
  isSticky?: boolean;
  isForcedSticky?: boolean;
  autoSaveState?: AutoSaveState;
  performSaveActionOnFormSubmit?: boolean;
  autoSaveType?: string;
  customFooterBackgroundColor?: string;
}

const defaultProps: Partial<FormFooterProps> = {
  saveLabel: "SAVE AND CONTINUE",
  discardLabel: "SAVE AS DRAFT",
  discardIcon: undefined,
  disableSubmit: false,
  disableDiscard: false,
  saveIcon: undefined,
  secondaryLeftButton: undefined,
  secondaryRightButton: undefined,
  hideSubmit: false,
  hideDiscard: false,
  saveVariant: "contained",
  minHeight: false,
  startIcon: undefined,
  isSticky: false,
  isForcedSticky: false,
  performSaveActionOnFormSubmit: false,
};
export const FormFooterSection = (props: FormFooterProps) => {
  const theme = useTheme();

  const allProps = { ...defaultProps, ...props };

  const { isMobileView } = useScreenDetection();

  const {
    onSave,
    onCancel,
    saveLabel,
    discardLabel,
    discardIcon,
    saveIcon,
    disableSubmit,
    disableDiscard,
    secondaryLeftButton,
    secondaryRightButton,
    hideSubmit,
    hideDiscard,
    saveVariant,
    minHeight,
    startIcon,
    isSticky,
    isForcedSticky,
    autoSaveState,
    performSaveActionOnFormSubmit,
    autoSaveType,
    customFooterBackgroundColor,
  } = allProps;

  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const root = document.querySelector(".MuiBox-root")!;
    const height = (root as HTMLElement).offsetHeight;

    setHeight(height);
  }, []);

  const styles = {
    footerContainer: css`
      ${isSticky &&
      (height > 750 || isForcedSticky) &&
      !isMobileView &&
      `position: sticky; 
      bottom: 0; `}
      z-index: 999;
      margin: ${theme.spacing(0, -6)};
      display: flex;
      border-top: 1px solid ${alpha(theme.palette.text.primary, 0.05)};
      background-color: ${customFooterBackgroundColor ||
      theme.palette.secondary.light};
      justify-content: space-between;
      align-items: center;
      min-height: ${minHeight && (secondaryLeftButton || hideDiscard)
        ? theme.spacing(14)
        : ""};
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
      @media (max-width: ${breakpoints.values.sm}px) {
        background-color: ${theme.palette.common.white};
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
      }
    `,
    buttonGroup: css`
      @media (max-width: ${breakpoints.values.sm}px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: flex-start;
        width: 100%;
        margin: ${theme.spacing(1, 0)};
      }
      margin: ${theme.spacing(0, 6)};
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: ${theme.spacing(2)};
      background-color: ${theme.palette.secondary.light};
    `,
  };

  return (
    <Box css={styles.footerContainer} data-testid="form-footer">
      <Box css={styles.buttonGroup}>
        {!hideDiscard && (
          <Button
            testId="secondary-button"
            sx={{ backgroundColor: "common.white" }}
            fullWidth={isMobileView}
            label={discardLabel}
            variant="outlined"
            onClick={onCancel}
            disabled={disableDiscard}
            {...(discardIcon && { startIcon: discardIcon })}
          />
        )}
        {secondaryLeftButton && secondaryLeftButton}
      </Box>

      <Box
        css={css`
          flex: 1;
        `}
      />

      <Box css={styles.buttonGroup} justifyContent="flex-end">
        {autoSaveState?.hasAttemptedSave && (
          <Box sx={{ backgroundColor: "secondary.light" }}>
            <AutoSaveIndicator {...autoSaveState} autoSaveType={autoSaveType} />
          </Box>
        )}
        {secondaryRightButton && secondaryRightButton}
        {!hideSubmit && (
          <Button
            testId="primary-button"
            type={performSaveActionOnFormSubmit ? "submit" : "button"}
            fullWidth={isMobileView}
            label={saveLabel}
            variant={saveVariant}
            {...(saveIcon && { endIcon: saveIcon })}
            {...(startIcon && { startIcon })}
            onClick={onSave}
            disabled={disableSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

const AutoSaveIndicator = ({
  isLoading,
  error,
  lastSavedAt,
  autoSaveType,
}: AutoSaveState) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <LoadingSpinner
        color={theme.palette.text.lightGray}
        label={t("pages.formPage.autoSaveStatusDraft.saveInProgress", {
          type: autoSaveType ? "profile" : "draft",
        })}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      {error ? (
        <FancyIcon
          icon={SyncProblem}
          iconColor={theme.palette.error.main}
          labelColor={theme.palette.error.main}
          label={t("pages.formPage.autoSaveStatusDraft.saveError", {
            type: autoSaveType ? "Profile" : "Draft",
          })}
        />
      ) : (
        <FancyIcon
          icon={CheckIcon}
          iconColor={theme.palette.primary.lightGreen}
          labelColor={theme.palette.text.lightGray}
          label={t("pages.formPage.autoSaveStatusDraft.saveSuccess", {
            type: autoSaveType ? "Profile" : "Draft",
          })}
        />
      )}
      {lastSavedAt && (
        <Typography
          fontSize="14px"
          lineHeight={1}
          color={theme.palette.text.lightGray}
        >
          Last saved at {lastSavedAt.format("HH:mm")}
        </Typography>
      )}
    </Box>
  );
};
