import { InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import { defaultContextFields, hospital } from "../../shared";

export const h2Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  hospital: hospital,
  registeredNurseIn: string()
    .required("Please select the option which applies")
    .default(null),
});

export type H2Part1DataSchema = InferType<typeof h2Part1DataSchema>;

export type H2Data = [PartData<H2Part1DataSchema>];

export const exampleH2Data: ExampleFormData<H2Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      registeredNurseIn: "sub-part1-mental-health-nursing",
    },
  },
];
