import { breakpoints, typography } from "./themeCommon";
import { ThemeOptions } from "@mui/material";
import { CSSObject } from "@emotion/css";

const palette = {
  common: {
    black: "#000",
    white: "#fff",
    lightGray: "#E2E2E6",
    lightBlue: "#DCE7FA",
    background: "#F8F8F9",
    royalBlue: "#164A9D",
    paleGray: "#f8f8f8",
    paleGreen: "#F2FCF9",
    paleBlue: "#EDF3FC",
  },
  primary: {
    main: "#2857A4",
    hint: "#554455",
    lightGreen: "#02C07C",
  },
  secondary: {
    light: "#F6F9FD",
    main: "#8ABE23",
    darker: "#345b99",
    lightBlue: "#4e87e51a",
  },
  success: {
    main: "#008758",
  },
  error: {
    light: "#FEF6F4",
    main: "#C74418",
    darker: "#EC501C",
  },
  warning: {
    main: "#FDC910",
  },
  text: {
    primary: "#0B122C",
    secondary: "#424552",
    disabled: "#AEB0B8",
    hint: "#9A9CA1",
    border: "#CCCCCC",
    lightGray: "#7B7D89",
  },
  background: {
    default: "#FFFFFF",
    lightGray: "#FAFAFB",
    highlighted: "#F7B9A4",
    lightYellow: "#FFF3CA",
    horizontalGray: "#E7E8EA",
  },
  statuses: {
    teal: "#69C9A7",
    yellow: "#E8CE71",
  },
};

const components = {
  MuiInput: {
    styleOverrides: {
      underline: {
        "&:before, &:hover:not(.Mui-disabled):before": {
          borderBottom: `2px solid ${palette.text.hint}`,
        },
        "&.Mui-disabled:before": {
          borderBottom: `2px solid ${palette.text.disabled}`,
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      input: {
        padding: "1em",
        "&::placeholder": {
          fontStyle: "italic",
          color: palette.text.hint,
          opacity: 1,
        },
      },
      inputMultiline: {
        padding: "1em",
      },
      inputAdornedStart: {
        paddingLeft: ".3em",
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionStart: {
        marginRight: 0,
      },
    },
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: palette.text.border,
            transition: "border-color 0.3s ease-in-out",
          },
        },
      },
    },
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        whiteSpace: "break-spaces" as CSSObject["whiteSpace"],
      },
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        margin: 0,
        backgroundColor: palette.background.default,
      },
      option: {
        fontSize: "0.9em",
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        "&$checked": {
          color: palette.primary.main,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: "50px",
        fontSize: "0.815em",
        fontWeight: "600",
        letterSpacing: "0.12em",
        "&.Mui-disabled": {
          color: palette.text.disabled,
        },
        "&:hover": {
          backgroundColor: "#1E4F87",
          color: palette.common.white,
        },
      },
      outlined: {
        "&:hover": {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          "& .MuiButton-startIcon svg": {
            color: palette.common.white,
          },
        },
      },
      text: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      containedPrimary: {
        color: palette.background.default,

        "&:hover:active": {
          backgroundColor: palette.primary.main,
        },
        "&$disabled": {
          backgroundColor: palette.text.disabled,
          color: palette.common.white,
          "&& $startIcon": {
            color: palette.text.hint,
          },
          "&& $endIcon": {
            color: palette.text.hint,
          },
        },
      },
      containedSecondary: {
        backgroundColor: palette.error.main,

        "&:hover:active": {
          backgroundColor: palette.error.main,
        },
        "&$disabled": {
          backgroundColor: palette.text.disabled,
          color: palette.common.white,
          "&& $startIcon": {
            color: palette.text.hint,
          },
          "&& $endIcon": {
            color: palette.text.hint,
          },
        },
      },
      startIcon: {
        color: palette.text.primary,
      },
      endIcon: {
        color: palette.text.primary,
      },
      textPrimary: {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.main,
        },
        "&:hover:active": {
          color: palette.primary.main,
        },
        "&$disabled": {
          color: palette.text.disabled,
        },
      },
      textSecondary: {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.main,
        },
        "&:hover:active": {
          color: palette.primary.main,
        },
        "&$disabled": {
          color: palette.text.disabled,
        },
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: 0,
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      switchHeader: {
        marginTop: 0,
        backgroundColor: palette.primary.main,
      },
      daysHeader: {
        paddingBottom: ".5em",
        borderBottom: `1px solid ${palette.text.disabled}`,
      },
      iconButton: {
        backgroundColor: palette.primary.main,
      },
      dayLabel: {
        color: palette.common.black,
      },
    },
  },
  MuiPickersBasePicker: {
    styleOverrides: {
      pickerView: {
        justifyContent: "flex-start",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      hidden: {
        opacity: 1,
        color: palette.text.disabled,
      },
      daySelected: {
        color: palette.common.white,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 0,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      dense: {
        minHeight: "40px",
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: palette.text.disabled,
      },
      colorPrimary: {
        "&.Mui-checked": {
          color: palette.secondary.main,
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: palette.background.horizontalGray,
        ".Mui-checked.Mui-checked + &": {
          opacity: 0.7,
          backgroundColor: "#CDE7DF",
        },
      },
    },
  },
};

export const appTheme: ThemeOptions = {
  palette,
  components,
  breakpoints,
  typography,
};
