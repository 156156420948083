import React, { useContext, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { apiHooks } from "../../api";
import {
  Button,
  ButtonIcon,
  MenuFlyout,
  MenuOptionsType,
} from "~/components/design-system";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../routes";
import { LoggedInUserContext } from "../../Contexts";
import { breakpoints } from "../../styles/themeCommon";
import { v4 } from "uuid";
import { css } from "@emotion/react";
import { ExtendedOrganisation } from "@aspire/common/types/extendedOrganisation";

function OrganisationManagementRow({
  organisation,
}: {
  organisation: ExtendedOrganisation;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const options: MenuOptionsType[] = [
    {
      name: "Edit",
      onClick: () => {
        navigate(routeFns.organisationCreateEdit(organisation.id));
      },
      icon: "edit",
      disabled: false,
    },
  ];

  return (
    <TableRow key={organisation.id}>
      <TableCell>
        {organisation.role === "guest"
          ? "❓: "
          : organisation.role === "independent"
            ? "👤: "
            : "🏢: "}
        {organisation.name}
      </TableCell>
      <TableCell>{organisation.role}</TableCell>
      <TableCell>{organisation.teams.length}</TableCell>
      <TableCell>{organisation.childOrganisations.length}</TableCell>
      <TableCell>{organisation.orgsInThisDataController.length}</TableCell>
      <TableCell>
        <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
          <MenuFlyout
            cardRef={cardRef}
            options={options}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}
export function OrganisationManagementPage({}: {}) {
  const [teamFilterQuery, setTeamFilterQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const teamsPageSize = 10;
  const userContext = useContext(LoggedInUserContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [{ data: organisations, loading: loadingTeams }] =
    apiHooks.organisations.search(
      teamFilterQuery,
      teamsPageSize,
      page * teamsPageSize,
    );

  const numPages =
    organisations && organisations.count
      ? Math.ceil(organisations.count / teamsPageSize)
      : 1;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Filter organisations..."}
          sx={{ width: "100%" }}
          value={teamFilterQuery}
          onChange={(e) => {
            setTeamFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box
          css={css`
            @media (max-width: ${breakpoints.values.sm}px) {
              margin-left: ${theme.spacing(2)};
            }

            display: flex;
            margin-bottom: ${theme.spacing(2)};
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Button
            variant={"contained"}
            label={"Create"}
            endIcon={ButtonIcon.add}
            onClick={() => navigate(routeFns.organisationCreateEdit(v4()))}
          />
        </Box>
      </Box>
      {!organisations ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}># Teams</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}># Child Orgs</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    # In Data Contr.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organisations.results?.map((organisation) => (
                  <OrganisationManagementRow organisation={organisation} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
