import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";
import {
  M1Data,
  m1Part1DataSchema,
  M1Part1DataSchema,
} from "../schemas/mhaForms/miscellaneous/m1";
import { ReceptionScenario } from "../types/miscellaneousForm";

export const m1Part1Template: FormPartTemplate<M1Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["amhp"],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospitalOrLocalAuthority,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Name of patient",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "branch",
      field: "receptionScenario",
      label: "Confirm the correct option",
      branches: [
        {
          fieldValue: ReceptionScenario.AdmittedToHospital,
          fieldsOwned: ["admissionDateTime", "admissionHospital"],
          fields: [
            {
              type: "hospital-picker",
              field: "admissionHospital",
              label:
                "Enter name and address of hospital patient was admitted to",
            },
            {
              type: "date-time",
              field: "admissionDateTime",
              label: "Enter date and time patient was admitted to hospital",
              maximum: (today) => today.endOf("day"),
            },
          ],
          label: "Patient was admitted to hospital",
        },
        {
          fieldValue: "received-into-guardianship",
          fieldsOwned: ["guardianNameAndAddress", "guardianshipDate"],
          fields: [
            {
              type: "name-address",
              field: "guardianNameAndAddress",
              nameLabel:
                "Enter name of guardian patient was received into the guardianship of",
              addressLabel:
                "Enter address of guardian patient was received into the guardianship of",
            },
            {
              type: "date",
              field: "guardianshipDate",
              label:
                "Enter date the patient was received into the guardianship on",
              maximum: (today) => today.endOf("day"),
            },
          ],
          label: "Patient was received into guardianship",
        },
        {
          fieldValue: ReceptionScenario.CommunityPatient,
          fieldsOwned: ["dischargeIntoCommunityDate", "responsibleHospital"],
          fields: [
            {
              type: "hospital-picker",
              field: "responsibleHospital",
              label:
                "Enter name and address of the responsible hospital the patient became a community patient as if discharged from",
            },
            {
              type: "date",
              field: "dischargeIntoCommunityDate",
              label:
                "Enter date patient became a community patient as if discharged from the responsible hospital on",
              maximum: (today) => today.endOf("day"),
            },
          ],
          label:
            "Patient became a community patient as if discharged from the responsible hospital",
        },
      ],
    },

    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: m1Part1DataSchema,
};
export const m1V1Base: BaseFormTemplate = {
  id: "mha-m1",
  category: "miscellaneous",
  version: "1.0",
  status: "active",
  formName: "M1",
  section: "",
  parts: [m1Part1Template],
  description: "Part 6 - date of reception of a patient in England",
  extractGenericData: (data: M1Data) => ({}),
};
