import { string, boolean, object, InferType } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  hospital,
  nonEmptyRequiredString,
  optionalCheckbox,
} from "../../shared";

export const cto3Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    approvedClinicianRequired: true,
  }),
  hospital: hospital,
  selectedOption: string().default(null).required("Please select an option"),

  extensionConsiderationUnderSection20A: boolean()
    .default(false)
    .optional()
    .when("selectedOption", {
      is: (value: string) => value === "failed-compliance-with-section-17B",
      then: () => optionalCheckbox,
      otherwise: () => boolean().default(false).optional(),
    }),

  enablePart4ACertificate: boolean()
    .default(false)
    .optional()
    .when("selectedOption", {
      is: (value: string) => value === "failed-compliance-with-section-17B",
      then: () =>
        optionalCheckbox.test(
          "at-least-one-reason-selected",
          "Please select the option(s) which apply",
          (_, { parent }) => {
            return (
              parent.enablePart4ACertificate ||
              parent.extensionConsiderationUnderSection20A
            );
          },
        ),
      otherwise: () => boolean().default(false).optional(),
    }),

  groundsForOpinion: string()
    .default(null)
    .nullable()
    .optional()
    .when("selectedOption", {
      is: (value: string) =>
        value === "requires-hospital-treatment-for-mental-disorder",
      then: () =>
        nonEmptyRequiredString.required("Please enter your reasoning"),
      otherwise: () => string().default(null).nullable().optional(),
    }),
});

export type Cto3Part1DataSchema = InferType<typeof cto3Part1DataSchema>;

export type CTO3Data = [PartData<Cto3Part1DataSchema>];

export const exampleCTO3Data: ExampleFormData<CTO3Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      selectedOption: "requires-hospital-treatment-for-mental-disorder",
      extensionConsiderationUnderSection20A: true,
      enablePart4ACertificate: false,
      groundsForOpinion: "Reasons",
    },
  },
];
