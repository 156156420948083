import { getLatestVersion } from "@aspire/common/types/form";
import { apiHooks } from "../../../../api";
import { PopupDialog, PopupDialogTitle } from "~/components/design-system";
import { CircularProgress, DialogContent } from "@mui/material";
import { Box } from "@mui/material";
import {
  AuditTrailEntry,
  AuditTrailEntryType,
} from "@aspire/common/types/audit";
import { getBaseFormTemplate } from "@aspire/common/formTemplates";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import _ from "lodash";
import { entries } from "./Entries";
import { FormContextData } from "@aspire/common/types/formContextData";
import {
  generateMergeMessage,
  generateUnmergeMessage,
  generateUpdatePatientMessage,
} from "./PatientActivityLog";

function getSignedFormTemplate(
  formContextData: FormContextData,
  audit: AuditTrailEntry,
) {
  const signedForm = formContextData.forms.find(
    (f) => f.id === audit.data.formId,
  );
  const signedFormTemplate = getBaseFormTemplate(
    signedForm?.formTemplate.id!,
    signedForm?.formTemplate.version,
  );

  return { signedFormTemplate, signedForm };
}

function getFormTemplate(
  formContextData: FormContextData,
  audit: AuditTrailEntry,
) {
  const form = formContextData.forms.find((f) => f.id === audit.data.formId);
  const formTemplate = getBaseFormTemplate(
    form?.formTemplate.id!,
    form?.formTemplate.version,
  );

  return formTemplate;
}

function getMessage(
  audit: AuditTrailEntry,
  formContextData?: FormContextData | null,
): string | string[] | null {
  if (!formContextData) return null;
  const formTemplate = getFormTemplate(formContextData, audit);
  const formName = `Form ${formTemplate?.formName}: Section ${
    formTemplate?.section
  } ${formTemplate?.description && ` -`} ${formTemplate?.description}`;

  const oneFormPart = formTemplate?.parts?.length === 1;

  const partText = oneFormPart ? "" : ` part ${audit.data.formPart} of `;

  switch (audit.type) {
    case AuditTrailEntryType.adminDeleteWorkItem:
      const teamResource = audit.resources.find(
        (r) => r.resourceType === "team",
      );
      return `A Thalamos Admin deleted this from the caseload of ${teamResource?.resourceName}. This will have been done at the request of the organisation.`;

    case AuditTrailEntryType.mergePatient:
      return generateMergeMessage(audit);

    case AuditTrailEntryType.unmergePatient:
      return generateUnmergeMessage(audit);

    case AuditTrailEntryType.updatePatient:
      return generateUpdatePatientMessage(audit);

    case AuditTrailEntryType.finaliseWorkRequest:
      return "Marked administrative scrutiny as complete";

    case AuditTrailEntryType.createAmendRequest:
      return [
        `Requested that ${audit.data.assignedUserName} - ${audit.data.teamName} amend ${partText}${formName} under Section 15 of the Act.`,
        `Reason(s): ${audit.data.requestReasons.map((r: any) => r.label)}`,
        `Description: ${audit.data.requestAdditionalInfo}`,
      ];

    case AuditTrailEntryType.createFileNoteRequest:
      return [`File note added: ${audit.data.fileNote}`];

    case AuditTrailEntryType.deleteFileNoteRequest:
      return [
        `File note(s) deleted: ${audit.data?.deleteFileNote?.map(
          (n: string) => n,
        )}`,
      ];

    case AuditTrailEntryType.unclaimWorkRequest:
      return `${audit.userName} unclaimed this form`;

    case AuditTrailEntryType.claimWorkRequest:
      return `${audit.userName} claimed this form`;

    case AuditTrailEntryType.recordReasonForNotProceedingMedRec:
      return [
        `Action confirmed: no medical recommendation made`,
        `Reason: ${audit.data.reason}`,
        ...(audit.data.reasonDescription
          ? [`Description: ${audit.data.reasonDescription}`]
          : []),
      ];

    case AuditTrailEntryType.recordReasonForNotProceedingNoApp:
      return [
        `Action confirmed: no application made`,
        `Reason: ${audit.data.reason}`,
        ...(audit.data.reasonDescription
          ? [`Description: ${audit.data.reasonDescription}`]
          : []),
      ];

    case AuditTrailEntryType.reopenAdmission:
      return [
        `Action confirmed: Admission is reopened`,
        `Reason: ${audit.data.reason}`,
        ...(audit.data.reasonDescription
          ? [`Description: ${audit.data.reasonDescription}`]
          : []),
      ];

    case AuditTrailEntryType.declineWorkRequest: {
      const isAmend = audit.data.requestType === "amend";
      const formId = audit.resources.find(
        (f) => f.resourceType === "form",
      )?.resourceId;
      const form = formContextData.forms.find((f) => f.id === formId);
      const template = form && getBaseFormTemplate(form);
      const formDescription = template
        ? `Form ${template?.formName}: Section ${template?.section}` +
          `${template?.description && ` -`} ${template?.description}`
        : "a form";
      return [
        isAmend
          ? `Declined to amend ${formDescription} under Section 15 of the Act. `
          : "Declined to work on this assessment",
        `Reason: ${audit.data.reason}`,
        ...(audit.data.reasonDescription
          ? [`Description: ${audit.data.reasonDescription}`]
          : []),
      ];
    }

    case AuditTrailEntryType.acceptWorkRequest: {
      if (audit.data.requestType === "amend") {
        return `Accepted request to amend ${partText}${formName} under Section 15 of the Act. `;
      } else {
        return `Accepted the request to work on this ${
          formContextData.type === "admission" ? "admission" : "form"
        }`;
      }
    }

    case AuditTrailEntryType.expireAmendRequest: {
      const expiryPeriod = audit.data.expiryPeriod;
      const formattedExpiryPeriod = `${expiryPeriod.value}-${expiryPeriod.unit}`
        .replace("minutes", "minute")
        .replace("hours", "hour")
        .replace("days", "day");
      return `The ${formattedExpiryPeriod} rectification period has expired. Form can no longer be amended.`;
    }

    case AuditTrailEntryType.expireForm:
      return `Section 4 has expired. Part 2 of Form H3 was not completed within the 72 hour period.`;

    case AuditTrailEntryType.createFormContext:
      return `Started this ${
        formContextData.type === "admission" ? "admission" : "form"
      }`;

    case AuditTrailEntryType.createForm:
      // This is a redundant thing to show for a standalone form
      if (formContextData.type === "standalone") {
        return null;
      } else {
        const formId = audit.resources.find(
          (f) => f.resourceType === "form",
        )!.resourceId;
        const form = formContextData.forms.find((f) => f.id === formId);
        const template = form && getBaseFormTemplate(form);

        return `Started a Form ${template?.formName}: Section ${
          template?.section
        }${template?.description && `-`} ${template?.description}`;
      }

    case AuditTrailEntryType.uploadForm:
      // This is a redundant thing to show for a standalone form
      if (formContextData.type === "standalone") {
        return null;
      } else {
        const formId = audit.resources.find(
          (f) => f.resourceType === "form",
        )!.resourceId;
        const form = formContextData.forms.find((f) => f.id === formId);
        const template = form && getBaseFormTemplate(form);

        return `Uploaded a Form ${template?.formName}: Section ${
          template?.section
        }${template?.description && `-`} ${template?.description}`;
      }

    case AuditTrailEntryType.signForm:
      // if audit is greater than one then its an amend
      const formPart = audit.data.part;
      if (audit.data.formVersion > 1) {
        return `Amended ${
          oneFormPart ? "" : ` part ${formPart} of `
        }${formName} under Section 15 of the Act.`;
      }

      if (formTemplate?.parts.length === 1) {
        return `Signed ${formName}`;
      }

      return `Signed part ${formPart} of a ${formName}`;

    case AuditTrailEntryType.createFormPushEvent: {
      const formId = audit.resources.find(
        (f) => f.resourceType === "form",
      )!.resourceId;
      const form = formContextData.forms.find((f) => f.id === formId);
      const template = form && getBaseFormTemplate(form);

      const externalSystemType =
        audit.resources.find((f) => f.resourceType === "rioInstance") !==
        undefined
          ? "Rio"
          : "External System";

      const formName = [
        template?.formName && `${template?.formName}`,
        template?.section && `: Section ${template?.section}`,
        template?.description && ` - ${template?.description}`,
      ]
        .filter((x) => x !== undefined)
        .join(" ");

      return `Form ${formName} uploaded to ${externalSystemType}`;
    }

    case AuditTrailEntryType.createWorkRequest:
      switch (audit.data.requestType) {
        case "medical-recommendation":
          return `Requested a medical recommendation from ${audit.data.teamName}`;
        case "admission":
          return `Requested an admission from ${audit.data.teamName}`;
        case "record-of-detention":
          return `Requested a record of detention form (H3) from ${audit.data.teamName}`;
        case "finalise":
          return `Requested administrative scrutiny from ${audit.data.teamName}`;
        case "complete":
          const requestedForm = formContextData.forms.find(
            (f) => f.id === audit.data.formId,
          );
          const requestedFormTemplate = getBaseFormTemplate(
            requestedForm?.formTemplate.id!,
            requestedForm?.formTemplate.version,
          );

          const requestedParty = audit.data.assignedUserName
            ? `${audit.data.assignedUserName} (${audit.data.teamName})`
            : audit.data.teamName;

          const { signedFormTemplate, signedForm } = getSignedFormTemplate(
            formContextData,
            audit,
          );

          const latestVersion = getLatestVersion(signedForm);

          const signatureWithMaxPart = _.maxBy(
            latestVersion?.signatures,
            "part",
          );

          let partText;

          if (signedFormTemplate?.parts.length === 1) {
            partText = "";
          }

          const signedFormTemplateLength =
            signedFormTemplate?.parts?.length ?? 0;

          const signatureWithMaxParts = signatureWithMaxPart?.part ?? 0;

          if (signedFormTemplateLength > 1 && signatureWithMaxParts > 0) {
            partText = ` part ${audit.data.formPart} of`;
          }

          const descriptionText = requestedFormTemplate?.description
            ? ` - ${requestedFormTemplate.description}`
            : "";

          return `Requested that ${requestedParty} sign${partText ?? ""} Form ${
            requestedFormTemplate?.formName
          }: Section ${requestedFormTemplate?.section}${descriptionText}`;
      }

      if (audit.data.assignedUserId === audit.userId) {
        return null;
      }

      const formattedUserNameOrTeam = audit.data.assignedUserName
        ? `${audit.data.assignedUserName ?? ""} (${audit.data.teamName})`
        : audit.data.teamName;

      return `Sent a request to ${formattedUserNameOrTeam} to work on this ${
        formContextData.type === "admission" ? "admission" : "form"
      }`;

    default:
      return null;
  }
}

export function FormContextActivityLog({
  onClose,
  formContext,
  user,
}: {
  formContext: FormContextData;
  user: ExtendedThalamosUser;
  onClose: () => void;
}) {
  const auditQueryParams = {
    limit: 1000,
    offset: 0,
    fromDate: "2000-01-01",
    toDate: "2050-01-01",
  };

  const [
    { data: auditData, loading: auditDataLoading, response: auditDataResponse },
  ] = apiHooks.audit.searchByFormContextId(formContext.id, auditQueryParams);

  const [
    {
      data: patientAuditData,
      loading: patientAuditDataLoading,
      response: patientAuditDataResponse,
    },
  ] = apiHooks.audit.searchByPatientId(formContext.patientId, auditQueryParams);

  const baseFormTemplate =
    formContext.type === "standalone"
      ? getBaseFormTemplate(formContext.forms[0])
      : null;

  const loaded =
    !auditDataLoading &&
    !patientAuditDataLoading &&
    auditDataResponse?.status === 200 &&
    patientAuditDataResponse?.status === 200;

  return (
    <PopupDialog open={true} onClose={onClose} fullWidth>
      <PopupDialogTitle
        titleText={`Activity Log for
          ${
            formContext.type === "admission"
              ? "Admission"
              : `Form ${baseFormTemplate!.formName}: Section ${
                  baseFormTemplate!.section
                }${baseFormTemplate!.description && `-`} ${
                  baseFormTemplate!.description
                }`
          }`}
        closeDialog={onClose}
      />
      <DialogContent>
        {!loaded ? (
          <CircularProgress />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {entries(
              user,
              [...auditData!.entries, ...patientAuditData!.entries],
              getMessage,
              formContext,
            )}
          </Box>
        )}
      </DialogContent>
    </PopupDialog>
  );
}
