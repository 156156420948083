import {
  CTO7Data,
  cto7Part1DataSchema,
  Cto7Part1DataSchema,
  cto7Part2DataSchema,
  Cto7Part2DataSchema,
  cto7Part3DataSchema,
  Cto7Part3DataSchema,
  Cto7Part4DataSchema,
  cto7Part4DataSchema,
} from "../schemas/mhaForms/cto/cto7";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto7Part1Template: FormPartTemplate<Cto7Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },

    {
      type: "date",
      label:
        "Enter the date the patient's current community treatment order was made on",
      field: "dateCTOWasMadeOn",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "date",
      label: "Enter the date you examined the patient on",
      field: "lastExaminedDate",
      maximum: (today) => today.endOf("day"),
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment.",
      ],
    },

    {
      type: "multi-select",
      label:
        "(b) It is necessary that this patient should receive treatment for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient's health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient's safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },

    {
      type: "label",
      label: [
        "(c) In my opinion, such treatment can be provided without the patient continuing to be detained in a hospital provided the patient is liable to being recalled to hospital for medical treatment.",
      ],
      showStaticLabel: true,
    },
    {
      type: "label",
      label: [
        "(d) In my opinion, it is necessary that the responsible clinician should continue to be able to exercise the power under section 17E(1) to recall the patient to hospital.",
      ],
      showStaticLabel: true,
    },
    {
      type: "label",
      label: [
        "(e) In my opinion, taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient.",
      ],
      showStaticLabel: true,
    },

    {
      type: "textbox",
      field: "groundsForOpinion",
      label: "Please enter the grounds your opinion is founded on",
      rows: "5",
    },

    {
      type: "label",
      label: [
        "I confirm that in determining whether the criterion at (d) above is met, I have considered what risk there would be of deterioration of the patient’s condition if the patient were to continue not to be detained in hospital, with regard to the patient’s history of mental disorder and any other relevant factors. ",
      ],
      showStaticLabel: true,
    },
  ],
  dataSchema: cto7Part1DataSchema,
};

export const cto7Part2Template: FormPartTemplate<Cto7Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["amhp"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedMentalHealthProfessional,
    },
  },
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address  ",
      field: "user",
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    { type: "label", showStaticLabel: true, label: ["I agree that:"] },
    {
      type: "label",
      indent: 3.5,
      showStaticLabel: true,
      label: [
        "(i) the patient meets the criteria for the extension of the community treatment period and",
        "(ii) it is appropriate to extend the community treatment period",
      ],
    },
  ],
  dataSchema: cto7Part2DataSchema,
};

export const cto7Part3Template: FormPartTemplate<Cto7Part3DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "textbox",
      field: "consultedPersonName",
      label:
        "Enter name of the person who has been professionally concerned with the patient's treatment who you have consulted before furnishing this report",
    },

    {
      type: "textbox",
      field: "consultedPersonProfession",
      label: "Enter profession of the person you have consulted",
    },

    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "I am furnishing this report by",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: [],
          fields: [],
          label:
            "today consigning it to the hospital managers’ internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: [],
          fields: [],
          label:
            "sending or delivering it without using the hospital managers’ internal mail system",
        },
      ],
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: cto7Part3DataSchema,
  autoAssignToPartSignatory: 1,
};

export const cto7Part4Template: FormPartTemplate<Cto7Part4DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "branch",
      field: "reportFurnishedBy",
      label: "This report was",
      warningBanner: {
        condition: (data, context) => {
          return (
            !!data.reportFurnishedBy &&
            data.reportFurnishedBy !== "electronic-communication"
          );
        },
        message:
          "The second option should be selected if you are sending this report by means of electronic communication (e.g. Thalamos). Please check if you have selected the correct option.",
      },
      branches: [
        {
          fieldValue: "internal-mail-system",
          fieldsOwned: [],
          fields: [],
          label:
            "furnished to the hospital managers through their internal mail system",
        },
        {
          fieldValue: "electronic-communication",
          fieldsOwned: [],
          fields: [],
          label:
            "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
        },
        {
          fieldValue: "in-person",
          fieldsOwned: ["reportReceivedDate"],
          fields: [
            {
              type: "date",
              field: "reportReceivedDate",
              label: "Enter the date this report was received on",
              maximum: (today) => today,
            },
          ],
          label: "received by me on behalf of the hospital managers on",
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: cto7Part4DataSchema,
};

export const cto7V1Base: BaseFormTemplate = {
  id: "mha-cto7",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO7",
  section: "20A",
  parts: [
    cto7Part1Template,
    cto7Part2Template,
    cto7Part3Template,
    cto7Part4Template,
  ],
  description:
    "Community treatment order: report extending the community treatment period",
  extractGenericData: (data: CTO7Data) => ({}),
};
