import React, { useState, useRef } from "react";
import { Box, tableCellClasses, useTheme } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { api, apiHooks } from "../../../api";
import {
  Button,
  ButtonIcon,
  FormTitle,
  LoadingSpinner,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
} from "~/components/design-system";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../../routes";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import dayjs from "dayjs";
import { ConfirmationModal } from "../../ConfirmationModal";
import { HorizontalLine } from "~/components/form/HorizontalLine";
import { Container } from "~/components/layout/styleWrappers";
import { getRoleLabel } from "~/util";
import { LoggedInUserContext } from "~/Contexts";

function UserManagementRow({
  user,
  refetchUsers,
  index,
}: {
  user: ExtendedThalamosUser;
  refetchUsers: () => {};
  index: number;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [modalData, setModalData] = useState<string[] | null>(null);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const [showAllMemberships, setShowAllMemberships] = useState(false);

  const theme = useTheme();

  const options: MenuOptionsType[] = [
    {
      name: "Edit Profile",
      onClick: () => {
        navigate(routeFns.userProfilePage(user.id));
      },
      icon: "edit",
      disabled: false,
    },
    ...(user.mfa === "disabled"
      ? [
          {
            name: "Enforce MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to enforce MFA for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.enforceMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([`Successfully enforced MFA ${user.email}`]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),

    ...(user.mfa === "enabled"
      ? [
          {
            name: "Remove MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to remove MFA for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.disableMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([
                      `Successfully disabled MFA for ${user.email}`,
                    ]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
          {
            name: "Reset MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to reset MFA (but keep it enabled) for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.resetMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([
                      `Successfully reset MFA for ${user.email}. They will be able to set it up again next time they log in`,
                    ]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),
    {
      name: "Send password reset email",
      onClick: async () => {
        const result = await api.users.triggerPasswordResetEmail(user.id);
        if (result.status === 204) {
          setModalData([
            `Successfully sent password reset email to ${user.email}`,
          ]);
        } else {
          setModalData(["Something went wrong. Please try again later."]);
        }
        setIsMenuOpen(false);
      },
      icon: "transfer",
      disabled: false,
    },
  ];

  return (
    <>
      <TableRow
        key={user.id}
        sx={{
          backgroundColor:
            index % 2 === 0 ? theme.palette.common.paleBlue : "white",
        }}
      >
        {confirmFn && (
          <ConfirmationModal
            message={confirmFn.message}
            confirmFn={confirmFn.confirmFn}
            closeFn={() => setConfirmFn(null)}
          />
        )}
        <PopupDialog
          open={modalData !== null}
          onClose={() => setModalData(null)}
        >
          <Box sx={{ padding: "2em" }}>
            {modalData?.map((s) => <p>{s}</p>)}
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button label={"Close"} onClick={() => setModalData(null)} />
            </Box>
          </Box>
        </PopupDialog>
        <TableCell>{user.name}</TableCell>
        <TableCell>
          {user.email} {user.mfa === "enabled" ? "(MFA)" : ""}
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.memberships.length === 0 ? (
              "No Memberships! This user will get an error on logging in"
            ) : (
              <>
                {user.memberships.slice(0, 2).map((m, index) => (
                  <span key={index}>
                    {`${m.type === "team" ? m.teamName : m.organisationName} (${getRoleLabel(m.role)})`}
                    {index !== user.memberships.length - 1 && ", "}
                  </span>
                ))}
                {user.memberships.length > 2 && (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowAllMemberships(true)}
                    >
                      + {user.memberships.length - 2} more
                    </span>
                    {showAllMemberships && (
                      <>
                        {user.memberships.slice(2).map((m, index) => (
                          <span key={index + 2}>
                            {`${m.type === "team" ? m.teamName : m.organisationName} (${getRoleLabel(m.role)})`}
                            {index !== user.memberships.length - 1 && ", "}
                          </span>
                        ))}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowAllMemberships(false)}
                        >
                          (show less)
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.loginCount > 0 ? (
              <>
                <span>{user.loginCount} logins</span>
                <span>
                  (
                  {`${dayjs(user.lastLoginTimestamp).format(
                    "DD/MM/YYYY HH:mm",
                  )}`}
                  )
                </span>
              </>
            ) : (
              "-"
            )}{" "}
            {user.failedGuestUserLoginCount > 0
              ? `(${user.failedGuestUserLoginCount} failed!)`
              : ""}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.accreditations.map((m) => {
              return <span>{`${m.type}`}</span>;
            })}
          </Box>
        </TableCell>
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
export function UserOverviewPage({}: {}) {
  const [userFilterQuery, setUserFilterQuery] = useState("");
  const userContext = React.useContext(LoggedInUserContext);

  const user = userContext?.user;
  const [page, setPage] = useState(0);
  const userPageSize = 10;

  const [{ data: users, loading: loadingUsers }, refetchUsers] =
    apiHooks.users.search(userFilterQuery, userPageSize, page * userPageSize);

  const numPages =
    users && users.count ? Math.ceil(users.count / userPageSize) : 1;

  const navigate = useNavigate();
  const theme = useTheme();

  const isTeamManager =
    user?.sessionContext?.type === "team" &&
    user?.sessionContext?.role === "manager";

  return (
    <Container>
      <Box sx={{ p: theme.spacing(4, 4, 4, 2) }}>
        <FormTitle
          titleText="User Overview"
          hasTitleBottomMargin={false}
          hasContainerMarginBottom={false}
          useReducedTopPadding={true}
        />
      </Box>
      <HorizontalLine />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <TextField
          placeholder={"Search keyword"}
          sx={{
            width: "241px",
          }}
          value={userFilterQuery}
          onChange={(e) => {
            setUserFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box>
          {!isTeamManager && (
            <Button
              sx={{ marginRight: "0.5em" }}
              variant={"contained"}
              label={"Create User"}
              endIcon={ButtonIcon.add}
              onClick={() => navigate(routeFns.adminCreateUsersPage())}
            />
          )}
        </Box>
      </Box>
      {!users ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <Box>
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Org / Team Memberships
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Usage</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Accreditations</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.results?.map((user, index) => (
                  <UserManagementRow
                    user={user}
                    refetchUsers={refetchUsers}
                    index={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              my: 4,
            }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
        </Box>
      )}
    </Container>
  );
}
