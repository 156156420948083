import { InferType, object, string, boolean } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  hospital,
  isoDateTimeString,
  nameAndAddress,
  nonEmptyRequiredString,
  generateDateBeforeTodayValidationRule,
  defaultAddress,
} from "../../shared";

const SECTION_VALUES = {
  ADMISSION_ASSESSMENT: "admissionForAssessment",
  ADMISSION_TREATMENT: "admissionForTreatment",
  CASES_EMERGENCY: "casesOfEmergency",
  GUARDIANSHIP_APPLICATION: "applicationForGuardianship",
  COMMUNITY_TREATMENT_ORDERS: "communityTreatmentOrders",
  POWER_OF_COURTS: "powerOfCourts",
};

const DISCHARGE_ORDER_VALUES = {
  RESPONSIBLE_CLINICIAN: "responsibleClinician",
  NEAREST_RELATIVE: "nearestRelative",
  HOSPITAL_MANAGER: "hospitalManager",
  RESPONSIBLE_LOCAL_SOCIAL_SERVICE_AUTHORITY:
    "responsibleLocalSocialServiceAuthority",
};

export const s23Part1DataSchema = object({
  stateSectionPatient: boolean().default(true),
  orderForDischarge: boolean().default(true),
  updateOrderForDischarge: string()
    .default(null)
    .oneOf([
      DISCHARGE_ORDER_VALUES.RESPONSIBLE_CLINICIAN,
      DISCHARGE_ORDER_VALUES.NEAREST_RELATIVE,
      DISCHARGE_ORDER_VALUES.HOSPITAL_MANAGER,
      DISCHARGE_ORDER_VALUES.RESPONSIBLE_LOCAL_SOCIAL_SERVICE_AUTHORITY,
    ])
    .required("Please confirm who made the order for discharge"),
  updateOrderForDischargeText: string(),
  updateNearestRelativeAddress: nameAndAddress().when(
    "updateOrderForDischarge",
    {
      is: (value: string) => value === DISCHARGE_ORDER_VALUES.NEAREST_RELATIVE,
      then: (addressSchema) =>
        addressSchema.required(
          "Name and address of the nearest relative who is ordering the discharge",
        ),
      otherwise: () => nameAndAddress(),
    },
  ),
  orderInWriting: boolean()
    .default(null)
    .when("updateOrderForDischarge", {
      is: (value: string) => value === DISCHARGE_ORDER_VALUES.NEAREST_RELATIVE,
      then: () =>
        boolean().required(
          "Please confirm if this order has been made in writing",
        ),
      otherwise: () => boolean().default(null).nullable(),
    }),
  updateStateSectionPatient: string()
    .default(null)
    .oneOf([
      SECTION_VALUES.ADMISSION_ASSESSMENT,
      SECTION_VALUES.ADMISSION_TREATMENT,
      SECTION_VALUES.CASES_EMERGENCY,
      SECTION_VALUES.GUARDIANSHIP_APPLICATION,
      SECTION_VALUES.COMMUNITY_TREATMENT_ORDERS,
      SECTION_VALUES.POWER_OF_COURTS,
    ])
    .required("Please state section patient is subject to"),
  updateStateSectionPatientText: string(),
  hospital: object()
    .default(defaultAddress)
    .when("updateStateSectionPatient", {
      is: (value: string) => {
        return (
          value === SECTION_VALUES.ADMISSION_ASSESSMENT ||
          value === SECTION_VALUES.ADMISSION_TREATMENT ||
          value === SECTION_VALUES.CASES_EMERGENCY ||
          value === SECTION_VALUES.COMMUNITY_TREATMENT_ORDERS ||
          value === SECTION_VALUES.POWER_OF_COURTS
        );
      },
      then: () => hospital,
      otherwise: () => object().default(defaultAddress).optional(),
    }),
  amActingOnBehalfOfApprovingAuthority: boolean().default(true),
  nameOfLocalAuthorityActingOnBehalfOf: nonEmptyRequiredString.when(
    "updateStateSectionPatient",
    {
      is: (value: string) => value === "applicationForGuardianship",
      then: (nameSchema) =>
        nameSchema.required(
          "Please enter name of local social service authority",
        ),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
  remainingInHospitalInformalPatient: boolean()
    .nullable()
    .default(null)
    .when("updateStateSectionPatient", {
      is: (value: string) =>
        value === SECTION_VALUES.ADMISSION_ASSESSMENT ||
        value === SECTION_VALUES.ADMISSION_TREATMENT ||
        value === SECTION_VALUES.CASES_EMERGENCY ||
        value === SECTION_VALUES.POWER_OF_COURTS,
      then: () => boolean().required("Please select an option"),
      otherwise: () => boolean().nullable().default(null).optional(),
    }),
  dischargeFromDetention: isoDateTimeString.when("updateStateSectionPatient", {
    is: (value: string) =>
      value === SECTION_VALUES.ADMISSION_ASSESSMENT ||
      value === SECTION_VALUES.ADMISSION_TREATMENT ||
      value === SECTION_VALUES.CASES_EMERGENCY ||
      value === SECTION_VALUES.GUARDIANSHIP_APPLICATION ||
      value === SECTION_VALUES.COMMUNITY_TREATMENT_ORDERS ||
      value === SECTION_VALUES.POWER_OF_COURTS,
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date-time",
        "User cannot enter a date in the future.",
      ).required("Please enter date and time of discharge"),
    otherwise: () => string().optional(),
  }),
  ...defaultContextFields({ approvedClinicianRequired: true }),
});

export type S23Part1DataSchema = InferType<typeof s23Part1DataSchema>;

export type S23Data = [PartData<S23Part1DataSchema>];

export const exampleS23Data: ExampleFormData<S23Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      orderForDischarge: true,
      updateNearestRelativeAddress: {
        name: "Nearest Relative",
        address: "Nearest Relative Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      orderInWriting: true,
      updateOrderForDischarge: DISCHARGE_ORDER_VALUES.RESPONSIBLE_CLINICIAN,
      updateOrderForDischargeText: "Responsible Clinician",
      updateStateSectionPatient: SECTION_VALUES.ADMISSION_ASSESSMENT,
      updateStateSectionPatientText: "Admission For Assessment",
      stateSectionPatient: true,
      amActingOnBehalfOfApprovingAuthority: true,
      nameOfLocalAuthorityActingOnBehalfOf: "Another LA",
      remainingInHospitalInformalPatient: false,
      dischargeFromDetention: "2001-01-01",
    },
  },
];
