import { InferType, object, string } from "yup";
import {
  defaultContextFields,
  hospital,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const h4Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your print name",
  }),
  hospital: hospital,
  hospitalDetained: hospital,
});

export const h4Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    nameErrorMessage: "Please enter your print name",
  }),
  hospital: object({
    name: string().trim().required("Please enter hospital name"),
  })
    .nullable()
    .default({ name: "" }),
  admissionDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter a date and time"),
});

export type H4Part1DataSchema = InferType<typeof h4Part1DataSchema>;
export type H4Part2DataSchema = InferType<typeof h4Part2DataSchema>;

export type H4Data = [
  PartData<H4Part1DataSchema>,
  PartData<H4Part2DataSchema>?,
];

export const exampleH4Data: ExampleFormData<H4Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      hospitalDetained: {
        name: "Hospital",
        address: "Hospital Address 2",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
    },
  },
  {
    data: {
      user: {
        name: "Consulted Clinician",
        email: "sarah@clinician.com",
        address: "Consulted Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: { name: "Hospital" },
      admissionDateTime: "2022-01-10T12:00:00.000Z",
    },
  },
];
