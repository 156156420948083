import React, { useContext, useEffect, useState } from "react";
import { api } from "../api";
import { styled } from "@mui/material/styles";
import { ExtendedThalamosNotification } from "@aspire/common/types/thalamosNotification";
import { ArrowBack, ArrowForward, Circle } from "@mui/icons-material";
import { DefaultPageProps } from "./defaultProps";
import { getBaseFormTemplate } from "@aspire/common/formTemplates";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../routes";
import {
  Avatar,
  Box,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { NotificationsContext } from "../Contexts";
import { FormTitle } from "~/components/design-system";
import { stringAvatar } from "~/components/layout/menu/helpers";
import { HorizontalLine } from "~/components/form";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

export const NotificationsContainer = styled(
  "div",
  {},
)<any>(({ theme, addMarginBottom, wasViewed }) => ({
  padding: theme.spacing(3),
  width: "100%",
  ...(wasViewed && { backgroundColor: theme.palette.secondary.light }),
  border: `0.5px solid ${theme.palette.background.container}`,
  borderRadius: "6px",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0px 4px 4px rgba(84, 89, 107, 0.25)",
  },
  ...(addMarginBottom && { marginBottom: theme.spacing(4) }),
}));

export function NotificationsPageInner({
  notifications,
  notificationsPage,
  setNotificationsPage,
  numberNotifications,
}: {
  numberNotifications: number;
  notifications: ExtendedThalamosNotification[];
  notificationsPage: number;
  setNotificationsPage: (page: number) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  function markAllAsViewed() {
    api.notifications.markAsViewed(
      notifications.filter((n) => !n.viewed).map((n) => n.id),
    );
  }

  const [wasViewed, setWasViewed] = React.useState(
    notifications.filter((n) => !n.viewed).map((n) => n.id),
  );

  useEffect(markAllAsViewed, [notifications]);

  const numPages = Math.floor(numberNotifications / 100) + 1;

  return notifications.length > 0 ? (
    <Box>
      <FormTitle
        titleText="Notifications"
        hasTitleBottomMargin={false}
        useReducedTopPadding
      />
      {notifications.map((notification) => {
        const form = notification.forms.find(
          (f) => f.id === notification?.workItem?.formId,
        );
        const template =
          form &&
          getBaseFormTemplate(form.formTemplate.id, form.formTemplate.version);

        const hasBeenViewed = wasViewed.includes(notification.id);

        return (
          <Box
            key={notification.id}
            sx={{ mb: 3 }}
            onClick={() => {
              if (notification.formContextId) {
                navigate(
                  routeFns.formContextPage(
                    notification.formContextId,
                    notification.patient.id!,
                  ),
                );
              }
            }}
          >
            <NotificationsContainer wasViewed={hasBeenViewed}>
              <Stack direction="row">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={{ sm: "flex-start", md: "center" }}
                >
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      width: 30,
                      height: 30,
                      fontWeight: 700,
                      fontSize: 15,
                    }}
                    {...stringAvatar(notification.createdByTeamName)}
                  />
                  <Typography>
                    {notification.text} (
                    {notification.formContextType === "admission"
                      ? "Admission"
                      : template
                        ? `${template.formName}`
                        : ""}
                    )
                  </Typography>

                  <Typography
                    sx={{
                      color: "text.lightGray",
                      fontWeight: "400",
                    }}
                  >
                    {dayjs.utc(notification.createdAt).fromNow()}
                  </Typography>
                </Stack>
                {hasBeenViewed && (
                  <Box
                    css={css`
                      margin-left: auto;
                    `}
                  >
                    <Circle
                      style={{ fontSize: 12 }}
                      sx={{ color: "primary.main" }}
                    />
                  </Box>
                )}
              </Stack>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 5.8 }}>
                <Box sx={{ my: 1.2 }}>
                  <HorizontalLine noMargin />
                </Box>
                <Typography
                  sx={{
                    fontSize: 13,
                    color: "text.lightGray",
                    fontWeight: 400,
                  }}
                >
                  {t("pages.notifications.patientLabel")}
                </Typography>
                <Typography>
                  {notification.patient.name &&
                    `${notification.patient.name.family}, ${notification.patient.name.given}`}
                </Typography>
              </Box>
            </NotificationsContainer>
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          mb: 1,
        }}
      >
        <Pagination
          sx={{
            ul: {
              "& .MuiPaginationItem-root.MuiPaginationItem-root.Mui-selected": {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            },
          }}
          count={numPages}
          page={page + 1}
          onChange={(event: any, value: any) => {
            setPage(value - 1);
          }}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: ArrowBack,
                next: ArrowForward,
              }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  ) : (
    <>No Notifications Yet</>
  );
}

export function NotificationsPage({}: DefaultPageProps) {
  const { notifications, notificationsPage, setNotificationsPage } =
    useContext(NotificationsContext);

  return notifications ? (
    <NotificationsPageInner
      notifications={notifications?.notifications}
      numberNotifications={notifications?.count}
      notificationsPage={notificationsPage}
      setNotificationsPage={setNotificationsPage}
    />
  ) : (
    <></>
  );
}
