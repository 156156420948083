import { ExampleFormData, PartData } from "../../types/form";
import { boolean, InferType, object, string } from "yup";
import { AcdPart1DataSchema } from "./acdPart1DataSchema";
import {
  confirmCheckbox,
  defaultContextFields,
  isoDateString,
  nonEmptyString,
} from "../shared";
import { requiredStringWhen } from "./util";

export const acdPart2DataSchema = object({
  ...defaultContextFields(),

  ourMentalHealthTreatmentAndCareRecommendations: nonEmptyString.required(
    "Please confirm recommendations",
  ),

  showUpdateDocumentDate: boolean().default(true),
  updateDocumentDate: string()
    .default(null)
    .oneOf(["6-months", "1-year", "3-years", "other"])
    .required("Please confirm next update date"),

  updateDocumentDateText: string(),

  updateDocumentOtherDate: isoDateString.when("updateDocumentDate", {
    is: (val: string) => val === "other",
    then: () => isoDateString.required("Please confirm next update date"),
    otherwise: () => string().nullable().optional(),
  }),
});

export type AcdPart2DataSchema = InferType<typeof acdPart2DataSchema>;

export const acdPart3DataSchema = object({
  ...defaultContextFields(),

  assessmentOfCapacityToMakeAdvanceChoicesAboutTreatmentAndCare:
    nonEmptyString.required(
      "Please confirm assessment of capacity to make advance choices about refusing treatments",
    ),

  doesThePersonHaveCapacityToMakeAdvanceChoicesAboutTreatmentAndCare: string()
    .default(null)
    .oneOf(["yes", "no", "unsure"])
    .required(
      "Please confirm if person has capacity to make Advance Choices about treatment and care",
    ),

  documentYourCapacity: string()
    .nullable()
    .default(null)
    .when(
      "doesThePersonHaveCapacityToMakeAdvanceChoicesAboutTreatmentAndCare",
      {
        is: (val: string) => val === "no" || val === "unsure",
        then: () =>
          // @ts-ignore
          nonEmptyString.required(
            "Please document your capacity assessment and the conclusion you reached",
          ),
        otherwise: () => string().nullable(),
      },
    ),

  occupation: nonEmptyString.required("Please confirm occupation"),

  capacityAssessmentDate: isoDateString.required(
    "Please confirm date of assessment",
  ),
});

export const acdPart4DataSchema = object({
  ...defaultContextFields(),

  summaryOfOtherConsultationsSelected: boolean().default(true),
  summaryOfOtherConsultations: requiredStringWhen(
    "summaryOfOtherConsultationsSelected",
    "Please confirm summary of other consultations",
  ),

  additionalCommentsSelected: boolean().default(true),
  additionalComments: requiredStringWhen(
    "additionalCommentsSelected",
    "Please confirm additional comments",
  ),
});

export type AcdPart4DataSchema = InferType<typeof acdPart4DataSchema>;

export const acdPart5DataSchema = object({
  ...defaultContextFields(),

  confirmInvolvement: confirmCheckbox,
});

export type AcdPart5DataSchema = InferType<typeof acdPart5DataSchema>;

export const acdPart6DataSchema = object({
  ...defaultContextFields(),

  confirmInvolvement: confirmCheckbox,
});

export type AcdPart6DataSchema = InferType<typeof acdPart6DataSchema>;

export const acdPart7DataSchema = object({
  ...defaultContextFields(),

  confirmInvolvement: confirmCheckbox,
});

export type AcdPart7DataSchema = InferType<typeof acdPart7DataSchema>;

export type AcdPart3DataSchema = InferType<typeof acdPart3DataSchema>;

export type AcdData = [
  PartData<AcdPart1DataSchema>,
  PartData<AcdPart2DataSchema>?,
  PartData<AcdPart3DataSchema>?,
  PartData<AcdPart4DataSchema>?,
  PartData<AcdPart5DataSchema>?,
  PartData<AcdPart6DataSchema>?,
  PartData<AcdPart7DataSchema>?,
];

const LOREM =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const exampleAcdData: ExampleFormData<AcdData> = [
  {
    data: {
      user: {
        name: "Facilitator",
        address: "Facilitator Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },

      patientNhsNumber: "1234567890",

      patientEthnicityCode: "A",
      patientEthnicityText: "White - British",

      patientGenderCode: "1",
      patientGenderText: "Male (including trans man)",

      trustId: "ACD-2134",

      mainMentalHealthDifficultiesSelected: false,
      mainMentalHealthDifficulties: null,

      mainPhysicalIllnessOrAllergiesSelected: true,
      mainPhysicalIllnessOrAllergies: "Main physical illness or allergies",

      learningOrCommunicationDifficultiesOrOtherSignificantDiagnosesSelected:
        true,
      learningOrCommunicationDifficultiesOrOtherSignificantDiagnoses:
        "Learning or communication difficulties or other significant diagnoses",

      whatImLikeWhenImWellSelected: true,
      whatImLikeWhenImWell: "What I’m like when I’m well",

      thingsThatHaveLedMeToBeingUnwellSelected: false,
      thingsThatHaveLedMeToBeingUnwell: null,
      keySignsIAmInCrisisSelected: true,
      keySignsIAmInCrisis: "Key signs I’m in crisis",
      earlySignsIAmLosingCapacitySelected: true,
      earlySignsIAmLosingCapacity: "Early signs I’m losing capacity",
      thingsToAvoidSelected: true,
      thingsToAvoid: "Key things I want to avoid",
      keyThingsPeopleMisunderstandAboutMeWhenUnwellSelected: true,
      keyThingsPeopleMisunderstandAboutMeWhenUnwell:
        "Key things people misunderstand about me when I’m unwell",

      communityHomeTreatmentTeamCrisisCareSelected: true,
      communityHomeTreatmentTeamCrisisCare:
        "Community home treatment team crisis care",

      whenINeedAdmissionToHospitalSelected: true,
      whenINeedAdmissionToHospital: "When I need admission to hospital",

      mentalHealthActAssessmentSelected: true,
      mentalHealthActAssessment: "Mental health act assessment",

      hospitalCareSelected: true,
      hospitalCare: "Hospital care",

      mentalHealthMedicalTreatmentsSelected: true,
      mentalHealthMedicalTreatments: "Mental health medical treatments",

      mentalHealthNonMedicalTreatmentsSelected: true,
      mentalHealthNonMedicalTreatments: "Mental health non medical treatments",

      whatIWantToAvoidWhenInCrisisSelected: true,
      whatIWantToAvoidWhenInCrisis: "What I want to avoid when in crisis",

      religiousOrSpiritualBeliefsAndCulturalPracticesSelected: true,
      religiousOrSpiritualBeliefsAndCulturalPractices:
        "Religious or spiritual beliefs and cultural practices",

      physicalHealthTreatmentAndCareDuringHomeTreatmentSelected: true,
      physicalHealthTreatmentAndCareDuringHomeTreatment:
        "Physical health treatment and care during home treatment",

      emergencyPhysicalHealthCareAndTreatmentSelected: true,
      emergencyPhysicalHealthCareAndTreatment:
        "Emergency physical health care and treatment",

      // practical help

      checkHomeIsSecure: true,
      throwAwayPerishableFoodsOrDrinks: true,

      careForChildrenNeedToBeArranged: true,
      careArrangementsForChildren: "Care arrangements for children",

      careForDependentRelativesNeedToBeArranged: false,
      careArrangementsForDependentRelatives: null,

      careForPetsNeedToBeArranged: true,
      careArrangementsForPets: "Care arrangements for pets",

      otherCareNeedToBeArranged: true,
      otherCareArrangements: "Other care arrangements",

      isThereAnyoneToInformOnAdmission: true,
      informedPerson1Name: "Informed Person 1",
      informedPerson1Relationship: "Informed Person 1 Relationship",
      informedPerson1PhoneNumber: "07888123444",
      informedPerson1EmailAddress: "informed1@informed.com",
      informedPerson1Information: "Please inform them",
      secondPersonToInformOnAdmission: true,
      informedPerson2Name: "Informed Person 2",
      informedPerson2Relationship: "Informed Person 2 Relationship",
      informedPerson2PhoneNumber: null,
      informedPerson2EmailAddress: "informed2@informed.com",
      informedPerson2Information: null,

      isThereAnyoneNotToInformOnAdmission: true,
      notInformedPerson1Name: "Not Informed Person 1",
      notInformedPerson1Relationship: "Not Informed Person 1 Relationship",
      secondPersonNotToInformOnAdmission: true,
      notInformedPerson2Name: "Not Informed Person 2",
      notInformedPerson2Relationship: "Not Informed Person 2 Relationship",

      // preferred contacts
      preferredContact1Name: "Preferred Contact 1",
      preferredContact1Relationship: "Preferred Contact 1 Relationship",
      preferredContact1RoleInCrisis: "Preferred Contact 1 Role in Crisis",
      preferredContact1PhoneNumber: "07888123444",
      preferredContact1EmailAddress: "preferred1@preferred.com",
      preferredContact1Address: {
        address: "Preferred Contact 1 Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      preferredContact1NearestRelative: "yes",
      isReasonNearestRelative1NotContacted: true,
      reasonNearestRelative1NotContacted: "Nearest Relative 1 Not Contacted",

      doYouHaveASecondPreferredContact: true,

      preferredContact2Name: "Preferred Contact 2",
      isReasonNearestRelative2NotContacted: false,
      reasonNearestRelative2NotContacted: "Nearest Relative 2 Not Contacted",

      preferredContact2Relationship: "Preferred Contact 2 Relationship",
      preferredContact2RoleInCrisis: "Preferred Contact 1 Role in Crisis",
      preferredContact2PhoneNumber: null,
      preferredContact2EmailAddress: null,
      preferredContact2Address: {
        address: "Preferred Contact 2 Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      preferredContact2NearestRelative: "no",

      doYouHaveACareCoordinator: true,
      careCoordinatorName: "Care Coordinator Name",
      careCoordinatorOrganisation: "Care Coordinator Organisation",
      careCoordinatorPhoneNumber: "07888123444",
      careCoordinatorEmailAddress: "care@coordinator.com",

      doYouHaveAConsultant: true,
      consultantName: "Consultant Name",
      consultantOrganisation: "Consultant Organisation",
      consultantPhoneNumber: "07888123444",
      consultantEmailAddress: "consultant@health.com",

      doYouHaveAGeneralPractitioner: true,
      generalPractitionerName: "General Practitioner Name",
      generalPractitionerOrganisation: "General Practitioner Organisation",
      generalPractitionerPhoneNumber: "07888123444",
      generalPractitionerEmailAddress: "gp@health.com",

      doYouHaveAnyOtherKeyCrisisSupporterContacts: true,
      keyCrisisOtherContact1Name: "Key Crisis Other Contact 1 Name",
      keyCrisisOtherContact1Organisation:
        "Key Crisis Other Contact 1 Organisation",
      keyCrisisOtherContact1RoleInCrisis: "Key Crisis Other Contact 1 Role",
      keyCrisisOtherContact1PhoneNumber: "07888123444",
      keyCrisisOtherContact1EmailAddress: "crisis1@email.com",
      secondKeyCrisisOtherContact: true,

      keyCrisisOtherContact2Name: "Key Crisis Other Contact 2 Name",
      keyCrisisOtherContact2Organisation:
        "Key Crisis Other Contact 2 Organisation",
      keyCrisisOtherContact2RoleInCrisis: "Key Crisis Other Contact 2 Role",
      keyCrisisOtherContact2PhoneNumber: "07888123444",
      keyCrisisOtherContact2EmailAddress: "crisis2@email.com",

      // access
      aAndEStaffAccessSelected: true,
      aAndEStaffAccess: "How A&E staff can access",

      ambulanceServiceCanAccessSelected: true,
      ambulanceServiceCanAccess: "How ambulance service can access",

      generalHospitalStaffAccessSelected: false,
      generalHospitalStaffAccess: null,

      canFamilyAndFriendsAccess: true,

      canPreferredContact1Access: true,
      howPreferredContact1CanAccess: "How preferred contact 1 can access",

      canPreferredContact2Access: true,
      howPreferredContact2CanAccess: "How preferred contact 2 can access",

      otherFamilyAndFriend1CanAccess: true,
      otherFamilyAndFriend1Name: "Other Family and Friend 1 Name",
      otherFamilyAndFriend1Relationship:
        "Other Family and Friend 1 Relationship",
      otherFamilyAndFriend1RoleInCrisis:
        "Other Family and Friend 1 Role in Crisis",
      otherFamilyAndFriend1PhoneNumber: "07888123444",
      otherFamilyAndFriend1EmailAddress: "otherfamily1@email.com",
      otherFamilyAndFriend1Address: {
        address: "Other Family and Friend 1 Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      otherFamilyAndFriend1HowCanAccess:
        "Other Family and Friend 1 How Can Access",
      otherFamilyAndFriend2CanAccess: true,
      otherFamilyAndFriend2Name: "Other Family and Friend 2 Name",
      otherFamilyAndFriend2Relationship:
        "Other Family and Friend 2 Relationship",
      otherFamilyAndFriend2RoleInCrisis:
        "Other Family and Friend 2 Role in Crisis",
      otherFamilyAndFriend2PhoneNumber: "07888123444",
      otherFamilyAndFriend2EmailAddress: "otherfamily2@email.com",
      otherFamilyAndFriend2Address: {
        address: "Other Family and Friend 2 Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },

      otherFamilyAndFriend2HowCanAccess:
        "Other Family and Friend 1 How Can Access",

      mentalHealthTrustStaffAccessSelected: true,
      mentalHealthTrustStaffAccess: "How mental health trust staff can access",

      GPsAccessSelected: false,
      GPsAccess: null,

      AMHPsAccessSelected: true,
      AMHPsAccess: "How AMHPs can access",

      canOtherPerson1Access: true,
      otherPersonWithAccess1Name: "Other Person 1 Name",
      otherPersonWithAccess1Role: "Other Person 1 Role",
      otherPersonWithAccess1HowCanAccess: "Other Person 1 How Can Access",

      canOtherPerson2Access: true,
      otherPersonWithAccess2Name: "Other Person 2 Name",
      otherPersonWithAccess2Role: "Other Person 2 Role",
      otherPersonWithAccess2HowCanAccess: "Other Person 2 How Can Access",
    },
  },
  {
    data: {
      user: {
        name: "Facilitator",
        address: "Facilitator Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },
      showUpdateDocumentDate: true,
      updateDocumentOtherDate: "2001-01-01",
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },

      ourMentalHealthTreatmentAndCareRecommendations: `${LOREM} ${LOREM} ${LOREM} ${LOREM} ${LOREM}`,
      updateDocumentDate: "6-months",
      updateDocumentDateText: "6 months",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        address: "Clinician Address",
        postalCode: "ABC 123",
        email: "bob@Clinician.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },

      assessmentOfCapacityToMakeAdvanceChoicesAboutTreatmentAndCare:
        "Assessment of Capacity to make choices",
      doesThePersonHaveCapacityToMakeAdvanceChoicesAboutTreatmentAndCare:
        "unsure",
      occupation: "Registered Mental Health Nurse",
      capacityAssessmentDate: "2021-01-01",
    },
  },
  {
    data: {
      user: {
        name: "Facilitator",
        address: "Facilitator Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },

      summaryOfOtherConsultationsSelected: true,
      summaryOfOtherConsultations: `Summary of other consultations. ${LOREM} ${LOREM}`,
      additionalCommentsSelected: true,
      additionalComments: `Additional Comments. ${LOREM} ${LOREM}`,
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        address: "Clinician Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },
      confirmInvolvement: true,
    },
  },
  {
    data: {
      user: {
        name: "Facilitator/Patient",
        address: "Facilitator/Patient Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },
      confirmInvolvement: true,
    },
  },
  {
    data: {
      user: {
        name: "Facilitator",
        address: "Facilitator Address",
        postalCode: "ABC 123",
        email: "david@facilitator.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "2001-01-01",
        nhsNumber: "1234567890",
      },
      confirmInvolvement: true,
    },
  },
];
