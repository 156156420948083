import { boolean, InferType, object, string } from "yup";
import { nonEmptyString } from "@aspire/common/schemas/shared";
import {
  ExtendedThalamosUser,
  UserOrTeamSearchResult,
  UserSearchResult,
} from "@aspire/common/types/user";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { Formik } from "formik";
import { Box } from "@mui/material";
import { Typeahead } from "../design-system/Typeahead/Typeahead";
import { api } from "../../api";
import { TextField } from "../design-system/TextField/TextField";
import { Banner, BannerList } from "../design-system/Banner/Banner";
import { Team } from "@aspire/common/types/teams";
import { Dropdown } from "../design-system/Dropdown/Dropdown";

export interface ResultWithoutAccreditation
  extends Omit<Result, "guestInviteAccreditation"> {}

export const formAssigneeData = object({
  type: string().oneOf(["full-user", "guest"]),
  teamId: string(),
  assignedUserId: string(),
  email: nonEmptyString
    .email("Please enter a valid email")
    .required("Please enter an email"),
  name: nonEmptyString.required("Please enter a name"),
  guestInviteAccreditation: nonEmptyString.required(
    "Please confirm who you are sending this invite to",
  ),
  isTrainingOrganisation: boolean(),
});

export type FormAssigneeData = InferType<typeof formAssigneeData>;

export type Result = {
  teamId: string;
  teamType: Team["type"];
  email: string;
  name: string;
  contexts?: UserSearchResult["contexts"];
  assignedUserId?: string;
  guestInviteAccreditation: string;
  isTrainingOrganisation: boolean;
};

export function expandSearchResults(
  results: UserOrTeamSearchResult[],
): ResultWithoutAccreditation[] {
  const expandedResults: ResultWithoutAccreditation[] = [];

  for (const result of results) {
    if (result.type === "team") {
      expandedResults.push({
        teamType: result.teamType,
        teamId: result.id,
        email: result.email,
        name: result.name,
        isTrainingOrganisation: !!result.isTrainingOrganisation,
      });
    } else {
      for (const context of result.contexts) {
        expandedResults.push({
          teamType: context.teamType,
          teamId: context.id,
          email: result.email,
          name: `${result.name} (${context.name})`,
          assignedUserId: result.id,
          isTrainingOrganisation: !!context.isTrainingOrganisation,
        });
      }
    }
  }

  return expandedResults;
}

export function FormAssigneeDialog({
  formDetails,
  user,
  onSave,
  submissionComponentFn,
  guestMessage,
  guestBannerType,
  setInviteSuccessMessageName,
  guestAccreditationOptions,
}: {
  formDetails: { template: { id: string; version: string }; part: number };
  user: ExtendedThalamosUser;
  onSave: (formData: FormAssigneeData) => Promise<void>;
  submissionComponentFn: (formikValues: {
    values: FormAssigneeData;
    isSubmitting: boolean;
    submitForm: () => void;
  }) => ReactElement;
  guestMessage?: string;
  guestBannerType?: BannerList;
  setInviteSuccessMessageName?: (params: any) => void;
  guestAccreditationOptions?: { label: string; value: string }[];
}) {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = React.useState<
    ResultWithoutAccreditation[]
  >([]);

  const defaultedGuestAccreditationOptions = !guestAccreditationOptions?.length
    ? [
        {
          label: "Other",
          value: "other",
        },
      ]
    : guestAccreditationOptions;

  const defaultGuestAccreditationValue =
    defaultedGuestAccreditationOptions.length > 1
      ? ""
      : defaultedGuestAccreditationOptions?.[0].value;

  const initialValues = {
    type: "full-user",
    teamId: "",
    email: "",
    name: "",
    guestInviteAccreditation: defaultGuestAccreditationValue,
    isTrainingOrganisation: false,
  } satisfies FormAssigneeData;
  return (
    <Formik<FormAssigneeData>
      validationSchema={formAssigneeData}
      initialValues={initialValues}
      onSubmit={onSave}
    >
      {(formikValues) => {
        const { values, errors, setValues, touched, setTouched } = formikValues;

        const emailRegex = new RegExp(
          "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
        );

        return (
          <>
            <Box>
              <Box>
                <Typeahead
                  enableFuse={false}
                  testId="form-assignee-email"
                  label={t("pages.formDraftSignPage.typeaheadLabel")}
                  options={searchResults}
                  name="recipientEmail"
                  onBlur={() => {
                    setTouched({ ...touched, email: true });
                  }}
                  errorMessage={touched.email ? (errors.email as string) : ""}
                  showHelperText={!!errors.email}
                  inputValue={values.email}
                  optionsKey="name"
                  getOptionLabel={(option) =>
                    typeof option === "string"
                      ? option
                      : `${option.name} (${option.email})`
                  }
                  onInputChange={async (value: string) => {
                    const match = searchResults.find(
                      (u) => value === `${u.name} (${u.email})`,
                    );

                    if (match) {
                      setInviteSuccessMessageName?.(match?.name);
                      if (match.teamType === "guest") {
                        setValues({
                          type: "guest",
                          email: match.email,
                          name: match.name,
                          guestInviteAccreditation:
                            defaultGuestAccreditationValue,
                        });
                      } else {
                        setValues({
                          type: "full-user",
                          guestInviteAccreditation:
                            defaultGuestAccreditationValue,
                          ...match,
                        });
                      }
                    } else {
                      setInviteSuccessMessageName?.(value);
                      setValues({
                        ...initialValues,
                        email: value,
                        type: value?.match(emailRegex) ? "guest" : "full-user",
                      });
                    }
                    if (value.length > 1) {
                      const results =
                        await api.formTemplates.searchAssignmentOptions({
                          id: formDetails.template.id,
                          version: formDetails.template.version,
                          query: value,
                          part: formDetails.part,
                        });

                      const expandedResults = expandSearchResults(
                        results.data.results,
                      );

                      setSearchResults(
                        expandedResults.filter(
                          (u) => u.assignedUserId !== user.id,
                        ),
                      );

                      const match = expandedResults.find(
                        (u) => value.toLowerCase() === u.email,
                      );

                      if (match) {
                        setInviteSuccessMessageName?.(match);
                        if (match.teamType === "guest") {
                          setValues({
                            type: "guest",
                            email: match.email,
                            name: match.name,
                            guestInviteAccreditation: "",
                          });
                        } else {
                          setValues({
                            type: "full-user",
                            guestInviteAccreditation: "",
                            ...match,
                          });
                        }
                      }
                    } else {
                      setSearchResults([]);
                    }
                  }}
                />
              </Box>
              <Box>
                {values.type === "guest" && (
                  <>
                    <Box sx={{ mt: 0.5, mb: 4 }}>
                      <Box sx={{ mb: 0.5 }}>
                        <TextField
                          useFullWidth={true}
                          value={values.name}
                          onBlur={() => {
                            setTouched({ ...touched, name: true });
                          }}
                          showHelperText={!!errors.name}
                          errorMessage={
                            touched.name ? (errors.name as string) : ""
                          }
                          name={"name"}
                          label={"Name"}
                          onChange={(e) => setValues({ ...values, name: e })}
                        />
                      </Box>
                      <Banner
                        bannerType={guestBannerType || BannerList.INFO}
                        title={
                          guestMessage ||
                          t("components.newFormAssignee.guestUserClarification")
                        }
                      />
                    </Box>

                    {defaultedGuestAccreditationOptions.length > 1 && (
                      <Box sx={{ my: 4 }}>
                        <Dropdown
                          label={
                            "Please confirm who you are sending this invite to"
                          }
                          selectedValue={values.guestInviteAccreditation}
                          name={"invite"}
                          values={defaultedGuestAccreditationOptions}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              guestInviteAccreditation: e,
                            });
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
            {submissionComponentFn({
              ...formikValues,
              values: {
                ...formikValues.values,
                email: formikValues.values.email.toLowerCase(),
                name: formikValues.values.name.trim(),
              },
            })}
          </>
        );
      }}
    </Formik>
  );
}
