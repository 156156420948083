import { useParams } from "react-router-dom";
import { useContext } from "react";
import { LoggedInUserContext } from "../../Contexts";
import {
  Banner,
  BannerList,
  LoadingSpinner,
  PopupDialog,
} from "~/components/design-system";
import { usePatientTimeline, useMhaStatus } from "~/hooks/apiCalls";
import { FormContextData } from "@aspire/common/types/formContextData";
import {
  RenderPatientBannerAndEditStatus,
  userCanEditMhaStatus,
} from "./EditMhaStatusPage";

export function EditMhaStatusDialog({
  hasFormWithApplicationOrMedRec,
  formContext,
}: {
  hasFormWithApplicationOrMedRec: boolean;
  formContext: FormContextData;
}) {
  const { patientId } = useParams();

  const { patientTimeline } = usePatientTimeline({
    patientId: patientId,
  });
  const { mhaStatus } = useMhaStatus({ patientId: patientId });

  const userContext = useContext(LoggedInUserContext);
  const showEditMhaStatus = userCanEditMhaStatus(userContext?.user);

  return (
    <PopupDialog open={true}>
      {!showEditMhaStatus ? (
        <Banner
          bannerType={BannerList.ERROR}
          title={"Incorrect permissions to edit MHA status page"}
        />
      ) : patientTimeline && mhaStatus ? (
        <RenderPatientBannerAndEditStatus
          patientTimeline={patientTimeline}
          mhaStatus={mhaStatus}
          patientId={patientTimeline.patient.id}
          hasFormWithApplicationOrMedRec={hasFormWithApplicationOrMedRec}
          formContext={formContext}
          isScrutiny={true}
        />
      ) : (
        <LoadingSpinner />
      )}
    </PopupDialog>
  );
}
