import {
  CTO2Data,
  cto2Part1DataSchema,
  Cto2Part1DataSchema,
} from "../schemas/mhaForms/cto/cto2";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto2Part1Template: FormPartTemplate<Cto2Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I am varying the conditions attaching to the community treatment order for the above named patient.",
      ],
    },

    {
      type: "textbox",
      field: "conditionsUnderSection17B",
      label:
        "The conditions made under section 17B(2), as varied, are: (List the conditions as varied in full (including any which are not being varied) or state that there are no longer to be any such conditions)",
      rows: "5",
    },

    {
      type: "date",
      label: "Enter the date the variation is to take effect from",
      field: "variationEffectiveDate",
      minimum: (today) => today.endOf("day"),
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I confirm that I consider the above conditions to be necessary or appropriate for one or more of the following purposes:",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      showAsBulletPoint: true,
      indent: 3.5,
      label: [
        "to ensure that the patient receives medical treatment",
        "to prevent risk of harm to the patient's health or safety",
        "to protect other persons",
      ],
    },
  ],
  dataSchema: cto2Part1DataSchema,
};

export const cto2V1Base: BaseFormTemplate = {
  id: "mha-cto2",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO2",
  section: "17B",
  parts: [cto2Part1Template],
  description: "variation of conditions of a community treatment order",
  extractGenericData: (data: CTO2Data) => ({}),
};
