import {
  BaseFormTemplate,
  getLatestVersion,
  SignDraftRequest,
  SupportingFormsDetails,
} from "@aspire/common/types/form";
import { api } from "../../../api";
import {
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { routeFns } from "../../../routes";
import {
  applicationTemplateIds,
  recordOfDetentionTemplateIds,
} from "@aspire/common/sections";
import { baseTemplates } from "@aspire/common/formTemplates";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { RequirementFriendlyDescription } from "@aspire/common/requirementFriendlyDescription";
import { GetFormDraftResponse } from "@aspire/common/types/formDraft";
import { getFullFormName } from "@aspire/common/util/form";

interface Props {
  signature?: string | undefined;
  requiresSupportingForms?: any;
  isSupportingFormsProvided?: boolean;
  allSelectedForms?: string[];
  reason?: string;
  formId: string;
  navigate: any;
  formDraft: GetFormDraftResponse<any>;
  template: BaseFormTemplate;
  requiresSignature: boolean;
  saveDefaultSignature: boolean;
  user: ExtendedThalamosUser;
}

function getBannerDescriptionOfFriendlyRequirement(
  friendlyRequirement: RequirementFriendlyDescription,
) {
  switch (friendlyRequirement) {
    case RequirementFriendlyDescription.registeredNurse:
      return "the registered nurse";
    case RequirementFriendlyDescription.onBehalfOfResponsibleHospital:
      return "someone acting on behalf of the responsible hospital";
    case RequirementFriendlyDescription.onBehalfOfResponsibleHospitalOrLocalAuthority:
      return "someone acting on behalf of the responsible hospital or the local social services authority";
    case RequirementFriendlyDescription.professionalConcernedWithPatientCareDifferentProfessionFromResponsibleClinician:
      return "a professional who has been professionally concerned with the patient's medical treatment and who is of a different profession from the responsible clinician";
    case RequirementFriendlyDescription.approvedMentalHealthProfessional:
      return "the approved mental health professional";
    case RequirementFriendlyDescription.responsibleClinician:
      return "the responsible clinician ";
    case RequirementFriendlyDescription.approvedClinicianOrMedicalPractitionerUnderSection52:
      return "a medical practitioner or an approved clinician qualified to do so under section 5(2) of the Act ";
    case RequirementFriendlyDescription.approvedClinicianOrRegisteredMedicalPractitioner:
      return "the approved clinician or a registered medical practitioner";
    case RequirementFriendlyDescription.registeredMedicalPractitioner:
      return "a registered medical practitioner";
  }
}

export const onHappyWithSignature = async ({
  signature,
  requiresSupportingForms,
  isSupportingFormsProvided,
  allSelectedForms,
  reason,
  formId,
  navigate,
  formDraft,
  template,
  requiresSignature,
  saveDefaultSignature,
  user,
}: Props) => {
  if (signature || !requiresSignature) {
    let supportingFormsDetails: SupportingFormsDetails | undefined = undefined;

    if (requiresSupportingForms) {
      if (!isSupportingFormsProvided) {
        supportingFormsDetails = {
          type: "provided",
          formIds: allSelectedForms!,
        };
      } else {
        supportingFormsDetails = {
          type: "not-provided",
          reason: reason!,
        };
      }
    }

    const signDraftRequest: SignDraftRequest = {
      draftId: formDraft.id,
      signatureBase64: signature,
      supportingFormsDetails: supportingFormsDetails,
    };

    const result = await api.forms.sign(formId, signDraftRequest);

    if (result.status === 204) {
      if (saveDefaultSignature && !user.defaultSignature)
        await api.users.update(user.id, {
          defaultSignature: signature,
        });

      const formSuccessfullySignedTitle = "Form successfully signed";

      renderSuccessToast({ message: formSuccessfullySignedTitle });

      const fullFormName = getFullFormName(template);
      const partText = `part ${formDraft.part}`;
      const formAndPartName = `${partText} of ${fullFormName}`;
      const isMultiPart = template.parts.length > 1;

      const isH3Form = formDraft.formTemplate.id === "mha-h3";
      const isSectionValue2or3 = ["2", "3"].includes(
        formDraft.data.sectionValue,
      );

      const isOnePartH3Form = isH3Form && isSectionValue2or3;

      const isASection4H3 = isH3Form && formDraft.data.sectionValue === "4";

      const partTemplateSignatureSuccessMessage =
        template.parts[formDraft.part - 1]?.overrideSignatureSuccessMessage;

      // Is the form going to be complete?
      if (
        template.parts.length === formDraft.part ||
        template.parts[formDraft.part].autoAssignToPartSignatory
      ) {
        if (formDraft.formContext.type === "admission") {
          // We're signing an H3
          if (
            recordOfDetentionTemplateIds.includes(formDraft.formTemplate.id)
          ) {
            const signedApplication = formDraft.formContext.forms.find((f) =>
              applicationTemplateIds.includes(f.formTemplate.id),
            );
            const applicationTemplate = baseTemplates.find(
              (t) => t.id === signedApplication?.formTemplate.id,
            );

            if (user.sessionContext?.teamType === "mha") {
              if (isASection4H3) {
                navigate(
                  routeFns.formContextPageSuccessDialog(
                    formDraft.formContextId,
                    formDraft.patientId,
                    {
                      title: formSuccessfullySignedTitle,
                      message: `You have successfully signed ${partText} of ${fullFormName}. Close to complete part ${
                        formDraft.part + 1
                      }`,
                    },
                  ),
                );
              } else {
                navigate(
                  routeFns.formContextPageSuccessDialog(
                    formDraft.formContextId,
                    formDraft.patientId,
                    {
                      title: formSuccessfullySignedTitle,
                      message: `You have successfully signed Section ${applicationTemplate?.section} - ${template.description}. Close to mark administrative scrutiny of this assessment complete.`,
                    },
                  ),
                );
              }
            } else {
              if (isASection4H3) {
                navigate(
                  routeFns.formContextPageSuccessDialog(
                    formDraft.formContextId,
                    formDraft.patientId,
                    {
                      title: formSuccessfullySignedTitle,
                      message: `You have successfully signed ${partText} of ${fullFormName}. Close to complete part ${
                        formDraft.part + 1
                      }`,
                    },
                  ),
                );
              } else {
                navigate(
                  routeFns.formContextPageSendRequest(
                    formDraft.formContextId,
                    formDraft.patientId,
                    {
                      requestType: "finalise",
                      title: `You have successfully signed Section ${applicationTemplate?.section} - ${template.description}. Send Section ${applicationTemplate?.section} admission forms to local MHA office for scrutiny.`,
                    },
                  ),
                );
              }
            }
          } else if (formDraft.formVersion > 1) {
            navigate(
              routeFns.formContextPageSuccessDialog(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  title: "Amend successfully confirmed and sent back",
                  message:
                    "The requesting team have been notified by email that you have confirmed the amend so you don't have to send it back.",
                },
              ),
            );
          }

          // We're signing an application
          else if (applicationTemplateIds.includes(formDraft.formTemplate.id)) {
            navigate(
              routeFns.formContextPageSendRequest(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  requestType: "record-of-detention",
                  title: `You have successfully signed Section ${template.section} - ${template.description}. Invite another user or team to complete the record of detention form.`,
                  infoMessage:
                    "Record of detention form to be completed by someone acting on behalf of the responsible hospital.",
                },
              ),
            );
          }

          // We're signing a med rec and it's going to be complete
          else {
            // If I was requested to do this med rec by somebody then just show a success dialog instead as they'll have been notified
            if (
              formDraft.formContext.workItems.find(
                (w) => w.teamId === user.sessionContext!.teamId,
              )?.requestType === "medical-recommendation"
            ) {
              navigate(
                routeFns.formContextPageSuccessDialog(
                  formDraft.formContextId,
                  formDraft.patientId,
                  {
                    title: "Form successfully signed and sent back",
                    message: `You have successfully signed Section ${template.section} - ${template.description}. The requesting team or user have been notified by email that you have signed this form so you don't have to send it back.`,
                  },
                ),
              );
            } else {
              navigate(
                routeFns.formContextPageSendRequest(
                  formDraft.formContextId,
                  formDraft.patientId,
                  {
                    title: `You have successfully signed Section ${template.section} - ${template.description}. Send form to another user or team to work on this assessment.`,
                  },
                ),
              );
            }
          }
        } else {
          // Standalone form
          // Form is complete
          if (template.parts.length === formDraft.part || isOnePartH3Form) {
            // If the user signing the last part of the form is an MHA, just prompt them to close and finalise
            if (user.sessionContext?.teamType === "mha") {
              navigate(
                routeFns.formContextPageSuccessDialog(
                  formDraft.formContextId,
                  formDraft.patientId,
                  {
                    title: formSuccessfullySignedTitle,
                    message: `You have successfully signed ${
                      isOnePartH3Form
                        ? fullFormName
                        : isMultiPart
                          ? partText
                          : fullFormName
                    }. Close to mark administrative scrutiny of this assessment complete.`,
                  },
                ),
              );
            } else if (template.autoFinalise) {
              navigate(
                routeFns.formContextPageSuccessDialog(
                  formDraft.formContextId,
                  formDraft.patientId,
                  {
                    title: formSuccessfullySignedTitle,
                    message: `You have successfully signed ${formAndPartName}. This form is now complete and has been moved to your archive.`,
                  },
                ),
              );
            } else {
              const signatureSuccessMessage =
                partTemplateSignatureSuccessMessage
                  ? {
                      infoMessage: partTemplateSignatureSuccessMessage,
                    }
                  : {};
              navigate(
                routeFns.formContextPageSendRequest(
                  formDraft.formContextId,
                  formDraft.patientId,
                  {
                    requestType: "finalise",
                    title: `You have successfully signed ${
                      isMultiPart ? partText : fullFormName
                    }. ${
                      partTemplateSignatureSuccessMessage
                        ? ""
                        : `Send ${
                            isMultiPart ? fullFormName : "form"
                          } to your local MHA office for scrutiny.`
                    }`,
                    ...signatureSuccessMessage,
                  },
                ),
              );
            }
          } else if (isASection4H3) {
            navigate(
              routeFns.formContextPageSuccessDialog(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  title: formSuccessfullySignedTitle,
                  message: `You have successfully signed ${partText} of ${fullFormName}. Close to complete part ${
                    formDraft.part + 1
                  }`,
                },
              ),
            );
          }
          // Auto-assign (e.g. part 3 of CTO1)
          else {
            const currentForm = formDraft.myWorkItem?.forms.find(
              (f) => f.id === formId,
            );
            const latestVersion = getLatestVersion(currentForm);

            const latestPrevPartFormDraft = formDraft?.previousPartsFormData[
              template.parts[formDraft.part]?.autoAssignToPartSignatory! - 1
            ]
              ? formDraft.part - 1
              : null;
            const autoAssignBackSignatory = latestVersion?.signatures.find(
              (s) => s.part === latestPrevPartFormDraft,
            )!;

            const autoAssignUser = `${autoAssignBackSignatory?.userName} (${autoAssignBackSignatory?.teamName})`;

            const message = `Form successfully sent back to ${autoAssignUser}`;

            renderSuccessToast({
              message: message,
            });

            navigate(
              routeFns.formContextPageSuccessDialog(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  title: "Form successfully signed and sent back",
                  message: `You have successfully signed ${formAndPartName}. The requesting team or user have been notified by email that you have signed this form so you don't have to send it back.`,
                },
              ),
            );
          }
        }
      } else {
        const friendlyRequirement =
          template.parts[formDraft.part].signing.userOrTeamRequirement
            .friendlyDescription;

        const friendlyRequirementMessage = friendlyRequirement
          ? {
              infoMessage: `Part ${
                formDraft.part + 1
              } to be completed by ${getBannerDescriptionOfFriendlyRequirement(
                friendlyRequirement,
              )}.`,
            }
          : {};

        if (formDraft.formContextType === "standalone") {
          const nextPartSignMessage = `You have successfully signed part ${
            formDraft.part
          }. Send Form ${template.formName}: Section ${template.section} - ${
            template.description
          } to another user or team to complete part ${formDraft.part + 1}`;

          navigate(
            routeFns.formContextPageSendRequest(
              formDraft.formContextId,
              formDraft.patientId,
              {
                formId: formDraft.formId,
                requestType: "complete",
                formPart: formDraft.part.toString(),
                title: nextPartSignMessage,
                ...friendlyRequirementMessage,
              },
            ),
          );
        } else {
          if (formDraft.formVersion > 1) {
            navigate(
              routeFns.formContextPageSuccessDialog(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  title:
                    "Amend successfully confirmed and sent to the second medical practitioner",
                  message:
                    "The second registered medical practitioner has now been notified of the amend, there is no further action required from you.",
                },
              ),
            );
          } else {
            const nextPartSignMessage = `You have successfully signed part ${
              formDraft.part
            }. Send Section ${template.section} - ${
              template.description
            } to another user or team to complete part ${formDraft.part + 1}`;

            navigate(
              routeFns.formContextPageSendRequest(
                formDraft.formContextId,
                formDraft.patientId,
                {
                  formId: formDraft.formId,
                  requestType: "complete",
                  formPart: "1",
                  title: nextPartSignMessage,
                  ...friendlyRequirementMessage,
                },
              ),
            );
          }
        }
      }
    } else {
      renderErrorToast({ message: "Signing failed" });
    }
  }
};
