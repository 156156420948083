import {
  A10Data,
  A10Part1DataSchema,
  a10Part1DataSchema,
} from "../schemas/mhaForms/admission/a10";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A10UploadableData,
  a10UploadablePart1DataSchema,
  A10UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a10";
import { a9And10Part1Signing } from "./helpers/signingLogic";

export const a10Part1Template: FormPartTemplate<A10Part1DataSchema> = {
  signing: a9And10Part1Signing,
  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I apply for the admission of this patient for assessment in accordance with Part 2 of the Mental Health Act 1983.",
      ],
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    {
      type: "date-time",
      field: "lastSeenDateTime",
      label: "Enter the date and time you last saw the patient at",
      minimum: (now) => now.subtract(24, "hour"),
      maximum: (now) => now,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: ["This date was within the last 24 hours"],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I have interviewed the patient and I am satisfied that detention in a hospital is in all the circumstances of the case the most appropriate way of providing the care and medical treatment of which the patient stands in need.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "In my opinion it is of urgent necessity for the patient to be admitted and detained under section 2 of the Act and compliance with the provisions of Part 2 of the Act relating to applications under that section would involve undesirable delay.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This application is founded on a medical recommendation in the prescribed form.",
      ],
    },
    {
      type: "branch",
      field: "medicalPractitionersPreviousAcquaintance",
      label:
        "Did the medical practitioner have previous acquaintance with the patient before making their recommendation?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: ["noPreviousAcquaintanceExplanation"],
          fields: [
            {
              type: "textbox",
              rows: "5",
              field: "noPreviousAcquaintanceExplanation",
              label:
                "Explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient",
            },
          ],
        },
      ],
    },
  ],
  dataSchema: a10Part1DataSchema,
};

export const a8UploadablePart1Template: FormPartTemplate<A10UploadablePart1DataSchema> =
  {
    signing: a9And10Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date-time",
        field: "lastSeenDateTime",
        label: "Date and time signee last saw the patient",
        minimum: (now) => now.subtract(24, "hour"),
        maximum: (now) => now,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did the medical practitioner have previous acquaintance with the patient before making their recommendation?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "date-time",
        field: "signatureDate",
        label: "Date and time of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a10UploadablePart1DataSchema,
  };

export const a10V1Base: UploadableBaseFormTemplate = {
  id: "mha-a10",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a10Part1Template],
  formName: "A10",
  section: "4",
  description:
    "Emergency application by an approved mental health professional for admission for assessment",
  linkableForms: [{ templateId: "mha-a11", reason: "supported-by" }],
  extractGenericData: (data: A10Data | A10UploadableData) => ({
    admissions: {
      applicationHospitalName: data[0].data?.hospital.name,
      amhpLastSeenDateTime: data[0].data?.lastSeenDateTime,
    },
  }),
  uploadable: true,
  uploadablePartTemplates: [a8UploadablePart1Template],
};
