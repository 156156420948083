import * as React from "react";
import { css } from "@emotion/react";
import {
  ChevronLeft,
  ChevronRight,
  LogoutSharp,
  NotificationsNoneRounded,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { menuData } from "./menuData";
import ThalamosLogo from "../../../assets/img/thalamos_logo.svg";
import ThalamosIcon from "../../../assets/img/thalamos_icon.svg";
import { AppBar, Drawer, DrawerHeader } from "./styleWrappers";
import { ExtendedThalamosUser, isAdminUser } from "@aspire/common/types/user";
import { useLocation, useNavigate } from "react-router-dom";
import { routeFns } from "../../../routes";
import { stringAvatar } from "./helpers";
import { logout } from "../../../api";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../../../Contexts";
import { ThalamosLink } from "../../ThalamosLink";
import { config } from "../../../config";

export type DrawerDesktopMenuProps = {
  user: ExtendedThalamosUser | null;
};

export default function DrawerDesktopMenu({ user }: DrawerDesktopMenuProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isGuestUser = user?.sessionContext?.teamType === "guest";

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const styles = {
    iconStyles: css`
      border-radius: 50%;
      border: 1px solid ${alpha(theme.palette.primary.main, 0.1)};
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.white};
    `,
  };

  const iconButtonStyles = {
    ...(open && { minWidth: 0 }),
    mr: open ? 0 : "auto",
    justifyContent: "center",
    px: 0.1,
    borderRadius: 0,
    color: "text.primary",
  };

  //TODO replace this when we set up the profile user page (this should get the image profile if not show the display name)
  const displayName = user?.name || "Not Logged In";

  const checkActive = (link: string | undefined) => {
    const splitCurrentLink = link && link.split("/");
    if (
      splitCurrentLink &&
      link === pathname &&
      pathname.includes(splitCurrentLink[1])
    ) {
      return true;
    }
    return false;
  };

  const { notifications } = React.useContext(NotificationsContext);

  const isTrainingOrganisation = user?.sessionContext?.isTrainingOrganisation;
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          bgcolor: "transparent",
        }}
        css={css`
          position: absolute;
          left: 0;
          width: ${theme.spacing(1)};
        `}
      >
        <Toolbar>
          <IconButton
            aria-label={t("layout.menu.openMenuLabel")}
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { left: 205 }),
              ...(!open && { left: 110 }),
            }}
            css={css`
              position: fixed;
            `}
          >
            {open ? (
              <ChevronLeft css={styles.iconStyles} fontSize="large" />
            ) : (
              <ChevronRight css={styles.iconStyles} fontSize="large" />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer data-testid="desktop-menu" variant="permanent" open={open}>
        <DrawerHeader open={open}>
          <ThalamosLink to={routeFns.home()} aria-label="Home">
            <Icon
              sx={{ overflow: "visible" }}
              css={css`
                &:hover {
                  cursor: grab;
                }
              `}
            >
              {!open ? (
                <img
                  width="40"
                  height="35"
                  alt="thalamosicon"
                  src={ThalamosIcon}
                />
              ) : (
                <img
                  width="130"
                  height="70"
                  alt="thalamoslogo"
                  src={ThalamosLogo}
                />
              )}
            </Icon>
          </ThalamosLink>
        </DrawerHeader>
        {user?.memberships.length && (
          <Box
            sx={{
              whiteSpace: "break-spaces",
              fontSize: "0.7em",
              padding: open ? theme.spacing(2, 3) : theme.spacing(2, 1),
              display: "flex",
              flexDirection: "column",
              textAlign: open ? "left" : "center",
              border: isTrainingOrganisation ? "2px solid red" : "",
            }}
          >
            <Typography
              sx={{
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {isTrainingOrganisation
                ? t("training.teamPicker")
                : t("common.currentTeam")}
            </Typography>
            {user?.sessionContext?.type === "team"
              ? user?.sessionContext.teamName
              : user?.sessionContext?.organisationName}
            {(user?.memberships.length || 0) > 1 && (
              <ThalamosLink to={routeFns.contextSelector()}>
                <Box
                  css={css`
                    cursor: pointer;
                    color: ${theme.palette.primary.main};
                  `}
                >
                  {t("layout.menu.changeContext")}
                </Box>{" "}
              </ThalamosLink>
            )}
          </Box>
        )}
        <List
          css={css`
            display: flex;
            flex-direction: column;
            ${open && `margin-left: ${theme.spacing(2.5)}; `};
          `}
          sx={{
            "& .MuiListItemButton-root:hover": {
              bgcolor: theme.palette.primary.main,
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              color: theme.palette.common.white,
            },
            "& .MuiListItemButton-root.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            },
          }}
        >
          {menuData(user)
            .filter(
              (item) =>
                !item.requiresTeamContext ||
                user?.sessionContext?.type === "team",
            )
            .filter((item) => !item.requiresAdminUser || isAdminUser(user))
            .map(
              ({
                label,
                text,
                icon,
                miniText,
                link,
                disabled,
                onClick,
                openNewTab,
              }) => (
                <ThalamosLink
                  disabled={disabled}
                  to={link}
                  openNewTab={openNewTab}
                  key={text}
                >
                  <List sx={{ mb: 0, py: 0 }}>
                    <ListItem
                      onClick={
                        link
                          ? () => {}
                          : () => {
                              if (label === "logout") return logout();
                              if (disabled) return;
                              if (link) return navigate(link);
                              if (onClick) return onClick();
                            }
                      }
                      key={text}
                      disablePadding
                      sx={{ display: "flex" }}
                    >
                      <ListItemButton
                        disabled={disabled}
                        selected={checkActive(link)}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          ...(!open && { px: 4.5 }),
                          ...(open && { px: 0.5 }),
                          flexWrap: "wrap",
                          marginBottom: theme.spacing(1),
                          "&.MuiListItemButton-root:hover": {
                            "& .MuiListItemIcon-root": {
                              color: theme.palette.common.white,
                            },
                          },
                          "&.Mui-selected .MuiListItemIcon-root": {
                            color: theme.palette.common.white,
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(open && { minWidth: 0 }),
                            mr: open ? 0.8 : "auto",
                            justifyContent: "center",
                            color: "text.primary",
                            ...(open && { marginRight: theme.spacing(1) }),
                          }}
                        >
                          {label === "notifications" ? (
                            <Badge
                              badgeContent={notifications?.unviewedCount}
                              color={
                                (notifications?.unviewedCount || 0) > 0
                                  ? "error"
                                  : "primary"
                              }
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <NotificationsNoneRounded
                                strokeWidth={0.8}
                                stroke="white"
                              />
                            </Badge>
                          ) : (
                            icon
                          )}
                        </ListItemIcon>
                        <Typography
                          sx={{
                            fontSize: "0.7em",
                            ...(!open && { marginTop: 1 }),
                            textAlign: "center",
                          }}
                        >
                          {!open && miniText ? miniText : text}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </ThalamosLink>
              ),
            )}
        </List>
        <Box
          css={css`
            display: flex;
            align-items: center;
            justify-content: ${!open && "center"};
            margin: ${open
              ? theme.spacing("auto", 0, 1, 1)
              : theme.spacing("auto", 0, 1, 0)};
            border-top: 1px solid ${alpha(theme.palette.text.primary, 0.05)};
            padding-top: 0.5em;
            flex-wrap: wrap;
          `}
        >
          <ThalamosLink
            to={
              !isGuestUser && user ? routeFns.userProfilePage(user?.id) : null
            }
          >
            <Box
              css={css`
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                font-size: 0.7em;
                color: black;
                font-weight: 400;
                text-decoration: none;
                &:hover {
                  cursor: grab;
                }
              `}
            >
              <Avatar
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  ...(!open && { mx: 1 }),
                }}
                {...stringAvatar(displayName)}
                css={css`
                  transform: ${open ? "scale(0.6)" : "scale(0.8)"};
                `}
              />
              {displayName}
            </Box>
          </ThalamosLink>
          <Box sx={{ mx: 1 }}>
            <ThalamosLink
              to={
                !isGuestUser && user ? routeFns.userProfilePage(user?.id) : null
              }
            >
              <IconButton
                disabled={isGuestUser}
                sx={{
                  iconButtonStyles,
                }}
                aria-label={t("layout.menu.profileLabel")}
              >
                <SettingsOutlined strokeWidth={0.8} stroke="white" />
              </IconButton>
            </ThalamosLink>
            <IconButton
              onClick={logout}
              sx={{
                iconButtonStyles,
              }}
              aria-label={t("buttonLabels.logout")}
            >
              <LogoutSharp strokeWidth={0.8} stroke="white" />
            </IconButton>
          </Box>
          <Typography fontSize="10px" color="#999" padding="0 10px">
            v: {config.version || "unknown"}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
}
