import React, { useCallback, useMemo, useState } from "react";
import { Box, Paper, Stack } from "@mui/material";

import {
  Banner,
  BannerList,
  PopupDialog,
  Button,
  LoadingSpinner,
  TextField,
  FormLabel,
  PopupDialogTitle,
  ReadOnlyContent,
} from "~/components/design-system";
import { FormFooterSection } from "~/components/form";
import { MultiPagePdf } from "~/components/MultiPagePdf";

import { FormContextData } from "@aspire/common/types/formContextData";
import { api } from "~/api";
import { ExternalPatientLinkEnhanced } from "@aspire/common/types/externalPatientLinkEnhanced";
import { FormContextType } from "@aspire/common/types/formcontext";
import dayjs from "dayjs";
import { getBaseFormTemplate } from "@aspire/common/formTemplates";

type StateOpen = Omit<Extract<ReviewDialogState, { type: "open" }>, "type"> & {
  formContextId: string;
  formId: string;
  externalPatientLinkId: string;
};

export const useUploadFormPdfViaExternalPatientLinks = () => {
  // This is the main state we require in order to open the dialog
  const [openState, setOpenState] = useState<StateOpen | undefined>(undefined);

  // This is additional state to track the progress of the upload
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  // We can only attempt to upload the form if we have all the required data
  const isOpen = openState !== undefined;

  const uploadFormPdfViaExternalPatientLink = useCallback(
    async (
      externalPatientLink: ExternalPatientLinkEnhanced,
      formId: string,
      formContext: FormContextData,
    ) => {
      try {
        // Load the PDF so we have something to preview
        const pdfResult = await api.forms.getPdf(
          formId,
          formContext.type === "admission",
        );
        if (pdfResult.status !== 200 || pdfResult.data === null) {
          throw new Error("Failed to load PDF");
        }

        // Validate the details of the Rio system
        if (externalPatientLink.canPush === false) {
          throw new Error("This external system does not support pushing data");
        }

        if (externalPatientLink.externalSystemType !== "rio") {
          throw new Error("Only Rio is supported as an external system");
        }

        if (externalPatientLink.externalSystemDisplayName === undefined) {
          throw new Error("External System has not been given a name");
        }

        // Calculate the initial filename, title, and description
        const form = formContext.forms.find((form) => form.id === formId);
        if (form === undefined) {
          throw new Error("Form not found in form context");
        }

        const baseFormTemplate = getBaseFormTemplate(
          form.formTemplate.id,
          form.formTemplate.version,
        );
        if (baseFormTemplate === null) {
          throw new Error("Base Form Template not found");
        }

        const patientName =
          formContext.patient.name.given + formContext.patient.name.family;

        const documentTypeCode: string = (
          {
            admission: "MHA",
            standalone: "MHA",
          } satisfies Record<FormContextType, string>
        )[formContext.type];

        const sortedDocumentTitleCodes = [baseFormTemplate.formName]
          .join("")
          .toUpperCase();

        const dateLatestFormReceived = dayjs(formContext.updated).format(
          "DDMMYYYY",
        );

        // TODO: ASP-2079 Construct from configurable template string
        const initialFilename =
          [
            patientName,
            documentTypeCode,
            sortedDocumentTitleCodes,
            dateLatestFormReceived,
          ].join("_") + ".pdf";

        // TODO: ASP-2079 Construct from configurable template string
        const initialTitle = [
          documentTypeCode,
          sortedDocumentTitleCodes,
          dateLatestFormReceived,
        ].join("_");

        // TODO: ASP-2079 Construct from configurable template string
        const initialDescription = [
          "Document generated by Thalamos",
          initialFilename,
        ].join("\n");

        // Set the data required to review and upload the form.
        setOpenState({
          systemName: externalPatientLink.externalSystemDisplayName,
          initialFilename,
          initialTitle,
          initialDescription,
          pdf: pdfResult.data,
          formContextId: formContext.id,
          formId: formId,
          externalPatientLinkId: externalPatientLink.id,
        });
      } catch (e) {
        // Capture the error
        setErrorMessage(typeof e === "string" ? e : "Failed to upload form");

        // Reset the rest of the state
        setOpenState(undefined);
      }
    },
    [],
  );

  const getExternalPatientFormPushEvent = useCallback(
    async (
      externalPatientLink: ExternalPatientLinkEnhanced,
      formContextId: string,
      formId: string,
    ) => {
      try {
        if (externalPatientLink.externalSystemType !== "rio") {
          throw new Error("Only Rio is supported as an external system");
        }

        if (externalPatientLink.externalSystemDisplayName === undefined) {
          throw new Error("External System has not been given a name");
        }

        const result = await api.forms.getExternalPatientFormPushEvent(
          formContextId,
          formId,
        );

        if (result.status === 200) {
          return result.data;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    },
    [],
  );

  const onUpload: ReviewDialogProps["onUpload"] = useCallback(
    async ({ filename, title, description }) => {
      if (isOpen) {
        setUploading(true);

        try {
          const result = await api.forms.uploadPdfToExternalSystem(
            openState.formContextId,
            openState.formId,
            openState.externalPatientLinkId,
            filename,
            title,
            description,
          );

          if (result.status === 204) {
            setUploaded(true);
          } else {
            throw new Error("Failed to upload form");
          }
        } catch (e: any) {
          setUploaded(false);
          const message = e.response?.data?.reason ?? "Failed to upload form";
          setErrorMessage(message);
          // console.error(message);
        } finally {
          setUploading(false);
        }
      }
    },
    [isOpen, openState?.formContextId, openState?.externalPatientLinkId],
  );

  const onClose = useCallback(() => {
    setOpenState(undefined);
    setUploading(false);
    setUploaded(false);
    setErrorMessage(undefined);
  }, []);

  const state: ReviewDialogState = useMemo(
    () =>
      errorMessage
        ? { type: "failed", errorMessage }
        : uploading
          ? { type: "uploading" }
          : uploaded
            ? { type: "succeeded" }
            : isOpen
              ? {
                  type: "open",
                  systemName: openState.systemName,
                  initialFilename: openState.initialFilename,
                  initialTitle: openState.initialTitle,
                  initialDescription: openState.initialDescription,
                  pdf: openState.pdf,
                }
              : { type: "closed" },
    [
      errorMessage,
      isOpen,
      openState?.initialDescription,
      openState?.initialFilename,
      openState?.initialTitle,
      openState?.pdf,
      openState?.systemName,
      uploaded,
      uploading,
    ],
  );

  const reviewDialogProps: ReviewDialogProps = {
    onUpload,
    onClose,
    state,
  };

  return {
    Component: ReviewDialog,
    componentProps: reviewDialogProps,
    uploadFormPdfViaExternalPatientLink,
    getExternalPatientFormPushEvent,
  };
};

export type ReviewDialogState =
  | { type: "closed" }
  | {
      type: "open";
      systemName: string;
      initialFilename: string;
      initialTitle: string;
      initialDescription: string;
      pdf: string;
    }
  | { type: "uploading" }
  | { type: "failed"; errorMessage: string }
  | { type: "succeeded" };

export type ReviewDialogProps = {
  state: ReviewDialogState;
  onUpload: (args: {
    filename: string;
    title: string;
    description: string;
  }) => void;
  onClose: () => void;
};

export const ReviewDialog = ({
  state,
  onUpload,
  onClose,
}: ReviewDialogProps) => {
  const open = state.type !== "closed";

  return (
    <PopupDialog open={open} hasPadding={false}>
      <Stack
        gap="1rem"
        sx={{
          marginTop: "2rem",
          marginLeft: "2rem",
          marginRight: "2rem",
        }}
        data-testid="upload-form-to-external-system"
      >
        <Header type={state.type} onClose={onClose} />
        <Stack sx={{ alignItems: "stretch", gap: "1rem" }}>
          {state.type === "uploading" && <BodyUpload />}
          {state.type === "open" && (
            <BodyReview
              systemName={state.systemName}
              initialFilename={state.initialFilename}
              initialTitle={state.initialTitle}
              initialDescription={state.initialDescription}
              pdf={state.pdf}
              onClose={onClose}
              onUpload={onUpload}
            />
          )}
        </Stack>
        {(state.type === "failed" || state.type === "succeeded") && (
          <FooterClose onClose={onClose} />
        )}
      </Stack>
    </PopupDialog>
  );
};

const Header = ({
  type,
  onClose,
}: Pick<ReviewDialogState, "type"> & Pick<ReviewDialogProps, "onClose">) => {
  const noOp = useCallback(() => {}, []);

  const header =
    type === "open"
      ? "Upload to Rio"
      : type === "uploading"
        ? "Uploading to Rio..."
        : type === "succeeded"
          ? "Form uploaded to Rio successfully"
          : type === "failed"
            ? "Failed to upload to Rio"
            : null;

  return (
    <>
      {header && (
        <PopupDialogTitle
          titleText={header}
          closeDialog={type === "uploading" ? noOp : onClose}
          removeMarginBottom
        />
      )}
      {type === "open" ? (
        <Banner
          bannerType={BannerList.WARNING}
          body={[
            "You are about to upload this form to Rio. Please make sure you have selected the correct form.",
            "You can upload forms multiple times. New uploads will never replace previous uploads although they might be the same form.",
          ]}
        />
      ) : type === "succeeded" ? (
        <Banner
          bannerType={BannerList.INFO}
          body={[
            "Please open Rio and check that the form has been uploaded to the Document List View of the correct patient record. ",
          ]}
        />
      ) : type === "failed" ? (
        <Banner
          bannerType={BannerList.ERROR}
          body={[
            "Something went wrong, the upload to Rio was unsuccessful. Click CLOSE to try again.",
            "The Thalamos support team have been informed about this error.",
          ]}
        />
      ) : null}
    </>
  );
};

const BodyReview = ({
  systemName,
  initialFilename,
  initialTitle,
  initialDescription,
  pdf,
  onClose,
  onUpload,
}: Omit<Extract<ReviewDialogState, { type: "open" }>, "type"> &
  Pick<ReviewDialogProps, "onClose" | "onUpload">) => {
  const [filename, setFilename] = useState<string>(initialFilename);
  const [title, setTitle] = useState<string>(initialTitle);
  const [description, setDescription] = useState<string>(initialDescription);

  const isValid = filename.endsWith(".pdf");

  return (
    <>
      <ReadOnlyContent label={"Rio System"} content={[systemName]} />
      <TextField
        data-testid="filename"
        name="filename"
        label="Filename"
        value={filename}
        onChange={setFilename}
        useFullWidth={true}
        errorMessage={isValid ? undefined : "Filename must end with .pdf"}
        showHelperText={true}
      />
      <TextField
        data-testid="title"
        name="title"
        label="Title"
        value={title}
        onChange={setTitle}
        useFullWidth={true}
      />
      <TextField
        data-testid="description"
        name="description"
        label="Description"
        value={description}
        onChange={setDescription}
        useFullWidth={true}
        multiline={true}
        rows="3"
      />

      <>
        <FormLabel label="Form preview" />
        <Paper
          elevation={4}
          sx={{
            width: "100%",
            maxHeight: "500px",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          <MultiPagePdf data={pdf} maxWidth={600} />
        </Paper>
      </>
      <FormFooterSection
        isSticky={true}
        isForcedSticky={true}
        saveLabel="Upload"
        onSave={() => onUpload({ filename, title, description })}
        discardLabel="Close"
        onCancel={onClose}
        disableSubmit={!isValid}
      />
    </>
  );
};

const BodyUpload = () => {
  return (
    <Box sx={{ alignSelf: "center", marginBottom: "2rem" }}>
      <LoadingSpinner label="Uploading" />
    </Box>
  );
};

const FooterClose = ({ onClose }: Pick<ReviewDialogProps, "onClose">) => {
  return (
    <Stack
      gap="1rem"
      direction="row"
      sx={{ justifyContent: "center", marginBottom: "2rem" }}
    >
      <Button variant="outlined" label="Close" onClick={onClose} />
    </Stack>
  );
};
