import {
  CTO12Data,
  cto12Part1DataSchema,
  Cto12Part1DataSchema,
} from "../schemas/mhaForms/cto/cto12";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto12Part1Template: FormPartTemplate<Cto12Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },

    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "approved-clinician-confirmation-checkbox",
      label:
        "I am the approved clinician in charge of the treatment of this patient who is subject to a community treatment order",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },

    {
      type: "branch",
      field: "patientConsentOrCompetenceCertification",
      label: "Confirm the correct option",
      branches: [
        {
          fieldValue: "patient-consent-certification",
          label:
            "I certify that this patient has the capacity to consent and has consented to the following treatment",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: "patient-competence-and-consent",
          label:
            "I certify that this patient is competent to consent and has consented to the following treatment",
          fieldsOwned: [],
          fields: [],
        },
      ],
    },

    {
      type: "textbox",
      field: "treatmentDescription",
      label:
        "The treatment is: (Give description of treatment plan or plan of treatment)",
      rows: "5",
    },
  ],
  dataSchema: cto12Part1DataSchema,
};

export const cto12V1Base: BaseFormTemplate = {
  id: "mha-cto12",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO12",
  section: "64C(4A)",
  parts: [cto12Part1Template],
  description:
    " certificate that community patient has capacity to consent (or if under 16 is competent to consent) to treatment and has done so (Part 4A consent certificate)",
  extractGenericData: (data: CTO12Data) => ({}),
};
