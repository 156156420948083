import React, { useState } from "react";
import { Element } from "react-scroll";
import Fuse from "fuse.js";
import { TextField, TextFieldProps } from "../TextField/TextField";
import { Autocomplete, Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import { SerializedStyles } from "@emotion/react";
import { useTheme } from "@mui/material/styles";

const filterOptions = <T,>(
  options: T[],
  inputValue: string,
  optionsKey: string,
) => {
  if (!inputValue) return options;
  const fuse = new Fuse(options, {
    keys: [optionsKey],
  });
  return fuse.search(inputValue).map((s) => s.item);
};

export interface TypeaheadProps<T> extends TextFieldProps {
  label?: string;
  options: T[];
  getOptionLabel?: (option: string | T) => string;
  onInputChange?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  inputValue?: any;
  testId?: string;
  maxLength?: number;
  allowFreeText?: boolean;
  name: string;
  optionsKey?: string;
  enableFuse?: boolean;
  groupBy?: (option: T) => string;
  css?: SerializedStyles;
  getOptionSubLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: any,
  ) => React.ReactNode;
}

const defaultProps: Partial<TypeaheadProps<any>> = {
  loading: false,
  onInputChange: () => {},
  label: undefined,
  placeholder: undefined,
  subtext: undefined,
  subtextIcon: undefined,
  errorMessage: undefined,
  inputValue: "",
  onBlur: undefined,
  disabled: false,
  InputProps: undefined,
  maxLength: undefined,
  allowFreeText: true,
  optionsKey: "label",
  enableFuse: true,
  groupBy: undefined,
  renderOption: undefined,
};

export const Typeahead = <T,>(props: TypeaheadProps<T>) => {
  const {
    label,
    options,
    getOptionLabel,
    onInputChange,
    disabled,
    loading,
    inputValue,
    maxLength,
    allowFreeText,
    name,
    optionsKey,
    enableFuse,
    errorMessage,
    onBlur,
    groupBy,
    getOptionSubLabel,
    renderOption,
    ...rest
  } = { ...defaultProps, ...props };

  const theme = useTheme();

  const testId = props.testId ? `field:${props.testId}` : undefined;
  const [textValue, setTextValue] = useState(inputValue);

  return (
    <Element name={name} data-testid={testId} style={{ position: "relative" }}>
      <Autocomplete
        filterOptions={
          enableFuse
            ? () => filterOptions(options, inputValue, optionsKey!)
            : options.some(
                  (option) =>
                    (option as any)[optionsKey!].toLowerCase() ===
                    inputValue.toLowerCase(),
                )
              ? (options) => options
              : undefined
        }
        fullWidth
        onBlur={onBlur}
        freeSolo={allowFreeText}
        value={textValue}
        inputValue={inputValue}
        disabled={disabled}
        loading={loading}
        options={options}
        includeInputInList
        getOptionLabel={getOptionLabel}
        ListboxProps={
          {
            "data-testid": testId,
          } as any
        }
        groupBy={groupBy}
        onChange={(e, v) => {
          setTextValue(v);
        }}
        onInputChange={(e, v) => onInputChange!(v)}
        renderOption={renderOption}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Box
              sx={{
                m: 2,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                paddingBottom: theme.spacing(1),
              }}
            >
              {params.group}
            </Box>
            {params.children}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...rest}
              {...params}
              muiTextFieldLabel={label}
              errorMessage={errorMessage}
              showHelperText
              useFullWidth={true}
              name={name}
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
                type: "search",
                inputProps: {
                  maxLength,
                  ...params.inputProps,
                },
                startAdornment: <Search fontSize="medium" color="primary" />,
              }}
            />
          );
        }}
      />
    </Element>
  );
};
