import { InferType, object } from "yup";
import {
  defaultAmhpSchemaFields,
  defaultContextFields,
  hospital,
  nonEmptyString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const cto5Part1DataSchema = object({
  ...defaultContextFields({ approvedClinicianRequired: true }),

  hospital: hospital,

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-recall-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  reasons: nonEmptyString
    .required()
    // @ts-ignore
    .nonNullable("Please enter your reasoning"),
}).required();

export const cto5Part2DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,
}).required();

export const cto5Part3DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
    userAddressOptional: true,
    userNameOptional: true,
  }),
  detainedDateTime: generateDateBeforeTodayValidationRule(
    "date-time",
    "User cannot enter a date in the future.",
  ).required(
    "Please enter the time and date the patient was detained in hospital after being recalled under section 17E(1)",
  ),
}).required();

export type Cto5Part1DataSchema = InferType<typeof cto5Part1DataSchema>;
export type Cto5Part2DataSchema = InferType<typeof cto5Part2DataSchema>;
export type Cto5Part3DataSchema = InferType<typeof cto5Part3DataSchema>;

export type CTO5Data = [
  PartData<Cto5Part1DataSchema>,
  PartData<Cto5Part2DataSchema>?,
  PartData<Cto5Part3DataSchema>?,
];

export const exampleCTO5Data: ExampleFormData<CTO5Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },

      necessaryForPatientsOwnHealth: false,
      necessaryForPatientsOwnSafety: true,
      necessaryForProtectionOfOtherPersons: true,

      reasons:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  },
  {
    data: {
      user: {
        email: "amhp@amhp.com",
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
        localAuthority: "Local Authority",
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Another LA",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      detainedDateTime: "2022-01-10T12:00:00.000Z",
    },
  },
];
