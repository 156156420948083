import {
  CTO5Data,
  cto5Part1DataSchema,
  Cto5Part1DataSchema,
  cto5Part2DataSchema,
  Cto5Part2DataSchema,
  cto5Part3DataSchema,
  Cto5Part3DataSchema,
} from "../schemas/mhaForms/cto/cto5";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto5Part1Template: FormPartTemplate<Cto5Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My name, email address and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of hospital the Community Patient is detained in",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This patient has been recalled to this hospital under Section 17E(1) of the Act.",
      ],
    },

    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital.",
      ],
    },
    {
      type: "multi-select",
      label:
        "(b) In my opinion, it is necessary that this patient should receive treatment in hospital for",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "the patient’s own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "the patient’s own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "the protection of other persons",
        },
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(c) In my opinion, such treatment cannot be provided unless the patient is detained for medical treatment under the Act.",
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "Enter your reasons. Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate; indicate why informal admission is not appropriate.",
      rows: "5",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I am also of the opinion that taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient at the hospital named above",
      ],
    },
  ],
  dataSchema: cto5Part1DataSchema,
};

export const cto5Part2Template: FormPartTemplate<Cto5Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: ["amhp"],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedMentalHealthProfessional,
    },
  },
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    { type: "label", showStaticLabel: true, label: ["I agree that:"] },
    {
      type: "label",
      indent: 3.5,
      showStaticLabel: true,
      label: [
        "(i) the patient meets the criteria for detention in hospital set out above and",
        "(ii) it is appropriate to revoke the community treatment order",
      ],
    },
  ],
  dataSchema: cto5Part2DataSchema,
};

export const cto5Part3Template: FormPartTemplate<Cto5Part3DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I exercise my power under section 17F(4) to revoke the community treatment order in respect of the patient named in Part 1.",
      ],
    },
    {
      type: "date-time",
      label:
        "Enter the time and date the patient was detained in hospital after being recalled under section 17E(1)",
      field: "detainedDateTime",
      maximum: (today) => today.endOf("day"),
    },
    {
      type: "approved-clinician-confirmation-checkbox",
      label: "I am the responsible clinician for this patient",
      checkboxLabel: "Please check to confirm",
      tooltip:
        "A responsible clinician is the approved clinician with overall responsibility for the patient. Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
    },
  ],
  dataSchema: cto5Part3DataSchema,
  autoAssignToPartSignatory: 1,
};

export const cto5V1Base: BaseFormTemplate = {
  id: "mha-cto5",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO5",
  section: "17F(4)",
  parts: [cto5Part1Template, cto5Part2Template, cto5Part3Template],
  description: "Revocation of community treatment order",
  extractGenericData: (data: CTO5Data) => ({}),
};
