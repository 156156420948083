import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Popper,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  useTheme,
  Stack,
} from "@mui/material";
import { Box } from "@mui/material";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { getIconFromName } from "../util";
import { alpha } from "@mui/material";
import { ThalamosLink } from "../ThalamosLink";
import { Button } from "./Button/Button";

export type MenuOptionsType = {
  icon:
    | "view"
    | "person"
    | "delete"
    | "transfer"
    | "edit"
    | "download"
    | "upload"
    | "cancel"
    | "accept"
    | string;
  name: string;
  onClick?: () => void;

  link?: string;

  disabled: boolean;
  tooltip?: string[];
};

interface RefObject<T> {
  readonly current: T | null;
}

interface MenuFlyoutProps<T> {
  options?: MenuOptionsType[];
  emptyMenuMessage?: string;
  isOpen?: boolean;
  cardRef?: RefObject<T>;
  onClose: () => void;
}

const defaultProps: Partial<MenuFlyoutProps<any>> = {
  emptyMenuMessage: undefined,
  isOpen: undefined,
  cardRef: undefined,
  onClose: undefined,
};
export const MenuFlyout = (props: MenuFlyoutProps<any>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const allProps: MenuFlyoutProps<any> = { ...defaultProps, ...props };
  const { options, emptyMenuMessage, isOpen, cardRef, onClose } = allProps;

  const handleOptionSelection = ({
    onClick,
    disabled,
  }: {
    onClick?: MenuOptionsType["onClick"];
    disabled: MenuOptionsType["disabled"];
  }) => {
    if (disabled) return;

    onClose();
    if (onClick) onClick();
  };

  const styles = {
    container: css`
      width: ${theme.spacing(30)};
    `,
    icon: css`
      padding: 0;
      color: ${theme.palette.text.secondary};
      margin-right: ${theme.spacing(1.5)};
      &:hover {
        color: ${theme.palette.primary.main};
      }
    `,
    listItem: css`
      height: ${theme.spacing(7)};
      &:hover {
        background-color: ${theme.palette.background.default};
        cursor: pointer;
        & :first-of-type {
          color: ${theme.palette.primary.main};
          fill: ${theme.palette.primary.main};
        }
      }
    `,
  };

  const renderFlyoutOptions = () => {
    if (options?.length) {
      return (
        <List>
          {options.map((op) => {
            return (
              <ThalamosLink to={op.link} key={op.name}>
                <Tooltip
                  placement="left"
                  arrow
                  title={
                    op.tooltip && (
                      <Stack>
                        {op.tooltip.map((t) => (
                          <Typography>{t}</Typography>
                        ))}
                      </Stack>
                    )
                  }
                >
                  <ListItem
                    key={op.name}
                    disabled={op.disabled}
                    classes={{ root: styles.listItem }}
                    onClick={() => handleOptionSelection(op)}
                  >
                    <ListItemIcon
                      sx={{ minWidth: (theme) => theme.spacing(1.5) }}
                      classes={{ root: styles.icon }}
                    >
                      {getIconFromName(op.icon)}
                    </ListItemIcon>
                    <Typography
                      css={css`
                        font-size: ${theme.spacing(2)};
                      `}
                      variant="inherit"
                    >
                      {op.name}
                    </Typography>
                  </ListItem>
                </Tooltip>
              </ThalamosLink>
            );
          })}
        </List>
      );
    }
    return (
      <List
        css={css`
          background-color: ${theme.palette.background.default};
        `}
      >
        <ListItem key="emptyMessage">
          <Typography
            css={css`
              font-size: ${theme.spacing(1.75)};
            `}
            variant="inherit"
          >
            {emptyMenuMessage}
          </Typography>
        </ListItem>
      </List>
    );
  };

  return (
    <Box>
      <Button
        variant="outlined"
        sx={{
          display: "flex",
          ...(isOpen && {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }),
          backgroundColor: theme.palette.common.white,
        }}
        label={t("buttonLabels.action")}
        color={"primary"}
        customFontSize={1.8}
      />

      <Popper
        open={!!isOpen}
        anchorEl={() => cardRef?.current}
        placement="bottom-end"
        css={styles.container}
        sx={{ zIndex: 9999 }}
      >
        <Paper>
          <ClickAwayListener onClickAway={onClose}>
            {renderFlyoutOptions()}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
};
