import dayjs from "dayjs";
import {
  ErrorValidationOutcome,
  errorValidationOutcome,
  Form,
  getLatestVersion,
  ValidationOutcome,
  warningValidationOutcome,
} from "../../types/form";

// Interface that all med rec form parts should conform to
export interface MedRecFormPart {
  data: {
    user: {
      isSection12Approved: boolean;
    };
    lastExaminedDate: string;
  };
}

/*
  For a person to be detained under section 2 or 3, the person must be assessed by two doctors and an applicant (AMHP
  / Nearest Relative). One of the two doctors must be section 12 approved and at least one of them should also have
  previous acquaintance with the patient. Both doctors must assess the patient either together or separate but there
  must be no more than 5 clear days (so the forms dates must be at most 6 days apart) between the medical examinations.
  The medical recommendations can be a joint form (A3) or two individual forms (A4). The applicant has 14 days beginning
  with when the last examination took place, to complete the application (A2).
*/
export const checkMedRecValidity = (
  forms: Pick<
    Form<MedRecFormPart[]>,
    "formTemplate" | "id" | "versions" | "latestVersion" | "source"
  >[],
  options: {
    validFormsCombinations: Array<Array<string>>;
    requirementsDescription: string;
    requireAtLeastOneSection12: boolean;
    hasManualUpload: boolean;
  },
): ValidationOutcome => {
  let errors: ErrorValidationOutcome[] = [];

  // Validation errors
  const GENERIC_REQUIREMENTS_WARNING = warningValidationOutcome(
    options.requirementsDescription,
  );
  const MAX_FIVE_CLEAR_DAYS = errorValidationOutcome(
    "The dates of the medical examination are more than 5 clear days apart (for example, where the first examination takes place on the 1st of the month, the second can be no later than the 7th of the month).",
  );
  const S12_REQUIRED = errorValidationOutcome(
    "Neither of the medical recommendations is made by a doctor approved under section 12 of the Act",
  );
  const MED_REC_OLDER_THAN_14_DAYS = errorValidationOutcome(
    "The date of the last medical examination was completed more than 14 days ago",
  );
  const MUST_NOT_BE_SIGNED_BY_SAME_DOCTOR = errorValidationOutcome(
    "The medical recommendations are not made by two different doctors",
  );

  // Sort forms so that they match order with the "valid combinations"
  forms.sort((a, b) => a.formTemplate.id.localeCompare(b.formTemplate.id));

  // Find "validCombinations" that have the same number of forms and formTemplate ids as the "forms" array.
  const matchingFormCombination = options.validFormsCombinations
    .filter((formTemplateIds) => formTemplateIds.length === forms.length)
    .find((formTemplateIds) => {
      // Sort formTemplateIds so that they match order with the forms
      return formTemplateIds
        .sort((a, b) => a.localeCompare(b))
        .every((id, index) => id === forms[index].formTemplate.id);
    });
  if (!matchingFormCombination) {
    return GENERIC_REQUIREMENTS_WARNING;
  }

  // Extract the form parts and signatures. There should always be exactly 2 of each, as valid combinations
  // of medical recommendations are either 2 single-part forms, or a a single 2-part form.
  const formParts = forms
    .flatMap((form) => getLatestVersion(form).data || [])
    .map((part) => part.data);
  const signatures = forms.flatMap(
    (form) => getLatestVersion(form).signatures || [],
  );
  if (formParts.length !== 2 || signatures.length !== 2) {
    return GENERIC_REQUIREMENTS_WARNING;
  }

  // There must be no longer than five CLEAR days between each of the medical assessments, for example if
  // an examination is completed on a Monday, the five days are not inclusive of this day (meaning Tuesday to
  // Saturday are clear days therefore Sunday is the last possible day for the second medical examination).
  // (see: https://www.hullappp.co.uk/mental-health-act-1983 Section 4.1.1)
  //
  // So in effect, the forms dates must at most SIX days apart
  //
  // This rule does not explicitly apply to Section 4 med recs. But it implicitly applies because the second med rec
  // must be completed within 72 hours of the application being signed, the application must be signed within 24
  // hours of the first med rec being completed, and 72 + 24 hours = 4 days < 6 days.
  const lastExaminedDates = formParts.map((part) =>
    dayjs(part.lastExaminedDate.slice(0, 10)),
  );
  if (Math.abs(lastExaminedDates[0].diff(lastExaminedDates[1], "day")) > 6) {
    errors.push(MAX_FIVE_CLEAR_DAYS);
  }

  // The applicant has 14 days beginning with when the last examination took place, to complete the application.
  const today = dayjs();
  if (lastExaminedDates.every((date) => today.diff(date, "day") > 14)) {
    errors.push(MED_REC_OLDER_THAN_14_DAYS);
  }

  // In most cases, at least one of the medical recommendations must be made by a Section 12 approved doctor
  const isOneDoctorSection12Approved = formParts.some(
    (part) => part.user.isSection12Approved,
  );
  if (options.requireAtLeastOneSection12 && !isOneDoctorSection12Approved) {
    errors.push(S12_REQUIRED);
  }

  // The medical recommendations must be signed by different doctors and if theres a manual upload then ignore this check
  if (
    signatures[0].userId === signatures[1].userId &&
    !options.hasManualUpload
  ) {
    errors.push(MUST_NOT_BE_SIGNED_BY_SAME_DOCTOR);
  }

  if (errors.length > 0) {
    return {
      type: "error",
      reason: errors.map((e) => e.reason) as string[],
    };
  } else {
    return {
      type: "success",
      reason: [
        "One of the medical recommendations is made by a doctor approved under section 12 of the Act.",
        "The dates of the medical examination are not more than 5 clear days apart.",
        "The date of the last medical examination was completed no more than 14 days ago.",
        "The medical recommendations are made by two different doctors.",
      ],
    };
  }
};
