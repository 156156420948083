import { InferType, object, boolean, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  hospital,
  generateDateBeforeTodayValidationRule,
} from "../../shared";

export const hpdPart1DataSchema = object({
  ...defaultContextFields({}),

  updateUserProfession: string()
    .default(null)
    .required("Please select the option which applies")
    .oneOf(["registeredMedicalPractitioner", "approvedClinician"]),

  updateSection: string()
    .default(null)
    .oneOf(["section52", "section54"])
    .required("Please select the option which applies"),
  updateSectionText: string(),

  hospital: hospital,

  updateReasonForDischarge: string()
    .default(null)
    .oneOf([
      "expireOfTimeframe",
      "noMhaAssessment",
      "notConsideredCriteria",
      "patientFurtherDetained",
      "other",
    ])
    .required("Please state section patient is subject to"),
  updateReasonForDischargeText: string(),

  reasonForDischargeOther: string()
    .nullable()
    .default(null)
    .when("updateReasonForDischarge", {
      is: (value: string) => value === "other",
      then: () => string().required("Please enter reason for discharge"),
      otherwise: () => string().optional(),
    }),

  remainingInHospitalInformalPatient: boolean()
    .default(null)
    .required("Please select either yes or no"),

  dischargeFromDetention: generateDateBeforeTodayValidationRule(
    "date-time",
    "User cannot enter a date in the future.",
  ).required("Please enter date and time of discharge"),
});

export type HPDPart1DataSchema = InferType<typeof hpdPart1DataSchema>;

export type HPDData = [PartData<HPDPart1DataSchema>];

export const exampleHPDData: ExampleFormData<HPDData> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      updateUserProfession: "registeredMedicalPractitioner",
      updateSectionText: "Section 5(2)",
      updateSection: "section52",
      updateReasonForDischargeText: "Expire of timeframe",
      updateReasonForDischarge: "expireOfTimeframe",
      remainingInHospitalInformalPatient: true,
      dischargeFromDetention: "2003-04-05T18:30:00.000Z",
    },
  },
];
