import React, { ChangeEventHandler, useCallback } from "react";
import { Box, Button } from "@mui/material";
import { blobToBase64 } from "~/util";

export type Base64FileUploadProps = {
  onDone: (files: UploadedFile[]) => void;
  label: string;
  accept?: string;
  icon?: any;
  multi?: boolean;
  transcodeImage?: TranscodeImageProps;
  disabled?: boolean;
};

export type TranscodeImageProps = {
  maxWidth: number;
  maxHeight: number;
  mimeType?: string;
};

export type UploadedFile = {
  name: string;
  type: string;
  size: number;
  base64: string | ArrayBuffer;
  file: File;
  transcodedImage?: TranscodedImage;
  inProgressUploadPromise?: Promise<void>;
  uploadedKey?: string;
  error?: boolean;
};

export type TranscodedImage = {
  width: number;
  height: number;
  blob: Blob;
  base64: string;
};

export function Base64FileUpload(props: Base64FileUploadProps): JSX.Element {
  const [loading, setLoading] = React.useState(false);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      setLoading(true);
      try {
        const files = e.target.files !== null ? Array.from(e.target.files) : [];

        // Process each file concurrently. We can't guarantee the order in
        // which they complete, so we collect up all of the promises.
        const allFilePromises = files.map((file) =>
          processFile(file, props.transcodeImage),
        );

        // Wait until all of the promises have "settled".
        // Filter out any files which are rejected.
        const allFiles: UploadedFile[] = (
          await Promise.allSettled(allFilePromises)
        )
          .filter((filePromise) => isFulfilled(filePromise))
          .map(
            (filePromise) =>
              (filePromise as PromiseFulfilledResult<UploadedFile>).value,
          );

        // Hand the processed files back to the caller
        props.onDone(allFiles);
      } finally {
        // @ts-ignore -- Hack to reset the value of the input so that the same file can be uploaded again
        e.target.value = null;
        setLoading(false);
      }
    },
    [props],
  );

  return (
    <Button
      component="label"
      data-testid="upload-button"
      variant="outlined"
      disabled={props.disabled || loading}
    >
      {props.icon && <Box sx={{ mr: 1 }}>{props.icon}</Box>}
      {props.label}
      <input
        accept={props.accept}
        disabled={props.disabled ?? false}
        onChange={handleChange}
        type="file"
        multiple={props.multi}
        capture="environment"
        hidden
      />
    </Button>
  );
}

async function processFile(
  file: File,
  transcodeImageProps?: TranscodeImageProps,
) {
  return new Promise<UploadedFile>((resolve, reject) => {
    // Make new FileReader
    let reader = new FileReader();

    // Success handler
    reader.onload = async () => {
      // Optionally transcode the image
      const isImage = file.type.startsWith("image/");
      let transcodedImage = undefined;
      if (isImage && transcodeImageProps !== undefined && reader.result) {
        try {
          transcodedImage = await transcodeImage(
            file.type,
            reader.result,
            transcodeImageProps,
          );
        } catch {}
      }

      // Make a fileInfo Object
      const fileInfo: UploadedFile = {
        name: file.name,
        type: file.type,
        size: file.size,
        base64: reader.result ?? "",
        file: file,
        transcodedImage,
      };

      resolve(fileInfo);
    };

    // Failure handler
    reader.onerror = () => {
      reject("Failed to read file");
    };

    // Having attached the handlers, trigger the processing
    reader.readAsDataURL(file);
  });
}

async function transcodeImage(
  inputMimeType: string,
  inputContent: ArrayBuffer | string,
  config: TranscodeImageProps,
): Promise<TranscodedImage> {
  return new Promise((resolve, reject) => {
    const mimeType = config.mimeType ?? inputMimeType;

    const img = new Image();

    // Success handler
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const [width, height] = calculateSize(
        img.width,
        img.height,
        config.maxWidth,
        config.maxHeight,
      );
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext("2d");
      if (context !== null) {
        context.drawImage(img, 0, 0, width, height);

        canvas.toBlob(async (blob) => {
          if (blob === null) {
            reject("Failed to transcode image");
          } else {
            try {
              const base64 = await blobToBase64(blob);
              resolve({ width, height, blob, base64 });
            } catch {
              reject("Failed to transcode image");
            }
          }
        }, mimeType);
      }
    };

    // Failure handler
    img.onerror = () => {
      reject("Failed to transcode image");
    };

    // Having attached the handlers, trigger the image loading
    img.src = inputContent as string;
  });
}

// This can be used to filter out rejected Promises
function isFulfilled<T>(
  val: PromiseSettledResult<T>,
): val is PromiseFulfilledResult<T> {
  return val.status === "fulfilled";
}

// This clamps the width and height to the maxWidth and maxHeight
// while maintaining the aspect ratio.
function calculateSize(
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number,
): [number, number] {
  // If the image is already smaller than the maximum size, return it as is
  if (width < maxWidth && height < maxHeight) return [width, height];

  const widthRatio = width / maxWidth;
  const heightRatio = height / maxHeight;
  const ratio = Math.max(widthRatio, heightRatio);

  return [Math.round(width / ratio), Math.round(height / ratio)];
}
