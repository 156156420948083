import { boolean, InferType, object } from "yup";
import {
  defaultAmhpSchemaFields,
  defaultContextFields,
  nonEmptyString,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

// @ts-ignore
export const cto9Part1DataSchema = object({
  ...defaultContextFields({ approvedClinicianRequired: true }),

  patientSubjectToConditionsUnder17B2: boolean()
    .default(null)
    .required(
      "Please confirm if the patient is subject to any conditions under section 17B(2)",
    ),

  conditionsUnderSection17B2: nonEmptyString.when(
    "patientSubjectToConditionsUnder17B2",
    {
      is: true,
      then: (schema) =>
        schema.required(
          "Please enter the conditions the patient is to be subject to under section 17B(2)",
        ),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
});

export const cto9Part2DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,
});

export type Cto9Part1DataSchema = InferType<typeof cto9Part1DataSchema>;
export type Cto9Part2DataSchema = InferType<typeof cto9Part2DataSchema>;

export type CTO9Data = [
  PartData<Cto9Part1DataSchema>,
  PartData<Cto9Part2DataSchema>?,
];

export const exampleCto9Data: ExampleFormData<CTO9Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      patientSubjectToConditionsUnder17B2: true,
      conditionsUnderSection17B2: "conditions",
    },
  },
  {
    data: {
      user: {
        email: "amhp@amhp.com",
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
        localAuthority: "Local Authority",
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Another LA",
    },
  },
];
