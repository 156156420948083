import {
  H3Data,
  h3Part1DataSchema,
  H3Part1DataSchema,
  h3Part2DataSchema,
  H3Part2DataSchema,
} from "../schemas/mhaForms/hospital/h3";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { section4Templates } from "../sections";
import dayjs from "dayjs";
import {
  BaseFormTemplate,
  FormContextMiniForm,
  FormPartTemplate,
  getLatestVersion,
  MhFormContext,
} from "../types/form";

const SECTION_TEXT = {
  "2": "Section 2",
  "3": "Section 3",
  "4": "Section 4",
};

export function isWithinS15AmendPeriod(data: H3Data): boolean {
  if (data.length === 1) {
    let section = data[0].data.sectionValue;
    let admissionDateTime = data[0].data.admissionDateTime;
    return dayjs(admissionDateTime)
      .add(section === "4" ? 72 : 14, section === "4" ? "hours" : "days")
      .isAfter(dayjs(), section === "4" ? undefined : "day");
  } else if (data.length === 2) {
    const admissionDateTime = data[1]!.data.secondMedRecReceivedTime;
    return dayjs(admissionDateTime).add(14, "days").isAfter(dayjs(), "day");
  } else {
    return false;
  }
}

function getApplication(
  context: MhFormContext,
): FormContextMiniForm | undefined {
  const linkedForm = context.linkedForms.find(
    (form) =>
      form.status === "complete" &&
      ["mha-a1", "mha-a2", "mha-a6", "mha-a5", "mha-a9", "mha-a10"].includes(
        form.formTemplate.id,
      ),
  );
  const linkedFormId = linkedForm ? linkedForm.formTemplate.id : null;
  return context.previousForms.find(
    (form) =>
      form.status === "complete" && form.formTemplate.id === linkedFormId,
  );
}

// Determines which section of the MHA (2, 3, or 4) should be filled in on the form
// based on which section the "application" (A2, A6, A9, or A10 form) from the admission
// context was filed under
function sectionValueFn(
  data: Partial<H3Part1DataSchema>,
  context: MhFormContext,
): string | undefined {
  const application = getApplication(context);
  const id = application?.formTemplate?.id;

  if (id === "mha-a1" || id === "mha-a2") return "2";
  if (id === "mha-a5" || id === "mha-a6") return "3";
  if (section4Templates.includes(id!)) return "4";
  return undefined;
}
function sectionDefaultFn(
  data: Partial<H3Part1DataSchema>,
  context: MhFormContext,
) {
  let value = sectionValueFn(data, context);
  let label = SECTION_TEXT[value as keyof typeof SECTION_TEXT];
  if (value && label) {
    return { value, label };
  } else {
    return undefined;
  }
}

// Pre-fills the hospital based on the hospital recorded on the "application" (A2, A6, A9, or A10 form)
// in this form's admission context (returns undefined if no such form is found, which will happen when
// a standalone H3 form is created (outside of an admissions context).
function hospitalDefaultFn(
  data: Partial<H3Part1DataSchema>,
  context: MhFormContext,
) {
  const application = getApplication(context);
  const latestVersionData = getLatestVersion(application)?.data;
  const applicationHospital = latestVersionData?.[0]?.data?.hospital;

  return applicationHospital
    ? { ...applicationHospital, isConfirmed: false }
    : undefined;
}

export const h3Part1Template: FormPartTemplate<H3Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      prohibitedAccreditations: ["doctor"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },

    linkableForms: {
      isBypassable: false,
      isMultiSelect: false,
      confirmAfterCompletion: false,
      source: "form-context",
      formTemplateIds: [
        "mha-a1",
        "mha-a10",
        "mha-a9",
        "mha-a5",
        "mha-a6",
        "mha-a2",
      ],
      checkValidityFn: (forms) => {
        return [
          {
            type: "warning",
            reason:
              "The section 2 and 3 application is valid for 14 days from the last time the doctor examined the patient. The emergency application is valid for 24 hours from the last time the doctor examined the patient or when the application was made. Please make sure to check the validity of the admission forms before proceeding. If you are unsure, please speak to your mental health act team. Please select the application made for purpose of the detention",
            isIgnorable: forms.length === 1,
          },
        ];
      },
    },
  },

  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
      defaultFn: hospitalDefaultFn,
    },
    {
      type: "readonly",
      label: "Patient Name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "branch",
      label: "Confirm the correct option",
      field: "patientWasInHospitalBeforeSectioning",
      branches: [
        {
          fieldValue: true,
          label: "(a) The patient is not already an in-patient in the hospital",
          fields: [
            {
              type: "date-time",
              label: "Enter date and time the patient was admitted to hospital",
              field: "admissionDateTime",
              maximum: (today) => today.endOf("day"),
            },
            {
              type: "dropdown",
              label:
                "What section of the MHA are you admitting the patient under?",
              valueField: "sectionValue",
              textField: "sectionText",
              defaultFn: sectionDefaultFn,
              isReadOnlyFn: (data, context) => !!sectionValueFn(data, context),
              afterLabel: "of the Mental Health Act 1983.",
              options: [
                { value: "2", label: "Section 2" },
                { value: "3", label: "Section 3" },
                { value: "4", label: "Section 4" },
              ],
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "(a) This patient was admitted to this hospital in pursuance of an application for admission under the above stated section of the Mental Health Act 1983.",
              ],
            },
          ],
          fieldsOwned: ["admissionDateTime", "sectionText", "sectionValue"],
        },
        {
          fieldValue: false,
          label: "(b) The patient is already an in-patient",
          fields: [
            {
              type: "dropdown",
              label:
                "What section of the MHA are you admitting the patient under? ",
              valueField: "sectionValue",
              textField: "sectionText",
              defaultFn: sectionDefaultFn,
              isReadOnlyFn: (data, context) => !!sectionValueFn(data, context),
              afterLabel: "of the Mental Health Act 1983.",
              options: [
                { value: "2", label: "Section 2" },
                { value: "3", label: "Section 3" },
                { value: "4", label: "Section 4" },
              ],
            },
            {
              type: "date-time",
              label:
                "Enter date and time you received the application for the admission",
              field: "admissionDateTime",
              maximum: (today) => today.endOf("day"),
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "(b) An application for this patient (who had already been admitted to this hospital) under the above stated section of the Mental Health Act 1983 was received by me on behalf of the hospital managers.",
              ],
            },
            {
              type: "label",
              showStaticLabel: true,
              label: [
                "(b) The patient was accordingly treated as admitted for the purpose of the Act from that time.",
              ],
            },
          ],
          fieldsOwned: ["admissionDateTime", "sectionText", "sectionValue"],
        },
      ],
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
  ],
  dataSchema: h3Part1DataSchema,
};

export const h3Part2Template: FormPartTemplate<H3Part2DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      prohibitedAccreditations: ["doctor"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },
  autoAssignToPartSignatory: 1,

  formBuilderFields: [
    {
      type: "readonly",
      label: "Patient Name",
      textFn: (data) => [data.patient?.name || ""],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "date-time",
      label:
        "Enter date and time the second medical recommendation was received",
      field: "secondMedRecReceivedTime",
      maximum: (now) => now.endOf("day"),
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideEmail: true,
      hideAddress: true,
    },
  ],
  dataSchema: h3Part2DataSchema,
};

export const h3V1Base: BaseFormTemplate = {
  id: "mha-h3",
  category: "hospital",
  version: "1.0",
  status: "active",
  parts: [h3Part1Template, h3Part2Template],
  formName: "H3",
  section: "2, 3 and 4",
  description: "Record of detention in Hospital",
  linkableForms: [
    { templateId: "mha-a1", reason: "acknowledges" },
    { templateId: "mha-a2", reason: "acknowledges" },
    { templateId: "mha-a5", reason: "acknowledges" },
    { templateId: "mha-a6", reason: "acknowledges" },
    { templateId: "mha-a10", reason: "acknowledges" },
    { templateId: "mha-a9", reason: "acknowledges" },
  ],
  isCompleteFn: (data: H3Data) => {
    // Form is complete if both parts are signed
    if (data.length === 2) return true;

    // Form is complete if one part is signed and it's a Section 2 or 3 H3
    const section = data[0].data.sectionValue;
    return section === "2" || section === "3";
  },
  extractGenericData: (data: H3Data) => ({
    admissions: {
      admissionDateTime: data[0]?.data.admissionDateTime,
      admissionHospitalName: data[0]?.data.hospital.name,
      sectionValue: data[0]?.data.sectionValue,
      admissionHospitalAddress: data[0]?.data.hospital.address,
      admissionsHospitalPostcode: data[0]?.data.hospital.postalCode,
      patientWasInHospitalBeforeSectioning:
        data[0]?.data.patientWasInHospitalBeforeSectioning,
      secondMedRecRecievedDateTime: data[1]?.data.secondMedRecReceivedTime,
      section4H3WithOnlyPart1Completed:
        data.length === 1 && data[0].data.sectionValue === "4",
    },
  }),
};
