import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "./design-system/Button/Button";
import React from "react";
import { css } from "@emotion/react";

export function FormCategoryRow({
  onLaunchForm,
  title,
  subtitle,
  launchFormLabel,
  buttonTestId,
  disabled,
}: {
  title: string;
  subtitle: string;
  onLaunchForm: () => void;
  launchFormLabel: string;
  buttonTestId?: string;
  disabled?: boolean;
}) {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          backgroundColor: "#FCFCFC",
          gap: theme.spacing(1.25),
          borderRadius: theme.spacing(0.75),
          justifyContent: "space-between",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          },
          opacity: disabled ? 0.7 : 1,
        }}
      >
        <Box
          css={css`
            @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
              width: 100%;
            }
            width: 50%;

            border-left: ${theme.spacing(0.4) +
            ` solid ` +
            theme.palette.primary.main};
            padding-left: ${theme.spacing(1.2)};
          `}
        >
          <Typography sx={{ fontSize: 22, fontWeight: 400 }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: 13, color: "primary.hint" }}>
            {subtitle}
          </Typography>
        </Box>

        <Box sx={{ mt: { md: 0, sm: 3 } }}>
          <Button
            color="primary"
            label={launchFormLabel}
            onClick={onLaunchForm}
            variant="contained"
            testId={buttonTestId}
            disabled={disabled}
          />
        </Box>
      </Box>
    </>
  );
}
