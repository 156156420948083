import dayjs, { Dayjs } from "dayjs";
import { Duration } from "dayjs/plugin/duration";
import { MhaStatus, PatientState } from "../types/patientState/PatientState";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { UpdatePatientStateWithManualEventRequest } from "../types/patientState/patientStateRequests";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export function isFirstDayOfMonth(utcString: string) {
  return (
    dayjs.utc(utcString).tz("Europe/London").startOf("day").format("DD") ===
    "01"
  );
}

export function startOfDayUkTime(timestamp: string): string {
  return dayjs(timestamp)
    .tz("Europe/London")
    .startOf("day")
    .utc()
    .toISOString();
}

export function grossTimezoneAwareAdd(
  startDate: Dayjs,
  duration: Duration,
  zone: string = "Europe/London",
) {
  const endDate = startDate.add(duration);
  const endDateOffset = endDate.tz(zone).utcOffset();
  const startDateOffset = startDate.tz(zone).utcOffset();
  const offsetDiff = startDateOffset - endDateOffset;

  if (offsetDiff > 0) {
    return startDate.add(offsetDiff, "minutes").add(duration);
  }
  return endDate.add(offsetDiff, "minutes");
}

export function getRenewalDates(
  initialStartDate: string,
  numberOfRenewals: number,
) {
  if (initialStartDate && dayjs(initialStartDate).isValid()) {
    const isInitialStartDateFirstDayOfMonth =
      isFirstDayOfMonth(initialStartDate);

    const startOfDay = startOfDayUkTime(initialStartDate);

    const originalRenewalEndDate = calculateSection3OrCtoEndDate(
      startOfDay,
      0,
      isInitialStartDateFirstDayOfMonth,
    );

    const currentRenewalStartDate = dayjs
      .utc(
        calculateSection3OrCtoEndDate(
          startOfDay,
          numberOfRenewals - 1,
          isInitialStartDateFirstDayOfMonth,
        ),
      )
      .add(1, "seconds")
      .toISOString();

    const currentRenewalEndDate = calculateSection3OrCtoEndDate(
      startOfDay,
      numberOfRenewals,
      isInitialStartDateFirstDayOfMonth,
    );

    return {
      originalRenewalEndDate,
      currentRenewalStartDate,
      currentRenewalEndDate,
    };
  }

  return {
    originalRenewalEndDate: undefined,
    currentRenewalStartDate: undefined,
    currentRenewalEndDate: undefined,
  };
}

/*
  Initial period 6 months. A section 3 / cto can be renewed after 6 months.
  After that, it can be renewed every 12 months.
*/
export function calculateSection3OrCtoEndDate(
  initialStartDate: string,
  numberOfRenewals: number,
  isInitialStartDateFirstDayOfMonth: boolean,
): string {
  let numberOfMonthsToAdd;
  switch (numberOfRenewals) {
    case 0:
      numberOfMonthsToAdd = 6;
      break;
    case 1:
      numberOfMonthsToAdd = 12;
      break;
    default:
      numberOfMonthsToAdd = 12 + (numberOfRenewals - 1) * 12;
      break;
  }

  if (isInitialStartDateFirstDayOfMonth) {
    return grossTimezoneAwareAdd(
      dayjs.utc(initialStartDate),
      dayjs.duration(numberOfMonthsToAdd, "months"),
    )
      .subtract(1, "seconds")
      .toISOString();
  }

  // Subtract a second to make the end date exclusive
  return grossTimezoneAwareAdd(
    dayjs.utc(initialStartDate).subtract(1, "seconds"),
    dayjs.duration(numberOfMonthsToAdd, "months"),
  ).toISOString();
}

export function getSection2ExpiryDate(effectiveDateTime: string): string {
  const section2StartDateTime = startOfDayUkTime(effectiveDateTime);
  return dayjs(section2StartDateTime)
    .subtract(1, "seconds")
    .add(28, "days")
    .toISOString();
}

export function getExpiryDate({
  MhaStatusType,
  effectiveDateTime,
  numberOfRenewals,
}: {
  MhaStatusType: MhaStatus;
  effectiveDateTime?: string;
  numberOfRenewals?: number;
}): string | undefined {
  switch (MhaStatusType) {
    case MhaStatus.Section5_4:
      return dayjs(effectiveDateTime).add(6, "hours").toISOString();
    case MhaStatus.Section5_2:
    case MhaStatus.Section4:
      return dayjs(effectiveDateTime).add(72, "hours").toISOString();
    case MhaStatus.Section2:
      return getSection2ExpiryDate(effectiveDateTime!);
    case MhaStatus.Section3:
    case MhaStatus.Section17A:
      const { currentRenewalEndDate } = getRenewalDates(
        effectiveDateTime!,
        numberOfRenewals!,
      );
      return currentRenewalEndDate;
  }
}

export function isValidStartDateForManualEvent(
  manualEvent: UpdatePatientStateWithManualEventRequest,
): boolean {
  const { status } = manualEvent;
  if (status === MhaStatus.Section3 || status === MhaStatus.Section17A) {
    // startDateTime is optional but if it is set should be in the past
    if (manualEvent.startDateTime) {
      return dayjs(manualEvent.startDateTime).isBefore(dayjs());
    }
  } else if (
    status === MhaStatus.Section5_4 ||
    status === MhaStatus.Section5_2 ||
    status === MhaStatus.Section4 ||
    status === MhaStatus.Section2
  ) {
    // startDateTime is required and should be in the past
    return dayjs(manualEvent.startDateTime).isBefore(dayjs());
  }
  return true;
}

export type MHAStatusType = "pending-renewal" | "pending-cto" | "active";

export function getActiveMhaStatus(
  state: PatientState,
  asOfEffectiveTime: string,
): {
  state: MhaStatus;
  type: MHAStatusType;
} {
  // These states have no start or expiry date
  if (
    [
      MhaStatus.Unknown,
      MhaStatus.Expired,
      MhaStatus.Incomplete,
      MhaStatus.NotDetained,
    ].includes(state.mhaStatus.status)
  )
    return { state: state.mhaStatus.status, type: "active" };

  if (state.mhaStatus.startDateTime && state.mhaStatus.expiryDateTime) {
    // Section in the future (e.g. renewed CTO or section 3 or a pending CTO)
    if (
      dayjs(state.mhaStatus.startDateTime).isAfter(dayjs(asOfEffectiveTime))
    ) {
      // Renewed Section 3 or CTO which has yet to come into effect
      if (
        dayjs(state.mhaStatus.initialStartDateTime).isBefore(
          dayjs(asOfEffectiveTime),
        )
      ) {
        return { state: state.mhaStatus.status, type: "pending-renewal" };
      }

      // Pending CTO means the patient is currently on a Section 3
      if (state.mhaStatus.status === MhaStatus.Section17A) {
        return { state: MhaStatus.Section3, type: "pending-cto" };
      }
    }

    // Currently in-effect section
    if (
      dayjs(state.mhaStatus.startDateTime).isBefore(dayjs(asOfEffectiveTime)) &&
      dayjs(state.mhaStatus.expiryDateTime).isAfter(dayjs(asOfEffectiveTime))
    ) {
      return { state: state.mhaStatus.status, type: "active" };
    }
  }

  return { state: MhaStatus.Unknown, type: "active" };
}
