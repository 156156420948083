import {
  A4Data,
  a4Part1DataSchema,
  A4Part1DataSchema,
} from "../schemas/mhaForms/admission/a4";
import {
  A4UploadableData,
  a4UploadablePart1DataSchema,
  A4UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a4";
import { validateSection2ExaminationDate } from "./section2";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";

export const A4SigningRequirments: FormPartTemplate<A4Part1DataSchema>["signing"] =
  {
    userOrTeamRequirement: { accreditation: ["doctor"], teamType: [] },
  };

export const a4Part1Template: FormPartTemplate<A4Part1DataSchema> = {
  signing: A4SigningRequirments,
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address ",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I, a registered medical practitioner, recommend that this patient be admitted to a hospital for assessment in accordance with Part 2 of the Mental Health Act 1983. ",
      ],
    },
    {
      type: "date",
      label: "Enter the date you last examined the patient on",
      field: "lastExaminedDate",
      maximum: (today) => today,
      warningFn: validateSection2ExaminationDate,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) In my opinion, this patient is suffering from mental disorder of a nature or degree which warrants the detention of the patient in hospital for assessment (or for assessment followed by medical treatment) for at least a limited period.",
      ],
    },
    {
      type: "multi-select",
      label: "(b) In my opinion, this patient ought to be so detained",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "in the interests of the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "in the interests of the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "with a view to the protection of other persons",
        },
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "My reasons for these opinions are: (Your reasons should cover both (a) and (b) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; explain why the patient ought to be admitted to hospital and why informal admission is not appropriate.)",
      rows: "10",
    },
  ],
  dataSchema: a4Part1DataSchema,
};

export const a4UploadablePart1Template: FormPartTemplate<A4UploadablePart1DataSchema> =
  {
    signing: A4SigningRequirments,
    formBuilderFields: [
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data, context) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date",
        label: "Date signee last examined this patient on",
        field: "lastExaminedDate",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label: "Did signee have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },
      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is signee approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },
      {
        type: "multi-select",
        label: "Confirm detention criteria",
        options: [
          {
            field: "necessaryForPatientsOwnHealth",
            label: "in the interests of the patient's own health",
          },
          {
            field: "necessaryForPatientsOwnSafety",
            label: "in the interests of the patient's own safety",
          },
          {
            field: "necessaryForProtectionOfOtherPersons",
            label: "with a view to the protection of other persons",
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Date of signature",
        maximum: (today) => today,
      },
    ],

    dataSchema: a4UploadablePart1DataSchema,
  };

export const a4V1Base: UploadableBaseFormTemplate = {
  id: "mha-a4",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a4Part1Template],
  formName: "A4",
  section: "2",
  description: "Medical Recommendation for Admission for Assessment",
  extractGenericData: (data: A4Data | A4UploadableData) => ({
    admissions: {
      section12ApprovedStatus: [data[0].data?.user?.isSection12Approved!],
      lastExaminedDate: [data[0].data?.lastExaminedDate],
      examinerPreviousAcquaintance: data[0].data?.previousAcquaintance,
      necessaryForPatientsOwnHealth:
        data[0].data?.necessaryForPatientsOwnHealth,
      necessaryForPatientsOwnSafety:
        data[0].data?.necessaryForPatientsOwnSafety,
      necessaryForProtectionOfOtherPersons:
        data[0].data?.necessaryForProtectionOfOtherPersons,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [a4UploadablePart1Template],
};
