import React from "react";
import { PopupDialog } from "~/components/design-system";
import { Box } from "@mui/material";
import { PatientEditPage } from "../../pages/PatientIndex/PatientEditPage";
import { useNavigate, useParams } from "react-router-dom";
import { routeFns } from "../../routes";

export const EditPatientDialog = ({
  refetchDraft,
}: {
  refetchDraft: () => void;
}) => {
  const navigate = useNavigate();
  const { formDraftId, patientId } = useParams();

  return (
    <>
      <PopupDialog
        open={true}
        onClose={() =>
          navigate(routeFns.formDraftsComplete(formDraftId!, patientId!))
        }
      >
        <Box display="flex" flexDirection="column" sx={{ padding: "1em" }}>
          <PatientEditPage
            mode="editPatientDialog"
            refetchDraft={refetchDraft}
          />
        </Box>
      </PopupDialog>
    </>
  );
};
