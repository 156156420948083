import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import { Box } from "@mui/material";
import { css } from "@emotion/react";
import ThalamosLogo from "../assets/img/thalamos_logo.svg";
import ReactCodeInput from "react-code-input";
import { Banner, BannerList, Button } from "~/components/design-system";

export function GuestUserLoginPage({}: {}) {
  const query = new URLSearchParams(window.location.search);
  const userId = query.get("userId");
  const returnTo = query.get("returnTo");

  const [email, setEmail] = React.useState("");
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState<string | null>(null);

  async function requestCode(forceSend: boolean) {
    setCodeLoading(true);
    const result = await api.users.requestGuestUserLoginCode(
      userId!,
      forceSend,
    );
    setCodeLoading(false);

    if (result.status === 200) {
      setEmail(result.data.email);
    } else if (result.status === 429) {
      setCodeError((result.data as any).reason);
    } else {
      setCodeError(
        "This link is invalid. Please contact support@thalamos.co.uk if you believe you should have access to a Thalamos Guest Login",
      );
    }
  }

  useEffect(() => {
    requestCode(false);
  }, []);

  const navigate = useNavigate();

  const [code, setCode] = React.useState("");
  const [isSubmittingCode, setIsSubmittingCode] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState<string | null>(
    null,
  );

  const [codeResent, setCodeResent] = React.useState(false);

  return (
    <Box
      css={css`
        display: flex;
        width: 100%;
      `}
    >
      {!codeLoading && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: { md: 4, lg: 5 },
            margin: "2em",
            py: 2,
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={ThalamosLogo} alt="logo" height="70px" />
          <h2>Guest User Login</h2>
          {codeError && (
            <Banner title={codeError} bannerType={BannerList.ERROR} />
          )}
          {email && (
            <>
              <div>
                A code has been emailed to your email address ({email}). Please
                enter it in the box below
              </div>
              <Box sx={{ mb: 2, mt: 2 }}>
                <ReactCodeInput
                  type="text"
                  pattern={"[0-9]*"}
                  fields={6}
                  name={"code"}
                  inputMode={"latin"}
                  value={code}
                  onChange={(value) => setCode(value)}
                />
              </Box>
              {submissionError && (
                <Box sx={{ mb: 2 }}>
                  <Banner
                    bannerType={BannerList.ERROR}
                    title={submissionError}
                    dense
                  />
                </Box>
              )}
              <Button
                label={"Login"}
                disabled={!code.match(/[0-9]{6}/) || isSubmittingCode}
                onClick={async () => {
                  setIsSubmittingCode(true);
                  setSubmissionError(null);

                  try {
                    const codeResult = await api.users.tryGuestUserLoginCode(
                      userId!,
                      code,
                    );
                    const reason = (codeResult.data as any).reason;

                    if (codeResult.status === 204) {
                      navigate(returnTo || "/");
                    } else if (codeResult.status === 400) {
                      setSubmissionError(reason);
                    } else if (codeResult.status === 429) {
                      setSubmissionError(reason);
                    } else {
                      setSubmissionError("Unknown Error");
                    }
                  } catch (e) {
                    setSubmissionError("Unknown Error");
                  } finally {
                    setIsSubmittingCode(false);
                  }
                }}
              />
              <Box sx={{ mt: 3, fontSize: "smaller" }}>
                {codeResent ? (
                  "Code Sent"
                ) : (
                  <button
                    onClick={async () => {
                      if (!codeResent) {
                        await requestCode(true);
                        setCodeResent(true);
                      }
                    }}
                  >
                    Resend Code
                  </button>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
