import { useEffect } from "react";
import { ValidationOutcome } from "@aspire/common/types/form";
import {
  FormTemplateWithLongDescription,
  SelectedFormsObject,
} from "@aspire/common/util/form";

// Update the selected formTemplate when the value from the form selection input changes
export function useFormTemplateUpdater(
  formTemplateIdFromUrl: string | undefined,
  formInputValue: string,
  activeTemplates: FormTemplateWithLongDescription[],
  formTemplate: FormTemplateWithLongDescription | undefined,
  setFormTemplate: React.Dispatch<
    React.SetStateAction<FormTemplateWithLongDescription | undefined>
  >,
) {
  useEffect(() => {
    let template;
    if (formTemplateIdFromUrl) {
      template = activeTemplates.find((t) => t.id === formTemplateIdFromUrl);
    } else {
      template = activeTemplates.find(
        (t) => t.longDescription === formInputValue,
      );
    }
    if (template !== formTemplate) {
      setFormTemplate(template);
    }
  }, [formTemplateIdFromUrl, formInputValue]);
}

// Clear supporting documents form state when form template changes
export function useClearSupportingDocuments(
  formTemplateId: string | undefined,
  setSupportingFormsProvided: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedForms: React.Dispatch<React.SetStateAction<SelectedFormsObject[]>>,
  setNotSelectedReason: React.Dispatch<React.SetStateAction<string>>,
  setValidationOutcome: React.Dispatch<
    React.SetStateAction<ValidationOutcome[] | null>
  >,
) {
  // Clear supporting documents form state when form template changes
  useEffect(() => {
    setSupportingFormsProvided(false);
    setSelectedForms([]);
    setNotSelectedReason("");
    setValidationOutcome(null);
  }, [formTemplateId]);
}
