import React from "react";
import notFound from "../assets/img/notFound.png";

import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "~/components/design-system";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../routes";
import { useTranslation } from "react-i18next";

const classes = (theme: any) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(3),
    },
  },
  title: {
    fontSize: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.secondary.darker,
  },
  paragraph: {
    textAlign: "center",
    fontSize: theme.spacing(2),
    padding: theme.spacing(2),
    color: theme.palette.secondary.darker,
    "&:last-child": {
      marginBottom: theme.spacing(4),
    },
  },
});

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={classes(theme).container}>
      <Box>
        <img src={notFound} alt={"Not Found"} width={400} />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography sx={classes(theme).title}>404 - Page not found</Typography>
        <Box>
          <Typography sx={classes(theme).paragraph}>
            Sorry, the page you are looking for does not exist.
          </Typography>

          <Typography sx={classes(theme).paragraph}>
            It may have been removed, had its name changed or not be available
            in your current work context.
          </Typography>
        </Box>
        <Box
          sx={{
            marginBottom: theme.spacing(5),
          }}
        >
          <Button
            label={t("buttonLabels.home")}
            variant="outlined"
            onClick={() => navigate(routeFns.home())}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
