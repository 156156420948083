import { UUID } from "../util";
import { Team } from "./teams";
import { OrganisationConfiguration } from "./configuration";
import {
  ExtendedMembership,
  ExtendedTeamMembership,
  Membership,
} from "./memberships";
import { UpdateTeamRequest } from "./teamRequests";

export type AcdFacilitatorAccreditation = {
  type: "acd-facilitator";
};

export type DoctorAccreditation = {
  type: "doctor";
  gmcNumber: string; // 7 character numeric or Letter + 6 character numeric
};

export type NurseAccreditation = {
  type: "nurse";
  pinNumber: string; // 8 digits numeric (3rd and last are letters)
};

export type SocialWorkerAccreditation = {
  type: "social-worker";
  registrationNumber: string; // TODO: rules unclear - may start with SW then some digits
};

export type HcpcAccreditation = {
  type: "hcpc";
  registrationNumber: string; // two letters then up to 6 numbers
};

export type AmhpAccreditation = {
  type: "amhp";
  localAuthority: string;
  localAuthorityOrganisationId?: string;
};

export type Section12Accreditation = {
  type: "section-12";
  expiryDate: string; // ISO date
};

export type ApprovedClinicianAccreditation = {
  type: "approved-clinician";
  expiryDate: string; // ISO date
};

export type Accreditation =
  | DoctorAccreditation
  | SocialWorkerAccreditation
  | NurseAccreditation
  | HcpcAccreditation
  | AmhpAccreditation
  | AcdFacilitatorAccreditation
  | Section12Accreditation
  | ApprovedClinicianAccreditation;

// We want to allow people without AC on their profile to launch AC forms, as we
// always ask them to re-confirm on the form itself. These are some sensible defaults
// for the list of accreds which can do this. Technically a social worker can be an AC
// but it is rare, so we require them to have the AC accreditation explicitly on their profile.
export const approvedClinicianAccreds: Accreditation["type"][] = [
  "doctor",
  "nurse",
  "hcpc",
  "approved-clinician",
];

export function validateAccreditation(
  accreditations: Accreditation[],
): string[] {
  const errors: string[] = [];
  const types = new Set(accreditations.map((t) => t.type));

  if (types.size !== accreditations.length) {
    errors.push("Duplicate accreditation type in accreditations");
  }

  if (accreditations.find((acc) => acc.type === "amhp")) {
    if (
      !accreditations.find(
        (accreditation) =>
          accreditation.type === "social-worker" ||
          accreditation.type === "hcpc" ||
          accreditation.type === "nurse",
      )
    ) {
      errors.push(
        "AMHPs must have either a HCPC, Nurse, or Social Worker accreditation",
      );
    }
  }

  if (accreditations.find((acc) => acc.type === "approved-clinician")) {
    if (
      !accreditations.find(
        (accreditation) =>
          accreditation.type === "social-worker" ||
          accreditation.type === "hcpc" ||
          accreditation.type === "nurse" ||
          accreditation.type === "doctor",
      )
    ) {
      errors.push(
        "Approved Clinicians must be either a Doctor, HCPC, Nurse, or Social Worker",
      );
    }
  }

  if (accreditations.find((acc) => acc.type === "section-12")) {
    if (
      !accreditations.find((accreditation) => accreditation.type === "doctor")
    ) {
      errors.push("S12 approved clinicians must be a Doctor");
    }
  }

  return errors;
}

export function isAmhpAccreditation(
  accreditation: Accreditation,
): accreditation is AmhpAccreditation {
  return accreditation.type === "amhp";
}

export function isDoctorAccreditation(
  accreditation: Accreditation,
): accreditation is DoctorAccreditation {
  return accreditation.type === "doctor";
}

export function isSocialWorkerAccreditation(
  accreditation: Accreditation,
): accreditation is SocialWorkerAccreditation {
  return accreditation.type === "social-worker";
}

export function isHcpcAccreditation(
  accreditation: Accreditation,
): accreditation is HcpcAccreditation {
  return accreditation.type === "hcpc";
}

export function isSection12Accreditation(
  accreditation: Accreditation,
): accreditation is Section12Accreditation {
  return accreditation.type === "section-12";
}

export function isNurseAccreditation(
  accreditation: Accreditation,
): accreditation is NurseAccreditation {
  return accreditation.type === "nurse";
}

export function isApprovedClinicianAccreditation(
  accreditation: Accreditation,
): accreditation is ApprovedClinicianAccreditation {
  return accreditation.type === "approved-clinician";
}

export function isAcdFacilitatorAccreditation(
  accreditation: Accreditation,
): accreditation is AcdFacilitatorAccreditation {
  return accreditation.type === "acd-facilitator";
}

export type ThalamosUser = {
  id: UUID;
  name: string;
  email: string;
  address: { address: string; postalCode: string };
  defaultSignature?: string;

  accreditations: Accreditation[];
  memberships: Membership[];

  authenticationMethods: { externalUserId: string }[];
  isOnboarded: boolean;
  hasPidAccess: boolean;
  hasPatientMergeAccess: boolean;

  status: "active" | "deleted";
  mfa?: "enabled" | "disabled";
  defaultSelectedContext?: { id: UUID; role: string } | null;
};

export type UserGuestUserConversionRequest = {
  type: "user";
  name: string;
};

export type TeamGuestUserConversionRequest = {
  type: "team";
  teamType: UpdateTeamRequest["type"];
  parentOrganisationId: UUID;
  teamName: string;
};

export type GuestUserConversionRequest =
  | UserGuestUserConversionRequest
  | TeamGuestUserConversionRequest;

export type UserCreate = {
  email: string;
  name: string;
};

export type UserUpdateEmail = {
  newEmail: string;
};

export type UserUpdate = Partial<
  Pick<
    ThalamosUser,
    | "name"
    | "address"
    | "defaultSignature"
    | "accreditations"
    | "isOnboarded"
    | "hasPidAccess"
    | "hasPatientMergeAccess"
    | "mfa"
    | "defaultSelectedContext"
  >
>;

export type ExtendedThalamosUser = Omit<ThalamosUser, "memberships"> & {
  memberships: ExtendedMembership[];

  // TODO: this can be an organisation membership or a team membership but
  // we have assumed in lots of places it's only a team membership. We need
  // to go through all of these complation issues and fix this
  sessionContext: ExtendedTeamMembership | null;
  sessionOrganisationConfiguration?: OrganisationConfiguration | null;
  isAdminSession?: boolean;
  failedGuestUserLoginCount: number;
  loginCount: number;
  lastLoginTimestamp: string | null;
  sessionsPerContext: { [contextId: string]: number };
  managingOrganisations: { organisationId: UUID; organisationName: string }[];
};

export const isAdminEmail = (email: string) =>
  email.endsWith("@thalamos.co.uk");

export const isAdminUser = (user: ExtendedThalamosUser | null | undefined) =>
  user && user.isAdminSession;

export const isManagerUser = (user: ExtendedThalamosUser | null | undefined) =>
  user && user?.sessionContext?.role === "manager";

export const isTeamManagerUser = (
  user: ExtendedThalamosUser | null | undefined,
) => user && user?.sessionContext?.type === "team" && isManagerUser(user);

export const isGuestUserSession = (
  user: ExtendedThalamosUser | null | undefined,
) => user?.sessionContext?.teamType === "guest";

export const isIndependentUserSession = (
  user: ExtendedThalamosUser | null | undefined,
) => user?.sessionContext?.teamType === "independent";

export const isGuestUser = (user: ExtendedThalamosUser | null | undefined) =>
  user &&
  user.authenticationMethods.length === 0 &&
  user.memberships.length === 1 &&
  user.memberships[0].type === "team" &&
  user.memberships[0].teamStatus === "active" &&
  user.memberships[0].teamType === "guest";

export type UniqueUserData = {
  email: string;
  name: string;
  teamId: string;
  teamName: string;
  teamType: string;
};

export type UserSearchResult = {
  id: UUID;
  name: string;
  email: string;
  type: "user";
  contexts: {
    id: string;
    name: string;
    email: string;
    teamType: Team["type"];
    type: "team";
    role: ExtendedTeamMembership["role"];
    isTrainingOrganisation: boolean;
  }[];
};

export type TeamSearchResult = {
  id: UUID;
  name: string;
  email: string;
  type: "team";
  teamType: Team["type"];
  isTrainingOrganisation: boolean;
};

export type UserOrTeamSearchResult = TeamSearchResult | UserSearchResult;
