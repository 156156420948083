import { NotificationsResponse } from "@aspire/common/types/thalamosNotification";
import React from "react";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { ManagedLocation } from "@aspire/common/managedLocations";

const emptyNotificationsContext = {
  notifications: { notifications: [], unviewedCount: 0, count: 0 },
  notificationsPage: 0,
  setNotificationsPage: () => {},
};

export const NotificationsContext = React.createContext<{
  notifications: NotificationsResponse;
  notificationsPage: number;
  setNotificationsPage: (n: number) => void;
}>(emptyNotificationsContext);

export const LoggedInUserContext = React.createContext<{
  user: ExtendedThalamosUser;
  refetchUser: () => void;
} | null>(null);

export const ManagedLocationsContext = React.createContext<{
  managedLocations: ManagedLocation[];
  refetchManagedLocations: () => void;
}>({ managedLocations: [], refetchManagedLocations: () => {} });
