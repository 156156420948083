import {
  A2Data,
  A2Part1DataSchema,
  a2Part1DataSchema,
} from "../schemas/mhaForms/admission/a2";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A2UploadableData,
  a2UploadablePart1DataSchema,
  A2UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a2";

import { a1AndA2Part1Signing } from "./helpers/signingLogic";

export const a2Part1Template: FormPartTemplate<A2Part1DataSchema> = {
  signing: a1AndA2Part1Signing,
  formBuilderFields: [
    {
      type: "hospital-picker",
      field: "hospital",
      label: "Enter name and address of hospital",
    },
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data, context) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I apply for the admission of this patient for assessment in accordance with Part 2 of the Mental Health Act 1983.",
      ],
    },
    {
      type: "amph-local-authority-picker",
      amActingOnBehalfOfApprovingAuthorityField:
        "amActingOnBehalfOfApprovingAuthority",
      userActingOnBehalfOfField: "nameOfLocalAuthorityActingOnBehalfOf",
    },
    {
      type: "branch",
      label: "Do you know who the nearest relative is?",
      field: "nearestRelativeKnown",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [
            "nearestRelativeStatement",
            "nearestRelativeOrAuthorisedPersonInfo",
            "personInformedNearestRelative",
            "nearestRelativeOrCountyCourt",
          ],
          fields: [
            {
              type: "branch",
              label: "Select the applicable option",
              field: "nearestRelativeStatement",
              branches: [
                {
                  fieldValue: "best-of-my-knowledge",
                  label:
                    "(a) To the best of my knowledge and belief, the following person is the patient's nearest relative within the meaning of the Act",
                  fields: [
                    {
                      type: "name-address",
                      field: "nearestRelativeOrAuthorisedPersonInfo",
                      nameLabel: "Enter name of patient's nearest relative",
                      addressLabel:
                        "Enter address of patient's nearest relative ",
                    },
                    {
                      type: "branch",
                      label:
                        "Have you informed that person that this application is to be made and of the nearest relative's power to order the discharge of this patient?",
                      field: "personInformedNearestRelative",
                      branches: [
                        {
                          fieldValue: true,
                          label: "Yes",
                          fieldsOwned: [],
                          fields: [],
                        },
                        {
                          fieldValue: false,
                          label: "No",
                          fieldsOwned: [],
                          fields: [],
                        },
                      ],
                    },
                  ],
                  fieldsOwned: [
                    "nearestRelativeOrCountyCourt",
                    "nearestRelativeOrAuthorisedPersonInfo",
                    "personInformedNearestRelative",
                  ],
                },
                {
                  fieldValue: "person-has-been-authorised",
                  label:
                    "(b) I understand that the following person has been authorised to exercise the functions under the Act of the patient's nearest relative by:",
                  visibleFields: [
                    {
                      type: "branch",
                      field: "nearestRelativeOrCountyCourt",
                      indent: 3.5,
                      branches: [
                        {
                          fieldValue: true,
                          label: "a county court",
                          fields: [],
                          fieldsOwned: [],
                        },
                        {
                          fieldValue: false,
                          label: "the patient's nearest relative",
                          fields: [],
                          fieldsOwned: [],
                        },
                      ],
                    },
                  ],
                  fields: [
                    {
                      type: "name-address",
                      field: "nearestRelativeOrAuthorisedPersonInfo",
                      addressLabel: "Enter address of authorised person",
                      nameLabel: "Enter name of authorised person ",
                    },

                    {
                      type: "branch",
                      label:
                        "Have you informed that person that this application is to be made and of the nearest relative's power to order the discharge of this patient?",
                      field: "personInformedNearestRelative",
                      branches: [
                        {
                          fieldValue: true,
                          label: "Yes",
                          fieldsOwned: [],
                          fields: [],
                        },
                        {
                          fieldValue: false,
                          label: "No",
                          fieldsOwned: ["nearestRelativeKnownAct"],
                          fields: [],
                        },
                      ],
                    },
                  ],
                  fieldsOwned: [
                    "nearestRelativeOrCountyCourt",
                    "nearestRelativeOrAuthorisedPersonInfo",
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: ["nearestRelativeKnownAct"],
          fields: [
            {
              type: "branch",
              label: "Select the applicable option",
              field: "nearestRelativeKnownAct",
              branches: [
                {
                  fieldValue: "unable-to-ascertain",
                  label:
                    "(a) I have been unable to ascertain who is the patient's nearest relative within the meaning of the Act",
                  fieldsOwned: [],
                  fields: [],
                },
                {
                  fieldValue: "knowledge-and-belief",
                  label:
                    "(b) To the best of my knowledge and belief this patient has no nearest relative within the meaning of the Act",
                  fieldsOwned: [],
                  fields: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "date",
      field: "lastSeenDate",
      label: "Enter the date you last saw the patient on",
      maximum: (today) => today,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This date was within the period of 14 days ending on the day this application is completed.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I have interviewed the patient and I am satisfied that detention in a hospital is in all the circumstances of the case the most appropriate way of providing the care and medical treatment of which the patient stands in need.",
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "This application is founded on two medical recommendations in the prescribed form.",
      ],
    },

    {
      type: "branch",
      field: "medicalPractitionersPreviousAcquaintance",
      label:
        "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
      branches: [
        {
          fieldValue: true,
          label: "Yes",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: false,
          label: "No",
          fieldsOwned: ["noRecommendation"],
          fields: [
            {
              type: "textbox",
              rows: "5",
              field: "noRecommendation",
              label:
                "Explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient",
            },
          ],
        },
      ],
    },
  ],
  dataSchema: a2Part1DataSchema,
};

export const a2UploadablePart1Template: FormPartTemplate<A2UploadablePart1DataSchema> =
  {
    signing: a1AndA2Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },
      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data, context) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },
      {
        type: "branch",
        label: "Is the nearest relative known?",
        field: "nearestRelativeKnown",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },
      {
        type: "date",
        field: "lastSeenDate",
        label: "Date signee last saw the patient on",
        maximum: (today) => today,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date",
        field: "signatureDate",
        label: "Signature Date",
        maximum: (today) => today,
      },
    ],
    dataSchema: a2UploadablePart1DataSchema,
  };

export const a2V1Base: UploadableBaseFormTemplate = {
  id: "mha-a2",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a2Part1Template],
  formName: "A2",
  section: "2",
  description:
    "Application by an Approved Mental Health Professional for Admission for Assessment",
  linkableForms: [
    { templateId: "mha-a3", reason: "supported-by" },
    { templateId: "mha-a4", reason: "supported-by" },
  ],

  extractGenericData: (data: A2Data | A2UploadableData) => ({
    admissions: {
      applicationHospitalName: data[0].data?.hospital.name,
      nearestRelativeKnown: data[0].data?.nearestRelativeKnown!,
      amhpLastSeenDate: data[0].data?.lastSeenDate,

      // This field isn't included in the uploaded form
      ...("personInformedNearestRelative" in data[0].data
        ? {
            personInformedNearestRelative:
              data[0].data.personInformedNearestRelative!,
          }
        : {}),
    },
  }),

  // Form upload mini-templates
  uploadable: true,
  uploadablePartTemplates: [a2UploadablePart1Template],
};
