import { AutoSaveState, AutoSaveAction } from "../components/types/form";
import dayjs from "dayjs";

export const initalAutoSaveState = {
  isLoading: false,
  hasAttemptedSave: false,
  error: false,
  lastSavedAt: undefined,
};
export function autoSaveStateReducer(
  prevState: AutoSaveState,
  action: AutoSaveAction,
): AutoSaveState {
  switch (action) {
    case "loading":
      return {
        isLoading: true,
        hasAttemptedSave: true,
        error: false,
        lastSavedAt: prevState.lastSavedAt,
      };
    case "success":
      return {
        isLoading: false,
        hasAttemptedSave: true,
        error: false,
        lastSavedAt: dayjs(),
      };
    case "error":
      return {
        isLoading: false,
        hasAttemptedSave: true,
        error: true,
        lastSavedAt: prevState.lastSavedAt,
      };
  }
}
