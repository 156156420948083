export enum RequirementFriendlyDescription {
  onBehalfOfResponsibleHospital = "onBehalfOfResponsibleHospital",
  onBehalfOfResponsibleHospitalOrLocalAuthority = "onBehalfOfResponsibleHospitalOrLocalAuthority",
  registeredNurse = "registeredNurse",

  responsibleClinician = "responsibleClinician",

  approvedMentalHealthProfessional = "approvedMentalHealthProfessional",

  professionalConcernedWithPatientCareDifferentProfessionFromResponsibleClinician = "professionalConcernedWithPatientCareDifferentProfessionFromResponsibleClinician",

  approvedClinicianOrRegisteredMedicalPractitioner = "approvedClinicianOrRegisteredMedicalPractitioner",

  approvedClinicianOrMedicalPractitionerUnderSection52 = "approvedClinicianOrMedicalPractitionerUnderSection52",

  registeredMedicalPractitioner = "registeredMedicalPractitioner",
}
