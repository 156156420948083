import { MhaStatus } from "@aspire/common/types/patientState/PatientState";
import { MHAStatusType } from "@aspire/common/util/patientstate";
import {
  Add,
  ArrowForwardOutlined,
  Check,
  Close,
  CloudDone,
  CloudUpload,
  Delete,
  Edit,
  GetApp,
  Person,
  Undo,
  VerticalAlignCenterOutlined,
  Visibility,
} from "@mui/icons-material";

export const getIconFromName = (iconName: string) => {
  switch (iconName) {
    case "add":
      return <Add fontSize="small" />;
    case "view":
      return <Visibility fontSize="small" />;
    case "person":
      return <Person fontSize="small" />;
    case "delete":
      return <Delete fontSize="small" />;
    case "transfer":
      return <ArrowForwardOutlined fontSize="small" />;
    case "edit":
      return <Edit fontSize="small" />;
    case "download":
      return <GetApp fontSize="small" />;
    case "upload":
      return <CloudUpload fontSize="small" />;
    case "uploadDone":
      return <CloudDone fontSize="small" />;
    case "cancel":
      return <Close fontSize="small" />;
    case "accept":
      return <Check fontSize="small" />;
    case "merge":
      return <VerticalAlignCenterOutlined fontSize="small" />;
    case "undo":
      return <Undo fontSize="small" />;
    default:
      return null;
  }
};

export function formatMhaStatus(
  status: MhaStatus,
  type?: MHAStatusType,
): string {
  switch (status) {
    case MhaStatus.Section2:
      return "Section 2";
    case MhaStatus.Section3:
      if (type === "pending-renewal") {
        return "Section 3 (renewal pending)";
      } else if (type === "pending-cto") {
        return "Section 3 (CTO pending)";
      } else {
        return "Section 3";
      }
    case MhaStatus.Section4:
      return "Section 4";
    case MhaStatus.Section5_2:
      return "Section 5(2)";
    case MhaStatus.Section5_4:
      return "Section 5(4)";
    case MhaStatus.Section17A:
      if (type === "pending-renewal") {
        return "CTO (extension pending)";
      } else {
        return "CTO";
      }
    case MhaStatus.NotDetained:
      return "Not detained";
    case MhaStatus.Unknown:
      return "Unknown";
    case MhaStatus.Incomplete:
      return "Unknown (Incomplete)";
    case MhaStatus.Expired:
      return "Unknown (Expired)";
  }
}
