import {
  HPDData,
  HPDPart1DataSchema,
  hpdPart1DataSchema,
} from "../../schemas/mhaForms/discharge/hpd";
import { RequirementFriendlyDescription } from "../../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../../types/form";

export const hpdPart1Template: FormPartTemplate<HPDPart1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription: RequirementFriendlyDescription.responsibleClinician,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient discharge is made in respect of ",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },

    {
      type: "branch",
      field: "updateUserProfession",
      label: "I am",
      branches: [
        {
          fieldValue: "registeredMedicalPractitioner",
          fieldsOwned: [],
          fields: [],
          label: "The registered medical practitioner",
        },
        {
          fieldValue: "approvedClinician",
          fieldsOwned: [],
          fields: [],
          label:
            "An approved clinician (who is not a registered medical practitioner)",
        },
      ],
    },

    {
      type: "dropdown",
      label: "State section patient is subject to ",
      valueField: "updateSection",
      textField: "updateSectionText",
      options: [
        {
          value: "section52",
          label: "Section 5(2)",
        },
        {
          value: "section54",
          label: "Section 5(4)",
        },
      ],
      fieldsOwned: [],
    },

    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter name and address of the hospital in which the patient is detained",
    },

    {
      type: "dropdown",
      label: "Enter reason for discharge",
      valueField: "updateReasonForDischarge",
      textField: "updateReasonForDischargeText",
      options: [
        {
          value: "expireOfTimeframe",
          label: "Expire of timeframe",
        },
        {
          value: "noMhaAssessment",
          label: "No MHA assessment for continued detention is necessary",
        },
        {
          value: "notConsideredCriteria",
          label:
            "The patient is not considered to meet the criteria following MHA assessment",
        },
        {
          value: "patientFurtherDetained",
          label: "The patient further detained for assessment or treatment",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      fieldsOwned: ["reasonForDischargeOther"],
    },

    {
      type: "branch",
      field: "updateReasonForDischarge",
      branches: [
        {
          hideLabel: true,
          fieldValue: "other",
          label: "other",
          fields: [
            {
              type: "textbox",
              rows: "5",
              field: "reasonForDischargeOther",
              label: "Enter reason for discharge",
            },
          ],
          fieldsOwned: ["reasonForDischargeOther"],
        },
      ],
    },

    {
      type: "branch",
      field: "remainingInHospitalInformalPatient",
      label:
        "Will the patient be remaining in hospital as an informal patient?",
      warningBanner: {
        condition: (data, context) => {
          return data.remainingInHospitalInformalPatient === true;
        },
        message:
          "If the patient is remaining in hospital as an informal patient, you should assess whether the patient has capacity to consent to care and treatment, and where the patient is assessed as lacking capacity, assess if there is a deprivation of liberty. Speak to the the mental health law team if you are unsure.",
      },
      branches: [
        { fieldValue: true, fieldsOwned: [], fields: [], label: "Yes" },
        { fieldValue: false, fieldsOwned: [], fields: [], label: "No" },
      ],
    },
    {
      type: "date-time",
      field: "dischargeFromDetention",
      label: "Enter date and time of discharge from detention",
      maximum: (today) => today.endOf("day"),
    },
  ],
  dataSchema: hpdPart1DataSchema,
};

export const hpdV1Base: BaseFormTemplate = {
  id: "mha-hpd",
  category: "discharge",
  version: "1.0",
  status: "active",
  formName: "Discharge from holding powers",
  section: "5(2), 5(4)",
  parts: [hpdPart1Template],
  description: "",
  extractGenericData: (data: HPDData) => {
    const hospitalDetained = data[0]?.data?.hospital;
    return {
      standalone: {
        hospitalDetained: hospitalDetained
          ? `${hospitalDetained.name} ${hospitalDetained.address} ${hospitalDetained.postalCode}`
          : undefined,
        dischargeFromDetention: data[0].data.dischargeFromDetention,
        stateSectionPatient: data[0].data.updateSectionText,
      },
    };
  },
};
