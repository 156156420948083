import { boolean, InferType, object } from "yup";
import {
  dateAndTimeWithin24HoursValidation,
  defaultContextFields,
  nonEmptyRequiredString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const a11Part1DataSchema = object({
  ...defaultContextFields({ section12Required: true }),

  examinedTimeAndDate: generateDateBeforeTodayValidationRule(
    "date-time",
    "You cannot enter a date and/or time in the future.",
  )
    .concat(dateAndTimeWithin24HoursValidation())
    .required("Please enter the date and time you last examined the patient"),

  previousAcquaintance: boolean()
    .default(null)
    .required("Please select the option which applies"),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  reasons: nonEmptyRequiredString.required("Please enter your reasons"),

  obtainSecondMedicalRecommendation: nonEmptyRequiredString.required(
    "Please estimate time for second medical recommendation and associated risks.",
  ),
});

export type A11Part1DataSchema = InferType<typeof a11Part1DataSchema>;

export type A11Data = [PartData<A11Part1DataSchema>];

export const exampleA11Data: ExampleFormData<A11Data> = [
  {
    data: {
      examinedTimeAndDate: "2022-07-10T12:00:00.000Z",
      previousAcquaintance: false,
      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
      reasons: "Reasons",
      obtainSecondMedicalRecommendation: "Obtain Second Medical Recommendation",
      user: {
        name: "Dr. Sarah Glass",
        address: "143 Fake Street",
        postalCode: "AB1 2CD",
        email: "sarah.glass@houses.com",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: false,
      },
      patient: {
        name: "Bob Smith",
        address: "123 Fake Street",
        postalCode: "AB1 2CD",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
