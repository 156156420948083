import {
  Box,
  Chip,
  Table,
  TableBody,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { api, apiHooks } from "../../../api";
import React, { useRef, useState, useEffect } from "react";
import { ExtendedThalamosUser, isGuestUser } from "@aspire/common/types/user";
import {
  Banner,
  BannerList,
  Button,
  ButtonIcon,
  FormLabel,
  HelperText,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
  PopupDialogTitle,
  SearchBarWithFilter,
  TextField,
  Typeahead,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import {
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { ConfirmationModal } from "../../ConfirmationModal";
import { useTranslation } from "react-i18next";
import { nonEmptyRequiredString } from "@aspire/common/schemas/shared";
import { boolean, object, string } from "yup";
import { Formik } from "formik";
import { ExtendedTeam } from "@aspire/common/types/extendedTeam";
import { CreateTeamRequest } from "@aspire/common/types/teamRequests";
import { Container } from "~/components/layout/styleWrappers";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection";
import { TeamMembership } from "@aspire/common/types/memberships";
import { getRoleLabel } from "~/util";
import { routeFns } from "~/routes";

function UserSelectionModal({
  open,
  onClose,
  onUserSelect,
  roleToAssign,
}: any) {
  const [searchResults, setSearchResults] = React.useState<
    ExtendedThalamosUser[]
  >([]);
  const [userValue, setUserValue] = React.useState<
    string | ExtendedThalamosUser
  >("");

  return (
    <>
      <PopupDialog open={open} onClose={onClose}>
        <PopupDialogTitle
          titleText={`Add ${getRoleLabel(roleToAssign)}`}
          closeDialog={onClose}
        />
        <Typeahead
          options={searchResults}
          name="addUser"
          enableFuse={false}
          inputValue={
            typeof userValue === "string" ? userValue : userValue?.email
          }
          optionsKey="name"
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : `${option.name} (${option.email})`
          }
          onInputChange={async (value: string) => {
            const match = searchResults.find(
              (u) => value === `${u.name} (${u.email})`,
            );
            if (match) {
              setUserValue(match);
            } else {
              setUserValue(value);
            }
            if (value.length > 1) {
              const results = await api.users.search(value, 10, 0);

              const filtered = results.data?.results.filter(
                (u) => !isGuestUser(u),
              );

              setSearchResults(filtered);

              if (match) {
                setUserValue(match);
              }
            } else {
              setSearchResults([]);
            }
          }}
        />
        <Button
          label={"Add"}
          disabled={typeof userValue === "string"}
          onClick={() => onUserSelect(userValue)}
        />
      </PopupDialog>
    </>
  );
}

const teamDetailsSchema = object({
  name: nonEmptyRequiredString.default(""),
  type: nonEmptyRequiredString
    .oneOf(["mha", "ward", "amhp", "generic"])
    .default("mha"),
  organisationId: nonEmptyRequiredString.default(""),
  organisationName: string().default(""),
  primaryEmail: nonEmptyRequiredString.email().default(""),
  hasPatientMergeAccess: boolean().default(false),
});

function TeamBasicDetailsEdit({
  initialData,
  team,
  onSubmit,
}: {
  initialData: Partial<CreateTeamRequest>;
  team: ExtendedTeam | null;
  onSubmit: (data: CreateTeamRequest) => void;
}) {
  return (
    <Formik<CreateTeamRequest & { organisationName: string | null }>
      initialValues={{
        ...teamDetailsSchema.getDefault(),
        ...initialData,
        organisationName: team?.organisationName || "",
        hasPatientMergeAccess: team?.hasPatientMergeAccess || false,
      }}
      onSubmit={(data) => {
        return onSubmit({
          ...data,
          organisationName: undefined,
        } as CreateTeamRequest);
      }}
      validationSchema={teamDetailsSchema}
    >
      {({ values, setValues, isSubmitting, touched, errors, submitForm }) => (
        <Box sx={{ maxWidth: "600px", marginBottom: "2em" }}>
          <FormLabel label={"Name"} error={touched.name && !!errors.name} />
          <TextField
            name={"name"}
            useFullWidth={true}
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e })}
          />
          {errors.name && touched.name && (
            <Box sx={{ mb: 4 }}>
              <HelperText errorMessage={errors.name as string} />
            </Box>
          )}

          <FormLabel
            label={"Email"}
            error={touched.primaryEmail && !!errors.primaryEmail}
          />
          <TextField
            name={"email"}
            useFullWidth={true}
            value={values.primaryEmail}
            onChange={(e) => setValues({ ...values, primaryEmail: e })}
          />
          {errors.primaryEmail && touched.primaryEmail && (
            <Box sx={{ mb: 4 }}>
              <HelperText errorMessage={errors.primaryEmail as string} />
            </Box>
          )}

          {errors.type && touched.type && (
            <Box sx={{ mb: 4 }}>
              <HelperText errorMessage={errors.type as string} />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Chip
                sx={{ fontWeight: "600", mr: 1 }}
                label={`${team?.members.length} members`}
              />
              <Chip
                sx={{ mr: 1, fontWeight: "600" }}
                label={`${team?.type} team`}
              />
            </Box>
            <Button
              disabled={isSubmitting}
              label={"Update Team"}
              onClick={() => {
                return submitForm();
              }}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
}

function TeamMemberRow({
  team,
  member,
  refetchTeam,
  index,
}: {
  refetchTeam: () => void;
  team: ExtendedTeam;
  member: ExtendedTeam["members"][0];
  index: number;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const navigate = useNavigate();

  const theme = useTheme();

  const options: MenuOptionsType[] = [
    {
      name: "Edit Profile",
      onClick: () => {
        navigate(routeFns.userProfilePage(member.userId));
      },
      icon: "edit",
      disabled: false,
    },
    ...(!member.mfa
      ? [
          {
            name: "Enforce MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to enforce MFA for user: ${member.userName} (${member.userEmail})?`,
                confirmFn: async () => {
                  const result = await api.users.enforceMfa(member.userId);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchTeam();
                    renderSuccessToast({
                      message: `Successfully enforced MFA ${member.userEmail}`,
                    });
                  } else if (result.status === 403) {
                    renderErrorToast({
                      message: `You do not have permission to enforce MFA for ${member.userEmail}, please contact Thalamos support.`,
                    });
                  } else {
                    renderErrorToast({
                      message:
                        "Something went wrong. Please try again later. if the issue persists please contact Thalamos support.",
                    });
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),

    ...(member.mfa
      ? [
          {
            name: "Remove MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to remove MFA for user: ${member.userName} (${member.userEmail})?`,
                confirmFn: async () => {
                  const result = await api.users.disableMfa(member.userId);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchTeam();
                    renderSuccessToast({
                      message: `Successfully disabled MFA for ${member.userEmail}`,
                    });
                  } else if (result.status === 403) {
                    renderErrorToast({
                      message: `You do not have permission to disable MFA for ${member.userEmail}, please contact Thalamos support.`,
                    });
                  } else {
                    renderErrorToast({
                      message:
                        "Something went wrong. Please try again later. if the issue persists please contact Thalamos support.",
                    });
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
          {
            name: "Reset MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to reset MFA (but keep it enabled) for user: ${member.userName} (${member.userEmail})?`,
                confirmFn: async () => {
                  const result = await api.users.resetMfa(member.userId);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchTeam();
                    renderSuccessToast({
                      message: `Successfully reset MFA for ${member.userEmail}. They will be able to set it up again next time they log in`,
                    });
                  } else if (result.status === 403) {
                    renderErrorToast({
                      message: `You do not have permission to reset MFA for ${member.userEmail}, please contact Thalamos support.`,
                    });
                  } else {
                    renderErrorToast({
                      message:
                        "Something went wrong. Please try again later. if the issue persists please contact Thalamos support.",
                    });
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),
    {
      name: "Send password reset email",
      onClick: async () => {
        const result = await api.users.triggerPasswordResetEmail(member.userId);
        if (result.status === 204) {
          renderSuccessToast({
            message: `Successfully sent password reset email to ${member.userEmail}`,
          });
        } else if (result.status === 403) {
          renderErrorToast({
            message: `You do not have permission to send password reset email to ${member.userEmail}, please contact Thalamos support.`,
          });
        } else {
          renderErrorToast({
            message:
              "Something went wrong. Please try again later. if the issue persists please contact Thalamos support.",
          });
        }
        setIsMenuOpen(false);
      },
      icon: "transfer",
      disabled: false,
    },

    {
      name: "Remove from Team",
      onClick: () => {
        setConfirmFn({
          confirmFn: async () => {
            const result = await api.teams.removeMember(team.id, {
              userId: member.userId,
              role: member.role,
            });

            if (result.status === 204) {
              renderSuccessToast({
                message: `Successfully removed ${member.userName} from ${team.name}`,
              });
            } else if (result.status === 403) {
              renderErrorToast({
                message: `You do not have permission to remove ${member.userName} from ${team.name}, please contact Thalamos support.`,
              });
            } else {
              renderErrorToast({
                message:
                  "Something went wrong. Please try again later. if the issue persists please contact Thalamos support.",
              });
            }
            refetchTeam();
          },
          message: `Are you sure you want to remove ${member.userName} from ${team.name}?`,
        });
      },
      icon: "delete",
      disabled: false,
    },
  ];

  return (
    <TableRow
      sx={{
        backgroundColor:
          index % 2 === 0 ? theme.palette.common.paleBlue : "white",
      }}
    >
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => {
            refetchTeam();
            setConfirmFn(null);
          }}
        />
      )}

      <TableCell>{member.userName}</TableCell>
      <TableCell>{member.userEmail}</TableCell>
      <TableCell>{getRoleLabel(member.role)}</TableCell>
      {
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      }
    </TableRow>
  );
}

function TeamEditPageInner({
  teamId,
  team,
  refetchTeam,
}: {
  teamId: string;
  team: ExtendedTeam | null;
  refetchTeam: () => void;
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(team?.members || []);

  const [submitError, setSubmitError] = React.useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [roleToAssign, setRoleToAssign] = useState("member");
  const { isMobileView } = useScreenDetection();
  const ITEMS_PER_PAGE = 5;

  const updateNumPages = (dataLength: number) => {
    const pages = Math.ceil(dataLength / ITEMS_PER_PAGE);
    setNumPages(pages);
  };

  const handleUserSelect = async (user: ExtendedThalamosUser) => {
    await api.teams.addMember(team!.id, {
      userId: user.id,
      role: roleToAssign as TeamMembership["role"],
    });
    refetchTeam();
    setModalOpen(false);
  };

  const startIndex = page * ITEMS_PER_PAGE;

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    if (team) {
      const filtered = team.members.filter(
        (member) =>
          member.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          member.userEmail.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredData(filtered);
      updateNumPages(filtered.length);
    }
  }, [team, searchQuery]);

  const handlePrevPageClick = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNextPageClick = () => {
    if (page < numPages - 1) {
      setPage(page + 1);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TeamBasicDetailsEdit
          team={team}
          initialData={
            team
              ? ({
                  name: team.name,
                  type: team.type,
                  organisationId: team.organisationId,
                  primaryEmail: team.primaryEmail,
                } as CreateTeamRequest)
              : {}
          }
          onSubmit={async (data) => {
            try {
              const result = await api.teams.update(team!.id, data);

              if (result.status === 409) {
                setSubmitError(
                  // @ts-ignore
                  `${result?.data?.reason ?? "Theres been an issue updating this team, if this persists please contact Thalamos support."}`,
                );
              } else if (result.status === 403) {
                setSubmitError(
                  "You do not have permission to edit this team, please contact Thalamos support.",
                );
              }
            } catch (e) {
              setSubmitError("Unknown error!");
            }
          }}
        />

        {submitError && (
          <Box sx={{ mb: 4 }}>
            <Banner
              bannerType={BannerList.ERROR}
              title={submitError as string}
            />
          </Box>
        )}

        <Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}>
            Search for member name or email
          </Typography>

          {team && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: isMobileView ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: isMobileView ? "100%" : 241,
                  maxWidth: 241,
                }}
              >
                <SearchBarWithFilter
                  selectedTab={0}
                  setSelectedTab={(params: any) => {}}
                  selectedOptions={selectedOptions}
                  optionValues={[]}
                  filters={[]}
                  hideFilter={true}
                  placeholder="Search for team member"
                  searchQuery={searchQuery}
                  setSearchQuery={handleSearchQueryChange}
                  setSelectedOptions={setSelectedOptions}
                  showDateRange
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Button
                  label={"Add Member"}
                  onClick={async () => {
                    setRoleToAssign("member");
                    setModalOpen(true);
                  }}
                  sx={{ ml: 2 }}
                  endIcon={ButtonIcon.add}
                />
                <Button
                  label={"Add Admin"}
                  onClick={async () => {
                    setRoleToAssign("manager");
                    setModalOpen(true);
                  }}
                  sx={{ ml: 2 }}
                  endIcon={ButtonIcon.add}
                />

                <UserSelectionModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  onUserSelect={handleUserSelect}
                  roleToAssign={roleToAssign}
                />
              </Box>
            </Box>
          )}
        </Box>

        {team && (
          <>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
                size="small"
                aria-label="Teams overview table"
              >
                <TableRow>
                  <TableCell>
                    <strong>{t("common.name")}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t("common.email")}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t("common.role")}</strong>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {filteredData
                    .slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)
                    .map((member, index) => (
                      <TeamMemberRow
                        key={index}
                        refetchTeam={refetchTeam}
                        team={team}
                        member={member}
                        index={startIndex + index}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                my: 4,
              }}
            >
              <button onClick={handlePrevPageClick} disabled={page === 0}>
                &#60;
              </button>
              <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
                Page {page + 1} of {numPages}
              </Box>
              <button
                onClick={handleNextPageClick}
                disabled={
                  page === Math.ceil(filteredData.length / ITEMS_PER_PAGE) - 1
                }
              >
                &#62;
              </button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

export function TeamEditPage({}: {}) {
  let { teamId } = useParams();

  const [{ data: team, loading: teamLoading, response }, refetchTeam] =
    apiHooks.teams.get(teamId!);

  return teamLoading ? (
    <></>
  ) : (
    <TeamEditPageInner
      teamId={teamId!}
      team={response?.status === 200 ? team! : null}
      refetchTeam={refetchTeam}
    />
  );
}
