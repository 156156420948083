import {
  A11Data,
  A11Part1DataSchema,
  a11Part1DataSchema,
} from "../schemas/mhaForms/admission/a11";
import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";

import {
  A11UploadableData,
  A11UploadablePart1DataSchema,
  a11UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a11";

const a11Part1Signing: FormPartTemplate<A11Part1DataSchema>["signing"] = {
  userOrTeamRequirement: { accreditation: ["doctor"], teamType: [] },
};

export const a11Part1Template: FormPartTemplate<A11Part1DataSchema> = {
  signing: a11Part1Signing,
  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "I, a registered medical practitioner, recommend that this patient be admitted to a hospital for assessment in accordance with Part 2 of the Mental Health Act 1983. ",
      ],
    },
    {
      type: "date-time",
      label: "Enter the date and time you last examined this patient on",
      field: "examinedTimeAndDate",
      minimum: (now) => now.subtract(24, "hour"),
      maximum: (now) => now,
    },
    {
      type: "branch",
      field: "previousAcquaintance",
      label:
        "Did you have previous acquaintance with the patient before you conducted the examination?",
      branches: [
        { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
        { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
      ],
    },
    {
      type: "section-12-confirmation-checkbox",
      checkboxLabel:
        "Are you approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder?",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(a) I am of the opinion, this patient is suffering from mental disorder of a nature or degree which warrants the detention of the patient in hospital for assessment (or for assessment followed by medical treatment) for at least a limited period",
      ],
    },
    {
      type: "multi-select",
      label: "(b) I am of the opinion, this patient ought to be so detained",
      options: [
        {
          field: "necessaryForPatientsOwnHealth",
          label: "in the interests of the patient's own health",
        },
        {
          field: "necessaryForPatientsOwnSafety",
          label: "in the interests of the patient's own safety",
        },
        {
          field: "necessaryForProtectionOfOtherPersons",
          label: "with a view to the protection of other persons.",
        },
      ],
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "(c) I am of the opinion, it is of urgent necessity for the patient to be admitted and detained under section 2 of the Act.",
      ],
    },
    {
      type: "textbox",
      field: "reasons",
      label:
        "My reasons for these opinions are: (Your reasons should cover both (a), (b) and (c) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; explain why the patient ought to be admitted to hospital urgently and why informal admission is not appropriate.)",
      rows: "10",
    },
    {
      type: "textbox",
      field: "obtainSecondMedicalRecommendation",
      label:
        "Compliance with the provisions of Part 2 of the Act relating to applications under section 2 would involve undesirable delay, because - (Say approximately how long you think it would take to obtain a second medical recommendation and what risk such a delay would pose to the patient or to other people.)",
      rows: "5",
    },
  ],
  dataSchema: a11Part1DataSchema,
};

export const a11UploadablePart1Template: FormPartTemplate<A11UploadablePart1DataSchema> =
  {
    signing: a11Part1Signing,
    formBuilderFields: [
      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date-time",
        field: "examinedTimeAndDate",
        label: "Date and time signee last examined this patient on",
        minimum: (now) => now.subtract(24, "hour"),
        maximum: (now) => now,
      },

      {
        type: "branch",
        field: "previousAcquaintance",
        label: "Did signee have previous acquaintance with the patient?",
        branches: [
          { fieldValue: true, label: "Yes", fieldsOwned: [], fields: [] },
          { fieldValue: false, label: "No", fieldsOwned: [], fields: [] },
        ],
      },

      {
        type: "branch",
        field: "practitionerApprovedUnderSection12",
        label: "Is signee approved under section 12 of the Act?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "multi-select",
        label: "Confirm detention criteria",
        options: [
          {
            field: "necessaryForPatientsOwnHealth",
            label: "in the interests of the patient's own health",
          },
          {
            field: "necessaryForPatientsOwnSafety",
            label: "in the interests of the patient's own safety",
          },
          {
            field: "necessaryForProtectionOfOtherPersons",
            label: "with a view to the protection of other persons",
          },
        ],
      },

      {
        type: "date-time",
        field: "signatureDate",
        label: "Date and time of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a11UploadablePart1DataSchema,
  };

export const a11V1Base: UploadableBaseFormTemplate = {
  id: "mha-a11",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a11Part1Template],
  formName: "A11",
  section: "4",
  description: "Medical recommendation for emergency admission for assessment",
  extractGenericData: (data: A11Data | A11UploadableData) => ({
    admissions: {
      examinerPreviousAcquaintance: data[0]?.data?.previousAcquaintance,
      lastExaminedDate: data[0] && [data[0].data.examinedTimeAndDate],
      necessaryForPatientsOwnHealth:
        data[0]?.data?.necessaryForPatientsOwnHealth,
      necessaryForPatientsOwnSafety:
        data[0]?.data?.necessaryForPatientsOwnSafety,
      necessaryForProtectionOfOtherPersons:
        data[0]?.data?.necessaryForProtectionOfOtherPersons,
    },
  }),
  uploadable: true,
  uploadablePartTemplates: [a11UploadablePart1Template],
};
