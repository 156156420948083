import React from "react";
import { Box } from "@mui/material";
import { FormTitle } from "../FormTitle/FormTitle";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface PopupDialogProps {
  titleText?: string;
  subtitleText?: string;
  closeDialog: () => void;
  removeMarginBottom?: boolean;
}

export const PopupDialogTitle = ({
  titleText,
  subtitleText,
  closeDialog,
  removeMarginBottom,
}: PopupDialogProps) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ ...(!removeMarginBottom && { mb: 4 }) }}
    >
      <FormTitle
        titleText={titleText || t("pages.patientHome.launchForm.confirmAction")}
        subtitleText={subtitleText}
        hasContainerMarginBottom={false}
        hasTitleBottomMargin={false}
        useReducedTopPadding={true}
      />
      <IconButton
        sx={{
          width: "40px",
          height: "40px",
        }}
        data-testid="close-dialog"
        aria-label={t("buttonLabels.close")}
        onClick={closeDialog}
      >
        <Close sx={{ color: "primary.hint" }} fontSize="small" />
      </IconButton>
    </Box>
  );
};
