import React from "react";
import {
  DischargeReason,
  MhaStatus,
} from "@aspire/common/types/patientState/PatientState";
import { PatientStateWithContext } from "@aspire/common/types/patientState/PatientStateWithContext";
import { Info, WarningAmberOutlined as Warning } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Button } from "../../design-system/Button/Button";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../../routes";
import { formatMhaStatus } from "~/components/util";
import {
  AutomaticExpiryEvent,
  ManualEvent,
  PatientStateEvent,
} from "@aspire/common/types/patientState/PatientStateEvent";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Banner, BannerList, PopupDialog } from "~/components/design-system";
import { getBaseFormTemplate } from "@aspire/common/formTemplates";
import { getFullFormName } from "@aspire/common/util/form";
import {
  getActiveMhaStatus,
  getExpiryDate,
  MHAStatusType,
} from "@aspire/common/util/patientstate";

dayjs.extend(utc);
dayjs.extend(timezone);

export type PatientStateDisplayProps = {
  state: PatientStateWithContext;
  disableEditMHAStatusBtn?: boolean;
  showEditMhaStatus?: boolean;
};

type PatientStateEventWithoutManualEvent = Exclude<
  PatientStateEvent,
  ManualEvent
>;

const isSection3PendingRenewal = (state: {
  state: MhaStatus;
  type: MHAStatusType;
}): boolean => {
  return state.state === MhaStatus.Section3 && state.type === "pending-renewal";
};

function isSection3PendingCto(state: {
  state: MhaStatus;
  type: MHAStatusType;
}): boolean {
  return state.state === MhaStatus.Section3 && state.type === "pending-cto";
}

function isSection17APendingRenewalCto(state: {
  state: MhaStatus;
  type: MHAStatusType;
}): boolean {
  return (
    state.state === MhaStatus.Section17A && state.type === "pending-renewal"
  );
}

function getFullFormNameBasedOnReasonForChange(
  reason: PatientStateEventWithoutManualEvent | undefined,
): string | undefined {
  const formTemplateId = reason?.formTemplateId;
  const template = formTemplateId ? getBaseFormTemplate(formTemplateId) : null;
  const formName = getFullFormName(template);
  return formName;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const getUpdatedByUserText = (state: PatientStateWithContext): string => {
  const event = state.event as ManualEvent;

  return `Manually input by ${event.updatedBy.name}${event.updatedBy.teamName ? ` (${event.updatedBy.teamName})` : ""} at ${dayjs(state.effectiveDateTime).format("DD/MM/YYYY HH:mm")}`;
};

const getTooltipForState = (
  state: PatientStateWithContext,
): { title: string; subtitle: string }[] => {
  const mhaStatus = state.state.mhaStatus.status;
  const relevantStatuses = [
    MhaStatus.Section5_4,
    MhaStatus.Section5_2,
    MhaStatus.Section4,
    MhaStatus.Section2,
    MhaStatus.Section3,
    MhaStatus.Section17A,
  ];

  const statusReason = state.statusReason;
  const statusReasonIsManualEvent = statusReason?.type === "manualEvent";

  if (mhaStatus === MhaStatus.Unknown) {
    return [
      {
        title: ``,
        subtitle: `No information available`,
      },
    ];
  }

  const activeState = getActiveMhaStatus(state.state, dayjs().toISOString());

  if (activeState) {
    const renewalsExpiryDate = getExpiryDate({
      MhaStatusType: MhaStatus.Section3,
      effectiveDateTime: state.effectiveDateTime,
      numberOfRenewals: state.state.mhaStatus.numberOfRenewals,
    });

    if (isSection3PendingRenewal(activeState)) {
      return [
        {
          title: `A Section 3 renewal has already been completed for this patient. This will come into action ${dayjs(renewalsExpiryDate).format("DD/MM/YYYY HH:mm")}`,
          subtitle: "",
        },
      ];
    }

    if (isSection3PendingCto(activeState)) {
      return [
        {
          title: `A CTO has already been completed for this patient. This will come into action ${dayjs(renewalsExpiryDate).format("DD/MM/YYYY HH:mm")}`,
          subtitle: "",
        },
      ];
    }

    if (isSection17APendingRenewalCto(activeState)) {
      return [
        {
          title: `A CTO extension has already been completed for this patient. This will come into action on ${dayjs(renewalsExpiryDate).format("DD/MM/YYYY HH:mm")}`,
          subtitle: "",
        },
      ];
    }
  }

  if (mhaStatus === MhaStatus.Incomplete) {
    return [
      {
        title: `${formatMhaStatus(mhaStatus)} status`,
        subtitle: `Data is missing, please contact your local MHA Legal Team to make sure the status is accurate.`,
      },
    ];
  }

  const isAutomaticExpiry = getIsAutomaticExpiry(state);

  if (isAutomaticExpiry) {
    return [
      {
        title: `${formatMhaStatus((state.event as AutomaticExpiryEvent).previousState.mhaStatus.status)} expired at ${dayjs(state.effectiveDateTime).format("DD/MM/YYYY HH:mm")}`,
        subtitle: `Patient detention period has expired, see patient log for more detail. Please contact your local MHA Legal Team to make sure the status is accurate.`,
      },
    ];
  }

  if (mhaStatus === MhaStatus.NotDetained) {
    if (statusReasonIsManualEvent) {
      const dischargeReason = state.state.episodeOfCare?.dischargeReason;

      const updatedByUserText = getUpdatedByUserText(state);

      if (dischargeReason === DischargeReason.Section23) {
        return [
          {
            title: `Discharged ${DischargeReason.Section23}`,
            subtitle: updatedByUserText,
          },
        ];
      }

      if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
        return [
          {
            title: `Discharged from holding power`,
            subtitle: updatedByUserText,
          },
        ];
      }

      if (dischargeReason === DischargeReason.TribunalOrNearestRelative) {
        return [
          {
            title: `${DischargeReason.TribunalOrNearestRelative}`,
            subtitle: updatedByUserText,
          },
        ];
      }

      if (dischargeReason === DischargeReason.Other) {
        return [
          {
            title: "Discharged, other reason",
            subtitle: updatedByUserText,
          },
        ];
      }
    } else {
      const dischargeReason = state.state.episodeOfCare?.dischargeReason;
      if (dischargeReason === DischargeReason.Section23) {
        return [
          {
            title: `Discharged ${DischargeReason.Section23}`,
            subtitle: `A ${DischargeReason.Section23} discharge form has been signed for this patient`,
          },
        ];
      }
      if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
        return [
          {
            title: `Holding Powers Discharge`,
            subtitle: `A discharge form has been signed for this patient`,
          },
        ];
      }
    }
  }

  if (relevantStatuses.includes(mhaStatus) && statusReasonIsManualEvent) {
    return [
      {
        title: `MHA Status ${formatMhaStatus(mhaStatus)}`,
        subtitle: `Manually input by ${statusReason.updatedBy.name}${statusReason.updatedBy.teamName ? ` (${statusReason.updatedBy.teamName})` : ""} at ${dayjs(statusReason.effectiveDateTime).format("DD/MM/YYYY HH:mm")}`,
      },
    ];
  } else if (relevantStatuses.includes(mhaStatus)) {
    type formEvent = Exclude<
      PatientStateEvent,
      ManualEvent | AutomaticExpiryEvent
    >;
    const statusReason = state.statusReason as formEvent;
    const formName = getFullFormNameBasedOnReasonForChange(statusReason);
    return [
      {
        title: `${formName ?? formatMhaStatus(mhaStatus)}`,
        subtitle: `Signed by ${statusReason.signedBy?.name} (${statusReason.signedBy?.teamName})`,
      },
    ];
  }
  return [];
};

const getIsAutomaticExpiry = (state: PatientStateWithContext): boolean => {
  return state.state.mhaStatus.status === MhaStatus.Expired &&
    state.event?.type === "automaticExpiry" &&
    state.event.previousState.hospital
    ? true
    : false;
};

const getHospitalSubtitle = (state: PatientStateWithContext): string => {
  const isAutomaticExpiry = getIsAutomaticExpiry(state);
  if (isAutomaticExpiry) {
    return (
      (state.event as AutomaticExpiryEvent).previousState.hospital?.name || ""
    );
  }

  const { hospitalReason } = state;

  // getting the last event which set the hospital
  if (hospitalReason && (hospitalReason as any).hospital?.name) {
    return (hospitalReason as any).hospital?.name;
  }

  return "Unknown";
};

const getTooltipForHospital = (
  state: PatientStateWithContext,
): { title: string; subtitle: string }[] | undefined => {
  const { status } = state.state.mhaStatus;
  const isAutomaticExpiry = getIsAutomaticExpiry(state);
  if (isAutomaticExpiry) {
    const stateEvent = state.event as AutomaticExpiryEvent;
    const autoExpiryFormName =
      getFullFormNameBasedOnReasonForChange(stateEvent);

    const isPreviousEventManual = stateEvent.previousType === "manualEvent";

    const formName =
      autoExpiryFormName ??
      (state.event as AutomaticExpiryEvent).previousState.mhaStatus.status;
    return [
      {
        title: `Hospital`,
        subtitle: isPreviousEventManual
          ? getUpdatedByUserText(state)
          : `Hospital name populated from ${formName}`,
      },
    ];
  }
  const hospitalReason: any = state.hospitalReason;
  const hospitalReasonIsManualEvent = hospitalReason?.type === "manualEvent";

  if (hospitalReasonIsManualEvent) {
    return [
      {
        title: `Hospital`,
        subtitle: `Manually input by ${hospitalReason.updatedBy.name}${hospitalReason.updatedBy.teamName ? ` (${hospitalReason.updatedBy.teamName})` : ""} at ${dayjs(hospitalReason.effectiveDateTime).format("DD/MM/YYYY HH:mm")}`,
      },
    ];
  } else if (hospitalReason && hospitalReason.hospital?.name) {
    const formName = getFullFormNameBasedOnReasonForChange(hospitalReason);
    return [
      {
        title: `Hospital`,
        subtitle: `Hospital name populated from ${formName ?? status}`,
      },
    ];
  }

  return undefined;
};

const getTooltipForExpiry = (
  state: PatientStateWithContext,
): { title: string; subtitle: string }[] | undefined => {
  const { status } = state.state.mhaStatus;

  const activeState = getActiveMhaStatus(state.state, dayjs().toISOString());

  if (activeState) {
    if (isSection3PendingRenewal(activeState)) {
      return [
        {
          title: "Current Section/CTO end date:",
          subtitle: formatDateTime(state.state.mhaStatus.expiryDateTime!),
        },
      ];
    }
    if (
      isSection17APendingRenewalCto(activeState) ||
      isSection3PendingCto(activeState)
    ) {
      return [
        {
          title: "Section/CTO start date:",
          subtitle: formatDateTime(state.state.mhaStatus.startDateTime!),
        },
        {
          title: "Section/CTO end date:",
          subtitle: formatDateTime(state.state.mhaStatus.expiryDateTime!),
        },
      ];
    }
  }

  if (
    status === MhaStatus.Section5_4 ||
    status === MhaStatus.Section5_2 ||
    status === MhaStatus.Section4 ||
    status === MhaStatus.Section2
  ) {
    return [
      {
        title: "Detention start date/time:",
        subtitle: formatDateTime(state.state.mhaStatus.startDateTime!),
      },
      {
        title: "Detention end date/time:",
        subtitle: formatDateTime(state.state.mhaStatus.expiryDateTime!),
      },
    ];
  }

  if (status === MhaStatus.Section3 || status === MhaStatus.Section17A) {
    const hasRenewals = getHasRenewals(state);
    if (hasRenewals && state.state.mhaStatus.numberOfRenewals! > 0) {
      return [
        {
          title: "Original Detention/CTO start date/time:",
          subtitle: formatDateTime(state.state.mhaStatus.initialStartDateTime!),
        },
        {
          title: "Current Section/CTO start date:",
          subtitle: formatDateTime(state.state.mhaStatus.startDateTime!),
        },
        {
          title: "Current Section/CTO end date:",
          subtitle: formatDateTime(state.state.mhaStatus.expiryDateTime!),
        },
      ];
    } else {
      return [
        {
          title: "Section/CTO start date:",
          subtitle: formatDateTime(state.state.mhaStatus.startDateTime!),
        },
        {
          title: "Section/CTO end date:",
          subtitle: formatDateTime(state.state.mhaStatus.expiryDateTime!),
        },
      ];
    }
  }

  if (status === MhaStatus.NotDetained) {
    if (state.event?.type === "manualEvent") {
      return [
        {
          title: "Expired",
          subtitle: getUpdatedByUserText(state),
        },
      ];
    } else {
      return [
        {
          title: "Expired",
          subtitle: `Date and time populated from relevant form ${status}`,
        },
      ];
    }
  }

  return undefined;
};

function InfoBox(props: {
  title: string;
  subtitle: string;
  useSmallSubtitle?: boolean;
  tooltip?: { title: string; subtitle: string }[];
  showWarningIcon?: boolean;
  warningColor?: string;
  testId?: string;
}) {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection="row"
      sx={{ maxWidth: "250px" }}
      data-testid={props.testId}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{ justifyContent: "flex-start" }}
      >
        <Typography
          sx={{ fontWeight: 400, fontSize: "11px", lineHeight: "13px" }}
        >
          {props.title}
        </Typography>
        <Box display="flex" flexGrow={1} alignItems={"center"}>
          <Typography
            sx={{
              marginTop: "3px",
              fontWeight: 600,
              fontSize: props.useSmallSubtitle ? "11px" : "15px",
              lineHeight: props.useSmallSubtitle ? "13px" : "22px",
              color: "#54596B",
              fontStyle: "normal",
            }}
          >
            {props.subtitle}
          </Typography>
        </Box>
      </Box>
      {props.tooltip && (
        <LightTooltip
          placement="top"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          title={
            <Box
              display="flex"
              flexDirection={"column"}
              rowGap={theme.spacing(0.5)}
            >
              {props.tooltip.map((item) => (
                <Box key={item.title}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      lineHeight: "13px",
                      color: "#54596B",
                      fontStyle: "normal",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      lineHeight: "13px",
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
        >
          {props.showWarningIcon ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Warning sx={{ color: props.warningColor, paddingLeft: "5px" }} />
            </Box>
          ) : (
            <IconButton sx={{ padding: 0, paddingLeft: "5px" }} color="primary">
              <Info />
            </IconButton>
          )}
        </LightTooltip>
      )}
    </Box>
  );
}

function formatDateTime(dateTime: string) {
  return dateTime && dayjs(dateTime).isValid()
    ? dayjs(dateTime).tz("Europe/London").format("DD.MM.YY - HH:mm")
    : "Unknown";
}

function zeroPad(num: number, places: number) {
  if (num === 0) return "0";
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function getHasRenewals(state: PatientStateWithContext): boolean {
  return (
    state.state.mhaStatus &&
    [MhaStatus.Section17A, MhaStatus.Section3].includes(
      state.state.mhaStatus?.status,
    ) &&
    state.state.mhaStatus.numberOfRenewals !== undefined
  );
}

function MhaStatusDialog({
  closeDialog,
  content,
  buttonLabel,
  open,
}: {
  closeDialog: () => void;
  open: boolean;
  content: string;
  buttonLabel: string;
}) {
  const theme = useTheme();
  return (
    <PopupDialog open={open} onClose={closeDialog}>
      <Banner
        bannerType={BannerList.INFO}
        body={content}
        sx={{ marginBottom: theme.spacing(2) }}
      />
      <Box display="flex" sx={{ justifyContent: "center" }}>
        <Button variant="outlined" label={buttonLabel} onClick={closeDialog} />
      </Box>
    </PopupDialog>
  );
}

export function PatientStateDisplay(props: PatientStateDisplayProps) {
  const { state, disableEditMHAStatusBtn, showEditMhaStatus } = props;
  const mhaStatus = state.state.mhaStatus.status;

  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const isAutomaticExpiry = getIsAutomaticExpiry(state);
  const isUnknown = mhaStatus === MhaStatus.Unknown;
  const isDischarged = mhaStatus === MhaStatus.NotDetained;
  const isUnknownExpired = mhaStatus === MhaStatus.Expired;
  const hasRenewals = getHasRenewals(state);

  const isExpiredSections = [MhaStatus.Expired, MhaStatus.NotDetained];

  const onLaunchEditMhaStatus = () => {
    navigate(routeFns.editMhaStatusPage(props.state.patientId));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activeState = getActiveMhaStatus(state.state, dayjs().toISOString());

  const infoBoxTitle = () => {
    const title = `${formatMhaStatus(state.state.mhaStatus.status).toUpperCase()}`;

    if (activeState) {
      if (isSection3PendingRenewal(activeState)) {
        return `${formatMhaStatus(activeState.state, activeState.type).toUpperCase()}`;
      }

      if (isSection3PendingCto(activeState)) {
        return `${formatMhaStatus(activeState.state, activeState.type).toUpperCase()}`;
      }

      if (isSection17APendingRenewalCto(activeState)) {
        return `${formatMhaStatus(activeState.state, activeState.type).toUpperCase()}`;
      }
    }

    return title;
  };

  const getDialogHeader = (headerText: string): string => {
    return `<p style='font-weight:600; color:black'>${headerText}</p>`;
  };

  const getDialogParagraph = (content: string): string => {
    return `<p>${content}</p>`;
  };
  return (
    <Box
      display="flex"
      sx={{
        borderRadius: theme.spacing(0.3),
        columnGap: theme.spacing(3),
        alignItems: "stretch",
        backgroundColor: "white",
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        width: "fit-content",
        fontStyle: "normal",
      }}
      flexDirection="column"
    >
      <Box
        display="flex"
        sx={{
          paddingBottom: theme.spacing(1),
          marginLeft: "auto",
        }}
      >
        <Link component="button" onClick={handleClickOpen}>
          <Typography
            sx={{
              textDecoration: "none",
              backgroundColor: "transparent",
              outline: "none",
              color: "inherit",
              fontWeight: 400,
              fontSize: "9px",
              lineHeight: "13px",
            }}
            variant="subtitle1"
          >
            {t("pages.patientStateDisplay.whatIsMHAStatus")}
          </Typography>
        </Link>
        {open && (
          <MhaStatusDialog
            closeDialog={handleClose}
            content={
              getDialogHeader(t("pages.patientStateDisplay.whatIsMHAStatus")) +
              getDialogParagraph(
                t("pages.patientStateDisplay.mhaStatusExplained"),
              ) +
              getDialogHeader(t("pages.patientStateDisplay.informationFrom")) +
              getDialogParagraph(
                t("pages.patientStateDisplay.informationFromExplained"),
              ) +
              getDialogParagraph(
                t("pages.patientStateDisplay.contactLegalTeam"),
              )
            }
            buttonLabel={t("buttonLabels.close")}
            open={open}
          />
        )}
      </Box>
      <Box
        display="flex"
        sx={{
          columnGap: theme.spacing(3),
          alignItems: "stretch",
          width: "fit-content",
          fontStyle: "normal",
        }}
      >
        <InfoBox
          title="MHA Status"
          subtitle={infoBoxTitle()}
          tooltip={getTooltipForState(state)}
          showWarningIcon={
            isAutomaticExpiry || mhaStatus === MhaStatus.Incomplete
          }
          warningColor={
            isAutomaticExpiry
              ? theme.palette.error.darker
              : theme.palette.warning.main
          }
          testId="mhaStatus"
        />
        {!isUnknown && (
          <>
            {isDischarged && (
              <>
                {state.state.episodeOfCare?.dischargeDateTime && (
                  <InfoBox
                    testId="episodeOfCareDischarge"
                    title="Expired"
                    subtitle={formatDateTime(
                      state.state.episodeOfCare.dischargeDateTime,
                    )}
                    tooltip={
                      state.event?.type === "manualEvent"
                        ? [
                            {
                              title: `Discharge date/time`,
                              subtitle: getUpdatedByUserText(state),
                            },
                          ]
                        : [
                            {
                              title: `Discharge date/time`,
                              subtitle: `Date and time populated from relevant form ${getFullFormNameBasedOnReasonForChange(state.dischargeReason as PatientStateEventWithoutManualEvent) ?? state.state.mhaStatus.status}`,
                            },
                          ]
                    }
                  />
                )}
              </>
            )}
            {!isDischarged && (
              <>
                <InfoBox
                  title={
                    isExpiredSections.includes(state.state.mhaStatus.status)
                      ? "Expired"
                      : "Expiry"
                  }
                  subtitle={
                    isAutomaticExpiry
                      ? formatDateTime(
                          state.state.episodeOfCare!.dischargeDateTime!,
                        )
                      : formatDateTime(state.state.mhaStatus.expiryDateTime!)
                  }
                  tooltip={getTooltipForExpiry(state)}
                />
                {hasRenewals && (
                  <InfoBox
                    title={`${state.state.mhaStatus.status === MhaStatus.Section17A ? "Extensions" : "Renewals"}`}
                    subtitle={zeroPad(
                      state.state.mhaStatus.numberOfRenewals!,
                      2,
                    )}
                    tooltip={
                      state.event?.type === "manualEvent"
                        ? [
                            {
                              title: `Renewals`,
                              subtitle: getUpdatedByUserText(state),
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              </>
            )}
            {!isUnknown && (
              <InfoBox
                testId="hospital"
                useSmallSubtitle={true}
                title={
                  isDischarged || isUnknownExpired
                    ? `${t("pages.editMHAStatus.previousHospital")}:`
                    : `${t("pages.editMHAStatus.hospital")}:`
                }
                subtitle={getHospitalSubtitle(state)}
                tooltip={getTooltipForHospital(state)}
              />
            )}
          </>
        )}
        {showEditMhaStatus && (
          <Button
            label="Edit"
            onClick={onLaunchEditMhaStatus}
            disabled={disableEditMHAStatusBtn}
          />
        )}
      </Box>
    </Box>
  );
}
