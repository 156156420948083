import React, { useMemo } from "react";
import { v4 } from "uuid";
import { Box } from "@mui/material";
import {
  Banner,
  BannerList,
  Button,
  FormLabel,
  FormTitle,
  HelperText,
  ReadOnlyContent,
  TextField,
} from "~/components/design-system";
import { Formik, FormikHelpers } from "formik";
import { api } from "../../api";
import { routeFns } from "../../routes";
import { object, string } from "yup";
import {
  formValidationErrorMessages,
  nonEmptyRequiredString,
} from "@aspire/common/schemas/shared";
import { useNavigate } from "react-router-dom";

export function AdminCreateUserPage({}: {}) {
  const id = useMemo(v4, []);

  const [submitError, setSubmitError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  return (
    <Formik<{ email: string; name: string }>
      initialValues={{ email: "", name: "" }}
      onSubmit={async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
          const result = await api.users.create(id, {
            email: values.email,
            name: values.name,
          });
          if (result.status === 409) {
            setSubmitError(
              // @ts-ignore
              `${result?.data?.reason ?? "User with that email already exists"}`,
            );
          } else if (result.status === 403) {
            setSubmitError(
              "You do not have permission to create a new user, please contact Thalamos support.",
            );
          } else {
            navigate(routeFns.userProfilePage(id));
          }
        } catch (e) {
          setSubmitError("Unknown error!");
        }
      }}
      validationSchema={object({
        email: string().email("Please enter a valid email").required(),
        name: nonEmptyRequiredString.max(
          255,
          formValidationErrorMessages.tooManyCharactersError,
        ),
      })}
    >
      {({ values, setValues, errors, touched, submitForm, isSubmitting }) => (
        <Box sx={{ maxWidth: 600 }}>
          <FormTitle
            titleText="Create User"
            hasTitleBottomMargin={false}
            useReducedTopPadding={true}
          />
          <Box sx={{ mb: "2em" }}>
            <Banner
              title={
                "Enter basic user information. You will be able to set their accreditations etc. on the profile page. They will not be send a password" +
                " reset email - you will need to manually send this from the user management page when you are happy with their profile and team memberships"
              }
              bannerType={BannerList.INFO}
            />
          </Box>
          <Box sx={{ mb: "2em" }}>
            <ReadOnlyContent content={[`User ID: ${id}`]} isActive={true} />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormLabel
              label={"Email"}
              error={touched.email && !!errors.email}
            />
            <TextField
              name={"email"}
              useFullWidth={true}
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e })}
            />
            {errors.email && touched.email && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.email as string} />
              </Box>
            )}
          </Box>
          <Box>
            <FormLabel label={"Name"} error={touched.name && !!errors.name} />

            <TextField
              name={"name"}
              useFullWidth={true}
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e })}
            />
            {errors.name && touched.name && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.name as string} />
              </Box>
            )}
          </Box>
          <Box>
            {submitError && (
              <Box sx={{ mb: 4 }}>
                <Banner
                  bannerType={BannerList.ERROR}
                  title={submitError as string}
                />
              </Box>
            )}
            <Button
              disabled={isSubmitting}
              label={"Create"}
              onClick={submitForm}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
