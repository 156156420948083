import { boolean, InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  hospital,
  isoDateTimeString,
  isTimeString,
  nonEmptyString,
  generateDateBeforeTodayValidationRule,
} from "../../shared";

const reportFurnishedBy = string()
  .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
  .default(null)
  .required("Please select the option which applies");

const userAccreditation = string()
  .oneOf(["registered-medical-practitioner", "approved-clinician"])
  .default(null)
  .required("Please select the option which applies");

const nominee = boolean()
  .default(null)
  .required("Please select the option which applies");

export const h1Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  hospital: hospital,
  noInformaltreatment: nonEmptyString.required("Please enter your reasoning"),
  userAccreditation,
  nominee,
  reportFurnishedBy,
  timeConsigning: isTimeString.when("reportFurnishedBy", {
    is: (value: string) => value === "internal-mail-system",
    then: () => isTimeString.required("Please enter a time"),
    otherwise: () => string().optional(),
  }),
});

export const h1Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),
  reportFurnishedBy: reportFurnishedBy,
  receivedDate: isoDateTimeString.when("reportFurnishedBy", {
    is: (value: string) => value === "in-person",
    then: () =>
      generateDateBeforeTodayValidationRule(
        "date-time",
        "You cannot enter a date and/or time in the future.",
      ).required("Please enter a date and time"),
    otherwise: () => string().optional(),
  }),
});

export type H1Part1DataSchema = InferType<typeof h1Part1DataSchema>;
export type H1Part2DataSchema = InferType<typeof h1Part2DataSchema>;

export type H1Data = [
  PartData<H1Part1DataSchema>,
  PartData<H1Part2DataSchema>?,
];

export const exampleH1Data: ExampleFormData<H1Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      reportFurnishedBy: "internal-mail-system",
      noInformaltreatment: "Informal treatment is no longer appropriate",
      userAccreditation: "approved-clinician",
      nominee: true,
      timeConsigning: "12:00",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "in-person",
      receivedDate: "2022-01-10T12:00:00.000Z",
    },
  },
];
