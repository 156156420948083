import { MhaStatus } from "@aspire/common/types/patientState/PatientState";
import { MhaDashboardDataParams } from "@aspire/common/types/patientState/patientStateRequests";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { apiHooks } from "~/api";
import { LoggedInUserContext } from "~/Contexts";
import PaginationControls from "~/pages/Caseload/PaginationControls";
import { routeFns } from "~/routes";
import {
  MhaStatusDashboard,
  MhaStatusSearchBarControls,
} from "./MhaStatusDashboard";
import { Banner, BannerList } from "~/components/design-system";
import { userCanEditMhaStatus } from "../EditMhaStatus/EditMhaStatusPage";

const ROWS_PER_PAGE = 12;
const defaultStatuses: MhaStatus[] = [
  MhaStatus.Incomplete,
  MhaStatus.Expired,
  MhaStatus.Section17A,
  MhaStatus.Section2,
  MhaStatus.Section3,
  MhaStatus.Section4,
  MhaStatus.Section5_2,
  MhaStatus.Section5_4,
];

export function MhaStatusDashboardPage(props: {}) {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;

  const isNhsNumberConfigEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  // This should be local state - patient identifiers were purposely removed (ASP-2132)
  const [patientSearchFilter, setPatientSearchFilter] = React.useState("");

  const qs = new URLSearchParams(search);
  const statusesFromUrl = qs.get("status");
  const pageFromUrl = qs.get("page") ?? "0";

  const params: Omit<MhaDashboardDataParams, "limit" | "offset"> = useMemo(
    () => ({
      statuses: statusesFromUrl
        ? statusesFromUrl.split(",").map((status) => status as MhaStatus)
        : defaultStatuses,
      organisationName: qs.get("organisationName") ?? undefined,
      expiryLessThan: qs.get("expiryLessThan") ?? "",
    }),
    [qs],
  );

  const showMhaDashboardLink = userCanEditMhaStatus(user);

  const [{ data, loading, error }] = apiHooks.patients.getMhaDashboardData({
    ...params,
    patientSearchFilter: patientSearchFilter,
    limit: ROWS_PER_PAGE,
    offset: parseInt(pageFromUrl) * ROWS_PER_PAGE,
  });

  const updateParams = useCallback(
    (params: MhaDashboardDataParams) => {
      // Reset page to 0 on any change of params
      navigate(routeFns.mhaStatusDashboardPage(0, params));
    },
    [navigate, pathname],
  );

  return !showMhaDashboardLink ? (
    <Banner
      bannerType={BannerList.ERROR}
      title={"Incorrect permissions to view MHA status dashboard"}
    />
  ) : (
    <>
      <MhaStatusSearchBarControls
        subTitle={`MHA Detention Statuses (${dayjs().format("DD MMM YYYY")})`}
        title={userContext?.user?.sessionContext?.dataControllerName ?? ""}
        params={params}
        patientSearchFilter={patientSearchFilter}
        setPatientSearchFilter={setPatientSearchFilter}
        updateParams={updateParams}
      />
      {!loading && !error && (data?.rows.length ?? 0) > 0 && (
        <PaginationControls
          page={parseInt(pageFromUrl ?? "1")}
          setPage={(page) => {
            navigate(routeFns.mhaStatusDashboardPage(page, params));
          }}
          numPages={Math.ceil((data?.total ?? 0) / ROWS_PER_PAGE)}
        />
      )}
      <MhaStatusDashboard
        data={data}
        error={error?.message}
        isLoading={loading}
        showNhsNumber={isNhsNumberConfigEnabled}
      />
    </>
  );
}
