import React, { useContext } from "react";
import { Box, useTheme } from "@mui/material";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from "@mui/material";
import { apiHooks } from "../../../api";
import { Button, FormTitle } from "~/components/design-system";
import { useNavigate } from "react-router-dom";
import { routeFns } from "../../../routes";
import { ExtendedTeam } from "@aspire/common/types/extendedTeam";
import { Container } from "~/components/layout/styleWrappers";
import { LoggedInUserContext } from "../../../Contexts";

function TeamManagementRow({
  team,
  index,
}: {
  team: ExtendedTeam;
  index: number;
}) {
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <TableRow
      sx={{
        backgroundColor:
          index % 2 === 0 ? theme.palette.common.paleBlue : "white",
      }}
      key={team.id}
    >
      <TableCell>{team.name}</TableCell>
      <TableCell>{team.primaryEmail}</TableCell>
      <TableCell>{team.organisationName}</TableCell>
      <TableCell>{team.members.length}</TableCell>
      <TableCell>
        <Button
          testId="secondary-button"
          sx={{ backgroundColor: "common.white" }}
          label={"View"}
          variant="outlined"
          onClick={() => navigate(routeFns.managerTeamEdit(team.id))}
        />
      </TableCell>
    </TableRow>
  );
}
export function TeamsOverviewPage({}: {}) {
  const [teamFilterQuery, setTeamFilterQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const teamsPageSize = 10;
  const userContext = useContext(LoggedInUserContext);

  const [{ data: teams, loading: loadingTeams }] = apiHooks.teams.search(
    teamFilterQuery,
    teamsPageSize,
    page * teamsPageSize,
    userContext?.user.sessionContext?.organisationId,
  );

  const numPages =
    teams && teams.count ? Math.ceil(teams.count / teamsPageSize) : 1;

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container>
      <Box sx={{ p: theme.spacing(4, 4, 4, 2) }}>
        <FormTitle
          titleText="Team Overview"
          hasTitleBottomMargin={false}
          hasContainerMarginBottom={false}
          useReducedTopPadding={true}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Search keyword"}
          sx={{ width: "241px" }}
          value={teamFilterQuery}
          onChange={(e) => {
            setTeamFilterQuery(e.target.value);
            setPage(0);
          }}
        />
      </Box>
      {!teams ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              aria-label="Teams overview table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Managing org</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Members</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.results?.map((team, index) => (
                  <TeamManagementRow team={team} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              my: 4,
            }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
        </Box>
      )}
    </Container>
  );
}
