import React, { ReactElement } from "react";
import { css } from "@emotion/react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import { Box, Typography, useTheme } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { InfoOutlined, Done, Close } from "@mui/icons-material";

interface Styles {
  root: ReturnType<typeof css>;
}

type IconType =
  | ReactElement<typeof InfoOutlined>
  | ReactElement<typeof Done>
  | ReactElement<typeof Close>;

interface ToastNotificationsProps {
  message: string;
  action?: () => void;
  buttonLabel?: string;
  showCloseIcon?: boolean;
  autoClose?: ToastOptions["autoClose"];
}

export type RenderedToastNotificationsProps = Omit<
  ToastNotificationsProps,
  "autoClose"
> & { icon?: IconType };

export function ToastNotifications() {
  const theme = useTheme();

  const styles: Styles = {
    root: css`
      width: 100%;
      & .Toastify__toast-container {
        width: auto;
        max-width: 450px;
        & .Toastify__toast {
          padding: 0;
          border-radius: 0;
          margin-bottom: ${theme.spacing(2.5)};
          min-height: 0;
        }
        & .close-toast-container {
          margin: ${theme.spacing(2)};
        }
        & .close-toast {
          position: absolute;
          right: ${theme.spacing(2)};
          transform: translate(-45%, -45%);
        }
      }
    `,
  };

  return (
    <div css={styles.root}>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        closeOnClick={false}
        draggable={false}
        pauseOnHover={true}
        bodyClassName={() => "body"}
        style={{ backgroundColor: "transparent" }}
      />
    </div>
  );
}

function ToastButton({
  action,
  buttonLabel,
}: {
  action: () => void;
  buttonLabel: string;
}) {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems={"center"} onClick={action!}>
      <Typography
        sx={{
          fontWeight: 600,
          color: "primary.main",
          fontSize: theme.spacing(1.625),
        }}
        css={css`
          &:hover {
            cursor: pointer;
          }
        `}
      >
        {buttonLabel}
      </Typography>
    </Box>
  );
}

function StylesToast() {
  const theme = useTheme();
  return {
    toast: css`
      display: flex;
      align-items: center;
      padding: ${theme.spacing(2, 3, 2, 3)};
      gap: ${theme.spacing(2)};
    `,
    toastText: css`
      font-weight: 400;
      font-size: ${theme.spacing(1.625)};
      color: ${theme.palette.text.primary};
    `,
  };
}

export function Toast({
  message,
  action,
  showCloseIcon,
  buttonLabel,
  icon,
}: RenderedToastNotificationsProps) {
  return (
    <Box css={StylesToast().toast}>
      {icon}
      <Typography css={StylesToast().toastText}>{message}</Typography>
      {action && <ToastButton action={action} buttonLabel={buttonLabel!} />}
      {showCloseIcon && (
        <Box className="close-toast-container">
          <Box className="close-toast">
            <Close
              sx={{ color: "primary.hint" }}
              fontSize="small"
              css={css`
                &:hover {
                  cursor: pointer;
                }
              `}
              onClick={() => toast.dismiss()}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

const DEFAULT_ON_CLOSE_TIMEOUT_MS = 30000;
const DEFAULT_POSITION = "top-right";
export const renderSuccessToast = ({
  message,
  action,
  buttonLabel,
  showCloseIcon = true,
  autoClose = DEFAULT_ON_CLOSE_TIMEOUT_MS,
}: ToastNotificationsProps) => {
  toast.success(
    <Toast
      message={message}
      action={action}
      showCloseIcon={showCloseIcon}
      buttonLabel={buttonLabel}
      icon={<Done sx={{ color: "secondary.main" }} />}
    />,
    {
      autoClose: autoClose,
      closeButton: false,
      icon: false,
      position: DEFAULT_POSITION,
      style: {
        backgroundColor: "#F2FCF9",
        border: "2px solid #00BF7C",
        borderRadius: "6px",
      },
    },
  );
};

export const renderInfoToast = ({
  message,
  action,
  buttonLabel,
  showCloseIcon = true,
  autoClose = DEFAULT_ON_CLOSE_TIMEOUT_MS,
}: ToastNotificationsProps) => {
  toast.info(
    <Toast
      message={message}
      action={action}
      showCloseIcon={showCloseIcon}
      buttonLabel={buttonLabel}
      icon={<InfoOutlined sx={{ color: "primary.main" }} />}
    />,
    {
      autoClose: autoClose,
      closeButton: false,
      icon: false,
      position: DEFAULT_POSITION,
      style: {
        backgroundColor: "#F6F9FD",
        border: "2px solid #4171BF",
        borderRadius: "6px",
      },
    },
  );
};

export const renderWarningToast = ({
  message,
  action,
  buttonLabel,
  showCloseIcon = true,
  autoClose = DEFAULT_ON_CLOSE_TIMEOUT_MS,
}: ToastNotificationsProps) => {
  toast.error(
    <Toast
      message={message}
      action={action}
      showCloseIcon={showCloseIcon}
      buttonLabel={buttonLabel}
      icon={<InfoOutlined sx={{ color: "warning.main" }} />}
    />,
    {
      autoClose: autoClose,
      closeButton: false,
      icon: false,
      position: DEFAULT_POSITION,
      style: {
        backgroundColor: "#FFFCF3",
        border: "2px solid #FDC910",
        borderRadius: "6px",
      },
    },
  );
};

export const renderErrorToast = ({
  message,
  action,
  buttonLabel,
  showCloseIcon = true,
  autoClose = DEFAULT_ON_CLOSE_TIMEOUT_MS,
}: ToastNotificationsProps) => {
  toast.error(
    <Toast
      message={message}
      action={action}
      showCloseIcon={showCloseIcon}
      buttonLabel={buttonLabel}
      icon={<InfoOutlined sx={{ color: "error.main" }} />}
    />,
    {
      autoClose: autoClose,
      closeButton: false,
      icon: false,
      position: DEFAULT_POSITION,
      style: {
        backgroundColor: "#FEF6F4",
        border: "2px solid #EC501C",
        borderRadius: "6px",
      },
    },
  );
};
