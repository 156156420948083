import React, { useEffect } from "react";
import {
  DropdownFormBuilderField,
  MhFormContext,
} from "@aspire/common/types/form";
import { FieldProps } from "../../pages/FieldProps";
import { FormControl, useTheme } from "@mui/material";
import { FormLabel } from "../design-system/FormLabel";
import { HelperText } from "../design-system/HelperText/HelperText";
import { Element } from "react-scroll";
import { css } from "@emotion/react";
import { Dropdown } from "../design-system/Dropdown/Dropdown";
import { Box } from "@mui/material";

export type DropdownFormFieldProps<Data extends { [k: string]: any }> = {
  field: DropdownFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function DropdownFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: DropdownFormFieldProps<Data>) {
  const selectedValue = fieldProps.values[field.valueField];
  const fieldTouched = fieldProps.touched[field.valueField];
  const isError = !!(
    fieldProps.touched[field.valueField] && fieldProps.errors[field.valueField]
  );
  const isReadOnly = field.isReadOnlyFn?.(
    fieldProps.values,
    fieldProps.context as MhFormContext,
  );

  const theme = useTheme();

  // Set default value if there is no selectedValue and the field definition has a defaultFn
  useEffect(() => {
    if (
      (selectedValue === null || selectedValue === undefined) &&
      !fieldTouched &&
      field.defaultFn
    ) {
      let defaultValue = field.defaultFn(
        fieldProps.values,
        fieldProps.context as MhFormContext,
      );
      if (defaultValue) {
        fieldProps.setValues({
          ...fieldProps.values,
          [field.valueField]: defaultValue.value,
          [field.textField]: defaultValue.label,
        });
      }
    }
  }, [selectedValue, fieldTouched]);

  return (
    <Element name={field.valueField} data-testid={`field:${field.textField}`}>
      <FormControl
        css={css`
          width: 100%;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {field.label && <FormLabel label={field.label} error={isError} />}

        <Dropdown
          name={field.valueField}
          values={field.options}
          readOnly={isReadOnly}
          selectedValue={selectedValue || ""}
          onChange={(z) => {
            const selected = field.options.find((o) => o.value === z);
            fieldProps.setFieldTouched(field.valueField, true, false);
            fieldProps.setValues({
              ...fieldProps.values,
              [field.valueField]: selected?.value,
              [field.textField]: selected?.label,
              ...Object.fromEntries(
                field?.fieldsOwned
                  ? field.fieldsOwned.map((field) => [
                      field,
                      fieldProps.validationSchema.getDefault()[field],
                    ])
                  : [],
              ),
            });
          }}
        />
        {isError && (
          <Box sx={{ mb: 2 }}>
            <HelperText
              errorMessage={fieldProps.errors[field.valueField] as string}
            />
          </Box>
        )}
      </FormControl>
    </Element>
  );
}
