import { UUID } from "../util";

export type AuditTrailEntryResources = {
  resourceType:
    | "patient"
    | "formContext"
    | "form"
    | "formDraft"
    | "workItem"
    | "user"
    | "team"
    | "organisation"
    | "resource"
    | "rioInstance"
    | "rioToken"
    | "externalPatientLink"
    | "patientDemographicsPullEvent";
  resourceId: UUID;
  resourceName?: string;
};

export enum AuditTrailEntryType {
  // Patient
  getPatient = "getPatient",
  getPatientTimeline = "getPatientTimeline",
  createPatient = "createPatient",
  updatePatient = "updatePatient",
  synchronisePatientWithPds = "synchronisePatientWithPds",
  searchPatientIndexes = "searchPatientIndexes",
  mergePatient = "mergePatient",
  unmergePatient = "unmergePatient",
  createExternalPatientLink = "createExternalPatientLink",
  createPatientDemographicsPullEvent = "createPatientDemographicsPullEvent",
  updatePatientDemographicsPullEvent = "updatePatientDemographicsPullEvent",
  updatePatientStateWithManualEvent = "updatePatientStateWithManualEvent",
  patientSearchReasoning = "patientSearchReasoning",
  updatePatientStateAutomatically = "updatePatientStateAutomatically",

  automaticallyExpirePatientState = "automaticallyExpirePatientState",

  // Form Draft
  createFormDraft = "createFormDraft",
  getFormDraft = "getFormDraft",
  deleteFormDraft = "deleteFormDraft",
  getFormDraftPdf = "getFormDraftPdf",

  // Form Context
  createFormContext = "createFormContext",

  // Form
  createForm = "createForm",
  uploadForm = "uploadForm",
  signForm = "signForm",
  getFormPdf = "getFormPdf",
  deleteForm = "deleteForm",
  expireForm = "expireForm",
  createFormPushEvent = "createFormPushEvent",

  // Work
  createWorkRequest = "createWorkRequest",
  createAmendRequest = "createAmendRequest",
  declineWorkRequest = "declineWorkRequest",
  acceptWorkRequest = "acceptWorkRequest",
  archiveWorkRequest = "archiveWorkRequest",
  unarchiveWorkRequest = "unarchiveWorkRequest",
  claimWorkRequest = "claimWorkRequest",
  unclaimWorkRequest = "unclaimWorkRequest",
  finaliseWorkRequest = "finaliseWorkRequest",
  expireAmendRequest = "expireAmendRequest",

  cancelWorkRequest = "cancelWorkRequest",

  adminDeleteWorkItem = "adminDeleteWorkItem",

  createFileNoteRequest = "createFileNoteRequest",
  deleteFileNoteRequest = "deleteFileNoteRequest",

  // User
  createUser = "createUser",
  updateUserEmail = "updateUserEmail",
  deleteUser = "deleteUser",
  convertGuestUser = "convertGuestUser",
  convertFullUserToGuest = "convertFullUserToGuest",
  createIndependentContext = "createIndependentContext",
  deleteIndependentContext = "deleteIndependentContext",
  updateUserProfile = "updateUserProfile",
  passwordResetTriggered = "passwordResetTriggered",
  resetAuthenticationMethods = "resetAuthenticationMethods",

  // MFA
  mfaEnforced = "mfaEnforced",
  mfaDisabled = "mfaDisabled",
  resetMfa = "resetMfa",

  guestUserLoginSuccess = "guestUserLoginSuccess",
  guestUserLoginFailure = "guestUserLoginFailure",
  userLoginSuccess = "userLoginSuccess",
  userLogout = "userLogout",

  // team

  createTeam = "createTeam",
  deleteTeam = "deleteTeam",
  updateTeam = "updateTeam",
  addUserToTeam = "addUserToTeam",
  addUserToManagingOrganisations = "addUserToManagingOrganisations",
  removeUserFromTeam = "removeUserFromTeam",

  addUserToOrganisation = "addUserToOrganisation",
  removeUserFromOrganisation = "removeUserFromOrganisation",

  // admin
  addApprovedEmailDomain = "addApprovedEmailDomain",
  removeApprovedEmailDomain = "removeApprovedEmailDomain",

  getGuestUserCode = "getGuestUserCode",
  resetGuestUserLogins = "resetGuestUserLogins",

  adminDeleteDataPermission = "adminDeleteDataPermission",

  // configuration
  setOrganisationConfiguration = "setOrganisationConfiguration",

  // organisation
  createOrganisation = "createOrganisation",
  updateOrganisation = "updateOrganisation",
  deleteOrganisation = "deleteOrganisation",

  // organisation managing users
  addOrganisationUserForManaging = "addOrganisationUserForManaging",
  deleteOrganisationUserForManaging = "deleteOrganisationUserForManaging",

  // dashboarding / reporting
  workReport = "workReport",

  //Admissions
  recordReasonForNotProceedingMedRec = "recordReasonForNotProceedingMedRec",
  recordReasonForNotProceedingNoApp = "recordReasonForNotProceedingNoApp",
  reopenAdmission = "reopenAdmission",

  //Rio Clickthrough
  rioClickThroughLaunch = "rioClickThroughLaunch",
  rioClickThroughLogin = "rioClickThroughLogin",
}

export type AuditTrailEntry = {
  id: UUID;
  timestamp: string;

  type: AuditTrailEntryType;
  data: any;

  userId?: UUID;
  userName?: string;

  teamId?: UUID;
  teamName?: string;

  organisationId?: UUID;
  organisationName?: string;

  dataControllerId?: UUID;
  dataControllerName?: string;

  parentTraceId?: string;
  sessionId?: string;
  codeVersion: string;

  resources: AuditTrailEntryResources[];
};
