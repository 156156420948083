import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api, apiHooks } from "../../api";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { formIsApplication, formIsMedRec } from "@aspire/common/types/form";
import { DefaultPageProps } from "../defaultProps";
import { AdmissionsFormPage } from "./admission/AdmissionsFormPage";
import { StandaloneFormPage } from "./standalone/StandaloneFormPage";
import { routeFns } from "../../routes";
import { WorkItem } from "@aspire/common/types/work";
import { RequestWorkItemDialog } from "./helpers/RequestWorkItemDialog";
import { LoggedInUserContext } from "../../Contexts";
import { BannerDialog } from "./helpers/BannerDialog";
import { FormContextActivityLog } from "./helpers/ActivityLogs/FormContextActivityLog";
import { RecordReasonDialog } from "./admission/RecordReasonDialog";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { admissionFormContextLogic } from "./helpers/formContextLogic";
import {
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { ConfirmationModal } from "../ConfirmationModal";
import { AxiosResponse } from "axios";
import { RequestAmendDialog } from "./admission/RequestAmendDialog";
import { AddFileNoteDialog } from "./admission/FileNoteDialogs/AddFileNoteDialog";
import { DeleteFileNoteDialog } from "./admission/FileNoteDialogs/DeleteFileNoteDialog";
import { usePatientTimeline } from "../../hooks/apiCalls";
import { FormContextData } from "@aspire/common/types/formContextData";
import SelectedForm from "../FormUpload/SelectedForm";
import { EditMhaStatusDialog } from "../EditMhaStatus/EditMhaStatusDialog";

const MED_REC_REASON_RECORD_OPTIONS = [
  {
    value: "No evidence of mental disorder",
  },
  { value: "Legal criteria are not met" },
  {
    value: "Less restrictive treatment option is available",
  },
  {
    value: "Informal admission",
  },
  {
    value: "Other",
    descriptionPrompt: "Please describe your reason",
  },
];

const NO_APP_REASON_RECORD_OPTIONS = [
  {
    value: "No evidence of mental disorder",
  },
  {
    value: "Legal criteria are not met",
  },
  {
    value: "Less restrictive treatment option is available",
  },
  {
    value: "Informal admission",
  },
  {
    value:
      "Objected by nearest relative (only applicable for s3 and s7 Applications)",
  },
  {
    value: "Other",
    descriptionPrompt: "Please enter your reason",
  },
];

const ADMISSION_REOPEN_OPTIONS = [
  {
    value: "Admission closed in error",
  },
  { value: "Patient clinical circumstances changed " },
  {
    value: "Patient withdrawn consent to informal admission",
  },
  {
    value: "Other",
    descriptionPrompt: "Please enter your reason",
  },
];

function ModalDialogs({
  formContext,
  reloadFormContext,
  user,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  user: ExtendedThalamosUser;
}) {
  const {
    canLaunchMedicalRecommendation,
    isAdmissionClosed,
    canLaunchApplication,
  } = admissionFormContextLogic(formContext, user);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const qs = new URLSearchParams(location.search);

  const closeFormContextPage = () => {
    navigate(routeFns.formContextPage(formContext.id, formContext.patientId));
  };
  const onClose = () => {
    navigate(routeFns.formContextPage(formContext.id, formContext.patientId), {
      replace: true,
    });
  };

  const showRequestAmendDialog = location.pathname.startsWith(
    routeFns.formContextPageRequestAmendDialog(
      formContext.id,
      formContext.patientId,
    ),
  );

  const formId = qs.get("formId");
  const formTemplateId = qs.get("formTemplateId");
  if (showRequestAmendDialog && formId && formTemplateId) {
    return (
      <RequestAmendDialog
        reloadFormContext={reloadFormContext}
        formContext={formContext}
        formId={formId}
        formTemplateId={formTemplateId}
        onClose={onClose}
      />
    );
  }

  const showConfirmDeleteDraftDialog = location.pathname.startsWith(
    routeFns.formContextPageConfirmDeleteDraftDialog(
      formContext.id,
      formContext.patientId,
    ),
  );
  const draftFormId = qs.get("draftFormId");
  if (showConfirmDeleteDraftDialog && draftFormId) {
    return (
      <ConfirmationModal
        message={
          "Please confirm you want to delete this form. This will delete all drafts and any requests you have sent to other teams or individuals to work on it. It cannot be undone."
        }
        confirmFn={async () => {
          const result = await api.forms.delete(draftFormId);
          if (result.status === 204) {
            renderSuccessToast({
              message: "Form deleted",
            });
            if (formContext.type === "admission") {
              navigate(
                routeFns.formContextPage(formContext.id, formContext.patientId),
              );
            } else {
              navigate(routeFns.patientHome(formContext.patientId));
            }
          } else {
            renderErrorToast({
              message: "Error deleting form",
            });
          }
        }}
        closeFn={onClose}
      />
    );
  }

  const showAddFileNoteDialog = location.pathname.startsWith(
    routeFns.formContextAddFileNoteDialog(
      formContext.id,
      formContext.patientId,
    ),
  );

  const showFormUploadSelectedForm = location.pathname.startsWith(
    routeFns.formUploadSelectedTemplate(formContext.id, formContext.patientId),
  );

  if (showAddFileNoteDialog && formId) {
    return (
      <AddFileNoteDialog
        reloadFormContext={reloadFormContext}
        closeFn={onClose}
        patientId={formContext.patientId}
        formContextId={formContext.id}
        formId={formId}
        user={user}
      />
    );
  }

  if (showFormUploadSelectedForm) {
    return <SelectedForm formContext={formContext} closeFn={onClose} />;
  }
  const showDeleteFileNoteDialog = location.pathname.startsWith(
    routeFns.formContextDeleteFileNoteDialog(
      formContext.id,
      formContext.patientId,
    ),
  );

  if (showDeleteFileNoteDialog && formId) {
    return (
      <DeleteFileNoteDialog
        reloadFormContext={reloadFormContext}
        closeFn={onClose}
        patientId={formContext.patientId}
        formContextId={formContext.id}
        formId={formId}
        user={user}
      />
    );
  }

  const showConfirmFinaliseDialog = location.pathname.startsWith(
    routeFns.formContextPageConfirmFinaliseDialog(
      formContext.id,
      formContext.patientId,
    ),
  );

  const showEditMhaStatusDialog = location.pathname.startsWith(
    routeFns.formContextPageEditMhaStatusDialog(
      formContext.id,
      formContext.patientId,
    ),
  );

  const hasFormWithApplicationOrMedRec = formContext.forms.some(
    (f) => formIsApplication(f) || formIsMedRec(f),
  );

  if (showConfirmFinaliseDialog) {
    const itemName = qs.get("itemName") ?? "item";
    return (
      <ConfirmationModal
        message={
          hasFormWithApplicationOrMedRec
            ? t("forms.completionConfirmationMessageWithRestrictions")
            : t("forms.completionConfirmationMessageWithNoteRestriction")
        }
        confirmFn={async () => {
          const result = await api.work.finalise(
            formContext.activeTeamworkWorkItem!.id,
          );
          if (result.status === 204) {
            renderSuccessToast({
              message: "Administrative scrutiny marked as complete",
            });
            navigate(
              routeFns.formContextPageSuccessDialog(
                formContext.id,
                formContext.patientId,
                {
                  title: "Administrative scrutiny marked complete",
                  message: `You have successfully marked administrative scrutiny of this ${itemName} complete`,
                },
              ),
            );
          } else {
            renderErrorToast({
              message: "Error marking administrative scrutiny as complete",
            });
          }
        }}
        closeFn={onClose}
      />
    );
  }

  if (showEditMhaStatusDialog) {
    return (
      <EditMhaStatusDialog
        hasFormWithApplicationOrMedRec={hasFormWithApplicationOrMedRec}
        formContext={formContext}
      />
    );
  }

  // Activity Log modal
  const showActivityLogModal = location.pathname.startsWith(
    routeFns.formContextPageActivityLog(formContext.id, formContext.patientId),
  );
  if (showActivityLogModal) {
    return (
      <FormContextActivityLog
        formContext={formContext}
        onClose={closeFormContextPage}
        user={user}
      />
    );
  }

  // Request modal
  const showRequestModal = location.pathname.startsWith(
    routeFns.formContextPageSendRequest(formContext.id, formContext.patientId),
  );
  if (showRequestModal) {
    const requestModalParams = {
      requestType: qs.get("requestType") as WorkItem["requestType"] | null,
      formId: qs.get("formId") as string | undefined,
      formPart: qs.get("formPart") as string | undefined,
      title: qs.get("title") as string | undefined,
      infoMessage: qs.get("infoMessage") as string | undefined,
    };
    return (
      <RequestWorkItemDialog
        reloadFormContext={reloadFormContext}
        formContext={formContext}
        user={user}
        formId={requestModalParams.formId}
        title={requestModalParams.title}
        infoMessage={requestModalParams.infoMessage}
        closeFn={closeFormContextPage}
      />
    );
  }

  // Success modal
  const showSuccessModal = location.pathname.startsWith(
    routeFns.formContextPageSuccessDialog(
      formContext.id,
      formContext.patientId,
    ),
  );
  if (showSuccessModal) {
    const successModalParams = {
      title: qs.get("title") as string | undefined,
      message: qs.get("message") as string | undefined,
    };
    return (
      <BannerDialog
        title={successModalParams.title || ""}
        message={successModalParams.message || ""}
        onClose={() =>
          navigate(
            routeFns.formContextPage(formContext.id, formContext.patientId),
          )
        }
      />
    );
  }

  // Reason for not proceeding modal
  const showReasonForNotProceedingDialog = location.pathname.startsWith(
    routeFns.formContextReasonForNotProceedingDialog(
      formContext.id,
      formContext.patientId,
    ),
  );
  if (
    showReasonForNotProceedingDialog &&
    (canLaunchApplication || canLaunchMedicalRecommendation)
  ) {
    type ReasonApiCallback = (
      workItemId: string,
      reason: string,
      reasonDescription: string | undefined,
    ) => Promise<AxiosResponse<any, any>>;

    async function onConfirmReason(
      reason: string,
      reasonDescription: string | undefined,
      apiCb: ReasonApiCallback,
      successMessage: string,
    ) {
      const workItemId = formContext.activeTeamworkWorkItem!.id;
      const result = await apiCb(workItemId, reason, reasonDescription);

      if (result.status === 204) {
        renderSuccessToast({ message: "Action successfully confirmed" });
        navigate(
          routeFns.formContextPageSuccessDialog(
            formContext.id,
            formContext.patientId,
            {
              title: "Action confirmed",
              message: successMessage,
            },
          ),
          { replace: true },
        );
      } else {
        renderErrorToast({
          message:
            "Failed to record reason. Please try again later. If the problem persists, contact support.",
        });
      }
    }

    // No medrec / No application modals
    if (canLaunchMedicalRecommendation) {
      return (
        <RecordReasonDialog
          title="I will not make a medical recommendation"
          dropDownLabel="Please enter a reason why you have chosen not to make a medical recommendation"
          bannerTitle="The admission will be archived once you have confirmed this action. The admission can be unarchived should circumstances change."
          options={MED_REC_REASON_RECORD_OPTIONS}
          onClose={onClose}
          onConfirmClick={async (reason, reasonDescription) => {
            await onConfirmReason(
              reason,
              reasonDescription,
              api.admissions.recordReasonForNotProceedingMedRec,
              "You have successfully confirmed that you will not make a medical recommendation. All participants have been notified by email that you have confirmed this action",
            );
          }}
        />
      );
    } else {
      return (
        <RecordReasonDialog
          title="I will not make an application form"
          dropDownLabel="Please enter a reason why you have chosen not to make an application form"
          bannerTitle="The admission will be archived once you have confirmed this action. The admission can be reopened should circumstances change."
          options={NO_APP_REASON_RECORD_OPTIONS}
          onClose={onClose}
          onConfirmClick={async (reason, reasonDescription) => {
            await onConfirmReason(
              reason,
              reasonDescription,
              api.admissions.recordReasonForNotProceedingAppForm,
              "You have successfully confirmed that you will not make an application form. All participants have been notified by email that you have confirmed this action",
            );
          }}
        />
      );
    }
  }

  // Reopen admission modal
  const showReopenAdmissionDialog = location.pathname.startsWith(
    routeFns.formContextReopenAdmissionDialog(
      formContext.id,
      formContext.patientId,
    ),
  );
  if (showReopenAdmissionDialog && isAdmissionClosed) {
    const workItemId = formContext.activeTeamworkWorkItem!.id;
    return (
      <RecordReasonDialog
        title="Reopen admission"
        bannerTitle="You can proceed working on the possible admission once you have confirmed this action. "
        dropDownLabel="Please enter a reason why you have chosen to reopen this admission"
        options={ADMISSION_REOPEN_OPTIONS}
        onClose={onClose}
        onConfirmClick={async (reason, reasonDescription) => {
          const result = await api.admissions.reOpenAdmission(
            workItemId,
            reason,
            reasonDescription || undefined,
          );

          if (result.status === 204) {
            renderSuccessToast({
              message: "Admission has successfully been reopened",
            });
            navigate(
              routeFns.formContextPageSuccessDialog(
                formContext.id,
                formContext.patientId,
                {
                  title: "Action confirmed",
                  message: `You have successfully reopened this admission. All participants have been notified by email that you have confirmed this action. `,
                },
              ),
              { replace: true },
            );
          } else {
            renderErrorToast({
              message:
                "Failed to reopen the admission. Please try again later. If the problem persists, contact support.",
            });
          }
        }}
      />
    );
  }
  return null;
}

export function FormPageInner({
  formContext,
  reloadFormContext,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
}) {
  const userContext = useContext(LoggedInUserContext);

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: formContext.patientId,
  });

  return (
    <>
      <ModalDialogs
        formContext={formContext}
        reloadFormContext={reloadFormContext}
        user={userContext!.user}
      />

      {formContext.type === "admission" ? (
        <AdmissionsFormPage
          formContext={formContext}
          reloadFormContext={reloadFormContext}
          user={userContext!.user}
          patientTimeline={patientTimeline!}
          reloadPatientTimeline={reloadPatientTimeline}
        />
      ) : (
        <StandaloneFormPage
          formContext={formContext}
          reloadFormContext={reloadFormContext}
          patientTimeline={patientTimeline!}
          reloadPatientTimeline={reloadPatientTimeline}
        />
      )}
    </>
  );
}

export function FormContextPage({}: DefaultPageProps) {
  const { formContextId } = useParams();
  const navigate = useNavigate();

  const [{ data: form, loading: formLoading }, reloadFormContext] =
    apiHooks.forms.getFormContext(formContextId!);

  useEffect(() => {
    if (!formLoading) {
      if (!form?.id) {
        navigate(routeFns.notFound(), { replace: true });
      } else if (form.activeTeamworkWorkItem?.status === "pending") {
        navigate(routeFns.formReview(form.id, form.patientId));
      }
    }
  }, [form, formLoading]);

  if (form?.id) {
    return (
      <FormPageInner
        formContext={form!}
        reloadFormContext={reloadFormContext}
      />
    );
  }

  return null;
}
