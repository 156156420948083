import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Avatar, Chip as MuiChip } from "@mui/material";
import { Container, ExpandMore } from "~/components/layout/styleWrappers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
  PopupDialogTitle,
  ReadOnlyContent,
} from "~/components/design-system";
import { baseTemplates } from "@aspire/common/formTemplates";
import { ExtendedForm } from "@aspire/common/types/extendedForm";
import { FormContextData } from "@aspire/common/types/formContextData";

export function ParticipantsList({
  formContext,
}: {
  formContext: FormContextData;
}) {
  const theme = useTheme();
  const [showParticipants, setShowParticipants] = useState(true);
  const { t } = useTranslation();

  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(
    null,
  );

  const isAdmission = formContext.type === "admission";

  return (
    <Container addMarginBottom>
      <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
        <FormTitle
          subtitleText={t("pages.formPage.participants")}
          hasContainerMarginBottom={false}
          subTitleTextFontSize={2}
        />
        <ExpandMore
          role="button"
          isExpanded={showParticipants}
          onClick={() => setShowParticipants(!showParticipants)}
          aria-expanded={showParticipants}
          aria-label={t("components.cardExtended.expandMoreLabel")}
        >
          <ExpandMoreIcon sx={{ color: "primary.main" }} />
        </ExpandMore>
      </Box>
      {showParticipants && (
        <Box>
          {formContext.workItems && (
            <Box display="flex" flexDirection={"row"} flexWrap={"wrap"}>
              {formContext.workItems.map((w, index) => {
                const isRejectedOrAdmissionCompleted =
                  w.status === "rejected" ||
                  (isAdmission && w.status === "completed" && w.reason) ||
                  (w.team.type === "amhp" && formContext.status === "closed");

                const isClickableChip =
                  isRejectedOrAdmissionCompleted ||
                  (!!isAdmission && !!w.reason && w.status === "rejected");

                return (
                  <ParticipantChip
                    key={index}
                    workItem={w}
                    isClickable={!!isClickableChip}
                    onClick={() => setSelectedChipIndex(index)}
                    isAdmission={isAdmission}
                    formContext={formContext}
                    setSelectedChipIndex={setSelectedChipIndex}
                    selectedChipIndex={selectedChipIndex!}
                    index={index}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}

interface ParticipantChipProps {
  workItem: ExtendedForm<any>["workItems"][0];
  isClickable: boolean;
  onClick: () => void;
  setSelectedChipIndex: (params: any) => void;
  selectedChipIndex: number;
  index: number;
  isAdmission: boolean;
  formContext: FormContextData;
}

const ParticipantChip: React.FC<ParticipantChipProps> = ({
  workItem,
  isClickable,
  onClick,
  setSelectedChipIndex,
  selectedChipIndex,
  index,
  isAdmission,
  formContext,
}) => {
  function getFormTemplateById(formId: string) {
    const form = formContext.forms.find((form) => form.id === formId);
    return form && form.formTemplate;
  }

  const formTemplate = baseTemplates.find((t) =>
    formContext.workItems
      .map((workItem) => getFormTemplateById(workItem.formId))
      .filter((template) => template)
      .find((template) => template?.id.includes(t.id)),
  );

  const readOnlyContentFn = (workItem: ExtendedForm<any>["workItems"][0]) => {
    if (
      isAdmission &&
      formContext.status === "closed" &&
      formContext.closedReason &&
      workItem.team.type === "amhp"
    ) {
      return [
        formContext.closedReason,
        formContext.closedReasonDescription || "",
      ];
    }

    if (isAdmission && workItem.status === "completed" && workItem.reason) {
      return [workItem.reason, workItem.reasonDescription || ""];
    }

    if (workItem.status === "rejected" && workItem.rejectedReason) {
      return [
        `${workItem.rejectedReason}`,
        `${
          workItem.rejectedReasonDescription
            ? workItem.rejectedReasonDescription
            : ""
        }`,
      ];
    }
    return [];
  };

  const generateLabel = (workItem: ExtendedForm<any>["workItems"][0]) => {
    const teamName = `${workItem.team.name}`;

    if (workItem.status === "completed" || workItem.status === "rejected") {
      if (
        isAdmission &&
        formContext.status === "closed" &&
        formContext.closedReason &&
        workItem.team.type === "amhp"
      ) {
        return teamName + ` (action confirmed: ${formContext.closedReason})`;
      }

      if (isAdmission && workItem.status === "completed" && workItem.reason) {
        return teamName + ` (action confirmed: ${workItem.reason})`;
      }

      if (workItem.rejectedReason && workItem.status === "rejected") {
        return teamName + ` (declined: ${workItem.rejectedReason})`;
      }
    }

    return teamName;
  };

  const isAdmissionType = formContext.type === "admission";
  const isClosedAdmission =
    isAdmissionType &&
    formContext.status === "closed" &&
    formContext.closedReason;
  const isCompletedWorkItem =
    isAdmissionType && workItem.status === "completed" && workItem.reason;

  const chipColor =
    isClosedAdmission || isCompletedWorkItem || workItem.status === "rejected"
      ? "error"
      : "default";
  const theme = useTheme();
  const isRejectedOrCompletedStatus = (
    work: ExtendedForm<any>["workItems"][0],
  ) => work.status === "completed" || work.status === "rejected";
  return (
    <Box display="flex">
      <Box onClick={isClickable ? onClick : undefined}>
        <MuiChip
          clickable={isClickable}
          sx={{
            mr: theme.spacing(1),
            my: 1,
          }}
          style={{
            backgroundColor:
              chipColor === "error"
                ? theme.palette.error.main
                : theme.palette.common.lightGray,
            ...(chipColor === "error" && { color: "white" }),
          }}
          avatar={
            <Avatar
              sx={{
                ...(chipColor === "default" && {
                  backgroundColor: "common.royalBlue",
                }),
              }}
            >
              <Box
                sx={{
                  ...(chipColor === "default" && { color: "common.white" }),
                }}
              >
                {workItem.team.name
                  .split(" ")
                  .slice(0, 2)
                  .map((x) => x.slice(0, 1))}
              </Box>
            </Avatar>
          }
          label={generateLabel(workItem)}
          onDelete={() => setSelectedChipIndex(null)}
          deleteIcon={
            workItem.status === "rejected" ? (
              <RemoveRedEyeOutlinedIcon sx={{ color: "black" }} />
            ) : (
              <></>
            )
          }
        />
      </Box>
      {isRejectedOrCompletedStatus(workItem) && selectedChipIndex === index && (
        <ParticipantPopupDialog
          workItem={workItem}
          generateLabel={generateLabel}
          setSelectedChipIndex={setSelectedChipIndex}
          selectedChipIndex={selectedChipIndex}
          index={index}
          isAdmission={isAdmission}
          formTemplate={formTemplate}
          readOnlyContentFn={readOnlyContentFn}
        />
      )}
    </Box>
  );
};

interface ParticipantPopupDialogProps {
  workItem: ExtendedForm<any>["workItems"][0];
  generateLabel: (...params: any) => string;
  setSelectedChipIndex: (params: any) => void;
  selectedChipIndex: number;
  index: number;
  isAdmission: boolean;
  formTemplate: any;
  readOnlyContentFn: (params: any) => string[];
}

const ParticipantPopupDialog: React.FC<ParticipantPopupDialogProps> = ({
  workItem,
  setSelectedChipIndex,
  selectedChipIndex,
  isAdmission,
  formTemplate,
  readOnlyContentFn,
}) => {
  const { t } = useTranslation();

  const bannerTitle = () => {
    if (isAdmission && workItem.status !== "rejected") {
      if (workItem.team.type === "amhp") {
        return `${workItem.team.name} has confirmed that an application form will not be made. Everyone involved in the assessment should discuss how to proceed.`;
      } else {
        return `${workItem.team.name} has confirmed that a medical recommendation form will not be made. Everyone involved in the assessment should discuss how to proceed.`;
      }
    }
    return `The invite you sent to ${workItem.team.name} ${
      formTemplate?.category === "admission" || !formTemplate
        ? "to access this patient's assessment documents"
        : `to complete form ${formTemplate.formName}: ${
            formTemplate.description || `Section ${formTemplate.section}`
          }`
    } was declined. Everyone involved in the assessment should discuss how to proceed.`;
  };
  return (
    <PopupDialog
      onClose={() => setSelectedChipIndex(null)}
      open={selectedChipIndex !== null}
    >
      <PopupDialogTitle
        titleText={t(
          isAdmission && workItem.status !== "rejected"
            ? workItem.team.type === "amhp"
              ? "pages.formPage.participantsList.popupDialog.admissionTitleNoApp"
              : "pages.formPage.participantsList.popupDialog.admissionTitleNoMedRec"
            : "pages.formPage.participantsList.popupDialog.title",
        )}
        closeDialog={() => setSelectedChipIndex(null)}
      />
      <Box sx={{ mb: 3 }}>
        <Banner title={bannerTitle()} bannerType={BannerList.WARNING} />
      </Box>
      <ReadOnlyContent
        label={t(
          isAdmission && workItem.status !== "rejected"
            ? workItem.team.type === "amhp"
              ? "pages.formPage.participantsList.popupDialog.admissionReadOnlyContentTitleNoApp"
              : "pages.formPage.participantsList.popupDialog.admissionReadOnlyContentTitleMedRec"
            : "pages.formPage.participantsList.popupDialog.readOnlyContentTitle",
        )}
        content={readOnlyContentFn(workItem)}
        isActive={true}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          label={t("buttonLabels.close")}
          onClick={() => setSelectedChipIndex(null)}
        />
      </Box>
    </PopupDialog>
  );
};
