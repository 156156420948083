import { Dayjs } from "dayjs";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { UUID } from "@aspire/common/util";
import { apiHooks } from "../api";
import { SelectedFilteredOptions, WorkMode } from "../pages/Caseload/types";

export function usePatientTimeline(props: {
  patientId: UUID | undefined;
  page?: number;
  limit?: number;
}) {
  const patientId = props.patientId;
  const [
    {
      data: patientTimeline,
      loading: patientTimelineCompleteLoading,
      response,
    },
    reloadPatientTimeline,
    // TODO: paging?
  ] = apiHooks.patients.getTimeline(patientId!, {
    limit: props.limit || 10,
    offset: props.page,
  });

  return {
    patientTimeline,
    patientTimelineCompleteLoading,
    reloadPatientTimeline,
    response,
  };
}

export function useGetFileNotes(formId: string, user: ExtendedThalamosUser) {
  const isMhaAdmin = user.sessionContext?.teamType === "mha";

  // Only call this if they are a mha admin
  if (isMhaAdmin && formId) {
    const [
      { data: fileNotes, loading: fileNotesCompleteLoading, response },
      reloadFileNotes,
    ] = apiHooks.forms.getFileNotes(formId);
    return { fileNotes, fileNotesCompleteLoading, response, reloadFileNotes };
  } else {
    return { fileNotes: [], reloadFileNotes: () => {} };
  }
}

export function useRetriveWorkItems({
  userId,
  contextId,
  mode,
  pageLimit,
  page,
  patientSearchFilter,
  selectedFilteredOptions,
  userSpecifiedFromDate,
  toDate,
}: {
  userId: string;
  contextId: string;
  mode: WorkMode;
  pageLimit: number;
  page: number;
  patientSearchFilter: string;
  selectedFilteredOptions: SelectedFilteredOptions;
  userSpecifiedFromDate: string | undefined;
  toDate: Dayjs | null;
}) {
  const [
    {
      data: work,
      loading: workLoading,
      response: response,
      error: retrieveWorkError,
    },
  ] = apiHooks.work.retrieveWork(userId, contextId, mode, {
    limit: pageLimit,
    offset: page * pageLimit,
    patientSearchFilter: patientSearchFilter || undefined,
    selectedFormTemplateIds:
      selectedFilteredOptions.formTemplateIds || undefined,
    selectedSenderNames: selectedFilteredOptions.senderNames || undefined,

    fromDate: userSpecifiedFromDate ? userSpecifiedFromDate : undefined,

    toDate: toDate?.isValid()
      ? toDate?.tz("Europe/London").format("YYYY-MM-DD")
      : undefined,
  });

  if (retrieveWorkError !== null || (response && response.status !== 200)) {
    const errorMessage =
      retrieveWorkError?.message ||
      (response?.data as any)?.reason ||
      "Unable to retrieve caseload";

    // throwing an error to hit the error boundary and display the error page
    throw Error(errorMessage);
  }

  return { work, workLoading };
}

export function useMhaStatus(props: {
  patientId: UUID | undefined;
  page?: number;
  limit?: number;
}) {
  const patientId = props.patientId;
  const [
    { data: mhaStatus, loading: mhaStatusCompleteLoading, response },
    reloadMhaStatus,
  ] = apiHooks.patients.getMhaState(patientId!, {
    limit: props.limit || 10,
    offset: props.page || 0,
  });

  return {
    mhaStatus,
    mhaStatusCompleteLoading,
    reloadMhaStatus,
    response,
  };
}
