import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogProps } from "@mui/material";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection";

const BootstrapDialog = styled(Dialog)<PopupDialogProps>(
  ({ theme, fullScreen, hasPadding, maxWidth }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        ...(!fullScreen && {
          display: "flex",
          padding: hasPadding && theme.spacing(4),
          width: "100%",
          maxWidth: maxWidth ? theme.breakpoints.values[maxWidth] : "800px",
        }),
      },
    },
  }),
);

export interface PopupDialogProps extends DialogProps {
  hasPadding?: boolean;
  testId?: string;
}

const defaultProps: Partial<PopupDialogProps> = {
  hasPadding: true,
  fullScreen: false,
};

export const PopupDialog = (props: PopupDialogProps) => {
  const allProps = { ...defaultProps, ...props };
  const { children, open, onClose, maxWidth, fullScreen, hasPadding } =
    allProps;

  const { isMobileView } = useScreenDetection();

  return (
    <BootstrapDialog
      onClose={onClose}
      fullScreen={isMobileView || fullScreen}
      aria-labelledby="popup-dialog"
      PaperProps={
        {
          "data-testid": props.testId,
        } as any
      }
      open={open}
      maxWidth={maxWidth}
      hasPadding={hasPadding}
    >
      <Box sx={{ m: isMobileView ? 2 : 0 }}>{children}</Box>
    </BootstrapDialog>
  );
};
