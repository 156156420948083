import React from "react";
import { Box } from "@mui/material";
import { Typeahead } from "~/components/design-system";
import { api } from "../../api";

export function TeamPicker<T>({
  error,
  idField,
  nameField,
  values,
  setValues,
  label,
  disabled,
}: {
  error: string | null;
  idField: keyof T;
  nameField: keyof T;
  values: T;
  setValues: (values: T) => void;
  label: string;
  disabled?: boolean;
}) {
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const teamName = values[nameField] as string;

  React.useEffect(() => {
    api.teams.search(teamName, 10, 0).then((result) => {
      const filteredResults = result.data.results.filter(
        (o) =>
          o.type !== "guest" &&
          !(o.type === "independent" && o.members.length > 0),
      );

      setOptions(
        filteredResults.map((o) => ({
          label: `${o.name}`,
          value: o.id,
        })),
      );
    });
  }, [teamName]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typeahead
        disabled={disabled || false}
        inputValue={teamName as string}
        onInputChange={(e) => {
          const match = options.find((o) => o.label === e);

          if (match) {
            setValues({ ...values, [idField]: match.value, [nameField]: e });
          } else {
            setValues({ ...values, [idField]: null, [nameField]: e });
          }
        }}
        options={options}
        name={"team"}
        label={label}
        errorMessage={error || undefined}
      />
    </Box>
  );
}
