import React, { useEffect, useState } from "react";
import { BaseFormTemplate, getLatestVersion } from "@aspire/common/types/form";
import { ExtendedThalamosUser } from "@aspire/common/types/user";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { v4 } from "uuid";
import { admissionFormContextLogic } from "../helpers/formContextLogic";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { NewResultWithoutAccreditation } from "../../../components/FormAssignee/NewFormAssigneeDialog";
import {
  FormikInitialValues,
  FormikNewFormAssigneeValues,
  NewFormAssigneeDialog,
  newFormAssigneeData,
} from "../../../components/FormAssignee/NewFormAssigneeDialog";

import { InferType } from "yup";
import { api } from "../../../api";
import { CreateWorkItemAction } from "@aspire/common/types/work";
import { baseTemplates } from "@aspire/common/formTemplates";
import { routeFns } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { recordOfDetentionTemplateIds } from "@aspire/common/sections";
import { UUID } from "@aspire/common/util";
import { FormContextData } from "@aspire/common/types/formContextData";
import { capitalize } from "@aspire/common/util/string";
import { getFullFormName } from "@aspire/common/util/form";
import { stringAvatar } from "~/components/layout/menu/helpers";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection";

import CloseIcon from "@mui/icons-material/Close";
import { logErrorToDatadog } from "~/tracing";

export const getTemplate = (formContext: Omit<FormContextData, "patient">) => {
  if (formContext.type === "standalone") {
    return {
      template: formContext.forms[0].formTemplate,
      part: getLatestVersion(formContext.forms[0]).data.length + 1,
    };
  }

  return { template: { id: "mha-a2", version: "1.0" }, part: 1 };
};
export function actions(
  formContext: Omit<FormContextData, "patient">,
  user: ExtendedThalamosUser,
): (CreateWorkItemAction | { type: "share" })[] {
  if (formContext.type === "admission") {
    const {
      admissionHasCompletedApplication,
      admissionHasCompletedRecordOfDetention,
      needsPart2Forms,
    } = admissionFormContextLogic(formContext, user);

    const availableActions: (CreateWorkItemAction | { type: "share" })[] = [];

    if (formContext.forms.some((f) => f.status === "finalised")) {
    } else if (admissionHasCompletedRecordOfDetention) {
      availableActions.push({ type: "finalise" });
    } else if (admissionHasCompletedApplication) {
      availableActions.push({ type: "record-of-detention" });
    } else {
      availableActions.push({ type: "application" });
      availableActions.push({ type: "medical-recommendation" });
    }

    for (const form of needsPart2Forms) {
      availableActions.push({
        type: "complete",
        formId: form.id,
        formPart: 2,
        formVersion: form.latestVersion,
      });
    }

    availableActions.push({ type: "share" });

    return availableActions;
  } else {
    const availableActions: (CreateWorkItemAction | { type: "share" })[] = [];

    const form = formContext.forms[0];

    if (form.status === "in-progress") {
      availableActions.push({
        type: "complete",
        formId: form.id,
        formPart: (getLatestVersion(form).data.length || 0) + 1,
        formVersion: form.latestVersion,
      });
    }

    if (form.status === "complete") {
      availableActions.push({ type: "finalise" });
    }

    availableActions.push({ type: "share" });

    return availableActions;
  }
}

const guestAccreditationOptions = [
  {
    label: "Registered medical practitioner",
    value: "registered-medical-practitioner",
  },
  {
    label: "Approved mental health professional",
    value: "approved-mental-health-professional",
  },
  {
    label: "Nurse",
    value: "nurse",
  },
  {
    label: "Mental health act administrator",
    value: "mental-health-act-administrator",
  },
  {
    label: "Other",
    value: "other",
  },
];

const completionOption = {
  label: "Complete",
  value: "complete",
};

export function mapGuestAccreditation(
  formContext: Omit<FormContextData, "patient">,
  value: string,
) {
  switch (value) {
    case "registered-medical-practitioner":
      return "medical-recommendation";
    case "approved-mental-health-professional":
      return "application";
    case "nurse":
      return "record-of-detention";
    case "mental-health-act-administrator":
      if (
        formContext.type === "admission" &&
        formContext.forms.find(
          (f) =>
            f.status === "complete" &&
            recordOfDetentionTemplateIds.includes(f.formTemplate.id),
        )
      ) {
        return "finalise";
      } else {
        return "record-of-detention";
      }
    case "complete":
      return "complete";
    default:
      return "other";
  }
}

const admissionBannerLogic = (
  user: ExtendedThalamosUser,
  formContext: Omit<FormContextData, "patient">,
  fullFormName: string | null,
  formTemplate: BaseFormTemplate,
  requestType: string,
) => {
  const {
    needsPart2Forms,
    admissionHasCompletedApplication,
    admissionHasCompletedRecordOfDetention,
    canScrutiniseAdmission,
  } = admissionFormContextLogic(formContext, user);

  const formSectionAndCategory = formTemplate
    ? `${formTemplate?.section} ${capitalize(formTemplate?.category)}`
    : "";

  if (
    admissionHasCompletedRecordOfDetention &&
    user.sessionContext?.teamType === "mha"
  ) {
    return {
      value: `Send Section ${formSectionAndCategory} Forms to another user or team. `,
    };
  }

  if (canScrutiniseAdmission) {
    return {
      value: `Send Section ${formSectionAndCategory} Forms to another user or team. `,
    };
  }

  // If finalised
  if (
    admissionHasCompletedApplication &&
    admissionHasCompletedApplication.status === "finalised"
  ) {
    const applicationTemplate = baseTemplates.find(
      (t) =>
        t.id === admissionHasCompletedApplication.formTemplate.id &&
        t.version === admissionHasCompletedApplication.formTemplate.version,
    )!;
    return {
      value: `Send Section ${applicationTemplate.section} Admission Forms to another user or team.`,
    };
  }

  if (!!needsPart2Forms.length && requestType === "complete") {
    return {
      guestAccreditationOptions: [completionOption],
      value: `Send ${fullFormName} to another user or team to complete Part 2.`,
      info: "Part 2 to be completed by a registered medical practitioner.",
    };
  }

  if (requestType === "medical-recommendation") {
    return {
      guestAccreditationOptions: [
        guestAccreditationOptions.find(
          (o) => o.value === "registered-medical-practitioner",
        )!,
      ],
      value:
        "Invite a registered medical practitioner to work on this assessment. ",
      info: "The registered medical practitioner will choose whether to make a medical recommendation based on their assessment.",
    };
  }

  if (requestType === "application") {
    return {
      guestAccreditationOptions: [
        guestAccreditationOptions.find(
          (o) => o.value === "approved-mental-health-professional",
        )!,
      ],
      value:
        "Invite an approved mental health professional to work on this assessment.",
      info: "The approved mental health professional will choose whether to make an application based on their assessment.",
    };
  }

  return {
    guestAccreditationOptions,
    value: "Invite another user(s) or team to work on this assessment.",
  };
};

export const bannerLogic = (
  user: ExtendedThalamosUser,
  formContext: Omit<FormContextData, "patient">,
  fullFormName: string | null,
  formTemplate: BaseFormTemplate,
  requestType: string,
) => {
  if (formContext.type === "admission") {
    return admissionBannerLogic(
      user,
      formContext,
      fullFormName,
      formTemplate,
      requestType,
    );
  }

  if (requestType === "complete") {
    return {
      guestAccreditationOptions: [completionOption],
      value: `Invite another user or team to work on this form.`,
    };
  }

  if (formContext.forms.some((f) => f.status === "finalised")) {
    return {
      value: `Send ${fullFormName} to another user or team`,
    };
  }

  return { value: `Invite another user or team to work on this form.` };
};

export function userNotSelectedFn(formikValues: FormikNewFormAssigneeValues) {
  return formikValues.values.type === "full-user"
    ? !formikValues.values.teamId
    : !formikValues.values.name ||
        !formikValues.values.guestInviteAccreditation;
}

export function shareToNonTrainingUserFromTrainingFn(
  formikValues: FormikNewFormAssigneeValues,
  userNotSelected: boolean,
  isTrainingContext: boolean,
) {
  return (
    !userNotSelected &&
    isTrainingContext &&
    !formikValues.values.isTrainingOrganisation &&
    formikValues.values.type !== "guest"
  );
}

export function shareToTrainingUserFromNonTrainingFn(
  formikValues: FormikNewFormAssigneeValues,
  userNotSelected: boolean,
  isTrainingContext: boolean,
) {
  return (
    !userNotSelected &&
    !isTrainingContext &&
    formikValues.values.isTrainingOrganisation
  );
}

export function RequestWorkItemDialog({
  closeFn,
  formContext,
  user,
  reloadFormContext,
  formId,
  title,
  infoMessage,
}: {
  closeFn: () => void;
  formContext: FormContextData;
  user: ExtendedThalamosUser;
  reloadFormContext: () => void;
  formId?: string;
  title?: string;
  infoMessage?: string;
}) {
  const isTrainingContext = formContext.isTrainingFormContext;
  const [recipients, setRecipients] = useState<
    InferType<typeof newFormAssigneeData>[]
  >([]);

  const [searchResults, setSearchResults] = React.useState<
    NewResultWithoutAccreditation[]
  >([]);

  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { t } = useTranslation();
  const qs = new URLSearchParams(window.location.search);
  const requestType = qs.get("requestType");

  const { isMobileView } = useScreenDetection();

  const availableActions = actions(formContext, user);

  const getForms = () => {
    const activeTeamworkWorkItem = formContext.activeTeamworkWorkItem;
    if (activeTeamworkWorkItem?.formContextType === "admission") {
      return activeTeamworkWorkItem.forms.find(
        (f) => f.formTemplate.id !== "mha-h3",
      );
    }
    return activeTeamworkWorkItem?.forms[0];
  };

  const getForm = getForms();

  const formTemplate = baseTemplates.find(
    (t) => t.id === getForm?.formTemplate.id,
  );

  const fullFormName = getFullFormName(formTemplate as BaseFormTemplate | null);

  useEffect(() => {
    if (availableActions.length === 1) {
      navigate(
        routeFns.formContextPageSendRequest(
          formContext.id,
          formContext.patientId,
          { requestType: "share" },
        ),
        { replace: true },
      );
    }
  }, []);

  const { value, info, guestAccreditationOptions } = bannerLogic(
    user,
    formContext,
    fullFormName,
    formTemplate!,
    requestType!,
  );

  return (
    <PopupDialog
      maxWidth="lg"
      open={true}
      onClose={closeFn}
      testId="request-work-item-dialog"
    >
      <PopupDialogTitle
        titleText={
          formContext.type === "admission"
            ? t("common.sendInvite")
            : t("common.sendForm")
        }
        closeDialog={() => {
          navigate(
            routeFns.formContextPage(formContext.id, formContext.patientId),
            { replace: true },
          );
        }}
      />

      <Box>
        <Box sx={{ mb: 4 }}>
          <Banner
            title={(title ? title : value)!}
            info={(title ? infoMessage : info)!}
            singleLine
            bannerType={BannerList.INFO}
          />
        </Box>

        <NewFormAssigneeDialog
          formId={formId}
          formContext={formContext}
          reloadFormContext={reloadFormContext}
          availableActions={availableActions}
          searchAssignmentOptions={async (formDetails, searchValue) => {
            return api.formTemplates.searchAssignmentOptions({
              id: formDetails.template.id,
              version: formDetails.template.version,
              query: searchValue,
              part: formDetails.part,
            });
          }}
          formDetails={getTemplate(formContext)}
          userId={user.id}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          onSave={async (formData: InferType<typeof newFormAssigneeData>) => {}}
          navigate={navigate}
          guestAccreditationOptions={guestAccreditationOptions}
        />
      </Box>
    </PopupDialog>
  );
}

export function SendRequestButton({
  shareToNonTrainingUserFromTraining,
  shareToTrainingUserFromNonTraining,
  recipients,
  setSubmitError,
  formContextId,
  reloadFormContext,
  patientId,
  navigate,
  signForm,
}: {
  shareToNonTrainingUserFromTraining: boolean;
  shareToTrainingUserFromNonTraining: boolean | undefined;
  recipients: InferType<typeof newFormAssigneeData>[];
  setSubmitError: (param: any) => void;
  formContextId: UUID;
  reloadFormContext: () => void;
  patientId: string;
  navigate: (param: any, options: any) => void;
  signForm?: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Box sx={{ mt: 2 }} display="flex" justifyContent="flex-end" width="100%">
      <Button
        testId="send-button"
        label={"send"}
        disabled={
          isSubmitting ||
          shareToNonTrainingUserFromTraining ||
          shareToTrainingUserFromNonTraining ||
          recipients.length === 0
        }
        onClick={async () => {
          setSubmitError(null);
          setIsSubmitting(true);
          let hasError = false;
          if (signForm) await signForm();

          // TODO - think about changing this to a batch request or something like that?
          for (const recipient of recipients) {
            const workItemId = v4();
            const result = await api.work.create(workItemId, {
              formContextId: formContextId,
              ...(recipient.type === "full-user"
                ? {
                    teamId: recipient.teamId!,
                    assignedUserId: recipient.assignedUserId,
                    type: "full-user",
                  }
                : {
                    type: "guest",
                    email: recipient.email!,
                    name: recipient.name!,
                  }),
              action: recipient.action as CreateWorkItemAction | undefined,
            });

            if (!result || result.status !== 204) {
              setIsSubmitting(false);
              hasError = true;
              const errorMessage = result.data?.reason || "Unknown error";
              renderErrorToast({ message: errorMessage });
              setSubmitError(errorMessage);
              logErrorToDatadog(errorMessage);
              break;
            }
          }

          if (!hasError) {
            const message = `Invites successfully sent to ${recipients.map((r) => r.name).join(", ")}`;
            renderSuccessToast({ message });
            reloadFormContext();
            navigate(
              routeFns.formContextPageSuccessDialog(formContextId, patientId, {
                title: "Invites successfully sent",
                message: message,
              }),
              { replace: true },
            );
            setIsSubmitting(false);
          }
        }}
      />
    </Box>
  );
}

export function AddRecipientButton({
  userNotSelected,
  formikValues,
  shareToNonTrainingUserFromTraining,
  shareToTrainingUserFromNonTraining,
  onClick,
}: {
  userNotSelected: boolean;
  formikValues: FormikNewFormAssigneeValues;
  shareToNonTrainingUserFromTraining: boolean;
  shareToTrainingUserFromNonTraining: boolean | undefined;
  onClick: () => void;
}) {
  return (
    <Box sx={{ mt: 2 }} display="flex" justifyContent="flex-end" width="100%">
      <Button
        testId="add-recipient-button"
        label={"add recipient"}
        disabled={
          userNotSelected ||
          formikValues.isSubmitting ||
          shareToNonTrainingUserFromTraining ||
          shareToTrainingUserFromNonTraining
        }
        onClick={onClick}
      />
    </Box>
  );
}

export function RecipientsList({
  recipients,
  isMobileView,
  setRecipients,
  setSubmitError,
}: {
  recipients: InferType<typeof newFormAssigneeData>[];
  isMobileView: boolean;
  setRecipients: (param: any) => void;
  setSubmitError: (param: any) => void;
}) {
  return (
    <Box
      data-testid="recipient-chips-container"
      display="flex"
      flexWrap="wrap"
      marginTop={2}
      justifyContent={isMobileView ? "center" : "flex-start"}
    >
      {recipients.map((recipient, index) => {
        return (
          <Box
            data-testid="recipient-chip"
            key={index}
            display="flex"
            marginRight={2}
            marginBottom={2}
            alignItems="center"
            border="1px solid black"
            width={isMobileView ? "100%" : "auto"}
            justifyContent={isMobileView ? "center" : "flex-start"}
            padding={1.5}
          >
            <Avatar
              sx={{
                backgroundColor:
                  recipient.type === "guest" ? "" : "primary.main",
                marginRight: 2,
              }}
              {...stringAvatar(recipient.name)}
            />
            <Box>
              <Typography>
                {recipient.name}
                {recipient.teamType === "independent"
                  ? ` (${recipient.teamType})`
                  : recipient.teamName
                    ? ` (${recipient.teamName})`
                    : recipient.type === "guest"
                      ? ` (${recipient.type})`
                      : ""}
              </Typography>
              <Typography>{recipient.email}</Typography>
            </Box>
            <IconButton
              aria-label={"Close"}
              size="small"
              onClick={() => {
                setSubmitError(null);
                setRecipients(
                  recipients.filter((_: unknown, i: number) => i !== index),
                );
              }}
              sx={{
                marginLeft: "auto",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
}

export function handleAddRecipient({
  formikValues,
  setRecipients,
  initialValues,
  action,
}: {
  formikValues: FormikNewFormAssigneeValues;
  setRecipients: (param: any) => void;
  initialValues: FormikInitialValues;
  action: CreateWorkItemAction | undefined;
}) {
  const teamMember = formikValues.values.userMembershipContext.find(
    (m) => m.role !== "manager" && formikValues.values.teamId === m.id,
  );

  let newRecipient = {
    ...(formikValues.values.type === "full-user"
      ? {
          teamId: formikValues.values.teamId!,
          assignedUserId: formikValues.values.assignedUserId,
          type: "full-user",
          email: formikValues.values.email,
          name: formikValues.values.name,
          teamName: teamMember?.name,
          teamType: formikValues.values.teamType,
        }
      : {
          type: "guest",
          email: formikValues.values.email!,
          name: formikValues.values.name!,
        }),
    action: action ?? undefined,
  };

  setRecipients((prevRecipients: InferType<typeof newFormAssigneeData>[]) => [
    ...prevRecipients,
    newRecipient,
  ]);

  formikValues.setValues(initialValues);
  formikValues.setTouched({ email: false });
}

export function TrainingWarningBanners({
  shareToNonTrainingUserFromTraining,
  shareToTrainingUserFromNonTraining,
}: {
  shareToNonTrainingUserFromTraining: boolean;
  shareToTrainingUserFromNonTraining: boolean | undefined;
}) {
  const { t } = useTranslation();

  return (
    <>
      {shareToNonTrainingUserFromTraining && (
        <Box sx={{ mb: 4 }}>
          <Banner
            title={t("training.onlyShareToTrainingUsers")}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}
      {shareToTrainingUserFromNonTraining && (
        <Box sx={{ mb: 4 }}>
          <Banner
            title={t("training.cannotShareToTrainingUsers")}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}
    </>
  );
}
