import { FormPartTemplate, UploadableBaseFormTemplate } from "../types/form";
import {
  A9UploadableData,
  A9UploadablePart1DataSchema,
  a9UploadablePart1DataSchema,
} from "../schemas/mhaForms/admission/uploadable/a9";
import {
  a9Part1DataSchema,
  A9Part1DataSchema,
} from "../schemas/mhaForms/admission/a9";
import { a9And10Part1Signing } from "./helpers/signingLogic";

export const a9Part1Template: FormPartTemplate<A9Part1DataSchema> = {
  signing: a9And10Part1Signing,
  formBuilderFields: [],
  dataSchema: a9Part1DataSchema,
};

export const a9UploadablePart1Template: FormPartTemplate<A9UploadablePart1DataSchema> =
  {
    signing: a9And10Part1Signing,
    formBuilderFields: [
      {
        type: "hospital-picker",
        field: "hospital",
        label: "Name and address of hospital",
      },

      {
        type: "name-address",
        field: "nameAndAddressOfSignee",
        nameLabel: "Name of signee",
        addressLabel: "Address of signee",
      },

      {
        type: "textbox",
        label: "Email address of signee",
        field: "emailAddressOfSignee",
      },

      {
        type: "readonly",
        label: "Name and address of patient",
        textFn: (data) => [
          data.patient?.name || "",
          [data.patient?.address, data.patient?.postalCode].join(", "),
        ],
      },

      {
        type: "date-time",
        field: "lastSeenDateTime",
        label: "Date and time signee last saw the patient on",
        minimum: (now) => now.subtract(24, "hour"),
        maximum: (now) => now,
      },

      {
        type: "branch",
        field: "medicalPractitionersPreviousAcquaintance",
        label:
          "Did one of the medical practitioners have previous acquaintance with the patient before making their recommendation?",
        branches: [
          {
            fieldValue: true,
            label: "Yes",
            fieldsOwned: [],
            fields: [],
          },
          {
            fieldValue: false,
            label: "No",
            fieldsOwned: [],
            fields: [],
          },
        ],
      },

      {
        type: "date-time",
        field: "signatureDate",
        label: "Date and time of signature",
        maximum: (today) => today,
      },
    ],
    dataSchema: a9UploadablePart1DataSchema,
  };

export const a9V1Base: UploadableBaseFormTemplate = {
  id: "mha-a9",
  category: "admission",
  version: "1.0",
  status: "active",
  parts: [a9Part1Template],
  formName: "A9",
  section: "4",
  description:
    "Emergency application by nearest relative for admission for assessment",
  extractGenericData: (data: A9UploadableData) => ({
    admissions: {
      applicationHospitalName: data[0].data?.hospital.name,
      amhpLastSeenDateTime: data[0].data?.lastSeenDateTime,
    },
  }),

  uploadable: true,
  uploadablePartTemplates: [a9UploadablePart1Template],
};
