import { boolean, InferType, object } from "yup";
import {
  defaultAddress,
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  hospital,
  nameAndAddress,
} from "../../../shared";
import { ExampleFormData, PartData } from "../../../../types/form";
import { optionalEmail } from "../../../acd/util";

export const a5UploadablePart1DataSchema = object({
  ...defaultContextFields(),

  hospital: hospital,

  nameAndAddressOfSignee: nameAndAddress(
    {
      nameRequiredError: "Please enter name of signee",
      addressRequiredError: "Please enter address of signee",
    },
    true,
  ).default(defaultAddress),

  emailAddressOfSignee: optionalEmail,

  lastSeenDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter date signee last saw the patient on"),

  medicalPractitionersPreviousAcquaintance: boolean()
    .default(null)
    .required(
      "Please confirm if one of the medical practitioners have previous acquaintance with the patient before making their recommendation",
    ),

  signatureDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter signature date "),
});

export type A5UploadablePart1DataSchema = InferType<
  typeof a5UploadablePart1DataSchema
>;

export type A5UploadableData = [PartData<A5UploadablePart1DataSchema>];

export const exampleA5UploadableData: ExampleFormData<A5UploadableData> = [
  {
    data: {
      nameAndAddressOfSignee: {
        name: "name",
        address: "Address",
        isConfirmed: true,
        postalCode: "ABC 123",
      },

      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },
      lastSeenDate: "2021-01-01",
      emailAddressOfSignee: "stuart@thalamos.co.uk",
      medicalPractitionersPreviousAcquaintance: true,
      signatureDate: "2021-01-01",
      user: {
        name: "Dr. Sarah Glass",
        address: "143 Fake Street",
        postalCode: "AB1 2CD",
        email: "sarah.glass@houses.com",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: false,
      },
      patient: {
        name: "Bob Smith",
        address: "123 Fake Street",
        postalCode: "AB1 2CD",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
    },
  },
];
