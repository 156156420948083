import {
  T4Data,
  T4Part1DataSchema,
  t4Part1DataSchema,
} from "../schemas/mhaForms/treatment/t4";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { approvedClinicianAccreds } from "../types/user";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const t4Part1Template: FormPartTemplate<T4Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [...approvedClinicianAccreds],
      teamType: [],
      friendlyDescription:
        RequirementFriendlyDescription.approvedClinicianOrRegisteredMedicalPractitioner,
    },
  },

  formBuilderFields: [
    {
      type: "practitioner",
      label: "My email address, name, and address",
      field: "user",
    },
    {
      type: "branch",
      field: "userAccreditation",
      label: "I am",
      branches: [
        {
          fieldValue: "approved-clinician",
          label:
            "the approved clinician in charge of the treatment described below",
          fieldsOwned: [],
          fields: [],
        },
        {
          fieldValue: "registered-medical-practitioner",
          label:
            "a registered medical practitioner appointed for the purposes of Part 4 of the Act (a SOAD)",
          fieldsOwned: [],
          fields: [],
        },
      ],
    },

    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: ["I certify that this patient has attained the age of 18 years. "],
    },
    {
      type: "textbox",
      field: "treatment",
      label:
        "I certify that this patient is capable of understanding the nature, purpose and likely effect of: (Give description of treatment or plan of treatment. Indicate clearly if the certificate is only to apply to any or all of the treatment for a specific period)",
      rows: "5",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: ["I certify that this patient has consented to that treatment."],
    },
  ],
  dataSchema: t4Part1DataSchema,
};

export const t4V1Base: BaseFormTemplate = {
  id: "mha-t4",
  category: "treatment",
  version: "1.0",
  status: "active",
  formName: "T4",
  section: "58A(3)",
  parts: [t4Part1Template],
  description:
    "Certificate of consent to treatment (patients at least 18 years old) ",
  extractGenericData: (data: T4Data) => ({}),
};
