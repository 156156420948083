import { InferType, object, string } from "yup";
import {
  defaultAmhpSchemaFields,
  defaultContextFields,
  hospital,
  isoDateString,
  nonEmptyRequiredString,
  nonEmptyString,
  optionalCheckbox,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

// @ts-ignore
export const cto7Part1DataSchema = object({
  ...defaultContextFields({ approvedClinicianRequired: true }),
  hospital: hospital,

  dateCTOWasMadeOn: generateDateBeforeTodayValidationRule(
    "date",
    "User cannot enter a date in the future.",
  ).required(
    "Please enter the date the current community treatment order was made on",
  ),

  lastExaminedDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter the date you examined the patient on"),

  necessaryForPatientsOwnHealth: optionalCheckbox,
  necessaryForPatientsOwnSafety: optionalCheckbox,
  necessaryForProtectionOfOtherPersons: optionalCheckbox.test(
    "at-least-one-detention-reason-selected",
    "Please select the option(s) which apply",
    (_, { parent }) => {
      return (
        parent.necessaryForPatientsOwnHealth ||
        parent.necessaryForPatientsOwnSafety ||
        parent.necessaryForProtectionOfOtherPersons
      );
    },
  ),

  groundsForOpinion: nonEmptyString
    .required()
    // @ts-ignore
    .nonNullable("Please enter your reasoning"),
});

export const cto7Part2DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,
});

export const cto7Part3DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
    userAddressOptional: true,
    userNameOptional: true,
  }),

  consultedPersonName: nonEmptyRequiredString.required(
    "Please enter the name of the person you consulted",
  ),

  consultedPersonProfession: nonEmptyRequiredString.required(
    "Please enter the profession of the person you consulted",
  ),

  reportFurnishedBy: string()
    .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
    .default(null)
    .required("Please select the option which applies"),
});

export const cto7Part4DataSchema = object({
  ...defaultContextFields({
    nameErrorMessage: "Please enter your print name",
    userAddressOptional: true,
  }),

  reportFurnishedBy: string()
    .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
    .default(null)
    .required("Please select the option which applies"),

  reportReceivedDate: isoDateString.when("reportFurnishedBy", {
    is: (val: string | boolean) => val === "in-person",
    then: () =>
      // @ts-ignore
      generateDateBeforeTodayValidationRule(
        "date",
        "User cannot enter a date in the future.",
      ).required("Please enter date this report was received on"),
    otherwise: () => isoDateString,
  }),
});

export type Cto7Part1DataSchema = InferType<typeof cto7Part1DataSchema>;
export type Cto7Part2DataSchema = InferType<typeof cto7Part2DataSchema>;
export type Cto7Part3DataSchema = InferType<typeof cto7Part3DataSchema>;
export type Cto7Part4DataSchema = InferType<typeof cto7Part4DataSchema>;

export type CTO7Data = [
  PartData<Cto7Part1DataSchema>,
  PartData<Cto7Part2DataSchema>?,
  PartData<Cto7Part3DataSchema>?,
  PartData<Cto7Part4DataSchema>?,
];

export const exampleCto7Data: ExampleFormData<CTO7Data> = [
  {
    data: {
      hospital: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      groundsForOpinion: "Reasons",

      dateCTOWasMadeOn: "1990-01-01",
      lastExaminedDate: '1990-01-01"',

      necessaryForPatientsOwnHealth: true,
      necessaryForPatientsOwnSafety: false,
      necessaryForProtectionOfOtherPersons: false,
    },
  },
  {
    data: {
      user: {
        email: "amhp@amhp.com",
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Another LA",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      consultedPersonName: "Persons Name",
      consultedPersonProfession: "Consulted Profession",
      reportFurnishedBy: "electronic-communication",
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isApprovedClinician: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },
      reportFurnishedBy: "in-person",
      reportReceivedDate: "1990-01-01",
    },
  },
];
