import {
  CTO4Data,
  cto4Part1DataSchema,
  Cto4Part1DataSchema,
} from "../schemas/mhaForms/cto/cto4";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { BaseFormTemplate, FormPartTemplate } from "../types/form";

export const cto4Part1Template: FormPartTemplate<Cto4Part1DataSchema> = {
  signing: {
    userOrTeamRequirement: {
      accreditation: [],
      teamType: ["ward", "mha"],
      friendlyDescription:
        RequirementFriendlyDescription.onBehalfOfResponsibleHospital,
    },
  },

  formBuilderFields: [
    {
      type: "readonly",
      label: "Name and address of patient",
      textFn: (data) => [
        data.patient?.name || "",
        [data.patient?.address, data.patient?.postalCode].join(", "),
      ],
      isReadOnlyPatientInfo: true,
    },
    {
      type: "label",
      showStaticLabel: true,
      label: ["The patient is currently a community patient."],
    },
    {
      type: "hospital-picker",
      field: "hospital",
      label:
        "Enter the name and address of hospital the community patient was detained in",
    },
    {
      type: "label",
      showStaticLabel: true,
      label: [
        "In pursuance of a notice recalling the patient to hospital under section 17E of the Act, the patient was detained in this hospital.",
      ],
    },
    {
      type: "date-time",
      label:
        "Enter date and time at which the patient’s detention in the hospital as a result of the recall notice began",
      field: "patientsDetentionDateTime",
      maximum: (today) => today.endOf("day"),
    },
    {
      type: "practitioner",
      label: "My name",
      field: "user",
      hideAddress: true,
      hideEmail: true,
    },
  ],
  dataSchema: cto4Part1DataSchema,
};

export const cto4V1Base: BaseFormTemplate = {
  id: "mha-cto4",
  category: "cto",
  version: "1.0",
  status: "active",
  formName: "CTO4",
  section: "17E",
  parts: [cto4Part1Template],
  description:
    "Community treatment order: record of patient’s detention in hospital after recall",
  extractGenericData: (data: CTO4Data) => ({}),
};
