import React, { Component, ErrorInfo, ReactNode } from "react";
import { Location } from "react-router-dom";
import { logErrorToDatadog } from "../../../tracing";
import ErrorPage from "../../ErrorPage";

interface ErrorBoundaryProps {
  children: ReactNode;
  location: Location;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logErrorToDatadog(error);
  }

  handleRefresh = (): void => {
    window.location.reload();
  };

  componentDidUpdate(prevProps: ErrorBoundaryProps): void {
    // If the location changes then we should reset the error states
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false, error: null });
    }
  }

  render(): ReactNode {
    return this.state.hasError ? (
      <ErrorPage error={this.state.error} onRefresh={this.handleRefresh} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
