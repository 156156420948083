import { InferType, object } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  generateDateAfterTodayValidationRule,
  nonEmptyString,
} from "../../shared";

export const cto2Part1DataSchema = object({
  ...defaultContextFields({
    approvedClinicianRequired: true,
  }),

  variationEffectiveDate: generateDateAfterTodayValidationRule(
    "date",
    "User cannot enter a date in the past.",
  ).required("Please enter the date the variation is to take effect from"),

  conditionsUnderSection17B: nonEmptyString.required().nonNullable(
    // @ts-ignore
    "Please list the conditions made under section 17B(2) as varied in full or state that there are no longer to be any such conditions",
  ),
});

export type Cto2Part1DataSchema = InferType<typeof cto2Part1DataSchema>;

export type CTO2Data = [PartData<Cto2Part1DataSchema>];

export const exampleCTO2Data: ExampleFormData<CTO2Data> = [
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      variationEffectiveDate: "2021-01-01",

      conditionsUnderSection17B:
        "The conditions made under section 17B(2), as varied, are: (List the conditions as varied in full (including any which are not being varied) or state that there are no longer to be any such conditions)",
    },
  },
];
