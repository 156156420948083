import { UUID } from "../util";
import { Dayjs } from "dayjs";
import { Team } from "./teams";
import {
  applicationTemplateIds,
  medicalRecommendationTemplateIds,
  recordOfDetentionTemplateIds,
  singleMedicalRecordsTemplateIds,
} from "../sections";
import { ObjectSchema } from "yup";
import { ThalamosUser } from "./user";
import { RequirementFriendlyDescription } from "../requirementFriendlyDescription";
import { FormPartContext } from "./formPartContext";

export interface FormIdAndVersion {
  id: string;
  version: string;
}
type FormOrTemplate = FormIdAndVersion | { formTemplate: FormIdAndVersion };

export function formIsCompleted(form: { status: FormStatus }) {
  return COMPLETED_FORM_STATUSES.includes(form.status);
}

export function formIsInProgress(form: { status: FormStatus }) {
  return ["in-progress"].includes(form.status);
}

export type WorkItemMiniForm = Pick<
  Form<any>,
  | "id"
  | "formTemplate"
  | "updated"
  | "created"
  | "status"
  | "ownerTeamId"
  | "linkedForms"
  | "latestVersion"
> & {
  formDraftId?: string;
  versions: Array<{ signatures: Omit<Signature, "base64Image">[] }>;
};

export function formHasSignatures(
  form: Pick<WorkItemMiniForm, "versions" | "latestVersion">,
) {
  return getLatestVersion(form).signatures?.length;
}

export function formIsMedRec(form: FormOrTemplate) {
  const id = "formTemplate" in form ? form.formTemplate?.id : form.id;
  return medicalRecommendationTemplateIds.includes(id);
}

export function formIsSingleMedRec(form: FormOrTemplate) {
  const id = "formTemplate" in form ? form.formTemplate?.id : form.id;
  return singleMedicalRecordsTemplateIds.includes(id);
}

export function formIsApplication(form: FormOrTemplate) {
  const id = "formTemplate" in form ? form.formTemplate?.id : form.id;
  return applicationTemplateIds.includes(id);
}

export function formIsRecordOfDetention(form: FormOrTemplate) {
  const id = "formTemplate" in form ? form.formTemplate?.id : form.id;
  return recordOfDetentionTemplateIds.includes(id);
}

export function getLatestVersion<T>(
  form: Pick<Form<T>, "latestVersion" | "versions">,
): Form<T>["versions"][0];
export function getLatestVersion<T>(
  form?: Pick<Form<T>, "latestVersion" | "versions">,
): Form<T>["versions"][0] | undefined;
export function getLatestVersion<T>(
  form: Pick<FormContextMiniForm, "latestVersion" | "versions">,
): FormContextMiniForm["versions"][0];
export function getLatestVersion<T>(
  form?: Pick<FormContextMiniForm, "latestVersion" | "versions">,
): FormContextMiniForm["versions"][0] | undefined;
export function getLatestVersion<T>(
  form: Pick<WorkItemMiniForm, "latestVersion" | "versions">,
): WorkItemMiniForm["versions"][0];
export function getLatestVersion<T>(
  form?: Pick<WorkItemMiniForm, "latestVersion" | "versions">,
): WorkItemMiniForm["versions"][0] | undefined;
export function getLatestVersion<T>(
  form:
    | Pick<
        Form<T> | WorkItemMiniForm | FormContextMiniForm,
        "latestVersion" | "versions"
      >
    | null
    | undefined,
):
  | Form<T>["versions"][0]
  | WorkItemMiniForm["versions"][0]
  | FormContextMiniForm["versions"][0]
  | null
  | undefined {
  return form?.versions[form.latestVersion - 1];
}

export type MhFormContext = {
  previousPartsFormData: any[];
  previousForms: FormContextMiniForm[];
  linkedForms: ExtendedLinkedForm[];
};

export type SupportingFormsNotProvidedReason = "completedOnPaper" | string;

/*
   TODO: These may not have a signature attached any more and may also not
   be linked to the user who signed. We should think about renaming them
   to something like 'ConfirmingUserDetails'
 */
export type Signature = {
  type: "signature" | "confirmation" | "uploaded-signature";
  base64Image?: string;
  part: number;

  legalSignatureDate: string;
  created: string;
  userName: string;
  userId: UUID;

  // Signatures are always made in a team context so these fields are always defined
  teamName: string;
  teamId: UUID;
  organisationName: string;
  organisationId: string;

  supportingFormsNotProvidedReason?: SupportingFormsNotProvidedReason;
};

export type FormDataAndSignatures<T> = {
  data: T;
  signatures: Signature[];
  fileNotes?: FileNote[];
};

export type AddFileNote = Pick<FileNote, "note"> & {
  note: string;
};

export type FileNotes = {
  fileNotes: FileNote[];
};

export type FormVersionMetadata = {
  formId: UUID;
  version: number;
  status: FormStatus;

  pdfUri?: string;
  requestedReasons?: { label: string; value: string }[];
  requestedAdditionalInfo?: string;
  requestedByUserId?: UUID;
  requestedByTeamId?: UUID;

  created: string;
  updated: string;
};

export type FormVersion<T> = FormVersionMetadata & FormDataAndSignatures<T>;

// The type you use to create a new form version
export type NewFormVersion<T> = Partial<FormVersion<T>> &
  Pick<FormVersion<T>, "formId">;

export type RenderableFormVersion<T> = {
  formId: UUID;
  version: number;
} & FormDataAndSignatures<T>;

export type BaseForm<T> = {
  id: string;
  versions: FormVersion<T>[];
};

export type FieldsOwned<Data extends object> = (keyof Data & string)[];

export type LinkedFormReason =
  | "supports"
  | "acknowledged-by"

  // Converse relationships
  | "supported-by"
  | "acknowledges";

// A map to allow us to easily reverse linked forms, so that if you retrieve a med rec form,
// you can see that it 'supports' an application, and if you retrieve the application you can
// see it is 'supported-by' the med rec. We will only store the 'supports' link in the DB
export const linkedFormLookup: {
  reason: LinkedFormReason;
  inverseReason: LinkedFormReason;

  // 'source' means that the source form is in the 'reason' relation to the destination form
  // i.e. source_form A3 supports destination_form A2 as a med rec.
  type: "source" | "destination";
}[] = [
  { reason: "supports", inverseReason: "supported-by", type: "source" },
  { reason: "supported-by", inverseReason: "supports", type: "destination" },
  { reason: "acknowledges", inverseReason: "acknowledged-by", type: "source" },
  {
    reason: "acknowledged-by",
    inverseReason: "acknowledges",
    type: "destination",
  },
];

// A4/A3 "supports" an A2, A2 is "supported-by" an A3/A4
export type LinkedForm = {
  // The 'destination' form
  id: string;
  reason: LinkedFormReason;
};

export type FormStatus = "in-progress" | "deleted" | "complete" | "finalised";
export const COMPLETED_FORM_STATUSES: FormStatus[] = ["complete", "finalised"];

export type ExtendedLinkedForm = LinkedForm & {
  formTemplate: { id: string; version: string }; // a4 or a3 etc.
  status: FormStatus;
  signatures: Omit<Signature, "base64Image">[];

  // This may be different from root form ID
  // when a form is linked across form contexts
  formContextId: UUID;
  created: string;
  updated: string;
};

export type FormWithoutLinks<T> = BaseForm<T> & {
  formContextId: UUID;
  latestVersion: number;
  hasAmendExpired?: boolean;

  patientId: UUID;
  ownerUserId: UUID;
  ownerTeamId?: UUID;

  formTemplate: FormIdAndVersion;
  status: FormStatus;

  source: "thalamos" | "manual-upload";

  isTrainingForm: boolean;

  // TODO: make regex for URI
  pdfUri?: string;

  created: string;
  updated: string;
};

export type Form<T> = FormWithoutLinks<T> & {
  linkedForms: LinkedForm[];
};

export type FormContextMiniForm = Pick<
  Form<any>,
  | "id"
  | "formTemplate"
  | "updated"
  | "created"
  | "status"
  | "ownerTeamId"
  | "linkedForms"
  | "latestVersion"
  | "versions"
  | "hasAmendExpired"
  | "formContextId"
  | "patientId"
  | "source"
>;

export type SupportingFormsNotSuppliedDetails = {
  type: "not-provided";
  reason: SupportingFormsNotProvidedReason;
};

export type SupportingFormsSuppliedDetails = {
  type: "provided";
  formIds: UUID[];
};

export type SupportingFormsDetails =
  | SupportingFormsNotSuppliedDetails
  | SupportingFormsSuppliedDetails;

export type SignDraftRequest = {
  draftId: UUID;
  signatureBase64?: string;

  supportingFormsDetails?: SupportingFormsDetails;
};

export type FileNote = {
  fileNoteId: UUID;
  note: string;
  createdBy: string;
  userId: string;
  createdByTeamId: string;
  createdAt?: string;
};

export type LabelFormBuilderField = {
  type: "label";
  label: string[];
  showStaticLabel?: boolean;
  showAsBulletPoint?: boolean;
  indent?: number;
  labelType?: "title" | "subtitle";
};

interface HospitalFormFieldValue {
  name: string;
  address: string;
  postalCode: string;
  isConfirmed: boolean;
}
export type HospitalFormBuilderField<Data extends object> = {
  type: "hospital-picker";
  label?: string;
  tooltip?: string;
  afterLabel?: string;
  field: keyof Data & string;
  defaultFn?: (
    data: Partial<Data>,
    context: MhFormContext,
  ) => Partial<HospitalFormFieldValue> | undefined;
  hideAddress?: boolean;
};

export type PractitionerFormBuilderField<Data extends object> = {
  type: "practitioner";
  label: string;
  field: keyof Data & string;
  hideEmail?: boolean;
  hideAddress?: boolean;
  hideName?: boolean;
  hideWarningBanners?: boolean;
};

export type CheckboxFormBuilderField<Data extends object> = {
  type: "confirmation-checkbox";
  field: keyof Data & string;
  label?: string;
  labelBullets?: string[];
  checkboxLabel: string;
};

export type Section12CheckboxFormBuilderField = {
  type: "section-12-confirmation-checkbox";
  checkboxLabel: string;
};

export type ApprovedClinicianCheckboxFormBuilderField = {
  type: "approved-clinician-confirmation-checkbox";
  label: string;

  checkboxLabel: string;
  tooltip: string;
};

export type NameAndAddressPickerFormBuilderField<Data extends object> = {
  type: "name-address";
  nameLabel?: string;
  addressLabel?: string;
  afterLabel?: string;
  field: keyof Data & string;

  disableEmail?: boolean;
  disableAddress?: boolean;
  disableName?: boolean;
};

export type DropdownFormBuilderField<Data extends object> = {
  type: "dropdown";
  label: string;
  afterLabel?: string;
  options: { value: string; label: string }[];
  valueField: keyof Data & string;
  textField: keyof Data & string;
  defaultFn?: (
    data: Partial<Data>,
    context: MhFormContext,
  ) => { value: string; label: string } | undefined;
  isReadOnlyFn?: (data: Partial<Data>, context: MhFormContext) => boolean;
  fieldsOwned?: FieldsOwned<Data>;
};

/*
  Sets the value of each field to true/false based on whether it's selected
 */
export type MultiSelectFormBuilderField<Data extends object> = {
  type: "multi-select";
  label?: string;
  disabled?: boolean;
  indent?: number;
  afterLabel?: string;
  options: { field: keyof Data & string; label: string }[];
};

export type TextboxFormBuilderField<Data extends object> = {
  type: "textbox";
  field: keyof Data & string;
  label?: string;
  afterLabel?: string;
  rows?: string;

  checkboxToShowField?: keyof Data & string;
};

export type DateFormBuilderField<Data extends object> = {
  type: "date";
  field: keyof Data & string;
  label?: string;
  afterLabel?: string;
  minimum?: (today: Dayjs) => Dayjs;
  maximum?: (today: Dayjs) => Dayjs;
  warningFn?: (selectedDate: Dayjs) => string | null;
  disabled?: boolean;
};

export type DateTimeFormBuilderField<Data extends object> = {
  type: "date-time";
  field: keyof Data & string;
  label?: string;
  minimum?: (today: Dayjs) => Dayjs;
  maximum?: (today: Dayjs) => Dayjs;
  showDateTimeMessage?: boolean;
};

export type TimeFormBuilderField<Data extends object> = {
  type: "time";
  field: keyof Data & string;
  label?: string;
  afterLabel?: string;
};

export type ReadonlyFormBuilderField<Data extends object> = {
  type: "readonly";
  label?: string;
  afterLabel?: string;
  valueFn?: (data: Partial<Data>, context: MhFormContext) => string | undefined;
  textFn: (data: Partial<Data>, context: MhFormContext) => string[];
  isReadOnlyPatientInfo?: boolean;
};

export type BranchingFormBuilderField<Data extends object> = {
  type: "branch";
  label?: string;
  indent?: number;
  disabled?: boolean;
  afterLabel?: string;
  field: keyof Data & string;
  branches: {
    fieldValue: string | boolean;
    fields: FormBuilderField<Data>[];
    visibleFields?: FormBuilderField<Data>[];
    label: string;
    hideLabel?: boolean; // e.g. if you're only using one branch, you can hide the label
    fieldsOwned: FieldsOwned<Data>;
  }[];

  warningBanner?: {
    message: string;
    condition: (data: Partial<Data>, context: MhFormContext) => boolean;
  };
};

export type AmhpLocalAuthorityFormBuilderField<Data extends object> = {
  type: "amph-local-authority-picker";
  amActingOnBehalfOfApprovingAuthorityField: keyof Data & string;
  userActingOnBehalfOfField: keyof Data & string;
};

export type LocalAuthorityFormBuilderField<Data extends object> = {
  type: "local-authority-picker";
  label: string;
  field: keyof Data & string;
};

export type FormBuilderField<Data extends object> =
  | LabelFormBuilderField
  | PractitionerFormBuilderField<Data>
  | ReadonlyFormBuilderField<Data>
  | TextboxFormBuilderField<Data>
  | HospitalFormBuilderField<Data>
  | CheckboxFormBuilderField<Data>
  | MultiSelectFormBuilderField<Data>
  | DateFormBuilderField<Data>
  | TimeFormBuilderField<Data>
  | DateTimeFormBuilderField<Data>
  | BranchingFormBuilderField<Data>
  | LocalAuthorityFormBuilderField<Data>
  | AmhpLocalAuthorityFormBuilderField<Data>
  | NameAndAddressPickerFormBuilderField<Data>
  | DropdownFormBuilderField<Data>
  | Section12CheckboxFormBuilderField
  | ApprovedClinicianCheckboxFormBuilderField;

// These are OR requirements on the user or their teams.
// The user must have at least one of the accreditations or
// be a member of a team of one of the specified types
export type UserOrTeamRequirement = {
  requireSendingWhenSigning?: boolean;
  accreditation?: ThalamosUser["accreditations"][0]["type"][];
  teamType?: Team["type"][];
  prohibitedAccreditations?: ThalamosUser["accreditations"][0]["type"][];
  prohibitedTeamTypes?: Team["type"][];

  // Used to describe this requirement in banners in the frontend etc.
  friendlyDescription?: RequirementFriendlyDescription;
};
export type SuccessValidationOutcome = {
  type: "success";
  reason: string | string[];
};
export type ErrorValidationOutcome = {
  type: "error";
  reason: string | string[];
};
export type WarningValidationOutcome = {
  type: "warning";
  reason: string | string[];
  isIgnorable?: boolean;
};
export type ValidationOutcome =
  | SuccessValidationOutcome
  | ErrorValidationOutcome
  | WarningValidationOutcome;
export const errorValidationOutcome = (
  reason: string,
): ErrorValidationOutcome => ({
  type: "error",
  reason,
});
export const warningValidationOutcome = (
  reason: string,
  isIgnorable: boolean = false,
): WarningValidationOutcome => ({
  type: "warning",
  reason,
  isIgnorable,
});
export type FormPartTemplate<DataSchema extends object> = {
  // Requirements around constraints at the point of signing
  signing: {
    // What determines if the user can sign this part? It might be a professional accreditation (e.g. being an AMHP approved
    // by a local authority is generally a requirement for most social care form parts) or it might be a team membership,
    // such as being a 'hospital manager' in order to legally represent a hospital.
    userOrTeamRequirement: UserOrTeamRequirement;

    // For some forms, we only require a user to confirm completion rather than add a signature (e.g. Advance Choice Documents)
    confirmationOnly?: boolean;

    // What forms can be linked to this form at the point of signing? These forms are generally assumed to be a requirement
    // when specified, unless the user explicitly chooses to override them.
    linkableForms?: {
      // What are the template IDs of forms that can be linked to this one (e.g. the different types of medical recommendation
      // for an admission)
      formTemplateIds: string[];

      // for the linkable forms have a boolean in place to check whether to use current form context or not
      source: "form-context" | "patient-forms";

      // this is used for the front-end and decides whether to hide or show specific components
      isBypassable: boolean;

      // should the front-end render a radio button or multi select checkbox
      isMultiSelect: boolean;

      // Should a confirmation documents selection screen (allowing the user to amend their selection)
      // be shown after the form has been filled in
      confirmAfterCompletion: boolean;

      // How do you validate the linked forms provided for this form part?
      checkValidityFn: (
        forms: Pick<
          Form<any>,
          "formTemplate" | "id" | "versions" | "latestVersion" | "source"
        >[],
      ) => ValidationOutcome[];
    };
  };

  formBuilderFields: FormBuilderField<DataSchema>[];

  // What is the shape of the data this form part produces?
  dataSchema: ObjectSchema<DataSchema>;

  // This is rendered when a user signs a form and the popup success message shows
  overrideSignatureSuccessMessage?: string;

  // Automatically create an assignment to the user who signed the specified part of
  // this form. This is useful for forms involving a specific user like an Approved Clinician
  // who will often sign parts 1 and parts 3/4 of a form.
  // One-indexed!!!
  autoAssignToPartSignatory?: number;
};
export type GenericFormData = {
  admissions?: {
    section12ApprovedStatus?: boolean[];
    lastExaminedDate?: string[];
    examinerPreviousAcquaintance?: boolean;
    necessaryForPatientsOwnHealth?: boolean;
    necessaryForPatientsOwnSafety?: boolean;
    necessaryForProtectionOfOtherPersons?: boolean;
    nearestRelativeKnown?: boolean;
    sectionValue?: string;
    personInformedNearestRelative?: boolean;
    amhpLastSeenDate?: string;
    amhpLastSeenDateTime?: string;
    hasNearestRelativeConsultation?: boolean;
    admissionDateTime?: string;
    secondMedRecRecievedDateTime?: string;
    section4H3WithOnlyPart1Completed?: boolean;
    patientWasInHospitalBeforeSectioning?: boolean;
    applicationHospitalName?: string;
    admissionHospitalName?: string;
    admissionHospitalAddress?: string;
    admissionsHospitalPostcode?: string;
  };
  standalone?: {
    hospitalDetained?: string;
    hospitalTransferred?: string;
    hospitalResponsible?: string;
    admissionDateTime?: string;
    dischargeFromDetention?: string;
    stateSectionPatient?: string;
    localSocialServiceAuthority?: string;
    effectiveDateTime?: string;
  };
};
export type BaseFormTemplate = {
  id: string;
  version: string;
  status: "active" | "inactive";
  parts: FormPartTemplate<any>[];
  description: string;
  formName: string;
  category:
    | "admission"
    | "hospital"
    | "guardianship"
    | "misc"
    | "treatment"
    | "cto"
    | "discharge"
    | "miscellaneous";
  section: string;

  // Forms which can be linked at creation
  linkableForms?: { templateId: string; reason: LinkedFormReason }[];

  // Determines whether the form is ready to be "completed" based on the data from the signed form parts
  // If `isComplete` is not set then is considered to be ready to complete if all parts are signed.
  // This function is mostly useful for forms with optional parts that may need to be "completed" without all parts being signed.
  isCompleteFn?: (partData: any) => boolean;

  extractGenericData: (partData: any) => GenericFormData;

  // Used for forms like ACD where we don't want to send to the MHAA team
  autoFinalise?: boolean;
};

export type UploadableBaseFormTemplate = BaseFormTemplate & {
  uploadable: boolean;
  uploadablePartTemplates: FormPartTemplate<any>[];
};

export type PartData<D> = {
  data: D;
  context: FormPartContext;
};

export type ExampleFormData<Type extends Array<any>> = {
  [P in keyof Type]: { data: Type[P]["data"]; context?: any };
};
