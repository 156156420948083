import { boolean, InferType, object, string } from "yup";
import {
  defaultAddress,
  defaultAmhpSchemaFields,
  defaultContextFields,
  hospital,
  nameAndAddress,
  nonEmptyString,
  generateDateBeforeTodayValidationRule,
} from "../../shared";
import { ExampleFormData, PartData } from "../../../types/form";

export const a2Part1DataSchema = object({
  ...defaultContextFields({ localAuthorityRequired: true }),
  ...defaultAmhpSchemaFields,

  hospital: hospital,

  nearestRelativeKnown: boolean()
    .default(null)
    .required("Please confirm if you know who the nearest relative is"),

  nearestRelativeStatement: string()
    .nullable()
    .default("")
    .when("nearestRelativeKnown", {
      is: true,
      then: () => string().required("Please select the option which applies"),
      otherwise: () => string().default(null).nullable(),
    }),

  nearestRelativeOrCountyCourt: boolean()
    .nullable()
    .default(null)
    .when("nearestRelativeStatement", {
      is: (value: string) => value === "person-has-been-authorised",
      then: () => boolean().required("Please select the option which applies"),
      otherwise: () => boolean().default(null).nullable(),
    }),

  nearestRelativeOrAuthorisedPersonInfo: object()
    .optional()
    .when("nearestRelativeKnown", {
      is: true,
      then: () => nameAndAddress({}, true),
      otherwise: (schema) => schema.notRequired(),
    })
    .default({ ...defaultAddress }),

  personInformedNearestRelative: boolean()
    .default(null)
    .when("nearestRelativeKnown", {
      is: true,
      then: (schema) =>
        schema.required(
          "Please confirm if you have informed the patient's nearest relative",
        ),
      otherwise: (schema) => schema.notRequired(),
    }),

  nearestRelativeKnownAct: string()
    .nullable()
    .default("")
    .when("nearestRelativeKnown", {
      is: false,
      then: (schema) => schema.required("Please select the option which apply"),
      otherwise: (schema) => schema.notRequired(),
    }),

  lastSeenDate: generateDateBeforeTodayValidationRule(
    "date",
    "You cannot enter a date and/or time in the future.",
  ).required("Please enter last date you saw the patient on"),

  medicalPractitionersPreviousAcquaintance: boolean()
    .default(null)
    .required(
      "Please confirm if one of the medical practitioners have previous acquaintance with the patient before making their recommendation",
    ),

  noRecommendation: nonEmptyString.when(
    "medicalPractitionersPreviousAcquaintance",
    {
      is: false,
      then: (schema) => schema.required("Please enter your reasoning"),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
});

export type A2Part1DataSchema = InferType<typeof a2Part1DataSchema>;

export type A2Data = [PartData<A2Part1DataSchema>];

export const exampleA2Data: ExampleFormData<A2Data> = [
  {
    data: {
      hospital: {
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
        isConfirmed: true,
      },

      user: {
        name: "AMHP",
        address: "AMHP Address",
        postalCode: "ABC 123",
        email: "david@amhp.com",
        isConfirmed: true,
        isGuest: false,
        isSection12Approved: false,
        localAuthority: "Local Authority",
      },

      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      amActingOnBehalfOfApprovingAuthority: false,
      nameOfLocalAuthorityActingOnBehalfOf: "Other LA",

      nearestRelativeKnown: true,
      nearestRelativeStatement: "best-of-my-knowledge",
      nearestRelativeOrCountyCourt: true,
      personInformedNearestRelative: true,
      nearestRelativeKnownAct: "unable-to-ascertain",

      nearestRelativeOrAuthorisedPersonInfo: {
        name: "name",
        address: "Address",
        isConfirmed: true,
        postalCode: "ABC 123",
      },

      lastSeenDate: "2021-01-01",

      medicalPractitionersPreviousAcquaintance: true,
      noRecommendation: "noRecommendation",
    },
  },
];
